import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";
import { api } from "../../../infra/Api";
import { StyledPanelContainer, StyledPanelContent, StyledPanelHeader, StyledPanelTitle } from "./styles";


export const CancelsPanel = () => {
  const [thisMonthCount, setThisMonthCount] = useState<number>();
  const [nextMonthCount, setNextMonthCount] = useState<number>();

  const fetchCount = useCallback(async () => {
    const  { status, result } = await fetchData();
    setThisMonthCount(result.this_month_count);
    setNextMonthCount(result.next_month_count)
  }, []);

  useEffect(() => { 
    fetchCount()
  }, []);

  return (
    <StyledPanelContainer>
      <StyledPanelContent>
        <StyledRow>
          <StyledPanelTitle>解約予定</StyledPanelTitle>
          <StyledRowTitle>今月</StyledRowTitle>
          <StyledRowValue><StyledNumber>{thisMonthCount?.numberFormat() ?? '-'}</StyledNumber>件</StyledRowValue>
          <StyledRowTitle>来月</StyledRowTitle>
          <StyledRowValue><StyledNumber>{nextMonthCount?.numberFormat() ?? '-'}</StyledNumber>件</StyledRowValue>
        </StyledRow>
      </StyledPanelContent>

    </StyledPanelContainer>
  )
}

const fetchData = async () => {
  const [status, result] = await api.get<any>(
    "/api/v1/contract_expected_cancel/",
  );
  return { status, result };
};

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: solid 1px #eee;
  padding: 1rem 0;
`

const StyledRowTitle = styled.div`
  font-size: 13px;
`

const StyledRowValue = styled.div`
  font-size: 13px;
`

const StyledNumber  = styled.span`
  font-size: 15px;
  font-weight: bold;
  margin-right: 0.2em;
`

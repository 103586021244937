import React, { ChangeEvent, useState } from "react";
import { css } from "@emotion/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RhfInput } from "../parts/form/input/RhfInput";
import { RhfCheckBox } from "../parts/form/checkbox/RhfCheckBox";
import Calendar, { CalendarProps, OnChangeDateCallback } from "react-calendar";
import "react-calendar/dist/Calendar.css";

// images
import parkName from "../../images/parkName.png";
import parkCode from "../../images/parkCode.png";
import numberPlate from "../../images/number.png";
import carCertImage from "../../images/carCert.jpg";
import carLicenseAImage from "../../images/licenseA.png";
import carLicenseBImage from "../../images/licenseB.jpg";
import { RhfTextArea } from "../parts/form/textarea/RhfTextArea";
import {
  auxiliaryStyle,
  calendarStyle,
  checkboxStyle,
  errStyle,
  fileInputStyle,
  fileUploadStyle,
  formSectionStyle,
  formStyle,
  h1Style,
  sampleImageStyle,
  submitStyle,
  textAreaStyle,
  titleStyle,
  uploadedImageStyle,
} from "./NewContractStyles";
import moment from "moment";

// Styles

// validation

const schema = yup
  .object()
  .shape({
    approval: yup
      .boolean()
      .required("上長、または所属長の承認を得た上でチェックをしてください。")
      .isTrue("上長、または所属長の承認を得た上でチェックをしてください。"),
    userName: yup.string().required("入力必須項目です"),
    employeeNumber: yup
      .string()
      .required("入力必須項目です")
      .matches(/^[0-9a-zA-Z]+$/, "半角英数字のみでご入力ください"),
    location: yup.string().required("入力必須項目です"),
    organization: yup.string().required("入力必須項目です"),
    mail: yup.string().email().required("入力必須項目です"),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, "電話番号は半角数字のみでご入力ください")
      .required("入力必須項目です"),
    parkName: yup.string().required("入力必須項目です"),
    parkCode: yup
      .string()
      .required("入力必須項目です")
      .matches(/^[0-9a-zA-Z]+$/, "半角英数字のみでご入力ください"),
    startDate: yup.date().typeError("").required("入力必須項目です"),
    numberOfCar: yup.number().required("入力必須項目です"),
    carMaker: yup.string().required("入力必須項目です"),
    carKind: yup.string().required("入力必須項目です"),
    consent: yup.string().required("入力必須項目です"),
    ownershipState: yup.string().required("入力必須項目です"),
    numberLocal: yup.string(),
    numberKind: yup.string(),
    numberKana: yup.string(),
    numberNumber: yup.string(),
    request: yup.string(),
  })
  .required();

interface NewContractDocument {
  approval: boolean;
  userName: string;
  employeeNumber: string;
  location: string;
  organization: string;
  mail: string;
  phone: string;
  parkName: string;
  parkCode: string;
  startDate: Date;
  numberOfCar: number;
  carMaker: string;
  carKind: string;
  consent: string;
  ownershipState: string;
  numberLocal: string;
  numberKind: string;
  numberKana: string;
  numberNumber: string;
  carCert: string | undefined;
  carLicenseA: string | undefined;
  carLicenseB: string | undefined;
  request: string;
}

export const NewContract = () => {
  const [ownership, setOwnership] = useState<"購入前" | "所有車">("購入前");

  const [carCert, setCarCert] = useState<string | undefined>();
  const [carLicenseA, setCarLicenseA] = useState<string | undefined>();
  const [carLicenseB, setCarLicenseB] = useState<string | undefined>();
  const [startDate, setStartDate] = useState(new Date());
  const [calendarState, setCalendarState] = useState(false);

  const submit = (data: NewContractDocument) => {
    data.carCert = carCert;
    data.carLicenseA = carLicenseA;
    data.carLicenseB = carLicenseB;
    console.log(data);
  };

  const setCalendarDate = (d: Date) => {
    setStartDate(d);
    setCalendarState(false);
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
    register,
  } = useForm<NewContractDocument>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const imageHandler = (
    event: ChangeEvent<HTMLInputElement>,
    imgId: string,
    set: (a: string) => void
  ) => {
    if (event.target.files === null) {
      return;
    }
    const file = event.target.files[0];
    if (file === null) {
      return;
    }
    let imgTag = document.getElementById(imgId) as HTMLImageElement;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result: string = reader.result as string;
      set(result);
      console.log(result);
      imgTag.src = result;
    };
  };

  return (
    <section css={formSectionStyle}>
      <div css={{marginLeft: "2rem"}}>
        <h1 css={h1Style}>月極駐車場　申込・契約申請</h1>
        <form onSubmit={handleSubmit(submit)} css={formStyle}>
          <div>上長承認</div>
          <div css={auxiliaryStyle}>
            月極駐車場の契約について、上長（または拠点長）の承認はとりましたか？
          </div>
          はい
          <RhfCheckBox
            errCss={errStyle}
            placeholder="false"
            name="approval"
            control={control}
            css={checkboxStyle}
            labels={{ value: "approval", name: "" }}
          />
          <div css={titleStyle}>氏名</div>
          <RhfInput
            errCss={errStyle}
            placeholder="お名前"
            name="userName"
            control={control}
            css={textAreaStyle}
            type="text"
          />
          <div css={titleStyle}>従業員番号</div>
          <RhfInput
            errCss={errStyle}
            placeholder="000"
            name="employeeNumber"
            control={control}
            css={textAreaStyle}
            type="text"
          />
          <div css={titleStyle}>所属拠点名</div>
          <RhfInput
            errCss={errStyle}
            placeholder="所属拠点名"
            name="location"
            control={control}
            css={textAreaStyle}
            type="text"
          />
          <div css={titleStyle}>所属組織名</div>
          <RhfInput
            errCss={errStyle}
            placeholder="所属組織名"
            name="organization"
            control={control}
            css={textAreaStyle}
            type="text"
          />
          <div css={titleStyle}>メールアドレス</div>
          <RhfInput
            errCss={errStyle}
            placeholder="example@domain.com"
            name="mail"
            control={control}
            css={textAreaStyle}
            type="text"
          />
          <div css={titleStyle}>電話番号</div>
          <RhfInput
            errCss={errStyle}
            placeholder="090-1234-5678"
            name="phone"
            control={control}
            css={textAreaStyle}
            type="text"
          />
          <div css={titleStyle}>契約希望の駐車場名</div>
          <img src={parkName} alt="park name" css={sampleImageStyle} />
          <RhfInput
            errCss={errStyle}
            placeholder="駐車場名"
            name="parkName"
            control={control}
            css={textAreaStyle}
            type="text"
          />
          <div css={titleStyle}>Park Direct管理コード</div>
          <img src={parkCode} alt="park code" css={sampleImageStyle} />
          <RhfInput
            errCss={errStyle}
            placeholder="0000000"
            name="parkCode"
            control={control}
            css={textAreaStyle}
            type="text"
          />
          <div css={titleStyle}>利用開始希望日</div>
          <div>
            <input
              {...register("startDate")}
              placeholder="20210101"
              css={textAreaStyle}
              type="text"
              value={moment(startDate).format("YYYYMMDD")}
              onFocus={() => setCalendarState(true)}
            />
            <Calendar
              onChange={(d: Date) => setCalendarDate(d)}
              value={startDate}
              css={calendarStyle(calendarState)}
            />
          </div>
          <div css={titleStyle}>契約希望台数</div>
          <RhfInput
            errCss={errStyle}
            placeholder="XXXX"
            name="numberOfCar"
            control={control}
            css={textAreaStyle}
            type="text"
          />
          <div css={titleStyle}>車種（メーカー）</div>
          <RhfInput
            errCss={errStyle}
            placeholder="XXXX"
            name="carMaker"
            control={control}
            css={textAreaStyle}
            type="text"
          />
          <div css={titleStyle}>車種（名称）</div>
          <RhfInput
            errCss={errStyle}
            placeholder="XXXX"
            name="carKind"
            control={control}
            css={textAreaStyle}
            type="text"
          />
          <div css={titleStyle}>保管場所使用承諾書</div>
          <div css={titleStyle}>
            <label>
              <input {...register("consent")} type="radio" value="必要" />
              　希望する
            </label>
          </div>
          <div>
            <label css={titleStyle}>
              <input
                {...register("consent")}
                type="radio"
                value="不要"
                checked
              />
              　希望しない
            </label>
          </div>
          <div css={titleStyle}>保有状況</div>
          <div css={titleStyle}>
            <label>
              <input
                {...register("ownershipState")}
                type="radio"
                value="所有車"
                checked={"所有車" === ownership}
                onChange={() => setOwnership("所有車")}
              />
              　所有車（またはリース利用中）
            </label>
          </div>
          <div>
            <label css={titleStyle}>
              <input
                {...register("ownershipState")}
                type="radio"
                value="購入前"
                checked={"購入前" === ownership}
                onChange={() => setOwnership("購入前")}
              />
              　購入前（またはリース利用前）
            </label>
          </div>
          {/* 所有状況 */}
          {ownership === "所有車" && (
            <>
              <img
                src={numberPlate}
                alt="ナンバープレート"
                css={sampleImageStyle}
              />

              <div css={titleStyle}>登録番号（地域名）</div>
              <RhfInput
                errCss={errStyle}
                placeholder="渋谷"
                name="numberLocal"
                control={control}
                css={textAreaStyle}
                type="text"
              />
              <div css={titleStyle}>登録番号（分類番号3桁）</div>
              <RhfInput
                errCss={errStyle}
                placeholder="789"
                name="numberKind"
                control={control}
                css={textAreaStyle}
                type="text"
              />
              <div css={titleStyle}>登録番号（平仮名等）</div>
              <RhfInput
                errCss={errStyle}
                placeholder="あ"
                name="numberKana"
                control={control}
                css={textAreaStyle}
                type="text"
              />
              <div css={titleStyle}>登録番号（一連指定番号4桁）</div>
              <RhfInput
                errCss={errStyle}
                placeholder="12-34"
                name="numberNumber"
                control={control}
                css={textAreaStyle}
                type="text"
              />
              <div css={titleStyle}>車検証</div>
              <img
                src={carCertImage}
                alt="車検証サンプル"
                css={sampleImageStyle}
              />
              <label css={fileUploadStyle} htmlFor="carCertificate">
                ファイルをアップロード
              </label>
              <input
                id="carCertificate"
                className={""}
                css={fileInputStyle}
                type="file"
                name="carCertificate"
                accept="image/*"
                onChange={(e) => imageHandler(e, "carCertImage", setCarCert)}
              />

              <img css={uploadedImageStyle} id="carCertImage" />
              <div css={titleStyle}>免許証（表）</div>
              <img
                src={carLicenseAImage}
                alt="免許証サンプル（表）"
                css={sampleImageStyle}
              />
              <label css={fileUploadStyle} htmlFor="carLicenseA">
                ファイルをアップロード
              </label>
              <input
                id="carLicenseA"
                className={""}
                css={fileInputStyle}
                type="file"
                name="carLicenseA"
                accept="image/*"
                onChange={(e) =>
                  imageHandler(e, "carLicenseAImage", setCarLicenseA)
                }
              />
              <img css={uploadedImageStyle} id="carLicenseAImage" />
              <div css={titleStyle}>免許証（裏）</div>
              <img
                src={carLicenseBImage}
                alt="免許証サンプル（裏）"
                css={sampleImageStyle}
              />
              <label css={fileUploadStyle} htmlFor="carLicenseB">
                ファイルをアップロード
              </label>
              <input
                id="carLicenseB"
                className={""}
                css={fileInputStyle}
                type="file"
                name="carLicenseB"
                accept="image/*"
                onChange={(e) =>
                  imageHandler(e, "carLicenseBImage", setCarLicenseB)
                }
              />
              <img css={uploadedImageStyle} id="carLicenseBImage" />
              <div>その他ご要望など</div>
              <RhfTextArea
                errCss={errStyle}
                placeholder="ご要望等"
                name="request"
                control={control}
                css={textAreaStyle}
                rows={5}
              />
            </>
          )}
          <div></div>
          <button css={submitStyle} type="submit">
            送信
          </button>
        </form>
      </div>
    </section>
  );
};

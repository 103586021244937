import {ContractDetailFromApi} from "./ContractFromApi";

export type Car = {
    carModel: string;       // 車種
    regnoRegionName: string // 登録番号（地域名）
    regnoClassDigit: string // 登録番号（分類番号3桁）	
    regnoHiragana: string // 登録番号（平仮名等）	
    regnoSeqDigit: string  // 登録番号(一連指定番号4桁)	
    certificate: string // 車検証（URL)
    certificatePath: string // 車検証（URL)
}

export type ParkingUser = {
    name: string;               // 利用者名
    nameKana: string;           // 利用者名(カナ)
    manager: string;            // 担当者名
    employeeNumber: string;         // 従業員番号 
    affiliationBranchName: string;             // 所属拠点名 利用者が所属している支店名
    affiliationBranchNumber: string;             // 所属拠点名 利用者が所属している支店番号
    affiliationDepartmentName: string;         // 所属組織名 利用者が所属している部署名
    affiliationDepartmentNumber: string;         // 所属組織名 利用者が所属している部署番号
    mobilePhoneNumber: string;  // 利用者携帯電話番号
    phoneNumber: string;    // 利用者自宅電話番号
    email: string; // 
}

export type ParkingContractor = {
    name: string;         // 契約者名
    nameKana: string;     // 契約者名(カナ)
    manager: string;        // 担当者
    employeeNumber: string;         // 従業員番号 
    affiliationBranchName: string;             // 所属拠点名 利用者が所属している支店名
    affiliationBranchNumber: string;            // 所属拠点名 利用者が所属している支店番号
    affiliationDepartmentName: string;         // 所属組織名 利用者が所属している部署名
    affiliationDepartmentNumber: string;        // 所属組織名 利用者が所属している部署番号
    email: string;         // 契約者メールアドレス
    phoneNumber: string;  // 契約者電話番号
    companyPhoneNumber: string;  // 契約者会社電話番号
}

export type ContractCondition = {
    taxCategory: number | null;  // 税区分 0:非課税 1:課税
    rentWithTax: string             // 賃料(税込)
    rentWithoutTax: string       // 賃料(税抜)
    rentTax: string // 賃料(消費税額等)
    firstMonthRentWithTax: string       // 初月賃料(税込)
    finalMonthRentWithTax: string       // 最終月賃料(税込)
    firstMonthRentWithoutTax: string       // 初月賃料(税抜)
    finalMonthRentWithoutTax: string       // 最終月賃料(税抜)
    monthlyGuaranteeAndManagementFeeWithTax: string // 月額保証／管理料(税込)
    initialGuaranteeAndManagementFeeWithTax: string // 初回保証管理料(税込)
    firstMonthGuaranteeAndManagementFeeWithTax: string // 初月保証／管理料(税込)
    finalMonthGuaranteeAndManagementFeeWithTax: string // 最終月保証／管理料(税込)
    // updateFeeWithTax: string        // 更新手数料 // TODO 消す
    updateBaseFeeWithTax: string    // 更新料(税込)
    updateManagementFeeWithTax: string  // 更新事務手数料(税込)
    renewalManagementFeeWithTax: string  // 管理更新料(税込)
    depositWithTax: string          // 敷金
    keyMoneyWithTax: string         // 礼金
    administrativeFeeWithTax: string// 事務手数料
    brokerageFeeWithTax: number | null;  // 仲介手数料(税込)
    issuePaperFeeWithTax: string    // 保管場所使用承諾書発行手数料
    contractedDate: Date            // 契約日 
    startDate: Date                 // 契約開始日
    endDate: Date | undefined       // 契約終了日
    firstStartDate: Date            // 初回契約開始日
    desiredCancellationDate: Date | undefined   // 解約希望日
    cancelDate: Date | undefined    // 解約日
    needIssuePaper: boolean         // 保管場所使用承諾証明書を希望するか
    issueDate: Date | undefined     // 保管場所使用承諾証明書の発行月
    paymentMethod: number;          //  -PD -振込 -口座振替 -クレジットカード ⇨ 契約詳細に入れる
    paymentDate: string;             //直近支払い日 ⇨ 契約詳細に入れる
    initialPaymentDate: Date;       // 初回支払日
    contractPeriodMonth: number;
    autoUpdate: boolean;            // 自動更新
    rentalItem: boolean;            // 貸与物有無
    rentalItemContents: string | null;  // 貸与物の内容
    seasonalAdjustment: number | null;  // 季節調整 0:冬季賃料 1:除雪費用 2:その他
    seasonalRentFrom: number | null;  // 季節賃料FROM 1~12
    seasonalRentTo: number | null;  // 季節賃料TO 1~12
    seasonalFeeWithTax: number | null;  // 季節費用(税込)
    proratedRentRefund: number | null;  // 日割賃料返金(税込)
    securityDepositRefund: number | null;  // 敷金返金(非課税)
    initialGuaranteeFeeWithoutTax: number | null;  // 初回保証料(非課税)
    monthlyGuaranteeFeeWithoutTax: number | null;  // 月額保証料(非課税)
}

export type ClientCompany = {
    clientCompanyName: string;      // 管理会社名
    clientStoreName: string;        // 店舗名
    parkingName: string             // 駐車場名
    partition: string | null;          // 区画番号
    partitionType: number | null;      // 区画種別
    partitionImage1: string | null;    // 区画図1
    partitionImage2: string | null;    // 区画図2
    partitionImage3: string | null;    // 区画図3
    address: string | null;            // 住所
    latitude: number | null;           // 緯度
    longitude: number | null;          // 経度
    clientCompanyPostalCode: string | null;  // 管理会社郵便番号
    clientCompanyAddress: string;      // 管理会社住所
    clientCompanyPhoneNumber: string;  // 管理会社電話番号
    clientCompanyFaxNumber: string;    // 管理会社FAX番号
    clientCompanyStaffName: string;    // 担当者名
    clientCompanyStaffEmail: string;        // 担当者メールアドレス
    clientCompanyInvoiceNameOrNumber: string | null; // 管理会社インボイス発行事業者の氏名または名称および登録番号
    clientCompanyInvoiceTaxRate: number | null;      // 管理会社適用税率
    clientCompanyInvoiceReceiverName: string | null; // 管理会社書類の交付を受ける事業者の氏名または名称
    guarantorCompanyName: string | null;  // 保証会社名
    guarantorInvoiceNumber: string | null;  // 保証会社インボイス登録番号
    clientCompanyNote: string;         // 備考
}

export type Lessor  = {
    lessorType: number | null;  // 貸主区分 0:個人 1:法人
    lessorName: string;         // 貸主名
    lessorPostalCode: string | null;  // 貸主郵便番号
    lessorAddress: string;      // 貸主住所
    lessorPhoneNumber: string;  // 貸主電話番号
    lessorFaxNumber: string;    // 貸主FAX番号
    lessorEmail: string;        // 貸主メールアドレス
    lessorInvoiceNameOrNumber: string | null; // 貸主インボイス発行事業者の氏名または名称および登録番号
    lessorInvoiceTaxRate: number | null;      // 貸主適用税率
    lessorInvoiceReceiverName: string | null; // 貸主書類の交付を受ける事業者の氏名または名称
    lessorNote: string;         // 備考
}

export type BrokerCompany = {
    brokerCompanyName: string;          // 仲介会社名
    brokerCompanyPostalCode: string | null;  // 仲介会社名郵便番号
    brokerCompanyAddress: string;       // 仲介会社住所
    brokerCompanyPhoneNumber: string;   // 仲介会社電話番号
    brokerCompanyFaxNumber: string;     // 仲介会社FAX番号
    brokerCompanyEmail: string;         // 仲介会社メールアドレス
    brokerCompanyInvoiceNameOrNumber: string | null; // 仲介会社インボイス発行事業者の氏名または名称および登録番号
    brokerCompanyInvoiceTaxRate: number | null;      // 仲介会社適用税率
    brokerCompanyInvoiceReceiverName: string | null; // 仲介会社書類の交付を受ける事業者の氏名または名称
    brokerCompanyNote: string;          // 備考
}

export type ContractAttribution = {
    other1: string                  // その他1
    other1Memo: string              // その他1_メモ
    other2: string                  // その他2
    other2Memo: string              // その他2_メモ
    other3: string                  // その他3
    other3Memo: string              // その他3_メモ
    memo1: string                   // メモ1
    memo2: string                   // メモ2
    memo3: string                   // メモ3
    usage: string                   // 用途
    noticeMemo1: string             // お知らせ・注意喚起のメモ1
    noticeMemo2: string             // お知らせ・注意喚起のメモ2
    noticeMemo3: string             // お知らせ・注意喚起のメモ3
    initPaymentCompanyName: string,
    initPaymentSendMoneyDate: Date,
    initPaymentBankNumber: string,
    initPaymentBankName: string,
    initPaymentBranchStoreNumber: string,
    initPaymentBranchName: string,
    initPaymentDepositKindType: number,
    initPaymentBankAccountNumber: string,
    initPaymentBankAccountName: string,
    initPaymentSendMoneyAmount: number,
    monthlyPaymentCompanyName: string,
    monthlyPaymentSendMoneyDate: Date,
    monthlyPaymentBankNumber: string,
    monthlyPaymentBankName: string,
    monthlyPaymentBranchStoreNumber: string,
    monthlyPaymentBranchName: string,
    monthlyPaymentDepositKindType: number,
    monthlyPaymentBankAccountNumber: string,
    monthlyPaymentBankAccountName: string,
    monthlyPaymentSendMoneyAmount: number,
}

export type OrganizationInfo = {
    id: string
    name: string;
}

export type ContractDetail = {
    customerId: number | null;             // customer_id
    contractId: number | null;             // contract_id
    organization: string | null;     // 組織
    organizationInfo: OrganizationInfo;  // organizationが複数箇所で使われているため、一旦organizationInfoとして別に定義
    customerCode: string;           // 顧客管理番号
    id: string;                     // pk 
    user: ParkingUser;              // 利用者
    contractor: ParkingContractor;  // 契約者
    carInfo: Car;                   // 契約車両
    lessor: Lessor;                 // 貸主情報
    clientCompany: ClientCompany; // 管理会社情報
    brokerCompany: BrokerCompany;   // 仲介会社情報
    contractKind: number;            // 契約の種類
    paymentBank: string | null;            // 振込先口座
    normalContract: boolean; // 通常契約
    contract: ContractCondition;      // 契約内容
    contractAttribution: ContractAttribution; // 契約付帯情報
    managementState: number;         // TODO: 契約ステータスと同じで良い？ 管理ステータス 
    leaseAgreement: string;  // 賃貸借契約書
    leaseAgreementPath: string;  // 賃貸借契約書
    autoUpdate: boolean;  // 自動更新
    rentalItem: boolean;  // 貸与物有無
    rentalItemContents: string | null;  // 貸与物の内容
    initialProcedureType: number | null;  // 初回契約手続き区分
}

export const convertToContractDetail = (contract: ContractDetailFromApi): ContractDetail => {
    const organizationInfo: OrganizationInfo = {
        id: contract?.organization_info?.id ?? "",
        name: contract?.organization_info?.name ?? ""
    }

    const car: Car = {
        carModel: contract?.contract_vehicle?.car_model ?? "",
        regnoRegionName: contract?.contract_vehicle?.regno_region_name ?? "",
        regnoClassDigit: contract?.contract_vehicle?.regno_class_digit ?? "",
        regnoHiragana: contract?.contract_vehicle?.regno_hiragana ?? "",
        regnoSeqDigit: contract?.contract_vehicle?.regno_seq_digit ?? "",
        certificate: contract?.contract_vehicle?.certificate,
        certificatePath: contract?.contract_vehicle?.certificate_path,
    }

    const parkingUser: ParkingUser = {
        name: contract?.parking_user?.name ?? "",
        nameKana: contract?.parking_user?.name_kana ?? "",
        employeeNumber: contract?.parking_user?.employee_number ?? "",
        affiliationBranchName: contract?.parking_user?.affiliation_branch_name ?? "",
        affiliationDepartmentName: contract?.parking_user?.affiliation_department_name ?? "",
        mobilePhoneNumber: contract?.parking_user?.mobile_phone_number ?? "",
        phoneNumber: contract?.parking_user?.phone_number ?? "",
        email: contract?.parking_user?.email ?? "",
        manager: contract?.parking_user?.manager ?? "",
        affiliationBranchNumber: contract?.parking_user?.affiliation_branch_number ?? "",
        affiliationDepartmentNumber: contract?.parking_user?.affiliation_department_number ?? "",
    }

    const parkingContractor: ParkingContractor = {
        name: contract?.parking_contractor?.name ?? "",
        nameKana: contract?.parking_contractor?.name_kana ?? "",
        email: contract?.parking_contractor?.email ?? "",
        phoneNumber: contract?.parking_contractor?.phone_number ?? "",
        companyPhoneNumber: contract?.parking_contractor?.company_phone_number ?? "",
        employeeNumber: contract?.parking_contractor?.employee_number ?? "",
        affiliationBranchName: contract?.parking_contractor?.affiliation_branch_name ?? "",
        affiliationDepartmentName: contract?.parking_contractor?.affiliation_department_name ?? "",
        manager:  contract?.parking_contractor?.manager ?? "",
        affiliationBranchNumber:  contract?.parking_contractor?.affiliation_branch_number ?? "",
        affiliationDepartmentNumber:  contract?.parking_contractor?.affiliation_department_number ?? "",
    }

    const contractCondition: ContractCondition = {
        taxCategory: contract?.contract_condition?.tax_category ?? null,
        rentWithTax: contract?.contract_condition?.rent_with_tax ?? "",
        firstMonthRentWithTax: contract?.contract_condition?.first_month_rent_with_tax ?? "",
        finalMonthRentWithTax: contract?.contract_condition?.final_month_rent_with_tax ?? "",
        firstMonthRentWithoutTax: contract?.contract_condition?.first_month_rent_without_tax ?? "",
        finalMonthRentWithoutTax: contract?.contract_condition?.final_month_rent_without_tax ?? "",
        rentWithoutTax: contract?.contract_condition?.rent_without_tax ?? "",
        rentTax: contract?.contract_condition?.rent_tax ?? "",
        monthlyGuaranteeAndManagementFeeWithTax: contract?.contract_condition?.monthly_guarantee_and_management_fee_with_tax ?? "",
        initialGuaranteeAndManagementFeeWithTax: contract?.contract_condition?.initial_guarantee_and_management_fee_with_tax ?? "",
        finalMonthGuaranteeAndManagementFeeWithTax: contract?.contract_condition?.final_month_guarantee_and_management_fee_with_tax ?? "",
        firstMonthGuaranteeAndManagementFeeWithTax: contract?.contract_condition?.first_month_guarantee_and_management_fee_with_tax ?? "",
        updateBaseFeeWithTax: contract?.contract_condition?.update_base_fee_with_tax ?? "",
        updateManagementFeeWithTax: contract?.contract_condition?.update_management_fee_with_tax ?? "",
        renewalManagementFeeWithTax: contract?.contract_condition?.renewal_management_fee_with_tax ?? "",
        depositWithTax: contract?.contract_condition?.deposit_with_tax ?? "",
        keyMoneyWithTax: contract?.contract_condition?.key_money_with_tax ?? "",
        administrativeFeeWithTax: contract?.contract_condition?.administrative_fee_with_tax ?? "",
        brokerageFeeWithTax: contract?.contract_condition?.brokerage_fee_with_tax ?? null,
        issuePaperFeeWithTax: contract?.contract_condition?.issue_paper_fee_with_tax ?? "",
        contractedDate: contract?.contract_condition?.contracted_date ?? "",
        firstStartDate: contract?.contract_condition?.first_start_date ?? "",
        startDate: contract?.contract_condition?.start_date ?? "",
        endDate: contract?.contract_condition?.end_date ?? "",
        desiredCancellationDate: contract?.contract_condition?.desired_cancellation_date ?? "",
        cancelDate: contract?.contract_condition?.cancel_date ?? "",
        needIssuePaper: contract?.contract_condition?.need_issue_paper ?? "",
        issueDate: contract?.contract_condition?.issue_date ?? "",
        paymentMethod: contract?.contract_condition?.payment_method ?? "",
        paymentDate: contract?.contract_condition?.payment_date,
        initialPaymentDate: contract?.contract_condition?.initial_payment_date,
        contractPeriodMonth: contract?.contract_condition?.contract_period_month,
        autoUpdate: contract?.auto_update,
        rentalItem: contract?.rental_item,
        rentalItemContents: contract?.rental_item_contents,
        seasonalAdjustment: contract?.contract_condition?.seasonal_adjustment ?? null,
        seasonalRentFrom: contract?.contract_condition?.seasonal_rent_from ?? null,
        seasonalRentTo: contract?.contract_condition?.seasonal_rent_to ?? null,
        seasonalFeeWithTax: contract?.contract_condition?.seasonal_fee_with_tax ?? null,
        proratedRentRefund: contract?.contract_condition?.prorated_rent_refund ?? null,
        securityDepositRefund: contract?.contract_condition?.security_deposit_refund ?? null,
        initialGuaranteeFeeWithoutTax: contract?.contract_condition?.initial_guarantee_fee_without_tax ?? null,
        monthlyGuaranteeFeeWithoutTax: contract?.contract_condition?.monthly_guarantee_fee_without_tax ?? null,
    }

    const clientCompany: ClientCompany = {
        clientCompanyName: contract?.client_company?.client_company_name ?? "",
        clientStoreName: contract?.client_company?.client_store_name ?? "",
        parkingName: contract?.client_company?.parking_name ?? "",
        partition: contract?.client_company?.partition ?? "",
        partitionType: contract?.client_company?.partition_type ?? null,
        address: contract?.client_company?.address ?? "",
        latitude: contract?.client_company?.latitude ?? null,
        longitude: contract?.client_company?.longitude ?? null,
        partitionImage1: contract?.client_company?.partition_image1 ?? "",
        partitionImage2: contract?.client_company?.partition_image2 ?? "",
        partitionImage3: contract?.client_company?.partition_image3 ?? "",
        clientCompanyPostalCode: contract?.client_company?.client_company_postal_code ?? "",
        clientCompanyAddress: contract?.client_company?.client_company_address ?? "",
        clientCompanyPhoneNumber: contract?.client_company?.phone_number ?? "",
        clientCompanyFaxNumber: contract?.client_company?.fax_number ?? "",
        clientCompanyStaffName: contract?.client_company?.staff_name ?? "",
        clientCompanyStaffEmail: contract?.client_company?.staff_email ?? "",
        clientCompanyInvoiceNameOrNumber: contract?.client_company?.invoice_name_or_number ?? "",
        clientCompanyInvoiceTaxRate: contract?.client_company?.invoice_tax_rate ?? null,
        clientCompanyInvoiceReceiverName: contract?.client_company?.invoice_receiver_name ?? "",
        clientCompanyNote: contract?.client_company?.note ?? "",
        guarantorCompanyName: contract?.client_company?.guarantor_company_name ?? "",
        guarantorInvoiceNumber: contract?.client_company?.guarantor_invoice_number ?? "",
    }

    const lessor: Lessor = {
        lessorName: contract?.lessor?.lessor_name ?? "",
        lessorType: contract?.lessor?.lessor_type ?? null,
        lessorPostalCode: contract?.lessor?.postal_code ?? "",
        lessorAddress: contract?.lessor?.address ?? "",
        lessorPhoneNumber: contract?.lessor?.phone_number ?? "",
        lessorFaxNumber: contract?.lessor?.fax_number ?? "",
        lessorEmail: contract?.lessor?.email ?? "",
        lessorInvoiceNameOrNumber: contract?.lessor?.invoice_name_or_number ?? "",
        lessorInvoiceTaxRate: contract?.lessor?.invoice_tax_rate ?? null,
        lessorInvoiceReceiverName: contract?.lessor?.invoice_receiver_name ?? "",
        lessorNote: contract?.lessor?.note ?? "",
    }

    const brokerCompany: BrokerCompany = {
        brokerCompanyName: contract?.broker_company?.broker_company_name ?? "",
        brokerCompanyPostalCode: contract?.broker_company?.postal_code ?? "",
        brokerCompanyAddress: contract?.broker_company?.address ?? "",
        brokerCompanyPhoneNumber: contract?.broker_company?.phone_number ?? "",
        brokerCompanyFaxNumber: contract?.broker_company?.fax_number ?? "",
        brokerCompanyEmail: contract?.broker_company?.email ?? "",
        brokerCompanyInvoiceNameOrNumber: contract?.broker_company?.invoice_name_or_number ?? "",
        brokerCompanyInvoiceTaxRate: contract?.broker_company?.invoice_tax_rate ?? null,
        brokerCompanyInvoiceReceiverName: contract?.broker_company?.invoice_receiver_name ?? "",
        brokerCompanyNote: contract?.broker_company?.note ?? "",
    }

    const contractAttribution: ContractAttribution = {
        other1: contract?.contract_attribution?.other1 ?? "",
        other1Memo: contract?.contract_attribution?.other1_memo ?? "",
        other2: contract?.contract_attribution?.other2 ?? "",
        other2Memo: contract?.contract_attribution?.other2_memo ?? "",
        other3: contract?.contract_attribution?.other3 ?? "",
        other3Memo: contract?.contract_attribution?.other3_memo ?? "",
        noticeMemo1: contract?.contract_attribution?.notice_memo1 ?? "",
        noticeMemo2: contract?.contract_attribution?.notice_memo2 ?? "",
        noticeMemo3: contract?.contract_attribution?.notice_memo3 ?? "",
        memo1: contract?.contract_attribution?.memo1 ?? "",
        memo2: contract?.contract_attribution?.memo2 ?? "",
        memo3: contract?.contract_attribution?.memo3 ?? "",
        usage: contract?.contract_attribution?.usage ?? "",
        initPaymentCompanyName: contract?.contract_attribution?.init_payment_company_name ?? "",
        initPaymentSendMoneyDate: contract?.contract_attribution?.init_payment_send_money_date ?? "",
        initPaymentBankNumber: contract?.contract_attribution?.init_payment_bank_number ?? "",
        initPaymentBankName: contract?.contract_attribution?.init_payment_bank_name ?? "",
        initPaymentBranchStoreNumber: contract?.contract_attribution?.init_payment_branch_store_number ?? "",
        initPaymentBranchName: contract?.contract_attribution?.init_payment_branch_name ?? "",
        initPaymentDepositKindType: contract?.contract_attribution?.init_payment_deposit_kind_type ?? null,
        initPaymentBankAccountNumber: contract?.contract_attribution?.init_payment_bank_account_number ?? "",
        initPaymentBankAccountName: contract?.contract_attribution?.init_payment_bank_account_name ?? "",
        initPaymentSendMoneyAmount: contract?.contract_attribution?.init_payment_send_money_amount ?? "",
        monthlyPaymentCompanyName: contract?.contract_attribution?.monthly_payment_company_name ?? "",
        monthlyPaymentSendMoneyDate: contract?.contract_attribution?.monthly_payment_send_money_date ?? "",
        monthlyPaymentBankNumber: contract?.contract_attribution?.monthly_payment_bank_number ?? "",
        monthlyPaymentBankName: contract?.contract_attribution?.monthly_payment_bank_name ?? "",
        monthlyPaymentBranchStoreNumber: contract?.contract_attribution?.monthly_payment_branch_store_number ?? "",
        monthlyPaymentBranchName: contract?.contract_attribution?.monthly_payment_branch_name ?? "",
        monthlyPaymentDepositKindType: contract?.contract_attribution?.monthly_payment_deposit_kind_type ?? null,
        monthlyPaymentBankAccountNumber: contract?.contract_attribution?.monthly_payment_bank_account_number ?? "",
        monthlyPaymentBankAccountName: contract?.contract_attribution?.monthly_payment_bank_account_name ?? "",
        monthlyPaymentSendMoneyAmount: contract?.contract_attribution?.monthly_payment_send_money_amount ?? "",
    }

    const contractDetail: ContractDetail = {
        customerId: contract?.customer_id,
        contractId: contract?.contract_id,
        organization: contract.organization,
        organizationInfo,
        customerCode: contract?.customer_code ?? "",
        contractAttribution: contractAttribution,
        id: contract.id,
        user: parkingUser,
        contractor: parkingContractor,
        carInfo: car,
        clientCompany: clientCompany,
        lessor,
        brokerCompany,
        contractKind: contract.contract_kind,
        paymentBank: contract.payment_bank,
        normalContract: contract.normal_contract,
        contract: contractCondition,
        managementState: contract.management_state,
        leaseAgreement: contract?.lease_agreement,
        leaseAgreementPath: contract?.lease_agreement_path,
        autoUpdate: contract?.auto_update,
        rentalItem: contract?.rental_item,
        rentalItemContents: contract?.rental_item_contents,
        initialProcedureType: contract?.initial_procedure_type ?? null,
    }

    return contractDetail;
}

import { css } from "@emotion/react";
import { useCallback, useState } from "react"
import { Modal,Button } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { api } from "../../../infra/Api";
import { HttpStatus } from "../../../models/HttpStatus";
import { Organization } from "../../../models/organization";
import { getOrganizations } from "../../../hooks/useOrganizations";
import { FormControl,FormHelperText} from "@mui/material";
import { RSelect } from "../../parts/form/selectbox/SelectBox";

export type ContractOrganizationModalProps = {
    open: boolean,
    contract:any,
    onClose: (isUpdated: boolean) => void,
};

export const ContractOrganizationModal = ({
    open,
    contract,
    onClose,
  }: ContractOrganizationModalProps) => {

    const [isUploading, setIsUploading] = useState(false);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState('0');
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [selectedOrgnaizationError, setSelectedOrgnaizationError] = useState<boolean>(false);
    const [selectedOrgnaizationHelperText, setSelectedOrgnaizationHelperText] = useState<string>('');

    if (organizations == null){
      getOrganizations().then((organizations) => setOrganizations(organizations));
    } 
 
    const onHandleChange = useCallback((value) => {
      console.log("onHandleChange")
      setSelectedOrganizationId(value)
    }, []);

    const setOrganization = useCallback(async () => {
      if (!selectedOrganizationId) return;
      try {
        setIsUploading(true); 
  
        const [res] = await api.put<{ id: string; organization_id: string }, void>(
          `/api/v1/admin/contract_organization/`,
          { id: contract.id, organization_id: selectedOrganizationId},
        );
  
        if (res.status !== HttpStatus.OK) {
          throw new Error('組織のアップデートに失敗しました。');
        }
        setSelectedOrganizationId('0')
        setSelectedOrgnaizationError(false)
        setSelectedOrgnaizationHelperText("");
        onClose(true);

        alert('組織をアップデートしました。')
  
      }catch (error) {
        if (error instanceof Error) {
          setSelectedOrgnaizationError(true)
          setSelectedOrgnaizationHelperText("この項目は必須です");
          alert(error.message);
        } 
      } finally {
        setIsUploading(false);
      }
    }, [contract,selectedOrganizationId]);

    const onCloseFunction = useCallback(async () => {
      setSelectedOrganizationId('0');
      setSelectedOrgnaizationError(false);
      setSelectedOrgnaizationHelperText("");
      onClose(false);
    }, []);
  

    const modalStyle = css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    });
    const modalContentStyle = css({
        backgroundColor: 'white',
        borderRadius: '10px',
        width: '30vw',
        borderWidth: 0,
        padding: '20px',
        maxHeight: '290vh',
    });

    const rowStyle = css({
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '20px',
      gap: '0 2%',
    });
  
    const selectFormStyle = css({
      width: '100%',
    });

    const modalTitleStyle = css({
      color: 'black',
      backgroundColor: '#F6F6F6',
      padding: '10px',
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: "0.5rem",
    });

    const footerButtonsStyle = css({
      textAlign: 'center',
    });

    const footerButtonStyle = css({
      width: '120px',
      marginLeft: '20px',
      marginRight: '20px',
    });

    return (
      <Modal open={open} css={modalStyle}>
        <div css={modalContentStyle}>
          <div css={modalTitleStyle}>組織選択</div>
          <div css={rowStyle}>
            <FormControl css={selectFormStyle}>
              <RSelect
                value={selectedOrganizationId}
                options={organizations?.map((x) => ({value: x.id, label: x.name}))}
                onChangeFunc={onHandleChange}
                isReq={true}
              />
              <FormHelperText error={selectedOrgnaizationError}>{selectedOrgnaizationHelperText}</FormHelperText>
            </FormControl>
          </div>
          <div css={footerButtonsStyle}>
            <LoadingButton variant="contained" onClick={setOrganization} css={footerButtonStyle} loading={isUploading} disabled={isUploading}>保存</LoadingButton>
            <Button variant="text" onClick={() => onCloseFunction()} css={footerButtonStyle} disabled={isUploading}>キャンセル</Button>
          </div>
        </div>
      </Modal>
    )
  }
import { css } from "@emotion/react"
import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { Navigate, useParams } from "react-router";
import { useApiGet } from "../../hooks/useApiGet";
import { useBack } from "../../hooks/useBack";
import { convertToContractDetail, ContractDetail } from "../../models/contract/ContractDetail";
import { convertToPaymentList, PaymentItem } from "../../models/payment/PaymentDetail";
import { ContractDetailFromApi } from "../../models/contract/ContractFromApi";
import { HttpStatus } from "../../models/HttpStatus";
import { extension } from "mime-types";
import { Loading } from "../parts/Loading";
import { api } from "../../infra/Api";
import { ContractDetail as ContractDetailParts } from "../parts/Contract/ContractDetail";

import { getFilenameAndDataUrl } from "../../util";
import { LoadingButton } from '@mui/lab';
import { loggedInUserAtom } from "../../atom/loggedInUserAtom";
import { useRecoilValue } from "recoil";
import React from "react";

const sectionStyle = css({
  width: "100%",
  minWidth: "800px",
});

const titleStyle = css({
  fontWeight: "normal",
  display: "flex",
  margin: "0",
  fontSize: "20px"
});

const organizationStyle = css({
  color: "#777777",
  fontSize: "12px"
});

const iconStyle = css({
  display: "flex",
  background: "transparent linear-gradient(270deg, #1387B6 0%, #0056B0 100%) 0% 0% no-repeat padding-box;",
  width: "40px",
  height: "40px",
  borderRadius: "20px",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "2rem",
});

const backButtonStyle = css({
  border: "none",
  margin: "1rem",
  color: "#007ad9",
  background: "transparent"
});

const titleWrapperStyle = css({
  display: "flex",
  alignItems: "center",
  columnGap: "1rem"
});

const leaseAgreementWrapperStyle = css({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  marginLeft: "auto",
  marginRight: "2rem"
});

const fontStyle = css({
  color: "red",
  fontSize: "14px",
  marginLeft: "2rem",
  marginTop: "0.5rem",
});


const leaseAgreementButtonStyle = (enable: boolean) => css({
  background: enable ? "#0056B0 0% 0% no-repeat padding-box" : "#555",
  borderRadius: "4px",
  padding: "14px 15px",
  color: "white",
  border: "none",
  fontWeight: "bold",
  "&:Link": {
    textDecoration: "none",
    color: "white",
    border: "none",
    fontWeight: "bold",
    background: enable ? "#0056B0 0% 0% no-repeat padding-box" : "#555",
  },
  "&:Visited": {
    textDecoration: "none",
    color: "white",
    border: "none",
    fontWeight: "bold",
    background: enable ? "#0056B0 0% 0% no-repeat padding-box" : "#555",
  },
  "&:hover": {
    opacity: enable ? "0.7" : "1",
    cursor: enable ? "pointer" : "default"
  }
});

const ContractKind = [
  'PD',
  'Agency',
  'Direct',
  'DirectPd',
] as const;

export type ContractDetailContext = {
  reloadContract: () => void,
  reloadPayments: () => void,
}

export const ContractDetailContext = React.createContext<ContractDetailContext>({} as ContractDetailContext)

export const ContractDetailComp = () => {

  const loggedInUser = useRecoilValue(loggedInUserAtom);

  const back = useBack();
  const { id } = useParams();

  const [httpStatus, contract,, reloadContract] = useApiGet<ContractDetailFromApi>(`/api/v1/contract/${id}/`);
  const [httpStatusRenew, payments,, reloadPayments] = useApiGet<[]>(`/api/v1/payments/${id}/`);
 
  const [contractDetail, setContractDetail] = useState<ContractDetail>();
  const [paymentList, setPaymentList] = useState<PaymentItem[]>();

  const [isUploading, setIsUploading] = useState(false);
  const [leaseAgreementFile, setLeaseAgreementFile] = useState<File>();

  useEffect(() => {
    if (contract) {
      setContractDetail(convertToContractDetail(contract));
    }
    if (payments) {
      setPaymentList(convertToPaymentList(payments));
    }
  }, [contract,payments]);

  const downloadFile = useCallback(async (kind: "lease_agreement" | "certificate", id: string) => {
    const [, blob, contentType] = await api.getBlob(`/api/v1/file/${id}/${kind}`);

    if (blob === undefined) {
      return;
    }
    const pdf = new Blob([blob], { type: contentType })
    const link = document.createElement('a')
    const ext = extension(contentType ?? "pdf");
    link.href = (window.URL || window.webkitURL).createObjectURL(pdf)
    link.download = `${kind}.${ext}`
    link.click();
  }, []);

  const lease_agreement = (contractDetail: ContractDetail) => {
    if (contractDetail.leaseAgreement === null && contractDetail.leaseAgreementPath === null) {
      return <div css={leaseAgreementButtonStyle(false)}>賃貸借契約書（PDF）ダウンロード</div>;
    }

    if (contractDetail.leaseAgreement !== null) {
      return <a css={leaseAgreementButtonStyle(true)} target="_blank" rel="noreferrer" href={contractDetail.leaseAgreement}>賃貸借契約書（PDF）ダウンロード</a>

    }

    if (contractDetail.leaseAgreementPath !== null) {
      return <button css={leaseAgreementButtonStyle(true)} onClick={() => downloadFile("lease_agreement", contractDetail.id)}>賃貸借契約書（PDF）ダウンロード</button>

    }
  }

  const upload = useCallback(async () => {
    if (!leaseAgreementFile) return;

    setIsUploading(true);

    try {
      const filenameAndDataUrl = await getFilenameAndDataUrl(leaseAgreementFile);
      const [res, response] = await api.patch<any, ContractDetailFromApi>(
        loggedInUser?.isAdmin
        ? `/api/v1/admin/contract/${contractDetail?.id}/`
        : `/api/v1/contract/${contractDetail?.id}/`
        ,{
          lease_agreement_file: filenameAndDataUrl,
        });

      if (res.status === HttpStatus.OK && response) {
        setLeaseAgreementFile(undefined);
        reloadContract();
      } else {
        alert(JSON.stringify(response, null, 2));
      }
    } finally {
      setIsUploading(false);
    }
  }, [leaseAgreementFile]);


  const onChangeLeaseAgreementFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setLeaseAgreementFile(event.target.files?.[0]);
  }, []);

  const onClickUpload = useCallback(() => {
    upload();
  }, [upload]);

  const isDisplayFileChooser = useMemo(() => {
    const contractKind = contractDetail ? ContractKind[contractDetail.contractKind] : undefined;
    const isAgencyAdmin = contractKind === 'Agency' && loggedInUser?.isAdmin;

    if (isAgencyAdmin ) return true;
    if (contractKind === 'DirectPd' && loggedInUser?.isAdmin) return true;
    if (contractKind === 'Direct' && !loggedInUser?.isReadOnly) return true;

    return false;
  }, [contractDetail, loggedInUser]);

  const [isWarningPayment, setWarningPayment] = useState(false);
  let notifyPaymentChange = function(){
    setWarningPayment(true);
  };


  if (httpStatus.status === HttpStatus.UNAUTHORIZED || !loggedInUser) {
    return <Navigate to="/login" />
  }

  if (httpStatus.status !== HttpStatus.OK || !contract  || !contractDetail || !paymentList) {
    return <Loading />
  }

  return (
    <ContractDetailContext.Provider value={{ reloadContract, reloadPayments }}>
      <section css={sectionStyle}>
        <button css={backButtonStyle} onClick={back}>{"◁ 戻る"}</button>
        <div css={titleWrapperStyle}>
          <div css={iconStyle}><FontAwesomeIcon color="#FFF" icon={faList} /></div>
          <div>
            <div>
              <h2 css={titleStyle} >
                契約ID: {contractDetail.id} {isWarningPayment &&<span css={fontStyle}>決済情報の変更がありました。各契約情報のご確認をお願いいたいします。</span>}
              </h2>
              <h2 css={titleStyle} >
                利用者: {contractDetail.user.name}
              </h2>
            </div>
            <div css={organizationStyle}>
              企業ID: {contract.organization}
            </div>
          </div>
          <div css={leaseAgreementWrapperStyle}>
            {lease_agreement(contractDetail)}
            {isDisplayFileChooser && (
              <>
                <input type="file" accept="image/*,.pdf" onChange={onChangeLeaseAgreementFile} />
                {leaseAgreementFile &&
                  <LoadingButton variant="contained" onClick={onClickUpload} loading={isUploading} disabled={isUploading}>アップロード</LoadingButton>
                }
              </>
            )}
          </div>
        </div>
        <ContractDetailParts contractDetail={contractDetail} paymentList={paymentList} notifyPaymentChange={notifyPaymentChange}/>
      </section>
    </ContractDetailContext.Provider>
  )
}
import { useCallback, useEffect, useState } from "react"
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { api } from "../../../infra/Api";
import { HttpStatus } from "../../../models/HttpStatus";
import { Button, Checkbox, FormControlLabel, Modal, Snackbar, Typography } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { CSVExportSetting } from "../../../models/CSVExportSetting";
import { SimpleDialog } from "../SimpleDialog";
import { CsvColumn, LocalizedValueLabels } from "./forDirect/types";

export type CSVExportSettingModalProps = {
  isVisible: boolean,
  onClose: () => void,
};

export const CSVExportSettingModal = ({
  isVisible,
  onClose,
}: CSVExportSettingModalProps) => {

  const [selectedCsvKeys, setSelectedCsvKeys] = useState<Set<CsvColumn>>();
  const [autoAddNewItems, setAutoAddNewItems] = useState<boolean>(false);
  const [isSaveRequesting, setIsSaveRequesting] = useState<boolean>(false);
  const [snackData, setSnackData] = useState<{ message: string; timeout?: number }>();
  const [fetchError, setFetchError] = useState(false);

  const unselectedCsvKeys = useCallback((checkedCsvKeys: CsvColumn[]) => (Object.keys(LocalizedValueLabels) as CsvColumn[]).filter(key => !checkedCsvKeys.includes(key)), []);

  const getCSVExportSetting = useCallback(async () => {
    setFetchError(false);

    const [res, response] = await api.get<CSVExportSetting>('/api/v1/csv_export_setting/');
  
    if (res.status === HttpStatus.OK && response) {
      const {
        auto_add_new_items: autoAddNewItems,
        configured_items: configuredItems,
      } = response;

      setAutoAddNewItems(autoAddNewItems);

      if (autoAddNewItems) {
        setSelectedCsvKeys(new Set(unselectedCsvKeys(configuredItems)));
      } else {
        setSelectedCsvKeys(new Set(configuredItems));
      }
    } else {
      setFetchError(true);
    }  
  }, [unselectedCsvKeys]);

  const saveCSVExportSetting = useCallback(async () => {
    if (!selectedCsvKeys) return;

    setIsSaveRequesting(true);

    const configuredItems = autoAddNewItems ? unselectedCsvKeys(Array.from(selectedCsvKeys)) : Array.from(selectedCsvKeys);

    const [res] = await api.put<CSVExportSetting, void>('/api/v1/csv_export_setting/', {
      auto_add_new_items: autoAddNewItems,
      configured_items: configuredItems,
    });

    if (res.status === HttpStatus.OK || res.status === HttpStatus.CREATED) {
      onClose();
      setSnackData({ message: '設定を保存しました' });
    }

    setIsSaveRequesting(false);
  }, [autoAddNewItems, onClose, selectedCsvKeys, unselectedCsvKeys]);

  const onChangeSelectedKey = useCallback((key: CsvColumn) => {
    setSelectedCsvKeys(prev => {
      const newSet = new Set(prev);
      if (newSet.has(key)) {
        newSet.delete(key);
      } else {
        newSet.add(key);
      }
      return newSet;
    });
  }, []);

  useEffect(() => {
    if (!isVisible) return;
    getCSVExportSetting();
  }, [isVisible, getCSVExportSetting]);

  return (
    <>
      <StyledModal open={isVisible}>
        <StyledModalContent>
          <StyledModalTitle>CSVエクスポート項目設定</StyledModalTitle>
          {!!selectedCsvKeys && (
            <StyledItems>
              {(Object.keys(LocalizedValueLabels) as CsvColumn[]).map(key => (
                <div key={key}>
                  <FormControlLabel
                    label={<StyledCheckBoxLabel>{LocalizedValueLabels[key]}</StyledCheckBoxLabel>}
                    control={<StyledCheckBox checked={selectedCsvKeys.has(key)} onChange={() => onChangeSelectedKey(key) } />}
                    css={ItemRowStyle}
                  />
                </div>
              ))}
            </StyledItems>
          )}
          <StyledFooter>
            <FormControlLabel
              css={ItemRowStyle}
              label={<StyledCheckBoxLabel>新しく追加された項目に自動でチェックを入れる</StyledCheckBoxLabel>}
              control={
                <StyledCheckBox
                  checked={autoAddNewItems}
                  value={autoAddNewItems}
                  onChange={() => { setAutoAddNewItems(!autoAddNewItems) }}
                />}
            />
            <div>
              <LoadingButton variant="contained" onClick={saveCSVExportSetting} css={FooterButtonStyle} loading={isSaveRequesting} disabled={isSaveRequesting}>保存</LoadingButton>
              <Button variant="text" onClick={() => onClose()} css={FooterButtonStyle}>キャンセル</Button>
            </div>
          </StyledFooter>
        </StyledModalContent>
      </StyledModal>

      <SimpleDialog
        open={fetchError}
        title="エラー"
        message="CSVエクスポート設定の読み込みに失敗しました。"
        buttons={[
          { label: '再読み込み', onClick: () => getCSVExportSetting() },
          { label: 'キャンセル', onClick: () => {
            setFetchError(false);
            onClose();
          } },
        ]}
      />

      <Snackbar
          open={!!snackData}
          autoHideDuration={snackData?.timeout ?? 2000}
          onClose={() => {
            setSnackData(undefined);
          }}
          message={snackData?.message}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
    </>
  )
}

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModalContent = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
`;

const StyledModalTitle = styled.div`
  color: black;
  background-color: #F6F6F6;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
`;

const StyledItems = styled.div`
  overflow: scroll;
`;

const ItemRowStyle = css`
  margin-bottom: 8px;
`;

const StyledFooter = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const FooterButtonStyle = css`
  width: 120px;
  margin-left: 20px;
  margin-right: 20px;
`;

const StyledCheckBox = styled(Checkbox)`
  width: 18px;
  height: 18px;
  margin-left: 12px;
  margin-right: 6px;
`;

const StyledCheckBoxLabel = styled(Typography)(({ disabled } : { disabled?: boolean }) => ({
  fontSize: "13px",
  color: disabled ? "rgba(0, 0, 0, 0.6)" : "#222222",
}));

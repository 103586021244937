import { css } from "@emotion/react";
import { Navigate, useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../../atom/loggedInUserAtom";
import { api } from "../../infra/Api";
import { usePaymentQuery } from "../../hooks/usePaymentQuery";
import { useContractQuery } from "../../hooks/useContractQuery";
import { useOrganizationContractQuery } from "../../hooks/useOrganizationContractQuery";

export const Header = () => {

  const user = useRecoilValue(loggedInUserAtom);

  const navigate = useNavigate();

  const { reset: resetPayment } = usePaymentQuery()
  const { reset: resetContract } = useContractQuery()
  const { reset: resetOrganizationContract } = useOrganizationContractQuery()


  const logout = () => {
    const securePathId = user?.organization?.securePathId
    const loginPath = securePathId ? `/${securePathId}/login` : '/login'

    api.logout();
    navigate(loginPath);
    resetPayment()
    resetContract()
    resetOrganizationContract()
  };

  const headerStyle = css({
    width: "100%",
    height: "100%",
    textAlign: "right",
    alignItems: "center",
    boxShadow: "0 3px 5px #f0f0f0",
    minWidth: "800px"
  });

  const userStyle = css({
    padding: "1rem",
    position: "relative",
    right: 0,
  });

  const buttonStyle = css({
    border: "none",
    borderLeft: "solid 1px #CDCDCD",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginTop: "0.5rem",
    background: "white",
    fontSize: "13px",
    color: "#0056B0",
    borderRadius: "4px",
    transition: "ease-in-out 0.2s all",
    "&:hover": {
      background: "#0351a1",
      color: "white"
    }
  });

  const userNameStyle = css({
    marginRight: "1rem",
    fontSize: "13px"
  });

  if (!user) {
    return <Navigate to="/login" />
  }

  return (
    <div css={headerStyle}>
      <div css={userStyle}>
        <span css={userNameStyle}>{user.email}</span>
        <button css={buttonStyle} type="button" onClick={logout}>
          ログアウト
        </button>
      </div>
    </div>
  )
};

import { useCallback, useEffect, useState } from "react";
import { api } from "../infra/Api";
import { HttpStatus } from "../models/HttpStatus";
import { User, UserFromApi } from "../models/User";

// TODO: 原因を探る
// eslint-disable-next-line no-shadow
export enum LoginState {
  UnFetched,
  Error,
  LoggedIn,
}

export const useUser = (): [LoginState, User | undefined] => {
  const [loginState, setLoginState] = useState<LoginState>(LoginState.UnFetched);
  const [userState, setUserState] = useState<User>();

  const getUser = useCallback(async () => {
    const [httpsState, user] = await api.get<UserFromApi>("/api/v1/user/");

    if (httpsState.status === HttpStatus.OK && user !== undefined) {
      setLoginState(LoginState.LoggedIn);
      setUserState(User.fromApi(user));
    } else {
      setLoginState(LoginState.Error);
      setUserState(undefined);  
    }
  }, []);

  useEffect(() => {
    loginState === LoginState.UnFetched && getUser();
  }, [loginState, getUser]);

  return [loginState, userState];
};
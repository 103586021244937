import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { api } from "../../../infra/Api";
import { HttpStatus } from "../../../models/HttpStatus";
import { ContractDetail } from "../../../models/contract/ContractDetail";
import { FormControlLabel, Checkbox, Typography, Button, MenuItem, Modal, Select, TextField, InputLabel, FormControl } from "@mui/material";
import { contractKindList, managementState as ManagementStatus, partition, paymentMethod, depositKind, initialProcedureType as InitialProcedureType, lessortType as LessortType, taxCategory as TaxCategory, seasonalAdjustment as SeasonalAdjustment} from "../../../constant";
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { getFilenameAndDataUrl } from "../../../util";
import { ContractDetailEditFormType } from "./ContractDetailEditFormType";
import moment from "moment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ja } from 'date-fns/locale';
import { FieldError, useForm, UseFormRegisterReturn } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toSnakeCaseKeys } from "../../../util/toScakeCase";
import { filterDataForForm } from "../../../util/filterFormData";

export type ContractDetailEditModalProps = {
  formType?: ContractDetailEditFormType,
  onClose: (isUpdated: boolean) => void,
  contractDetail: ContractDetail,
  isAdmin?: boolean,
};

const ContractKind = [
  'PD',
  'Agency',
  'Direct', // 直接契約/直接支払い
  'DirectPd', // 直接契約/PD支払い
] as const;

const StyledTextField = styled(TextField)<{ link?: boolean }>`
  width: 49%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledTextField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};

const StyledFileField = styled(TextField)<{ link?: boolean }>`
  width: 100%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledFileField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};

const StyledCheckBoxLabel = styled(Typography)(({ disabled } : { disabled?: boolean }) => ({
  fontSize: "13px",
  color: disabled ? "rgba(0, 0, 0, 0.6)" : "#222222",
}));

const StyledCheckBox = styled(Checkbox)({
  width: "18px",
  height: "18px",
  marginLeft: "12px",
  marginRight: "6px",
});

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

export const ContractDetailEditModal = ({
  formType,
  onClose,
  contractDetail,
  isAdmin,
}: ContractDetailEditModalProps) => {
  const [isUploading, setIsUploading] = useState(false);

  const [certificateFile, setCertificateFile] = useState<[string,string]>();
  const [partitionImage1File, setPartitionImage1File] = useState<[string,string]>();
  const [partitionImage2File, setPartitionImage2File] = useState<[string,string]>();
  const [partitionImage3File, setPartitionImage3File] = useState<[string,string]>();

  const defaultValues = useMemo(() => filterDataForForm<FormData>(toSnakeCaseKeys(contractDetail), formSchema), [contractDetail]);

  const { register, getValues, clearErrors, setError, formState: { errors }, reset, watch, setValue } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onTouched',
    defaultValues,
  });

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  useEffect(() => {
    if (isMounted) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, formType]);

  const onChangeCertificateFile = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const results = await getFilenameAndDataUrl(event.target.files?.[0]);
    if (results) {
      setCertificateFile(results);
      console.log(results);
    }
  }, []);

  const onChangePartitionImageFile = useCallback((number: 1 | 2 | 3) => async (event: ChangeEvent<HTMLInputElement>) => {
    const results = await getFilenameAndDataUrl(event.target.files?.[0]);
    if (results) {
      if (number === 1) {
        setPartitionImage1File(results);
      } else if (number === 2) {
        setPartitionImage2File(results);
      } else if (number === 3) {
        setPartitionImage3File(results);
      }
    }
  }, []);

  const contractKind = useMemo(() => ContractKind[contractDetail.contractKind], [contractDetail]);

  const { isPdAdmin, isPdUser, isAgencyAdmin, isAgencyUser, isDirectAdmin, isDirectUser, isDirectPdUser} = useMemo(() => ({
    isPdAdmin: contractKind === 'PD' && isAdmin,
    isPdUser: contractKind === 'PD' && !isAdmin,
    isAgencyAdmin: contractKind === 'Agency' && isAdmin,
    isAgencyUser: contractKind === 'Agency' && !isAdmin,
    isDirectAdmin: ((contractKind === 'Direct') || (contractKind === 'DirectPd')) && isAdmin,
    isDirectUser: contractKind === 'Direct' && !isAdmin,
    isDirectPdUser: contractKind === 'DirectPd' && !isAdmin,
  }), [isAdmin, contractKind]);

  const updateContract = useCallback(async () => {
    try {
      setIsUploading(true);

      const values = getValues();

      const {
        management_state,
        initial_procedure_type,
        auto_update,
        rental_item,
        rental_item_contents,
        payment_bank,
        normal_contract,
        user: parking_user,
        contractor: parking_contractor,
        client_company,
        lessor,
        broker_company,
        contract_attribution,
        contract: contract_condition,
        car_info: contract_vehicle,
      } = values;

      clearErrors();

      const updatedContract = (() => {
        switch (formType) {
          // 基本情報
          case 'basic_information':
            if (isPdAdmin) {
              return {
                initial_procedure_type,
              }
            }
            if (isPdUser || isAgencyUser || isDirectPdUser) return {};
            return {
              management_state,
              auto_update,
              initial_procedure_type,
              contract_condition: {
                contracted_date: contract_condition.contracted_date ? contract_condition.contracted_date : null,
                cancel_date: contract_condition.cancel_date ? moment(contract_condition.cancel_date).format('YYYY-MM-DD') : null,
                need_issue_paper: contract_condition.need_issue_paper,
                issue_date: contract_condition.issue_date ? moment(contract_condition.issue_date).format("YYYY-MM-01") : null,
                contract_period_month: contract_condition.contract_period_month,
                first_start_date: contract_condition.first_start_date ? moment(contract_condition.first_start_date).format('YYYY-MM-DD') : null,
                start_date: contract_condition.start_date ? moment(contract_condition.start_date).format('YYYY-MM-DD') : null,
                end_date: contract_condition.end_date ? moment(contract_condition.end_date).format('YYYY-MM-DD') : null,
                initial_payment_date: contract_condition.initial_payment_date ? moment(contract_condition.initial_payment_date).format('YYYY-MM-DD') : null,
              },
              ...(isAdmin ? { normal_contract } : {}),
            };
          // 利用者情報
          case 'user_information':
            if (isAgencyAdmin || isDirectAdmin || isDirectPdUser || isDirectUser) {
              return {
                parking_user: {
                  name: parking_user.name,
                  name_kana: parking_user.name_kana,
                  manager: parking_user.manager,
                  email: parking_user.email,
                  mobile_phone_number: parking_user.mobile_phone_number,
                  phone_number: parking_user.phone_number,
                  employee_number: parking_user.employee_number,
                  affiliation_branch_name: parking_user.affiliation_branch_name,
                  affiliation_branch_number: parking_user.affiliation_branch_number,
                  affiliation_department_name: parking_user.affiliation_department_name,
                  affiliation_department_number: parking_user.affiliation_department_number,
                }
              };
            }
            return {
              parking_user: {
                employee_number: parking_user.employee_number,
                affiliation_branch_name: parking_user.affiliation_branch_name,
                affiliation_branch_number: parking_user.affiliation_branch_number,
                affiliation_department_name: parking_user.affiliation_department_name,
                affiliation_department_number: parking_user.affiliation_department_number,
            }
            };
          // 契約者情報
          case 'contractor_information':
            if (isAgencyAdmin || isDirectAdmin || isDirectUser) {
              return {
                parking_contractor: {
                  name: parking_contractor.name,
                  name_kana: parking_contractor.name_kana,
                  manager: parking_contractor.manager,
                  email: parking_contractor.email,
                  phone_number: parking_contractor.phone_number,
                  company_phone_number: parking_contractor.company_phone_number,
                  employee_number: parking_contractor.employee_number,
                  affiliation_branch_name: parking_contractor.affiliation_branch_name,
                  affiliation_branch_number: parking_contractor.affiliation_branch_number,
                  affiliation_department_name: parking_contractor.affiliation_department_name,
                  affiliation_department_number: parking_contractor.affiliation_department_number,
                }
              };
            }
            return {
              parking_contractor: {
                employee_number: parking_contractor.employee_number,
                affiliation_branch_name: parking_contractor.affiliation_branch_name,
                affiliation_branch_number: parking_contractor.affiliation_branch_number,
                affiliation_department_name: parking_contractor.affiliation_department_name,
                affiliation_department_number: parking_contractor.affiliation_department_number,
              }
            };
          // 管理会社情報
          case 'management_company_information':
            if (isAgencyAdmin || isDirectAdmin || isDirectUser) {
              return {
                client_company: {
                  client_company_name: client_company.client_company_name,
                  client_store_name: client_company.client_store_name,
                  client_company_postal_code: client_company.client_company_postal_code,
                  client_company_address: client_company.client_company_address,
                  phone_number: client_company.client_company_phone_number,
                  fax_number: client_company.client_company_fax_number,
                  staff_name: client_company.client_company_staff_name,
                  staff_email: client_company.client_company_staff_email,
                  invoice_name_or_number: client_company.client_company_invoice_name_or_number,
                  invoice_tax_rate: client_company.client_company_invoice_tax_rate,
                  invoice_receiver_name: client_company.client_company_invoice_receiver_name,
                  guarantor_company_name: client_company.guarantor_company_name,
                  guarantor_invoice_number: client_company.guarantor_invoice_number,
                  note: client_company.client_company_note,
                },
              };
            }
            if (isPdAdmin) {
              return {
                client_company: {
                  client_company_postal_code: client_company.client_company_postal_code,
                  invoice_tax_rate: client_company.client_company_invoice_tax_rate,
                  invoice_receiver_name: client_company.client_company_invoice_receiver_name,
                  guarantor_company_name: client_company.guarantor_company_name,
                  guarantor_invoice_number: client_company.guarantor_invoice_number,
                },
              };
            }
            return {};

          // 貸主情報
          case 'lessor':
            if (isAgencyAdmin || isDirectAdmin || isDirectUser) {
              return {
                lessor: {
                  lessor_type: lessor.lessor_type,
                  lessor_name: lessor.lessor_name,
                  postal_code: lessor.lessor_postal_code,
                  address: lessor.lessor_address,
                  phone_number: lessor.lessor_phone_number,
                  fax_number: lessor.lessor_fax_number,
                  email: lessor.lessor_email,
                  invoice_name_or_number: lessor.lessor_invoice_name_or_number,
                  invoice_tax_rate: lessor.lessor_invoice_tax_rate,
                  invoice_receiver_name: lessor.lessor_invoice_receiver_name,
                  note: lessor.lessor_note,
                },
              };
            }
            if (isPdAdmin) {
              return {
                lessor: {
                  lessor_type: lessor.lessor_type,
                  postal_code: lessor.lessor_postal_code,
                  invoice_tax_rate: lessor.lessor_invoice_tax_rate,
                  invoice_receiver_name: lessor.lessor_invoice_receiver_name,
                }
              }
            }
            return {}

          // 仲介会社情報
          case 'broker_company':
            if (isAgencyAdmin || isDirectAdmin || isDirectUser) {
              return {
                broker_company: {
                  broker_company_name: broker_company.broker_company_name,
                  postal_code: broker_company.broker_company_postal_code,
                  address: broker_company.broker_company_address,
                  phone_number: broker_company.broker_company_phone_number,
                  fax_number: broker_company.broker_company_fax_number,
                  email: broker_company.broker_company_email,
                  invoice_name_or_number: broker_company.broker_company_invoice_name_or_number,
                  invoice_tax_rate: broker_company.broker_company_invoice_tax_rate,
                  invoice_receiver_name: broker_company.broker_company_invoice_receiver_name,
                  note: broker_company.broker_company_note,
                },
              };
            }
            if (isPdAdmin) {
              return {
                broker_company: {
                  postal_code: broker_company.broker_company_postal_code,
                  invoice_name_or_number: broker_company.broker_company_invoice_name_or_number,
                  invoice_tax_rate: broker_company.broker_company_invoice_tax_rate,
                  invoice_receiver_name: broker_company.broker_company_invoice_receiver_name,
                }
              }
            }
            return {}

          // 駐車場情報
          case 'parking_information':
            if (isAgencyAdmin || isDirectAdmin || isDirectUser) {
              return {
                client_company: {
                  parking_name: client_company.parking_name,
                  partition: client_company.partition,
                  partition_type: client_company.partition_type,
                  address: client_company.address,
                  latitude: client_company.latitude,
                  longitude: client_company.longitude,
                  partition_image1: partitionImage1File,
                  partition_image2: partitionImage2File,
                  partition_image3: partitionImage3File,
                },
                rental_item: rental_item,
                rental_item_contents: rental_item ? rental_item_contents : '',
              };
            }
            if (isPdAdmin) {
              return {
                rental_item_contents: rental_item ? rental_item_contents : '',
              };
            }
            return {};
          // 契約車両情報
          case 'vehicle_information':
            if (isAgencyAdmin || isDirectAdmin || isDirectPdUser || isDirectUser) {
              return {
                contract_vehicle: {
                  car_model: contract_vehicle.car_model,
                  regno_region_name: contract_vehicle.regno_region_name,
                  regno_class_digit: contract_vehicle.regno_class_digit,
                  regno_hiragana: contract_vehicle.regno_hiragana,
                  regno_seq_digit: contract_vehicle.regno_seq_digit,
                  certificate_file: certificateFile,
                }
              };
            }
            if (isPdAdmin) {
              return {
                contract_vehicle: {
                  certificate_file: certificateFile,
                },
              };
            }
            return {};
          // お知らせ・注意喚起のメモ
          case 'notice_memo':
            if (isAdmin) {
              return {
                contract_attribution: {
                  notice_memo1: contract_attribution.notice_memo1,
                  notice_memo2: contract_attribution.notice_memo2,
                  notice_memo3: contract_attribution.notice_memo3,
                }
              };
            }
            return {};
          // 備考
          case 'note':
            return {
              contract_attribution: {
                memo1: contract_attribution.memo1,
                memo2: contract_attribution.memo2,
                memo3: contract_attribution.memo3,
                usage: contract_attribution.usage,
              }
            };
          // 備考
          case 'init_payment':
            return {
              contract_attribution: {
                init_payment_company_name: contract_attribution.init_payment_company_name,
                init_payment_send_money_date: contract_attribution.init_payment_send_money_date ? moment(contract_attribution.init_payment_send_money_date).format('YYYY-MM-DD') : null,
                init_payment_bank_number: contract_attribution.init_payment_bank_number,
                init_payment_bank_name: contract_attribution.init_payment_bank_name,
                init_payment_branch_store_number: contract_attribution.init_payment_branch_store_number,
                init_payment_branch_name: contract_attribution.init_payment_branch_name,
                init_payment_deposit_kind_type: contract_attribution.init_payment_deposit_kind_type,
                init_payment_bank_account_number: contract_attribution.init_payment_bank_account_number,
                init_payment_bank_account_name: contract_attribution.init_payment_bank_account_name,
                init_payment_send_money_amount: contract_attribution.init_payment_send_money_amount,
              }
            };
          case 'monthly_payment':
            return {
              contract_attribution: {
                monthly_payment_company_name: contract_attribution.monthly_payment_company_name,
                monthly_payment_send_money_date: contract_attribution.monthly_payment_send_money_date ? moment(contract_attribution.monthly_payment_send_money_date).format('YYYY-MM-DD') : null,
                monthly_payment_bank_number: contract_attribution.monthly_payment_bank_number,
                monthly_payment_bank_name: contract_attribution.monthly_payment_bank_name,
                monthly_payment_branch_store_number: contract_attribution.monthly_payment_branch_store_number,
                monthly_payment_branch_name: contract_attribution.monthly_payment_branch_name,
                monthly_payment_deposit_kind_type: contract_attribution.monthly_payment_deposit_kind_type,
                monthly_payment_bank_account_number: contract_attribution.monthly_payment_bank_account_number,
                monthly_payment_bank_account_name: contract_attribution.monthly_payment_bank_account_name,
                monthly_payment_send_money_amount: contract_attribution.monthly_payment_send_money_amount,
              }
            };
          // 契約条件, その他費用, 支払い方法
          case 'payment_information':
            if (isAgencyAdmin || isDirectAdmin || isDirectUser) {
              return {
                payment_bank,
                contract_condition: {
                  tax_category: contract_condition.tax_category,
                  rent_with_tax: contract_condition.rent_with_tax,
                  rent_without_tax: contract_condition.rent_without_tax,
                  first_month_rent_with_tax: contract_condition.first_month_rent_with_tax,
                  final_month_rent_with_tax: contract_condition.final_month_rent_with_tax,
                  first_month_rent_without_tax: contract_condition.first_month_rent_without_tax,
                  final_month_rent_without_tax: contract_condition.final_month_rent_without_tax,
                  monthly_guarantee_and_management_fee_with_tax: contract_condition.monthly_guarantee_and_management_fee_with_tax,
                  initial_guarantee_and_management_fee_with_tax: contract_condition.initial_guarantee_and_management_fee_with_tax,
                  first_month_guarantee_and_management_fee_with_tax: contract_condition.first_month_guarantee_and_management_fee_with_tax,
                  final_month_guarantee_and_management_fee_with_tax: contract_condition.final_month_guarantee_and_management_fee_with_tax,
                  deposit_with_tax: contract_condition.deposit_with_tax,
                  key_money_with_tax: contract_condition.key_money_with_tax,
                  administrative_fee_with_tax: contract_condition.administrative_fee_with_tax,
                  brokerage_fee_with_tax: contract_condition.brokerage_fee_with_tax,
                  payment_method: contract_condition.payment_method,
                  payment_date: contract_condition.payment_date,
                  update_base_fee_with_tax: contract_condition.update_base_fee_with_tax,
                  update_management_fee_with_tax: contract_condition.update_management_fee_with_tax,
                  renewal_management_fee_with_tax: contract_condition.renewal_management_fee_with_tax,
                  issue_paper_fee_with_tax: contract_condition.issue_paper_fee_with_tax,
                  seasonal_adjustment: contract_condition.seasonal_adjustment,
                  seasonal_rent_from: contract_condition.seasonal_rent_from,
                  seasonal_rent_to: contract_condition.seasonal_rent_to,
                  seasonal_fee_with_tax: contract_condition.seasonal_fee_with_tax,
                  prorated_rent_refund: contract_condition.prorated_rent_refund,
                  security_deposit_refund: contract_condition.security_deposit_refund,
                  initial_guarantee_fee_without_tax: contract_condition.initial_guarantee_fee_without_tax,
                  monthly_guarantee_fee_without_tax: contract_condition.monthly_guarantee_fee_without_tax,
                },
                contract_attribution: {
                  other1: contract_attribution.other1,
                  other1_memo: contract_attribution.other1_memo,
                  other2: contract_attribution.other2,
                  other2_memo: contract_attribution.other2_memo,
                  other3: contract_attribution.other3,
                  other3_memo: contract_attribution.other3_memo,
                },
              };
            }
            return isAdmin ? {
              payment_bank,
              contract_condition: {
                tax_category: contract_condition.tax_category,
                brokerage_fee_with_tax: contract_condition.brokerage_fee_with_tax,
                payment_method: contract_condition.payment_method,
                seasonal_adjustment: contract_condition.seasonal_adjustment,
                seasonal_rent_from: contract_condition.seasonal_rent_from || null,
                seasonal_rent_to: contract_condition.seasonal_rent_to || null,
                seasonal_fee_with_tax: contract_condition.seasonal_fee_with_tax,
                prorated_rent_refund: contract_condition.prorated_rent_refund,
                security_deposit_refund: contract_condition.security_deposit_refund,
                initial_guarantee_fee_without_tax: contract_condition.initial_guarantee_fee_without_tax,
                monthly_guarantee_fee_without_tax: contract_condition.monthly_guarantee_fee_without_tax,
            },
              contract_attribution: {
                other1: contract_attribution.other1,
                other1_memo: contract_attribution.other1_memo,
                other2: contract_attribution.other2,
                other2_memo: contract_attribution.other2_memo,
                other3: contract_attribution.other3,
                other3_memo: contract_attribution.other3_memo,
              },
            } : {};
          default:
            return {};
        };
      })();

      if (Object.keys(updatedContract).length === 0) return onClose(false);

      const validationKeys = getValidationKeys(updatedContract);
      const formErrors = await validateFields(validationKeys, values);
    
      if (formErrors.length > 0) {
        formErrors.forEach(err => {
          if (err.path) {
            setError(err.path as keyof FormData, { type: 'manual', message: err.message });
          }
        });
        return;
      }

      const [res, response] = await api.patch(
        isAdmin
          ? `/api/v1/admin/contract/${contractDetail.id}/`
          : `/api/v1/contract/${contractDetail.id}/`
        , updatedContract);

      if (res.status === HttpStatus.OK) {
        onClose(true);
      } else {
        showError(response);
      }
    } finally {
      setIsUploading(false);
    }
  }, [getValues, clearErrors, onClose, isAdmin, contractDetail.id, formType, isPdAdmin, isAgencyAdmin, isPdUser, isAgencyUser, isDirectPdUser, isDirectAdmin, isDirectUser, partitionImage1File, partitionImage2File, partitionImage3File, certificateFile, setError]);

  const isCarCertificateRegistered = defaultValues.car_info && (defaultValues.car_info.certificate || defaultValues.car_info.certificate_path)
  const isPartitionImage1Registered = defaultValues.client_company && defaultValues.client_company.partition_image1
  const isPartitionImage2Registered = defaultValues.client_company && defaultValues.client_company.partition_image2
  const isPartitionImage3Registered = defaultValues.client_company && defaultValues.client_company.partition_image3

  const djangoCarCertificateOpen = useMemo(() => (
    isAdmin
      ? () => window.open(`${ADMIN_URL}admin/pd_biz/contractvehicle/${contractDetail.id}/change/`)
      : undefined
  ), [contractDetail.id, isAdmin]);

  const isDisplayCarCertificateFileChooser = useMemo(() => {
    if (isAgencyAdmin) return true;
    if (contractKind === 'Direct' || contractKind === 'DirectPd') return true;

    return false;
  }, [isAgencyAdmin, contractKind]);

  const isDisplayPartitionImageFileChooser1 = useMemo(() => {
    if (isAgencyAdmin) return true;
    if (contractKind === 'Direct' || contractKind === 'DirectPd') return true;
    return false;
  }, [isAgencyAdmin, contractKind]);

  const isDisplayPartitionImageFileChooser2 = useMemo(() => {
    if (isAgencyAdmin) return true;
    if (contractKind === 'Direct' || contractKind === 'DirectPd') return true;
    return false;
  }, [isAgencyAdmin, contractKind]);

  const isDisplayPartitionImageFileChooser3 = useMemo(() => {
    if (isAgencyAdmin) return true;
    if (contractKind === 'Direct' || contractKind === 'DirectPd') return true;
    return false;
  }, [isAgencyAdmin, contractKind]);

  const noneDisplayStyle = isAdmin ? "" : "none";

  const rentalItem = watch('rental_item');
  const issueDate = watch('contract.issue_date');
  const updateBaseFeeWithTax = watch('contract.update_base_fee_with_tax');
  const updateManagementFeeWithTax = watch('contract.update_management_fee_with_tax');
  const renewalManagementFeeWithTax = watch('contract.renewal_management_fee_with_tax');

  const total_renewal_fee =
    (Number(updateBaseFeeWithTax) || 0)
    + (Number(updateManagementFeeWithTax) || 0)
    + (Number(renewalManagementFeeWithTax) || 0);

  return (
    <Modal open={!!formType} css={modalStyle}>
      <div css={modalContentStyle}>
        {formType === 'basic_information' && (
          <>
            <div css={modalTitleStyle}>基本情報</div>
            <div css={rowStyle}>
              <StyledTextField label="契約形態" value={contractKindList[contractDetail.contractKind]} disabled />
              <FormControl css={selectFormStyle} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser}>
                <InputLabel id="managementState" variant="standard">契約状態</InputLabel>
                <Select label="契約状態" labelId="managementState" variant="standard" {...registerMui(register('management_state'))} error={!!errors.management_state} defaultValue={defaultValues.management_state}>
                  {ManagementStatus.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
            <div css={rowStyle} style={{display:noneDisplayStyle}}>
              <FormControlLabel
                label={<StyledCheckBoxLabel>通常契約</StyledCheckBoxLabel>}
                control={<StyledCheckBox {...registerMui(register('normal_contract'))} defaultChecked={defaultValues.normal_contract} />}
              />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="date" label="契約日" {...registerMui(register('contract.contracted_date'))} error={!!errors.contract?.contracted_date} helperText={(errors.contract?.contracted_date as FieldError)?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="date" label="解約日" {...registerMui(register('contract.cancel_date'))} error={!!errors.contract?.cancel_date} helperText={(errors.contract?.cancel_date as FieldError)?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="契約期間" {...registerMui(register('contract.contract_period_month'))} error={!!errors.contract?.contract_period_month} helperText={errors.contract?.contract_period_month?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="date" label="初回契約開始日" {...registerMui(register('contract.first_start_date'))} error={!!errors.contract?.first_start_date} helperText={(errors.contract?.first_start_date as FieldError)?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="date" label="賃貸借期間_開始" {...registerMui(register('contract.start_date'))} error={!!errors.contract?.start_date} helperText={(errors.contract?.start_date as FieldError)?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="date" label="賃貸借期間_終了" {...registerMui(register('contract.end_date'))} error={!!errors.contract?.end_date} helperText={(errors.contract?.end_date as FieldError)?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              {/* 保管場所使用承諾証明書 ひとまず消さずにコメントアウトとしておく */}
              {/*
              <FormControl css={selectFormStyle} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser}>
                <InputLabel id="needIssuePaper" variant="standard">保管場所使用承諾証明書</InputLabel>
                <Select label="保管場所使用承諾証明書" labelId="needIssuePaper" variant="standard" value={+contract.needIssuePaper} onChange={onChangeNeedIssuePaper}>
                  <MenuItem value={1}>必要</MenuItem>
                  <MenuItem value={0}>不要</MenuItem>
                </Select>
              </FormControl>
              */}
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
                <DatePicker
                  views={['year', 'month']}
                  label="保管場所使用承諾証明書の発行月"
                  inputFormat="yyyy/MM"
                  openTo="month"
                  value={issueDate}
                  onChange={(value) => setValue('contract.issue_date', value)}
                  renderInput={(params) => <StyledTextField {...params} error={!!errors.contract?.issue_date} helperText={(errors.contract?.issue_date as FieldError)?.message} />}
                />
              </LocalizationProvider>
              <StyledTextField type="date" label="初回支払日" {...registerMui(register('contract.initial_payment_date'))} error={!!errors.contract?.initial_payment_date} helperText={(errors.contract?.initial_payment_date as FieldError)?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle} disabled={!isAdmin && !isDirectUser}>
                <InputLabel id="initialProcedureType" variant="standard" shrink>初回契約手続き区分</InputLabel>
                <Select label="初回契約手続き区分" labelId="initialProcedureType" variant="standard" {...registerMui(register('initial_procedure_type'))} error={!!errors.initial_procedure_type} defaultValue={defaultValues.initial_procedure_type} disabled={!isAdmin && !isDirectUser}>
                {InitialProcedureType.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
            <div css={rowStyle}>
              <FormControlLabel
                label={<StyledCheckBoxLabel disabled={isPdAdmin || isPdUser || isAgencyUser || isDirectPdUser}>自動更新</StyledCheckBoxLabel>}
                control={<StyledCheckBox {...registerMui(register('auto_update'))} defaultChecked={defaultValues.auto_update} disabled={isPdAdmin || isPdUser || isAgencyUser || isDirectPdUser} />}
              />
            </div>
          </>
        )}
        {formType === 'user_information' && (
          <>
            <div css={modalTitleStyle}>利用者情報</div>
            <div css={rowStyle}>
              <StyledTextField label="利用者名" {...registerMui(register('user.name'))} error={!!errors.user?.name} helperText={errors.user?.name?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectPdUser && !isDirectUser} />
              <StyledTextField label="利用者名(カナ)" {...registerMui(register('user.name_kana'))} error={!!errors.user?.name_kana} helperText={errors.user?.name_kana?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectPdUser && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="利用-担当者名" {...registerMui(register('user.manager'))} error={!!errors.user?.manager} helperText={errors.user?.manager?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectPdUser && !isDirectUser} />
              <StyledTextField type="email" label="利用者メールアドレス" {...registerMui(register('user.email'))} error={!!errors.user?.email} helperText={errors.user?.email?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectPdUser  && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="利用者携帯電話番号" {...registerMui(register('user.mobile_phone_number'))} error={!!errors.user?.mobile_phone_number} helperText={errors.user?.mobile_phone_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectPdUser && !isDirectUser} />
              <StyledTextField label="利用者自宅電話番号" {...registerMui(register('user.phone_number'))} error={!!errors.user?.phone_number} helperText={errors.user?.phone_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectPdUser && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="利用者従業員番号" {...registerMui(register('user.employee_number'))} error={!!errors.user?.employee_number} helperText={errors.user?.employee_number?.message} />
              <div />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="利用者所属拠点名" {...registerMui(register('user.affiliation_branch_name'))} error={!!errors.user?.affiliation_branch_name} helperText={errors.user?.affiliation_branch_name?.message} />
              <StyledTextField label="利用者所属拠点番号" {...registerMui(register('user.affiliation_branch_number'))} error={!!errors.user?.affiliation_branch_number} helperText={errors.user?.affiliation_branch_number?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="利用者所属組織名" {...registerMui(register('user.affiliation_department_name'))} error={!!errors.user?.affiliation_department_name} helperText={errors.user?.affiliation_department_name?.message} />
              <StyledTextField label="利用者所属組織番号" {...registerMui(register('user.affiliation_department_number'))} error={!!errors.user?.affiliation_department_number} helperText={errors.user?.affiliation_department_number?.message} />
            </div>
          </>
        )}
        {formType === 'contractor_information' && (
          <>
            <div css={modalTitleStyle}>契約者情報</div>
            <div css={rowStyle}>
              <StyledTextField label="契約者名" {...registerMui(register('contractor.name'))} error={!!errors.contractor?.name} helperText={errors.contractor?.name_kana?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="契約者名(カナ)" {...registerMui(register('contractor.name_kana'))} error={!!errors.contractor?.name_kana} helperText={errors.contractor?.name_kana?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="契約-担当者名" {...registerMui(register('contractor.manager'))} error={!!errors.contractor?.manager} helperText={errors.contractor?.manager?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="契約者メールアドレス" {...registerMui(register('contractor.email'))} error={!!errors.contractor?.email} helperText={errors.contractor?.email?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="契約者電話番号" {...registerMui(register('contractor.phone_number'))} error={!!errors.contractor?.phone_number} helperText={errors.contractor?.phone_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="契約者会社電話番号" {...registerMui(register('contractor.company_phone_number'))} error={!!errors.contractor?.company_phone_number} helperText={errors.contractor?.company_phone_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="契約者従業員番号" {...registerMui(register('contractor.employee_number'))} error={!!errors.contractor?.employee_number} helperText={errors.contractor?.employee_number?.message} />
              <div />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="契約者所属拠点名" {...registerMui(register('contractor.affiliation_branch_name'))} error={!!errors.contractor?.affiliation_branch_name} helperText={errors.contractor?.affiliation_branch_name?.message} />
              <StyledTextField label="契約者所属拠点番号" {...registerMui(register('contractor.affiliation_branch_number'))} error={!!errors.contractor?.affiliation_branch_number} helperText={errors.contractor?.affiliation_branch_number?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="契約者所属組織名" {...registerMui(register('contractor.affiliation_department_name'))} error={!!errors.contractor?.affiliation_department_name} helperText={errors.contractor?.affiliation_department_name?.message} />
              <StyledTextField label="契約者所属組織番号" {...registerMui(register('contractor.affiliation_department_number'))} error={!!errors.contractor?.affiliation_department_number} helperText={errors.contractor?.affiliation_department_number?.message} />
            </div>
          </>
        )}
        {formType === 'parking_information' && (
          <>
            <div css={modalTitleStyle}>駐車場情報</div>
            <div css={rowStyle}>
              <StyledTextField label="駐車場名" {...registerMui(register('client_company.parking_name'))} error={!!errors.client_company?.parking_name} helperText={errors.client_company?.parking_name?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="区画番号" {...registerMui(register('client_company.partition'))} error={!!errors.client_company?.partition} helperText={errors.client_company?.partition?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle}>
                <InputLabel id="partitionType" variant="standard">区画種別</InputLabel>
                <Select label="区画種別" labelId="partitionType" variant="standard" {...registerMui(register('client_company.partition_type'))} error={!!errors.client_company?.partition_type} defaultValue={defaultValues.client_company.partition_type} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser}>
                  {partition.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
              <StyledTextField label="住所" {...registerMui(register('client_company.address'))} error={!!errors.client_company?.address} helperText={errors.client_company?.address?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="緯度" {...registerMui(register('client_company.latitude'))} error={!!errors.client_company?.latitude} helperText={errors.client_company?.latitude?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="経度" {...registerMui(register('client_company.longitude'))} error={!!errors.client_company?.longitude} helperText={errors.client_company?.longitude?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <div css={inputFileColStyle}>
                <StyledFileField label="区画図1" value={isPartitionImage1Registered ? '登録あり' : '登録なし'}  disabled />
                {isDisplayPartitionImageFileChooser1 && <div css={partitionImageInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangePartitionImageFile(1)} /></div>}
              </div>
              <div css={inputFileColStyle}>
                <StyledFileField label="区画図2" value={isPartitionImage2Registered ? '登録あり' : '登録なし'}  disabled />
                {isDisplayPartitionImageFileChooser2 && <div css={partitionImageInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangePartitionImageFile(2)} /></div>}
              </div>
            </div>
            <div css={rowStyle}>
              <div css={inputFileColStyle}>
                <StyledFileField label="区画図3" value={isPartitionImage3Registered ? '登録あり' : '登録なし'}  disabled />
                {isDisplayPartitionImageFileChooser3 && <div css={partitionImageInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangePartitionImageFile(3)} /></div>}
              </div>
              <div css={inputFileColStyle} />
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle}>
                <InputLabel id="rentalItem" variant="standard">貸与物</InputLabel>
                <Select label="貸与物" labelId="rentalItem" variant="standard" {...registerMui(register('rental_item'))} error={!!errors.rental_item} defaultValue={defaultValues.rental_item} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser}>
                  <MenuItem value={1}>あり</MenuItem>
                  <MenuItem value={0}>なし</MenuItem>
                </Select>
              </FormControl>
              <StyledTextField label="貸与物の内容" {...registerMui(register('rental_item_contents'))} {...(!rentalItem ? { value: '' } : {})} error={!!errors?.rental_item_contents} disabled={(!isPdAdmin && !isAgencyAdmin && !isDirectAdmin && !isDirectUser) || !rentalItem} />
            </div>
          </>
        )}
        {formType === 'management_company_information' && (
          <>
            <div css={modalTitleStyle}>管理会社情報</div>
            <div css={rowStyle}>
              <StyledTextField label="管理会社名" {...registerMui(register('client_company.client_company_name'))} error={!!errors.client_company?.client_company_name} helperText={errors.client_company?.client_company_name?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="店舗名" {...registerMui(register('client_company.client_store_name'))} error={!!errors.client_company?.client_store_name} helperText={errors.client_company?.client_store_name?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="管理会社郵便番号" {...registerMui(register('client_company.client_company_postal_code'))} error={!!errors.client_company?.client_company_postal_code} helperText={errors.client_company?.client_company_postal_code?.message} disabled={!isAdmin && !isDirectUser} />
              <StyledTextField label="管理会社住所" {...registerMui(register('client_company.client_company_address'))} error={!!errors.client_company?.client_company_address} helperText={errors.client_company?.client_company_address?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="管理会社電話番号" {...registerMui(register('client_company.client_company_phone_number'))} error={!!errors.client_company?.client_company_phone_number} helperText={errors.client_company?.client_company_phone_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="管理会社FAX番号" {...registerMui(register('client_company.client_company_fax_number'))} error={!!errors.client_company?.client_company_fax_number} helperText={errors.client_company?.client_company_fax_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="担当者名" {...registerMui(register('client_company.client_company_staff_name'))} error={!!errors.client_company?.client_company_staff_name} helperText={errors.client_company?.client_company_staff_name?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="担当者メールアドレス" {...registerMui(register('client_company.client_company_staff_email'))} error={!!errors.client_company?.client_company_staff_email} helperText={errors.client_company?.client_company_staff_email?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="インボイス発行事業者の氏名または名称および登録番号" {...registerMui(register('client_company.client_company_invoice_name_or_number'))} error={!!errors.client_company?.client_company_invoice_name_or_number} helperText={errors.client_company?.client_company_invoice_name_or_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="number" label="適用税率" {...registerMui(register('client_company.client_company_invoice_tax_rate'))} error={!!errors.client_company?.client_company_invoice_tax_rate} helperText={errors.client_company?.client_company_invoice_tax_rate?.message} disabled={!isPdAdmin && !isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('client_company.client_company_invoice_receiver_name'))} error={!!errors.client_company?.client_company_invoice_receiver_name} helperText={errors.client_company?.client_company_invoice_receiver_name?.message} disabled={!isPdAdmin && !isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="保証会社名" {...registerMui(register('client_company.guarantor_company_name'))} error={!!errors.client_company?.guarantor_company_name} helperText={errors.client_company?.guarantor_company_name?.message} disabled={!isAdmin && !isDirectUser} />
              <StyledTextField label="保証会社インボイス登録番号" {...registerMui(register('client_company.guarantor_invoice_number'))} error={!!errors.client_company?.guarantor_invoice_number} helperText={errors.client_company?.guarantor_invoice_number?.message} disabled={!isAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="備考" {...registerMui(register('client_company.client_company_note'))} error={!!errors.client_company?.client_company_note} helperText={errors.client_company?.client_company_note?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
          </>
        )}
        {formType === 'lessor' && (
          <>
            <div css={modalTitleStyle}>貸主情報</div>
            <div css={rowStyle}>
              <StyledTextField label="貸主名" {...registerMui(register('lessor.lessor_name'))} error={!!errors.lessor?.lessor_name} helperText={errors.lessor?.lessor_name?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <FormControl css={selectFormStyle}>
                <InputLabel id="lessorType" variant="standard" shrink>貸主区分</InputLabel>
                <Select label="貸主区分" labelId="lessorType" variant="standard" {...registerMui(register('lessor.lessor_type'))} error={!!errors.lessor?.lessor_type} defaultValue={defaultValues.lessor.lessor_type} disabled={!isAdmin && !isDirectUser}>
                  {LessortType.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
            <div css={rowStyle}>
              <StyledTextField label="貸主郵便番号" {...registerMui(register('lessor.lessor_postal_code'))} error={!!errors.lessor?.lessor_postal_code} helperText={errors.lessor?.lessor_postal_code?.message} disabled={!isAdmin && !isDirectUser} />
              <StyledTextField label="貸主住所" {...registerMui(register('lessor.lessor_address'))} error={!!errors.lessor?.lessor_address} helperText={errors.lessor?.lessor_address?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="貸主電話番号" {...registerMui(register('lessor.lessor_phone_number'))} error={!!errors.lessor?.lessor_phone_number} helperText={errors.lessor?.lessor_phone_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="貸主FAX番号" {...registerMui(register('lessor.lessor_fax_number'))} error={!!errors.lessor?.lessor_fax_number} helperText={errors.lessor?.lessor_fax_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="インボイス発行事業者の氏名または名称および登録番号" {...registerMui(register('lessor.lessor_invoice_name_or_number'))} error={!!errors.lessor?.lessor_invoice_name_or_number} helperText={errors.lessor?.lessor_invoice_name_or_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="貸主メールアドレス" {...registerMui(register('lessor.lessor_email'))} error={!!errors.lessor?.lessor_email} helperText={errors.lessor?.lessor_email?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="適用税率" {...registerMui(register('lessor.lessor_invoice_tax_rate'))} error={!!errors.lessor?.lessor_invoice_tax_rate} helperText={errors.lessor?.lessor_invoice_tax_rate?.message} disabled={!isPdAdmin && !isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('lessor.lessor_invoice_receiver_name'))} error={!!errors.lessor?.lessor_invoice_receiver_name} helperText={errors.lessor?.lessor_invoice_receiver_name?.message} disabled={!isPdAdmin && !isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="備考" {...registerMui(register('lessor.lessor_note'))} error={!!errors.lessor?.lessor_note} helperText={errors.lessor?.lessor_note?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
          </>
        )}
        {formType === 'broker_company' && (
          <>
            <div css={modalTitleStyle}>仲介会社情報</div>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社名" {...registerMui(register('broker_company.broker_company_name'))} error={!!errors.broker_company?.broker_company_name} helperText={errors.broker_company?.broker_company_name?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社郵便番号" {...registerMui(register('broker_company.broker_company_postal_code'))} error={!!errors.broker_company?.broker_company_postal_code} helperText={errors.broker_company?.broker_company_postal_code?.message} disabled={!isAdmin && !isDirectUser} />
              <StyledTextField label="仲介会社住所" {...registerMui(register('broker_company.broker_company_address'))} error={!!errors.broker_company?.broker_company_address} helperText={errors.broker_company?.broker_company_address?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社電話番号" {...registerMui(register('broker_company.broker_company_phone_number'))} error={!!errors.broker_company?.broker_company_phone_number} helperText={errors.broker_company?.broker_company_phone_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="仲介会社FAX番号" {...registerMui(register('broker_company.broker_company_fax_number'))} error={!!errors.broker_company?.broker_company_fax_number} helperText={errors.broker_company?.broker_company_fax_number?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社メールアドレス" {...registerMui(register('broker_company.broker_company_email'))} error={!!errors.broker_company?.broker_company_email} helperText={errors.broker_company?.broker_company_email?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="インボイス発行事業者の氏名または名称および登録番号" {...registerMui(register('broker_company.broker_company_invoice_name_or_number'))} error={!!errors.broker_company?.broker_company_invoice_name_or_number} helperText={errors.broker_company?.broker_company_invoice_name_or_number?.message} disabled={!isPdAdmin && !isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="適用税率" {...registerMui(register('broker_company.broker_company_invoice_tax_rate'))} error={!!errors.broker_company?.broker_company_invoice_tax_rate} helperText={errors.broker_company?.broker_company_invoice_tax_rate?.message} disabled={!isPdAdmin && !isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('broker_company.broker_company_invoice_receiver_name'))} error={!!errors.broker_company?.broker_company_invoice_receiver_name} helperText={errors.broker_company?.broker_company_invoice_receiver_name?.message} disabled={!isPdAdmin && !isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="備考" {...registerMui(register('broker_company.broker_company_note'))} error={!!errors.broker_company?.broker_company_note} helperText={errors.broker_company?.broker_company_note?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
          </>
        )}
        {formType === 'vehicle_information' && (
          <>
            <div css={modalTitleStyle}>契約車両情報</div>
            <div css={rowStyle}>
              <StyledTextField label="車種" {...registerMui(register('car_info.car_model'))} error={!!errors.car_info?.car_model} helperText={errors.car_info?.car_model?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectPdUser && !isDirectUser} />
              <StyledTextField label="車種-登録番号（地域名）" {...registerMui(register('car_info.regno_region_name'))} error={!!errors.car_info?.regno_region_name} helperText={errors.car_info?.regno_region_name?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectPdUser && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="車種-登録番号（分類番号3桁）" {...registerMui(register('car_info.regno_class_digit'))} error={!!errors.car_info?.regno_class_digit} helperText={errors.car_info?.regno_class_digit?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectPdUser && !isDirectUser} />
              <StyledTextField label="車種-登録番号（平仮名等）" {...registerMui(register('car_info.regno_hiragana'))} error={!!errors.car_info?.regno_hiragana} helperText={errors.car_info?.regno_hiragana?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectPdUser && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="車種-登録番号(一連指定番号4桁)" {...registerMui(register('car_info.regno_seq_digit'))} error={!!errors.car_info?.regno_seq_digit} helperText={errors.car_info?.regno_seq_digit?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectPdUser && !isDirectUser} />
              <StyledTextField label="車検証" value={isCarCertificateRegistered ? '登録あり' : '登録なし'} link={isAdmin} onClick={djangoCarCertificateOpen} disabled />
              {isDisplayCarCertificateFileChooser && <div css={certificateInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangeCertificateFile} /></div>}
            </div>
          </>
        )}
        {formType === 'notice_memo' && (
          <>
            <div css={modalTitleStyle}>お知らせ・注意喚起のメモ</div>
            <div css={rowStyle}>
              <StyledTextField label="メモ1" {...registerMui(register('contract_attribution.notice_memo1'))} error={!!errors.contract_attribution?.notice_memo1} helperText={errors.contract_attribution?.notice_memo1?.message} />
              <StyledTextField label="メモ2" {...registerMui(register('contract_attribution.notice_memo2'))} error={!!errors.contract_attribution?.notice_memo2} helperText={errors.contract_attribution?.notice_memo2?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="メモ3" {...registerMui(register('contract_attribution.notice_memo3'))} error={!!errors.contract_attribution?.notice_memo3} helperText={errors.contract_attribution?.notice_memo3?.message} />
            </div>
          </>
        )}
        {formType === 'note' && (
          <>
            <div css={modalTitleStyle}>備考</div>
            <div css={rowStyle}>
              <StyledTextField label="メモ1" {...registerMui(register('contract_attribution.memo1'))} error={!!errors.contract_attribution?.memo1} helperText={errors.contract_attribution?.memo1?.message} />
              <StyledTextField label="メモ2" {...registerMui(register('contract_attribution.memo2'))} error={!!errors.contract_attribution?.memo2} helperText={errors.contract_attribution?.memo2?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="メモ3" {...registerMui(register('contract_attribution.memo3'))} error={!!errors.contract_attribution?.memo3} helperText={errors.contract_attribution?.memo3?.message} />
              <StyledTextField label="使用用途" {...registerMui(register('contract_attribution.usage'))} error={!!errors.contract_attribution?.usage} helperText={errors.contract_attribution?.usage?.message} />
            </div>
          </>
        )}
        {formType === 'payment_information' && (
          <>
            <div css={modalTitleStyle}>契約条件</div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle} disabled={!isAdmin && !isDirectUser}>
                <InputLabel id="taxCategory" variant="standard" shrink>税区分</InputLabel>
                <Select label="税区分" labelId="taxCategory" variant="standard"
                  {...registerMui(register('contract.tax_category'))} error={!!errors.contract?.tax_category} defaultValue={defaultValues.contract.tax_category}>
                  {TaxCategory.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
              <StyledTextField type="number" label="月額賃料(税込)" {...registerMui(register('contract.rent_with_tax'))} error={!!errors.contract?.rent_with_tax} helperText={errors.contract?.rent_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="月額賃料(税抜)" {...registerMui(register('contract.rent_without_tax'))} error={!!errors.contract?.rent_without_tax} helperText={errors.contract?.rent_without_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="number" label="初月賃料(税込)" {...registerMui(register('contract.first_month_rent_with_tax'))} error={!!errors.contract?.first_month_rent_with_tax} helperText={errors.contract?.first_month_rent_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="最終月賃料(税込)" {...registerMui(register('contract.final_month_rent_with_tax'))} error={!!errors.contract?.final_month_rent_with_tax} helperText={errors.contract?.final_month_rent_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="number" label="初月賃料(税抜)" {...registerMui(register('contract.first_month_rent_without_tax'))} error={!!errors.contract?.first_month_rent_without_tax} helperText={errors.contract?.first_month_rent_without_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="最終月賃料(税抜)" {...registerMui(register('contract.final_month_rent_without_tax'))} error={!!errors.contract?.final_month_rent_without_tax} helperText={errors.contract?.final_month_rent_without_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="number" label="月額保証／管理料(税込)" {...registerMui(register('contract.monthly_guarantee_and_management_fee_with_tax'))} error={!!errors.contract?.monthly_guarantee_and_management_fee_with_tax} helperText={errors.contract?.monthly_guarantee_and_management_fee_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="初回保証／管理料(税込)" {...registerMui(register('contract.initial_guarantee_and_management_fee_with_tax'))} error={!!errors.contract?.initial_guarantee_and_management_fee_with_tax} helperText={errors.contract?.initial_guarantee_and_management_fee_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="number" label="初月保証／管理料(税込)" {...registerMui(register('contract.first_month_guarantee_and_management_fee_with_tax'))} error={!!errors.contract?.first_month_guarantee_and_management_fee_with_tax} helperText={errors.contract?.first_month_guarantee_and_management_fee_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="最終月保証／管理料(税込)" {...registerMui(register('contract.final_month_guarantee_and_management_fee_with_tax'))} error={!!errors.contract?.final_month_guarantee_and_management_fee_with_tax} helperText={errors.contract?.final_month_guarantee_and_management_fee_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="number" label="敷金(非課税)" {...registerMui(register('contract.deposit_with_tax'))} error={!!errors.contract?.deposit_with_tax} helperText={errors.contract?.deposit_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="礼金(税込)" {...registerMui(register('contract.key_money_with_tax'))} error={!!errors.contract?.key_money_with_tax} helperText={errors.contract?.key_money_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="number" label="事務手数料(税込)" {...registerMui(register('contract.administrative_fee_with_tax'))} error={!!errors.contract?.administrative_fee_with_tax} helperText={errors.contract?.administrative_fee_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="仲介手数料(税込)" {...registerMui(register('contract.brokerage_fee_with_tax'))} error={!!errors.contract?.brokerage_fee_with_tax} helperText={errors.contract?.brokerage_fee_with_tax?.message} disabled={!isAdmin && !isDirectUser} />
              <StyledTextField type="number" label="初回保証料(非課税)" {...registerMui(register('contract.initial_guarantee_fee_without_tax'))} error={!!errors.contract?.initial_guarantee_fee_without_tax} helperText={errors.contract?.initial_guarantee_fee_without_tax?.message} disabled={!isAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="月額保証料(非課税)" {...registerMui(register('contract.monthly_guarantee_fee_without_tax'))} error={!!errors.contract?.monthly_guarantee_fee_without_tax} helperText={errors.contract?.monthly_guarantee_fee_without_tax?.message} disabled={!isAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="その他1" {...registerMui(register('contract_attribution.other1'))} error={!!errors.contract_attribution?.other1} helperText={errors.contract_attribution?.other1?.message} disabled={!isAdmin && !isDirectUser} />
              <StyledTextField label="その他1_メモ" {...registerMui(register('contract_attribution.other1_memo'))} error={!!errors.contract_attribution?.other1_memo} helperText={errors.contract_attribution?.other1_memo?.message} disabled={!isAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="その他2" {...registerMui(register('contract_attribution.other2'))} error={!!errors.contract_attribution?.other2} helperText={errors.contract_attribution?.other2?.message} disabled={!isAdmin && !isDirectUser} />
              <StyledTextField label="その他2_メモ" {...registerMui(register('contract_attribution.other2_memo'))} error={!!errors.contract_attribution?.other2_memo} helperText={errors.contract_attribution?.other2_memo?.message} disabled={!isAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="その他3" {...registerMui(register('contract_attribution.other3'))} error={!!errors.contract_attribution?.other3} helperText={errors.contract_attribution?.other3?.message} disabled={!isAdmin && !isDirectUser} />
              <StyledTextField label="その他3_メモ" {...registerMui(register('contract_attribution.other3_memo'))} error={!!errors.contract_attribution?.other3_memo} helperText={errors.contract_attribution?.other3_memo?.message} disabled={!isAdmin && !isDirectUser} />
            </div>
            <div css={modalTitleStyle}>その他費用</div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="更新料(税込)" {...registerMui(register('contract.update_base_fee_with_tax'))} error={!!errors.contract?.update_base_fee_with_tax} helperText={errors.contract?.update_base_fee_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="number" label="更新事務手数料(税込)" {...registerMui(register('contract.update_management_fee_with_tax'))} error={!!errors.contract?.update_management_fee_with_tax} helperText={errors.contract?.update_management_fee_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="管理更新料(税込)" {...registerMui(register('contract.renewal_management_fee_with_tax'))} error={!!errors.contract?.renewal_management_fee_with_tax} helperText={errors.contract?.renewal_management_fee_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <StyledTextField type="number" label="更新費用合計(税込)" value={total_renewal_fee} disabled />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="保管場所使用承諾書発行手数料(税込)" {...registerMui(register('contract.issue_paper_fee_with_tax'))} error={!!errors.contract?.issue_paper_fee_with_tax} helperText={errors.contract?.issue_paper_fee_with_tax?.message} disabled={!isAgencyAdmin && !isDirectAdmin && !isDirectUser} />
              <FormControl css={selectFormStyle}>
                <InputLabel id="contract.seasonal_adjustment" variant="standard" shrink>季節調整</InputLabel>
                <Select label="季節調整" labelId="seasonalAdjustment" variant="standard" {...registerMui(register('contract.seasonal_adjustment'))} error={!!errors.contract?.seasonal_adjustment} defaultValue={defaultValues.contract.seasonal_adjustment} disabled={!isAdmin && !isDirectUser}>
                  {SeasonalAdjustment.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle}>
                <InputLabel id="seasonalRentFrom" variant="standard" shrink>季節賃料FROM</InputLabel>
                <Select label="季節調整" labelId="seasonalRentFrom" variant="standard" {...registerMui(register('contract.seasonal_rent_from'))} error={!!errors.contract?.seasonal_rent_from} defaultValue={defaultValues.contract.seasonal_rent_from} disabled={!isAdmin && !isDirectUser}>
                  <MenuItem value={0}></MenuItem>
                  {jpMonthLabels.map((value, index) => <MenuItem key={index} value={1 + index}>{value}</MenuItem>)}
                  </Select>
              </FormControl>
              <FormControl css={selectFormStyle}>
                <InputLabel id="seasonalRentTo" variant="standard" shrink>季節賃料TO</InputLabel>
                <Select label="季節調整" labelId="seasonalRentTo" variant="standard" {...registerMui(register('contract.seasonal_rent_to'))} error={!!errors.contract?.seasonal_rent_to} defaultValue={defaultValues.contract.seasonal_rent_to} disabled={!isAdmin && !isDirectUser}>
                  <MenuItem value={0}></MenuItem>
                  {jpMonthLabels.map((value, index) => <MenuItem key={index} value={1 + index}>{value}</MenuItem>)}
                  </Select>
              </FormControl>
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="季節費用(税込)" {...registerMui(register('contract.seasonal_fee_with_tax'))} error={!!errors.contract?.seasonal_fee_with_tax} helperText={errors.contract?.seasonal_fee_with_tax?.message} disabled={!isAdmin && !isDirectUser} />
              <StyledTextField type="number" label="日割賃料返金(税込)" {...registerMui(register('contract.prorated_rent_refund'))} error={!!errors.contract?.prorated_rent_refund} helperText={errors.contract?.prorated_rent_refund?.message} disabled={!isAdmin && !isDirectUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="敷金返金(非課税)" {...registerMui(register('contract.security_deposit_refund'))} error={!!errors.contract?.security_deposit_refund} helperText={errors.contract?.security_deposit_refund?.message} disabled={!isAdmin && !isDirectUser} />
            </div>
            <div css={modalTitleStyle}>支払い方法</div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle}>
                <InputLabel id="paymentMethod" variant="standard">支払先</InputLabel>
                <Select label="支払先" labelId="paymentMethod" variant="standard" {...registerMui(register('contract.payment_method'))} error={!!errors.contract?.payment_method} defaultValue={defaultValues.contract.payment_method} disabled={!isAdmin && !isDirectUser}>
                  {paymentMethod.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
              <StyledTextField label="毎月支払日" type="text" {...(contractKind === 'PD' ? { value: 'PD請求' } : {})} {...registerMui(register('contract.payment_date'))} error={!!errors.contract?.payment_date} helperText={(errors.contract?.payment_date as FieldError)?.message} disabled={contractKind === 'PD' || (!isAdmin && !isDirectUser)} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="支払先口座情報" {...registerMui(register('payment_bank'))} error={!!errors.payment_bank} helperText={errors.payment_bank?.message} disabled={!isAdmin && !isDirectUser} />
            </div>
          </>
        )}
        {formType === 'init_payment' && (
          <>
            <div css={modalTitleStyle}>初期費用支払い</div>
            <div css={rowStyle}>
              <StyledTextField label="法人名" {...registerMui(register('contract_attribution.init_payment_company_name'))} error={!!errors.contract_attribution?.init_payment_company_name} helperText={errors.contract_attribution?.init_payment_company_name?.message} />
              <StyledTextField type="date" label="送金日"{...registerMui(register('contract_attribution.init_payment_send_money_date'))} error={!!errors.contract_attribution?.init_payment_send_money_date} helperText={(errors.contract_attribution?.init_payment_send_money_date as FieldError)?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="銀行番号" {...registerMui(register('contract_attribution.init_payment_bank_number'))} error={!!errors.contract_attribution?.init_payment_bank_number} helperText={errors.contract_attribution?.init_payment_bank_number?.message} />
              <StyledTextField label="銀行名" {...registerMui(register('contract_attribution.init_payment_bank_name'))} error={!!errors.contract_attribution?.init_payment_bank_name} helperText={errors.contract_attribution?.init_payment_bank_name?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="支店番号" {...registerMui(register('contract_attribution.init_payment_branch_store_number'))} error={!!errors.contract_attribution?.init_payment_branch_store_number} helperText={errors.contract_attribution?.init_payment_branch_store_number?.message} />
              <StyledTextField label="支店名" {...registerMui(register('contract_attribution.init_payment_branch_name'))} error={!!errors.contract_attribution?.init_payment_branch_name} helperText={errors.contract_attribution?.init_payment_branch_name?.message} />
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle}>
                <InputLabel id="initPaymentDepositKindType" variant="standard">預金種目</InputLabel>
                <Select label="預金種目" labelId="initPaymentDepositKindType" variant="standard" {...registerMui(register('contract_attribution.init_payment_deposit_kind_type'))} error={!!errors.contract_attribution?.init_payment_deposit_kind_type} disabled={!isAdmin && !isDirectUser}>
                  {depositKind.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
              <StyledTextField label="口座番号" {...registerMui(register('contract_attribution.init_payment_bank_account_number'))} error={!!errors.contract_attribution?.init_payment_bank_account_number} helperText={errors.contract_attribution?.init_payment_bank_account_number?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="口座名" {...registerMui(register('contract_attribution.init_payment_bank_account_name'))} error={!!errors.contract_attribution?.init_payment_bank_account_name} helperText={errors.contract_attribution?.init_payment_bank_account_name?.message} />
              <StyledTextField label="送金金額" {...registerMui(register('contract_attribution.init_payment_send_money_amount'))} error={!!errors.contract_attribution?.init_payment_send_money_amount} helperText={errors.contract_attribution?.init_payment_send_money_amount?.message} />
            </div>
          </>
        )}
        {formType === 'monthly_payment' && (
          <>
            <div css={modalTitleStyle}>毎月支払</div>
            <div css={rowStyle}>
              <StyledTextField label="法人名" {...registerMui(register('contract_attribution.monthly_payment_company_name'))} error={!!errors.contract_attribution?.monthly_payment_company_name} helperText={errors.contract_attribution?.monthly_payment_company_name?.message} />
              <StyledTextField type="date" label="送金日" {...registerMui(register('contract_attribution.monthly_payment_send_money_date'))} error={!!errors.contract_attribution?.monthly_payment_send_money_date} helperText={(errors.contract_attribution?.monthly_payment_send_money_date as FieldError)?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="銀行番号" {...registerMui(register('contract_attribution.monthly_payment_bank_number'))} error={!!errors.contract_attribution?.monthly_payment_bank_number} helperText={errors.contract_attribution?.monthly_payment_bank_number?.message} />
              <StyledTextField label="銀行名" {...registerMui(register('contract_attribution.monthly_payment_bank_name'))} error={!!errors.contract_attribution?.monthly_payment_bank_name} helperText={errors.contract_attribution?.monthly_payment_bank_name?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="支店番号" {...registerMui(register('contract_attribution.monthly_payment_branch_store_number'))} error={!!errors.contract_attribution?.monthly_payment_branch_store_number} helperText={errors.contract_attribution?.monthly_payment_branch_store_number?.message} />
              <StyledTextField label="支店名" {...registerMui(register('contract_attribution.monthly_payment_branch_name'))} error={!!errors.contract_attribution?.monthly_payment_branch_name} helperText={errors.contract_attribution?.monthly_payment_branch_name?.message} />
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle}>
                <InputLabel id="monthlyPaymentDepositKindType" variant="standard">預金種目</InputLabel>
                <Select label="預金種目" labelId="monthlyPaymentDepositKindType" variant="standard" {...registerMui(register('contract_attribution.monthly_payment_deposit_kind_type'))} error={!!errors.contract_attribution?.monthly_payment_deposit_kind_type} disabled={!isAdmin && !isDirectUser}>
                  {depositKind.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
              <StyledTextField label="口座番号" {...registerMui(register('contract_attribution.monthly_payment_bank_account_number'))} error={!!errors.contract_attribution?.monthly_payment_bank_account_number} helperText={errors.contract_attribution?.monthly_payment_bank_account_number?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="口座名" {...registerMui(register('contract_attribution.monthly_payment_bank_account_name'))} error={!!errors.contract_attribution?.monthly_payment_bank_account_name} helperText={errors.contract_attribution?.monthly_payment_bank_account_name?.message} />
              <StyledTextField label="送金金額"{...registerMui(register('contract_attribution.monthly_payment_send_money_amount'))} error={!!errors.contract_attribution?.monthly_payment_send_money_amount} helperText={errors.contract_attribution?.monthly_payment_send_money_amount?.message} />
            </div>
          </>
        )}
        <div css={footerButtonsStyle}>
          <LoadingButton variant="contained" onClick={updateContract} css={footerButtonStyle} loading={isUploading} disabled={isUploading}>保存</LoadingButton>
          <Button variant="text" onClick={() => onClose(false)} css={footerButtonStyle} disabled={isUploading}>キャンセル</Button>
        </div>
      </div>
    </Modal>
  )
}

const LocalizedKeys: { [key: string]: string } = {
  management_state: '契約状態',
  payment_bank: '支払先口座情報',
  normal_contract: '通常契約',
  initial_procedure_type: '初回契約手続き区分',
  contract_condition__contracted_date: '契約日',
  contract_condition__cancel_date: '解約日',
  contract_condition__need_issue_paper: '保管場所使用承諾証明書',
  contract_condition__issue_date: '保管場所使用承諾証明書の発行月',
  contract_condition__contract_period_month: '契約期間',
  contract_condition__first_start_date: '初回契約開始日',
  contract_condition__start_date: '賃貸借期間_開始',
  contract_condition__end_date: '賃貸借期間_終了',
  contract_condition__rent_with_tax: '賃料(税込)',
  contract_condition__rent_without_tax: '賃料（税抜）',
  contract_condition__first_month_rent_with_tax: '初月賃料（税込）',
  contract_condition__final_month_rent_with_tax: '最終月賃料（税込）',
  contract_condition__first_month_rent_without_tax: '初月賃料（税抜）',
  contract_condition__final_month_rent_without_tax: '最終月賃料（税抜）',
  contract_condition__monthly_guarantee_and_management_fee_with_tax: '月額保証／管理料（税込）',
  contract_condition__initial_guarantee_and_management_fee_with_tax: '初回保証／管理料（税込）',
  contract_condition__first_month_guarantee_and_management_fee_with_tax: '初月月額保証/管理料（税込）',
  contract_condition__final_month_guarantee_and_management_fee_with_tax: '最終月月額保証管理料（税込）',
  contract_condition__deposit_with_tax: '敷金（非課税）',
  contract_condition__key_money_with_tax: '礼金（税込）',
  contract_condition__administrative_fee_with_tax: '事務手数料（税込）',
  contract_condition__payment_method: '支払先',
  contract_condition__payment_date: '毎月支払日',
  contract_condition__initial_payment_date: '初回支払日',
  contract_condition__update_base_fee_with_tax: '更新料(税込)',
  contract_condition__update_management_fee_with_tax: '更新事務手数料(税込)',
  contract_condition__renewal_management_fee_with_tax: '管理更新料(税込)',
  contract_condition__issue_paper_fee_with_tax: '保管場所使用承諾証明書発行料（税込）',
  contract_condition__tax_category: '税区分', 
  contract_condition__brokerage_fee_with_tax: '仲介手数料(税込)',
  contract_condition__seasonal_adjustment: '季節調整',
  contract_condition__seasonal_rent_from: '季節賃料FROM',
  contract_condition__seasonal_rent_to: '季節賃料TO',
  contract_condition__seasonal_fee_with_tax: '季節費用(税込)',
  contract_condition__prorated_rent_refund: '日割賃料返金(税込)',
  contract_condition__security_deposit_refund: '敷金返金(非課税)',
  parking_user__name: '利用者名',
  parking_user__name_kana: '利用者名(カナ)',
  parking_user__manager: '利用-担当者名',
  parking_user__email: '利用者メールアドレス',
  parking_user__mobile_phone_number: '利用者携帯電話番',
  parking_user__phone_number: '利用者自宅電話番号',
  parking_user__employee_number: '利用者従業員番号',
  parking_user__affiliation_branch_name: '利用者所属拠点名',
  parking_user__affiliation_branch_number: '利用者所属拠点番号',
  parking_user__affiliation_department_name: '利用者所属組織名',
  parking_user__affiliation_department_number: '利用者所属組織番号',
  parking_contractor__name: '契約者名',
  parking_contractor__name_kana: '契約者名(カナ)',
  parking_contractor__manager: '契約-担当者名',
  parking_contractor__email: '契約者メールアドレス',
  parking_contractor__phone_number: '契約者携帯電話番号',
  parking_contractor__company_phone_number: '契約者会社電話番号',
  parking_contractor__employee_number: '契約者従業員番号',
  parking_contractor__affiliation_branch_name: '契約者所属拠点名',
  parking_contractor__affiliation_branch_number: '契約者所属拠点番号',
  parking_contractor__affiliation_department_name: '契約者所属組織名',
  parking_contractor__affiliation_department_number: '契約者所属組織番号',
  client_company__client_company_name: '管理会社名',
  client_company__client_company_postal_code: '管理会社郵便番号',
  client_company__client_store_name: '店舗名',
  client_company__parking_name: '駐車場名',
  client_company__partition: '区画番号',
  client_company__partition_type: '区画種別',
  client_company__address: '駐車場住所',
  client_company__latitude: '経度',
  client_company__longitude: '緯度',
  client_company__partition_image1: '区画図1',
  client_company__partition_image2: '区画図2',
  client_company__partition_image3: '区画図3',
  client_company__invoice_name_or_number: '（管理会社）インボイス発行事業者の氏名または名称および登録番号',
  client_company__invoice_tax_rate: '（管理会社）適用税率',
  client_company__invoice_receiver_name: '（管理会社）書類の交付を受ける事業者の氏名または名称',
  client_company__guarantor_company_name: '保証会社名',
  client_company__guarantor_invoice_number: '保証会社インボイス登録番号',
  contract_vehicle__car_model: '車種',
  contract_vehicle__regno_region_name: '車種-登録番号（地域名）',
  contract_vehicle__regno_class_digit: '車種-登録番号（分類番号3桁）',
  contract_vehicle__regno_hiragana: '車種-登録番号（平仮名等）',
  contract_vehicle__regno_seq_digit: '車種-登録番号(一連指定番号4桁)',
  contract_vehicle__certificate_file: '車検証',
  contract_attribution__notice_memo1: 'メモ1',
  contract_attribution__notice_memo2: 'メモ2',
  contract_attribution__notice_memo3: 'メモ3',
  contract_attribution__memo1: 'メモ1',
  contract_attribution__memo2: 'メモ2',
  contract_attribution__memo3: 'メモ3',
  contract_attribution__usage: '使用用途',
  contract_attribution__other1: 'その他1',
  contract_attribution__other1_memo: 'その他1_メモ',
  contract_attribution__other2: 'その他2',
  contract_attribution__other2_memo: 'その他2_メモ',
  contract_attribution__other3: 'その他3',
  contract_attribution__other3_memo: 'その他3_メモ',
  lessor__lessor_type: '貸主区分',
  lessor__lessor_postal_code: '貸主郵便番号',
  lessor__invoice_tax_rate: '（貸主）適用税率',
  lessor__invoice_receiver_name: '（貸主）書類の交付を受ける事業者の氏名または名称',
  broker_company__broker_company_postal_code: '仲介会社郵便番号',
  broker_company__invoice_tax_rate: '（仲介会社）適用税率',
  broker_company__invoice_receiver_name: '（仲介会社）書類の交付を受ける事業者の氏名または名称',
} as const;

const showError = (response: any) => {
  alert(convert(response));
};

const convert = (data: any, section?: string): string => {
  let messages = '';

  if (typeof data === 'object') {
    const keys = Object.keys(data);
    keys.forEach(key => {
      if (Array.isArray(data[key])) {
        const localizedKey = LocalizedKeys[(section ? section + '__' : '') + key] ?? key;
        messages += `${localizedKey}\n` + data[key].map((message: string) => `  ・${message}\n`) + "\n";
      } else {
        messages += convert(data[key], key);
      }
    });
  }

  return messages;
}

const jpMonthLabels =  Array.from({ length: 12 }, (_, i) => `${i + 1}月`);


// ContractDetailのプロパティ名と、APIのプロパティ名が異なる部分のマッピング
const keyAlias: Record<string, string> = {
  'client_company.phone_number': 'client_company.client_company_phone_number',
  'client_company.fax_number': 'client_company.client_company_fax_number',
  'client_company.staff_name': 'client_company.client_company_staff_name',
  'client_company.staff_email': 'client_company.client_company_staff_email',
  'client_company.invoice_name_or_number': 'client_company.client_company_invoice_name_or_number',
  'client_company.invoice_tax_rate': 'client_company.client_company_invoice_tax_rate',
  'client_company.invoice_receiver_name': 'client_company.client_company_invoice_receiver_name',
  'client_company.note': 'client_company.client_company_note',
  'lessor.postal_code': 'lessor.lessor_postal_code',
  'lessor.address': 'lessor.lessor_address',
  'lessor.phone_number': 'lessor.lessor_phone_number',
  'lessor.fax_number': 'lessor.lessor_fax_number',
  'lessor.email': 'lessor.lessor_email',
  'lessor.invoice_name_or_number': 'lessor.lessor_invoice_name_or_number',
  'lessor.invoice_tax_rate': 'lessor.lessor_invoice_tax_rate',
  'lessor.invoice_receiver_name': 'lessor.lessor_invoice_receiver_name',
  'lessor.note': 'lessor.lessor_note',
  'broker_company.postal_code': 'broker_company.broker_company_postal_code',
  'broker_company.address': 'broker_company.broker_company_address',
  'broker_company.phone_number': 'broker_company.broker_company_phone_number',
  'broker_company.fax_number': 'broker_company.broker_company_fax_number',
  'broker_company.email': 'broker_company.broker_company_email',
};

const getValidationKeys = (obj: Record<string, any>, prefix: string = ''): string[] => {
  const keys: string[] = [];
  Object.keys(obj).forEach(key => {
    const fullPath = prefix ? `${prefix}.${key}` : key;
    const value = obj[key];
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      keys.push(...getValidationKeys(value, fullPath));
    } else {
      const key = fullPath
        .replace(/^contract_condition\./, 'contract.')
        .replace(/^parking_contractor\./, 'contractor.')
        .replace(/^contract_vehicle\./, 'car_info.');
      keys.push(keyAlias[key] ?? key);
    }
  });
  return keys;
}

const transformEmptyStringToNull = (value: any, originalValue: any) => {
  return typeof originalValue === 'string' && originalValue.trim() === '' ? null : value;
};

const formSchema = yup.object({
  management_state: yup.number().transform(transformEmptyStringToNull).nullable(),
  normal_contract: yup.boolean(),
  initial_procedure_type: yup.number().transform(transformEmptyStringToNull).nullable(),
  auto_update: yup.boolean(),
  rental_item: yup.string().nullable(),
  rental_item_contents: yup.string().nullable(),
  payment_bank: yup.string().nullable(),
  user: yup.object({
    name: yup.string().nullable(),
    name_kana: yup.string().nullable(),
    manager: yup.string().nullable(),
    email: yup.string().nullable().email('有効なメールアドレスを入力してください。'),
    mobile_phone_number: yup.string().nullable(),
    phone_number: yup.string().nullable(),
    employee_number: yup.string().nullable(),
    affiliation_branch_name: yup.string().nullable(),
    affiliation_branch_number: yup.string().nullable(),
    affiliation_department_name: yup.string().nullable(),
    affiliation_department_number: yup.string().nullable(),
  }),
  contractor: yup.object({
    name: yup.string().nullable(),
    name_kana: yup.string().nullable(),
    manager: yup.string().nullable(),
    email: yup.string().nullable().email('有効なメールアドレスを入力してください。'),
    phone_number: yup.string().nullable(),
    company_phone_number: yup.string().nullable(),
    employee_number: yup.string().nullable(),
    affiliation_branch_name: yup.string().nullable(),
    affiliation_branch_number: yup.string().nullable(),
    affiliation_department_name: yup.string().nullable(),
    affiliation_department_number: yup.string().nullable(),
  }),
  client_company: yup.object({
    client_company_name: yup.string().nullable(),
    client_store_name: yup.string().nullable(),
    client_company_postal_code: yup.string().transform(value => value.replace(/^(\d{3})(\d{1,4})$/, '$1-$2')).matches(/^(?:\d{3}-\d{4})?$/, 'ハイフン付きの正しい郵便番号を入力してください'),
    client_company_address: yup.string().nullable(),
    client_company_phone_number: yup.string().nullable(),
    client_company_fax_number: yup.string().nullable(),
    client_company_staff_name: yup.string().nullable(),
    client_company_staff_email: yup.string().nullable().email('有効なメールアドレスを入力してください。'),
    client_company_invoice_name_or_number: yup.string().nullable(),
    client_company_invoice_tax_rate: yup.number().transform(transformEmptyStringToNull).nullable().typeError('税率は数字で入力してください'),
    client_company_invoice_receiver_name: yup.string().nullable(),
    guarantor_company_name: yup.string().nullable(),
    guarantor_invoice_number: yup.string().nullable(),
    client_company_note: yup.string().nullable(),
    partition: yup.string().nullable(),
    partition_type: yup.number().transform(transformEmptyStringToNull).nullable(),
    address: yup.string().nullable(),
    latitude: yup.number().transform(transformEmptyStringToNull).nullable(),
    longitude: yup.number().transform(transformEmptyStringToNull).nullable(),
    parking_name: yup.string().nullable(),
    partition_image1: yup.string().nullable(),
    partition_image2: yup.string().nullable(),
    partition_image3: yup.string().nullable(),
  }),
  lessor: yup.object({
    lessor_type: yup.number().transform(transformEmptyStringToNull).nullable(),
    lessor_name: yup.string().nullable(),
    lessor_postal_code: yup.string().transform(value => value.replace(/^(\d{3})(\d{1,4})$/, '$1-$2')).matches(/^(?:\d{3}-\d{4})?$/, 'ハイフン付きの正しい郵便番号を入力してください'),
    lessor_address: yup.string().nullable(),
    lessor_phone_number: yup.string().nullable(),
    lessor_fax_number: yup.string().nullable(),
    lessor_email: yup.string().nullable().email('有効なメールアドレスを入力してください。'),
    lessor_invoice_name_or_number: yup.string().nullable(),
    lessor_invoice_tax_rate: yup.number().transform(transformEmptyStringToNull).nullable().typeError('税率は数字で入力してください'),
    lessor_invoice_receiver_name: yup.string().nullable(),
    lessor_note: yup.string().nullable(),
  }),
  broker_company: yup.object({
    broker_company_name: yup.string().nullable(),
    broker_company_postal_code: yup.string().transform(value => value.replace(/^(\d{3})(\d{1,4})$/, '$1-$2')).matches(/^(?:\d{3}-\d{4})?$/, 'ハイフン付きの正しい郵便番号を入力してください'),
    broker_company_address: yup.string().nullable(),
    broker_company_phone_number: yup.string().nullable(),
    broker_company_fax_number: yup.string().nullable(),
    broker_company_email: yup.string().nullable().email('有効なメールアドレスを入力してください。'),
    broker_company_invoice_name_or_number: yup.string().nullable(),
    broker_company_invoice_tax_rate: yup.number().transform(transformEmptyStringToNull).nullable().typeError('税率は数字で入力してください'),
    broker_company_invoice_receiver_name: yup.string().nullable(),
    broker_company_note: yup.string().nullable(),
  }),
  contract_attribution: yup.object({
    init_payment_company_name: yup.string().nullable(),
    init_payment_send_money_date: yup.date().transform(transformEmptyStringToNull).nullable().typeError('正しい日付を設定してください'),
    init_payment_bank_number: yup.string().nullable(),
    init_payment_bank_name: yup.string().nullable(),
    init_payment_branch_store_number: yup.string().nullable(),
    init_payment_branch_name: yup.string().nullable(),
    init_payment_deposit_kind_type: yup.number().transform(transformEmptyStringToNull).nullable(),
    init_payment_bank_account_number: yup.string().nullable(),
    init_payment_bank_account_name: yup.string().nullable(),
    init_payment_send_money_amount: yup.number().transform(transformEmptyStringToNull).nullable(),
    monthly_payment_company_name: yup.string().nullable(),
    monthly_payment_send_money_date: yup.date().transform(transformEmptyStringToNull).nullable().typeError('正しい日付を設定してください'),
    monthly_payment_bank_number: yup.string().nullable(),
    monthly_payment_bank_name: yup.string().nullable(),
    monthly_payment_branch_store_number: yup.string().nullable(),
    monthly_payment_branch_name: yup.string().nullable(),
    monthly_payment_deposit_kind_type: yup.number().transform(transformEmptyStringToNull).nullable(),
    monthly_payment_bank_account_number: yup.string().nullable(),
    monthly_payment_bank_account_name: yup.string().nullable(),
    monthly_payment_send_money_amount: yup.number().transform(transformEmptyStringToNull).nullable(),
    memo1: yup.string().nullable(),
    memo2: yup.string().nullable(),
    memo3: yup.string().nullable(),
    usage: yup.string().nullable(),
    other1: yup.string().nullable(),
    other1_memo: yup.string().nullable(),
    other2: yup.string().nullable(),
    other2_memo: yup.string().nullable(),
    other3: yup.string().nullable(),
    other3_memo: yup.string().nullable(),
    notice_memo1: yup.string().nullable(),
    notice_memo2: yup.string().nullable(),
    notice_memo3: yup.string().nullable(),
  }),
  contract: yup.object({
    tax_category: yup.number().transform(transformEmptyStringToNull).nullable(),
    contracted_date: yup.date().transform(transformEmptyStringToNull).nullable().typeError('正しい日付を設定してください'),
    cancel_date: yup.date().transform(transformEmptyStringToNull).nullable().typeError('正しい日付を設定してください'),
    need_issue_paper: yup.boolean(),
    issue_date: yup.date().transform(transformEmptyStringToNull).nullable().typeError('正しい日付を設定してください'),
    contract_period_month: yup.number().transform(transformEmptyStringToNull).nullable(),
    first_start_date: yup.date().transform(transformEmptyStringToNull).nullable(),
    start_date: yup.date().transform(transformEmptyStringToNull).nullable().typeError('正しい日付を設定してください'),
    end_date: yup.date().transform(transformEmptyStringToNull).nullable().typeError('正しい日付を設定してください'),
    rent_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    rent_without_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    first_month_rent_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    final_month_rent_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    first_month_rent_without_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    final_month_rent_without_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    monthly_guarantee_and_management_fee_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    initial_guarantee_and_management_fee_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    first_month_guarantee_and_management_fee_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    final_month_guarantee_and_management_fee_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    deposit_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    key_money_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    administrative_fee_with_tax:  yup.number().transform(transformEmptyStringToNull).nullable(),
    payment_method: yup.number().transform(transformEmptyStringToNull).nullable(),
    payment_date: yup.string().nullable(),
    initial_payment_date: yup.date().transform(transformEmptyStringToNull).nullable().typeError('正しい日付を設定してください'),
    update_base_fee_with_tax:  yup.number().transform(transformEmptyStringToNull).nullable(),
    update_management_fee_with_tax:  yup.number().transform(transformEmptyStringToNull).nullable(),
    renewal_management_fee_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    issue_paper_fee_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    brokerage_fee_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    seasonal_adjustment: yup.number().transform(transformEmptyStringToNull).nullable(),
    seasonal_rent_from: yup.number().transform(transformEmptyStringToNull).nullable(),
    seasonal_rent_to: yup.number().transform(transformEmptyStringToNull).nullable(),
    seasonal_fee_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    prorated_rent_refund: yup.number().transform(transformEmptyStringToNull).nullable(),
    security_deposit_refund: yup.number().transform(transformEmptyStringToNull).nullable(),
    initial_guarantee_fee_without_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    monthly_guarantee_fee_without_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
  }),
  car_info: yup.object({
    car_model: yup.string().nullable(),
    regno_region_name: yup.string().nullable(),
    regno_class_digit: yup.string().nullable(),
    regno_hiragana: yup.string().nullable(),
    regno_seq_digit: yup.string().nullable(),
    certificate: yup.string().nullable(),
    certificate_path: yup.string().nullable(),
    certificate_file: yup.string().nullable(),
  }),
});

type FormData = yup.InferType<typeof formSchema>;

type ValidationResult = 
  | { key: string; status: 'fulfilled' }
  | { key: string; status: 'rejected'; error: yup.ValidationError };

const safeValidateAt = (key: string, values: any): Promise<ValidationResult> => {
  return formSchema.validateAt(key, values)
    .then(() => ({ key, status: 'fulfilled' as const }))
    .catch((error: yup.ValidationError) => ({ key, status: 'rejected' as const, error }));
};

const validateFields = async (validationKeys: string[], values: any): Promise<yup.ValidationError[]> => {
  try {
    const results = await Promise.all(validationKeys.map(key => safeValidateAt(key, values)));
    const formErrors: yup.ValidationError[] = [];

    results.forEach(result => {
      if (result.status === 'rejected') {
        formErrors.push(result.error);
      }
    });

    return formErrors
  } catch (error) {
    console.error('Unexpected error:', error);
    return [];
  }
}

const registerMui = (res: UseFormRegisterReturn) => ({
  inputRef: res.ref,
  onChange: res.onChange,
  onBlur: res.onBlur,
  name: res.name,
})

const modalStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const modalContentStyle = css({
  backgroundColor: 'white',
  borderRadius: '10px',
  width: '80vw',
  borderWidth: 0,
  padding: '20px',
  overflow: 'scroll',
  maxHeight: '90vh',
});

const modalTitleStyle = css({
  color: 'black',
  backgroundColor: '#F6F6F6',
  padding: '10px',
  marginBottom: '20px',
  fontSize: '14px',
  fontWeight: 'bold',
});

const rowStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  gap: '0 2%',
});

const footerButtonsStyle = css({
  textAlign: 'center',
});

const footerButtonStyle = css({
  width: '120px',
  marginLeft: '20px',
  marginRight: '20px',
});

const selectFormStyle = css({
  width: '49%',
});

const certificateInputFileStyle = css({
  position: 'absolute',
  marginTop: '1em',
  right: 0,
  width: '30%',
});

const partitionImageInputFileStyle = css({
  position: 'absolute',
  right: '15%',
  bottom: '7px',
  width: '30%',
});

const inputFileColStyle = css({
  flex: 1,
  position: 'relative',
});

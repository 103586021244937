import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, TextFieldProps, Typography } from "@mui/material";
import { DatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ja } from 'date-fns/locale';
import { isValid } from 'date-fns';
import styled from "@emotion/styled";
import { api } from "../../infra/Api";
import { HttpStatus } from "../../models/HttpStatus";
import moment from "moment";
import { Statement } from "../../models/Statement";

export const StatementDownloadComp = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [statements, setStatements] = useState<Statement[]>([]);

  const fetchStatements = useCallback(async () => {
    if (selectedDate && isValid(selectedDate)) {
      const [httpStatus, data] = await api.get<Statement[]>(`api/v1/statements/?date=${moment(selectedDate).format('YYYY-MM-01')}`);

      if (httpStatus.status === HttpStatus.OK && data) {
        setStatements(data);
      }
    }
  }, [selectedDate]);

  const downloadStatement = useCallback(async (statement: Statement) => {
    const [httpStatus, blob] = await api.getBlob(`api/v1/statements/download/${statement.id}/`);
    if (!blob) return;

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', statement.filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      fetchStatements();
    } else if (statements) {
      setStatements([]);
    }
  }, [selectedDate]);

  return (
    <>
      <StyledSection>
        <StyledTitle>明細ダウンロード</StyledTitle>

        <StyledRow>
          <StyledLabel>明細の年月</StyledLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
            <DatePicker
              views={['year', 'month']}
              value={selectedDate}
              inputFormat="yyyy/MM"
              openTo="month"
              onChange={(date) => { setSelectedDate(date) }}
              renderInput={DateTextFiled}
            />
          </LocalizationProvider>
        </StyledRow>

        {statements.length > 0 && (
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCellHeader colSpan={2}>明細ファイル</StyledTableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {statements.sort((a, b) => a.filename.localeCompare(b.filename)).map((statement, index: number) => (
                <TableRow key={`statement-${index}`}>
                  <TableCell>{statement.filename}</TableCell>
                  <TableCell>
                    <StyledCellButtons>
                      <Button variant="contained" component="span" onClick={() => downloadStatement(statement)}>ダウンロード</Button>
                    </StyledCellButtons>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        )}
      </StyledSection>
    </>
  )
}

const StyledSection = styled.section`
  margin: 2em 3em;
`;

const StyledTitle = styled.h2`
  margin-top: 2rem;
  font-weight: normal;
`;

const StyledRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  gap: 0 1%;
`;

const StyledCellButtons = styled.div`
  display: flex;
  gap: 0 .5em;
`

const StyledLabel = styled.div`
  font-size: 12px;
  width: 100px;
  font-weight: bold;
`;

const StyledTable = styled(Table)`
`;

const StyledTableCellHeader = styled(TableCell)`
  font-weight: bold;
  font-size: 12px;
`;

const DateTextFiled = (params: TextFieldProps) => <TextField
  {...params}
  InputLabelProps={{ shrink: true, style: { visibility: 'hidden' } }}
  inputProps={{ ...params.inputProps, placeholder: "YYYY/MM" }}
  InputProps={{
    ...params.InputProps,
    style: {
      paddingTop: 0,
      paddingBottom: 0,
      background: '#ffffff',
      width: '20em',
      height: '39px',
    }
  }}
/>

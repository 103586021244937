import { toSnakeCase } from "./toScakeCase";

export const filterDataForForm = <T>(data: any, schema: any): T => {
  const filteredData: any = {};
  Object.keys(schema.fields).forEach(key => {
    const snakeKey = toSnakeCase(key);
    if (data[key] !== undefined) {
      if (typeof schema.fields[key] === 'object' && schema.fields[key].fields) {
        filteredData[snakeKey] = filterDataForForm(data[key], schema.fields[key]);
      } else {
        filteredData[snakeKey] = data[key];
      }
    }
  });
  return filteredData as T;
};

import styled from "@emotion/styled";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../../../atom/loggedInUserAtom";
import { api } from "../../../infra/Api";
import { HttpStatus } from "../../../models/HttpStatus";
import { StyledIcon, StyledPanelContainer, StyledPanelContent, StyledPanelHeader, StyledPanelTitle } from "./styles";
import { Notifications, Notification } from "./types";


export const NotificationPanel = () => {
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const organization = loggedInUser?.organization

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const fetchNotifications = useCallback(async () => {
    const  { status, result } = await fetchData();
    if (status.status === HttpStatus.OK) {
      setNotifications(result?.results ?? []);
    }
  }, []);

  useEffect(() => { 
    fetchNotifications()
  }, []);
  
  return (
    <StyledPanelContainer>
      <StyledPanelHeader>
        <StyledPanelTitle>お知らせ</StyledPanelTitle>
        <HeaderRight href={organization?.notificationsUrl} target="_blank">過去のお知らせ<StyledIcon icon={faChevronRight} /></HeaderRight>
      </StyledPanelHeader>
      <StyledPanelContent>
        {notifications.map((notification, index) => (
          <StyledList key={index}>
            <StyledLink href={notification.url} target="_blank">{notification.title}</StyledLink>
          </StyledList>
        ))}
      </StyledPanelContent>
    </StyledPanelContainer>
  )
}

const fetchData = async () => {
  const [status, result] = await api.get<Notifications>(
    "/api/v1/notifications/",
  );
  return { status, result };
};

const HeaderRight = styled.a`
  font-size: 13px;
  text-decoration: none;
  color: #0056B0;
`

const StyledList = styled.div`
  margin: 0.5em 0;
`

const StyledLink = styled.a`
  font-size: 13px;
  color: #b0012f;
`
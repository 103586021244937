import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { api } from "../../../infra/Api";
import { HttpStatus } from "../../../models/HttpStatus";
import { ContractDetail } from "../../../models/contract/ContractDetail";
import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { useForm, UseFormRegisterReturn } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { toSnakeCaseKeys } from "../../../util/toScakeCase";
import { filterDataForForm } from "../../../util/filterFormData";
import { initialProcedureType, lessortType, taxCategory, seasonalAdjustment } from "../../../constant";

export type PDContractDetailEditModalProps = {
  isVisible: boolean,
  onClose: (isUpdated: boolean) => void,
  contractDetail: ContractDetail,
  isAdmin?: boolean,
};

export const PDContractDetailEditModal = ({
  isVisible,
  onClose,
  contractDetail,
  isAdmin,
}: PDContractDetailEditModalProps) => {
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);
  
  const defaultValues = useMemo(() => filterDataForForm<FormData>(toSnakeCaseKeys(contractDetail), formSchema), [contractDetail]);

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onTouched',
    defaultValues,
  });

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (isMounted) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);
  
  const updateContract = useCallback(async (formData: FormData) => {
    try {
      setIsUploading(true);

      const {
        initial_procedure_type,
        user: parking_user,
        contractor: parking_contractor,
        client_company,
        lessor,
        broker_company,
        contract_attribution,
        contract: contract_condition,
      } = formData;

      const updatedContract = {
        initial_procedure_type,
        parking_user,
        parking_contractor,
        contract_attribution,
        contract_condition,
        ...(isAdmin ? {
          client_company: {
            client_company_postal_code: client_company.client_company_postal_code,
            invoice_receiver_name: client_company.client_company_invoice_receiver_name,
            invoice_tax_rate: client_company.client_company_invoice_tax_rate,   
            guarantor_company_name: client_company.guarantor_company_name,
            guarantor_invoice_number: client_company.guarantor_invoice_number,
          },
          lessor: {
            lessor_type: lessor.lessor_type,
            postal_code: lessor.lessor_postal_code,
            invoice_receiver_name: lessor.lessor_invoice_receiver_name,
            invoice_tax_rate: lessor.lessor_invoice_tax_rate,            
          },
          broker_company: {
            postal_code: broker_company.broker_company_postal_code,
            invoice_receiver_name: broker_company.broker_company_invoice_receiver_name,
            invoice_tax_rate: broker_company.broker_company_invoice_tax_rate,            
          },
        } : {
          client_company: {
            client_company_postal_code: client_company.client_company_postal_code,
            guarantor_company_name: client_company.guarantor_company_name,
            guarantor_invoice_number: client_company.guarantor_invoice_number,
          },
          lessor: {
            lessor_type: lessor.lessor_type,
            postal_code: lessor.lessor_postal_code,
          },
          broker_company: {
            postal_code: broker_company.broker_company_postal_code,
          },
        }),
      };

      const [res, response] = await api.patch(
        isAdmin
          ? `/api/v1/admin/contract/${contractDetail.id}/`
          : `/api/v1/contract/${contractDetail.id}/`
        , updatedContract);

      if (isMounted) {
        if (res.status === HttpStatus.OK) {
          onClose(true);
        } else {
          showError(response);
        }
      }
    } finally {
      if (isMounted) {
        setIsUploading(false);
      }
    }
  }, [onClose, contractDetail, isAdmin]);

  return (
    <Modal open={isVisible} css={modalStyle}>
      <div css={modalContentStyle}>
        <div css={modalTitleStyle}>基本情報</div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel id="initialProcedureType" variant="standard" shrink>初回契約手続き区分</InputLabel>
            <Select label="初回契約手続き区分" labelId="initialProcedureType" variant="standard" {...registerMui(register('initial_procedure_type'))} defaultValue={defaultValues.initial_procedure_type}>
              {initialProcedureType.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
            </Select>
          </FormControl>
        </div>
        <div css={modalTitleStyle}>利用者情報</div>
        <div css={rowStyle}>
          <StyledTextField label="利用者所属拠点名" {...registerMui(register('user.affiliation_branch_name'))} error={!!errors.user?.affiliation_branch_name} helperText={errors.user?.affiliation_branch_name?.message} />
          <StyledTextField label="利用者所属拠点番号" {...registerMui(register('user.affiliation_branch_number'))} error={!!errors.user?.affiliation_branch_number} helperText={errors.user?.affiliation_branch_number?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="利用者所属組織名" {...registerMui(register('user.affiliation_department_name'))} error={!!errors.user?.affiliation_department_name} helperText={errors.user?.affiliation_department_name?.message} />
          <StyledTextField label="利用者所属組織番号" {...registerMui(register('user.affiliation_department_number'))} error={!!errors.user?.affiliation_department_number} helperText={errors.user?.affiliation_department_number?.message} />
        </div>
        <div css={modalTitleStyle}>契約者情報</div>
        <div css={rowStyle}>
          <StyledTextField label="契約者従業員番号" {...registerMui(register('contractor.employee_number'))} error={!!errors.contractor?.employee_number} helperText={errors.contractor?.employee_number?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="契約者所属拠点名" {...registerMui(register('contractor.affiliation_branch_name'))} error={!!errors.contractor?.affiliation_branch_name} helperText={errors.contractor?.affiliation_branch_name?.message} />
          <StyledTextField label="契約者所属拠点番号" {...registerMui(register('contractor.affiliation_branch_number'))} error={!!errors.contractor?.affiliation_branch_number} helperText={errors.contractor?.affiliation_branch_number?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="契約者所属組織名" {...registerMui(register('contractor.affiliation_department_name'))} error={!!errors.contractor?.affiliation_department_name} helperText={errors.contractor?.affiliation_department_name?.message} />
          <StyledTextField label="契約者所属組織番号" {...registerMui(register('contractor.affiliation_department_number'))} error={!!errors.contractor?.affiliation_department_number} helperText={errors.contractor?.affiliation_department_number?.message} />
        </div>

        {isAdmin && (
          <>
            <div css={modalTitleStyle}>管理会社情報</div>
            <div css={rowStyle}>
            <StyledTextField label="管理会社郵便番号" {...registerMui(register('client_company.client_company_postal_code'))} error={!!errors.client_company?.client_company_postal_code} helperText={errors.client_company?.client_company_postal_code?.message} />
            <StyledTextField label="適用税率" {...registerMui(register('client_company.client_company_invoice_tax_rate'))} type="number" error={!!errors.client_company?.client_company_invoice_tax_rate} helperText={errors.client_company?.client_company_invoice_tax_rate?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('client_company.client_company_invoice_receiver_name'))} error={!!errors.client_company?.client_company_invoice_receiver_name} helperText={errors.client_company?.client_company_invoice_receiver_name?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="保証会社名" {...registerMui(register('client_company.guarantor_company_name'))} error={!!errors.client_company?.guarantor_company_name} helperText={errors.client_company?.guarantor_company_name?.message} />
              <StyledTextField label="保証会社インボイス登録番号" {...registerMui(register('client_company.guarantor_invoice_number'))} error={!!errors.client_company?.guarantor_invoice_number} helperText={errors.client_company?.guarantor_invoice_number?.message} />
            </div>
          </>
        )}
        <div css={modalTitleStyle}>貸主情報</div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel id="lessorType" variant="standard" shrink>貸主区分</InputLabel>
            <Select label="貸主区分" labelId="lessorType" variant="standard" {...registerMui(register('lessor.lessor_type'))} error={!!errors.lessor?.lessor_type} defaultValue={defaultValues.lessor.lessor_type}>
              {lessortType.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
            </Select>
          </FormControl>
        </div>
        {isAdmin ? (
          <>
            <div css={rowStyle}>
              <StyledTextField label="貸主郵便番号" {...registerMui(register('lessor.lessor_postal_code'))} error={!!errors.lessor?.lessor_postal_code} helperText={errors.lessor?.lessor_postal_code?.message} />
              <StyledTextField label="適用税率" {...registerMui(register('lessor.lessor_invoice_tax_rate'))} type="number" error={!!errors.lessor?.lessor_invoice_tax_rate} helperText={errors.lessor?.lessor_invoice_tax_rate?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('lessor.lessor_invoice_receiver_name'))} error={!!errors.lessor?.lessor_invoice_receiver_name} helperText={errors.lessor?.lessor_invoice_receiver_name?.message} />
            </div>
          </>
        ) : (
          <div css={rowStyle}>
            <StyledTextField label="貸主郵便番号" {...registerMui(register('lessor.lessor_postal_code'))} error={!!errors.lessor?.lessor_postal_code} helperText={errors.lessor?.lessor_postal_code?.message} />
          </div>
        )}
        <div css={modalTitleStyle}>仲介会社情報</div>
        {isAdmin ? (
          <>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社郵便番号" {...registerMui(register('broker_company.broker_company_postal_code'))} error={!!errors.broker_company?.broker_company_postal_code} helperText={errors.broker_company?.broker_company_postal_code?.message} />
              <StyledTextField label="適用税率" {...registerMui(register('broker_company.broker_company_invoice_tax_rate'))} type="number" error={!!errors.broker_company?.broker_company_invoice_tax_rate} helperText={errors.broker_company?.broker_company_invoice_tax_rate?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('broker_company.broker_company_invoice_receiver_name'))} error={!!errors.broker_company?.broker_company_invoice_receiver_name} helperText={errors.broker_company?.broker_company_invoice_receiver_name?.message} />
            </div>
          </>
        ) : (
          <div css={rowStyle}>
            <StyledTextField label="仲介会社郵便番号" {...registerMui(register('broker_company.broker_company_postal_code'))} error={!!errors.broker_company?.broker_company_postal_code} helperText={errors.broker_company?.broker_company_postal_code?.message} />
          </div>
        )}
        <div css={modalTitleStyle}>備考</div>
        <div css={rowStyle}>
          <StyledTextField label="メモ1" {...registerMui(register('contract_attribution.memo1'))} error={!!errors.contract_attribution?.memo1} helperText={errors.contract_attribution?.memo1?.message} />
          <StyledTextField label="メモ2" {...registerMui(register('contract_attribution.memo2'))} error={!!errors.contract_attribution?.memo2} helperText={errors.contract_attribution?.memo2?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="メモ3" {...registerMui(register('contract_attribution.memo3'))} error={!!errors.contract_attribution?.memo3} helperText={errors.contract_attribution?.memo3?.message} />
          <StyledTextField label="使用用途" {...registerMui(register('contract_attribution.usage'))} error={!!errors.contract_attribution?.usage} helperText={errors.contract_attribution?.usage?.message} />
        </div>
        <div css={modalTitleStyle}>契約条件</div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel id="taxCategory" variant="standard" shrink>税区分</InputLabel>
            <Select label="税区分" labelId="taxCategory" variant="standard"
              {...registerMui(register('contract.tax_category'))} error={!!errors.contract?.tax_category} defaultValue={defaultValues.contract.tax_category}>
              {taxCategory.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
            </Select>
          </FormControl>
          <StyledTextField type="number" label="仲介手数料(税込)" {...registerMui(register('contract.brokerage_fee_with_tax'))} error={!!errors.contract?.brokerage_fee_with_tax} helperText={errors.contract?.brokerage_fee_with_tax?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField type="number" label="初回保証料(非課税)" {...registerMui(register('contract.initial_guarantee_fee_without_tax'))} error={!!errors.contract?.initial_guarantee_fee_without_tax} helperText={errors.contract?.initial_guarantee_fee_without_tax?.message} />
          <StyledTextField type="number" label="月額保証料(非課税)" {...registerMui(register('contract.monthly_guarantee_fee_without_tax'))} error={!!errors.contract?.monthly_guarantee_fee_without_tax} helperText={errors.contract?.monthly_guarantee_fee_without_tax?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="その他1" {...registerMui(register('contract_attribution.other1'))} error={!!errors.contract_attribution?.other1} helperText={errors.contract_attribution?.other1?.message} />
          <StyledTextField label="その他1_メモ" {...registerMui(register('contract_attribution.other1_memo'))} error={!!errors.contract_attribution?.other1_memo} helperText={errors.contract_attribution?.other1_memo?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="その他2" {...registerMui(register('contract_attribution.other2'))} error={!!errors.contract_attribution?.other2} helperText={errors.contract_attribution?.other2?.message} />
          <StyledTextField label="その他2_メモ" {...registerMui(register('contract_attribution.other2_memo'))} error={!!errors.contract_attribution?.other2_memo} helperText={errors.contract_attribution?.other2_memo?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="その他3" {...registerMui(register('contract_attribution.other3'))} error={!!errors.contract_attribution?.other3} helperText={errors.contract_attribution?.other3?.message} />
          <StyledTextField label="その他3_メモ" {...registerMui(register('contract_attribution.other3_memo'))} error={!!errors.contract_attribution?.other3_memo} helperText={errors.contract_attribution?.other3_memo?.message} />
        </div>
        <div css={modalTitleStyle}>お知らせ・注意喚起のメモ</div>
        <div css={rowStyle}>
          <StyledTextField label="メモ1" {...registerMui(register('contract_attribution.notice_memo1'))} error={!!errors.contract_attribution?.notice_memo1} helperText={errors.contract_attribution?.notice_memo1?.message} />
          <StyledTextField label="メモ2" {...registerMui(register('contract_attribution.notice_memo2'))} error={!!errors.contract_attribution?.notice_memo2} helperText={errors.contract_attribution?.notice_memo2?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="メモ3" {...registerMui(register('contract_attribution.notice_memo3'))} error={!!errors.contract_attribution?.notice_memo3} helperText={errors.contract_attribution?.notice_memo3?.message} />
        </div>
        <div css={modalTitleStyle}>その他費用</div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel id="contract.seasonal_adjustment" variant="standard" shrink>季節調整</InputLabel>
            <Select label="季節調整" labelId="seasonalAdjustment" variant="standard" {...registerMui(register('contract.seasonal_adjustment'))} error={!!errors.contract?.seasonal_adjustment} defaultValue={defaultValues.contract.seasonal_adjustment}>
              {seasonalAdjustment.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
            </Select>
          </FormControl>
        </div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel id="seasonalRentFrom" variant="standard" shrink>季節賃料FROM</InputLabel>
            <Select label="季節調整" labelId="seasonalRentFrom" variant="standard" {...registerMui(register('contract.seasonal_rent_from'))} error={!!errors.contract?.seasonal_rent_from} defaultValue={defaultValues.contract.seasonal_rent_from}>
              <MenuItem value={0}></MenuItem>
              {jpMonthLabels.map((value, index) => <MenuItem key={index} value={1 + index}>{value}</MenuItem>)}
              </Select>
          </FormControl>
          <FormControl css={selectFormStyle}>
            <InputLabel id="seasonalRentTo" variant="standard" shrink>季節賃料TO</InputLabel>
            <Select label="季節調整" labelId="seasonalRentTo" variant="standard" {...registerMui(register('contract.seasonal_rent_to'))} error={!!errors.contract?.seasonal_rent_to} defaultValue={defaultValues.contract.seasonal_rent_to}>
              <MenuItem value={0}></MenuItem>
              {jpMonthLabels.map((value, index) => <MenuItem key={index} value={1 + index}>{value}</MenuItem>)}
              </Select>
          </FormControl>
        </div>
        <div css={rowStyle}>
          <StyledTextField type="number" label="季節費用(税込)" {...registerMui(register('contract.seasonal_fee_with_tax'))} error={!!errors.contract?.seasonal_fee_with_tax} helperText={errors.contract?.seasonal_fee_with_tax?.message} />
          <StyledTextField type="number" label="日割賃料返金(税込)" {...registerMui(register('contract.prorated_rent_refund'))} error={!!errors.contract?.prorated_rent_refund} helperText={errors.contract?.prorated_rent_refund?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField type="number" label="敷金返金(非課税)" {...registerMui(register('contract.security_deposit_refund'))} error={!!errors.contract?.security_deposit_refund} helperText={errors.contract?.security_deposit_refund?.message} />
        </div>

        <div css={footerButtonsStyle}>
          <LoadingButton variant="contained" onClick={handleSubmit(updateContract)} css={footerButtonStyle} loading={isUploading} disabled={isUploading}>保存</LoadingButton>
          <Button variant="text" onClick={() => onClose(false)} css={footerButtonStyle} disabled={isUploading}>キャンセル</Button>
        </div>
      </div>
    </Modal>
  )
}

const LocalizedKeys: { [key: string]: string } = {
  // 基本情報
  initial_procedure_type: '初回契約手続き区分',
  // 利用者情報
  parking_user__employee_number: '利用者従業員番号',
  parking_user__affiliation_branch_name: '利用者所属拠点名',
  parking_user__affiliation_branch_number: '利用者所属拠点番号',
  parking_user__affiliation_department_name: '利用者所属組織名',
  parking_user__affiliation_department_number: '利用者所属組織番号',
  // 契約者情報
  parking_contractor__employee_number: '契約者従業員番号',
  parking_contractor__affiliation_branch_name: '契約者所属拠点名',
  parking_contractor__affiliation_branch_number: '契約者所属拠点番号',
  parking_contractor__affiliation_department_name: '契約者所属組織名',
  parking_contractor__affiliation_department_number: '契約者所属組織番号',
  // 管理会社情報
  client_company__client_company_postal_code: '管理会社郵便番号',
  client_company__guarantor_company_name: '保証会社名',
  client_company__guarantor_invoice_number: '保証会社インボイス登録番号',
  client_company__invoice_tax_rate: '（管理会社）適用税率',  // ※管理者のみ編集可能
  client_company__invoice_receiver_name: '（管理会社）書類の交付を受ける事業者の氏名または名称',  // ※管理者のみ編集可能
  // 貸主情報
  lessor__lessor_type: '貸主区分',
  lessor__lessor_postal_code: '貸主郵便番号',
  lessor__invoice_tax_rate: '（貸主）適用税率',  // ※管理者のみ編集可能
  lessor__invoice_receiver_name: '（貸主）書類の交付を受ける事業者の氏名または名称',  // ※管理者のみ編集可能
  // 仲介会社情報
  broker_company__broker_company_postal_code: '仲介会社郵便番号',
  broker_company__invoice_tax_rate: '（仲介会社）適用税率',  // ※管理者のみ編集可能
  broker_company__invoice_receiver_name: '（仲介会社）書類の交付を受ける事業者の氏名または名称',  // ※管理者のみ編集可能
  // 備考　※管理者のみ編集可能
  contract_attribution__notice_memo1: 'メモ1',
  contract_attribution__notice_memo2: 'メモ2',
  contract_attribution__notice_memo3: 'メモ3',
  // 契約条件
  contract_condition__tax_category: '税区分', 
  contract_condition__brokerage_fee_with_tax: '仲介手数料(税込)',
  contract_acon__other1: 'その他1',  // ※管理者のみ編集可能
  contract_attribution__other1_memo: 'その他1_メモ',  // ※管理者のみ編集可能
  contract_attribution__other2: 'その他2',  // ※管理者のみ編集可能
  contract_attribution__other2_memo: 'その他2_メモ',  // ※管理者のみ編集可能
  contract_attribution__other3: 'その他3',  // ※管理者のみ編集可能
  contract_attribution__other3_memo: 'その他3_メモ',  // ※管理者のみ編集可能
  // お知らせ・注意喚起のメモ (管理者のみ編集可能)
  contract_attribution__memo1: 'メモ1',
  contract_attribution__memo2: 'メモ2',
  contract_attribution__memo3: 'メモ3',
  // その他費用
  contract_condition__seasonal_adjustment: '季節調整',
  contract_condition__seasonal_rent_from: '季節賃料FROM',
  contract_condition__seasonal_rent_to: '季節賃料TO',
  contract_condition__seasonal_fee_with_tax: '季節費用(税込)',
  contract_condition__prorated_rent_refund: '日割賃料返金(税込)',
  contract_condition__security_deposit_refund: '敷金返金(非課税)',
} as const;

const showError = (response: any) => {
  alert(convert(response));
};

const convert = (data: any, section?: string): string => {
  let messages = '';

  if (typeof data === 'object') {
    const keys = Object.keys(data);
    keys.forEach(key => {
      if (Array.isArray(data[key])) {
        const localizedKey = LocalizedKeys[(section ? section + '__' : '') + key] ?? key;
        messages += `${localizedKey}\n` + data[key].map((message: string) => `  ・${message}\n`) + "\n";
      } else {
        messages += convert(data[key], key);
      }
    });
  }

  return messages;
}

const transformEmptyStringToNull = (value: any, originalValue: any) => {
  return typeof originalValue === 'string' && originalValue.trim() === '' ? null : value;
};

const formSchema = yup.object({
  initial_procedure_type: yup.number().transform(transformEmptyStringToNull).nullable(),
  user: yup.object({
    employee_number: yup.string().nullable(),
    affiliation_branch_name: yup.string().nullable(),
    affiliation_branch_number: yup.string().nullable(),
    affiliation_department_name: yup.string().nullable(),
    affiliation_department_number: yup.string().nullable(),
  }),
  contractor: yup.object({
    employee_number: yup.string().nullable(),
    affiliation_branch_name: yup.string().nullable(),
    affiliation_branch_number: yup.string().nullable(),
    affiliation_department_name: yup.string().nullable(),
    affiliation_department_number: yup.string().nullable(),
  }),
  client_company: yup.object({
    client_company_postal_code: yup.string().transform(value => value.replace(/^(\d{3})(\d{1,4})$/, '$1-$2')).matches(/^(?:\d{3}-\d{4})?$/, 'ハイフン付きの正しい郵便番号を入力してください'),
    client_company_invoice_tax_rate: yup.number().transform(transformEmptyStringToNull).nullable().typeError('税率は数字で入力してください'),
    client_company_invoice_receiver_name: yup.string().nullable(),
    guarantor_company_name: yup.string().nullable(),
    guarantor_invoice_number: yup.string().nullable(),
  }),
  lessor: yup.object({
    lessor_type: yup.number().transform(transformEmptyStringToNull).nullable(),
    lessor_postal_code: yup.string().transform(value => value.replace(/^(\d{3})(\d{1,4})$/, '$1-$2')).matches(/^(?:\d{3}-\d{4})?$/, 'ハイフン付きの正しい郵便番号を入力してください'),
    lessor_invoice_tax_rate: yup.number().transform(transformEmptyStringToNull).nullable().typeError('税率は数字で入力してください'),
    lessor_invoice_receiver_name: yup.string().nullable(),
  }),
  broker_company: yup.object({
    broker_company_postal_code: yup.string().transform(value => value.replace(/^(\d{3})(\d{1,4})$/, '$1-$2')).matches(/^(?:\d{3}-\d{4})?$/, 'ハイフン付きの正しい郵便番号を入力してください'),
    broker_company_invoice_tax_rate: yup.number().transform(transformEmptyStringToNull).nullable().typeError('税率は数字で入力してください'),
    broker_company_invoice_receiver_name: yup.string().nullable(),
  }),
  contract_attribution: yup.object({
    memo1: yup.string().nullable(),
    memo2: yup.string().nullable(),
    memo3: yup.string().nullable(),
    usage: yup.string().nullable(),
    other1: yup.string().nullable(),
    other1_memo: yup.string().nullable(),
    other2: yup.string().nullable(),
    other2_memo: yup.string().nullable(),
    other3: yup.string().nullable(),
    other3_memo: yup.string().nullable(),
    notice_memo1: yup.string().nullable(),
    notice_memo2: yup.string().nullable(),
    notice_memo3: yup.string().nullable(),
  }),
  contract: yup.object({
    tax_category: yup.number().transform(transformEmptyStringToNull).nullable(),
    brokerage_fee_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    seasonal_adjustment: yup.number().transform(transformEmptyStringToNull).nullable(),
    seasonal_rent_from: yup.number().transform(transformEmptyStringToNull).nullable(),
    seasonal_rent_to: yup.number().transform(transformEmptyStringToNull).nullable(),
    seasonal_fee_with_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    prorated_rent_refund: yup.number().transform(transformEmptyStringToNull).nullable(),
    security_deposit_refund: yup.number().transform(transformEmptyStringToNull).nullable(),
    initial_guarantee_fee_without_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
    monthly_guarantee_fee_without_tax: yup.number().transform(transformEmptyStringToNull).nullable(),
  }),
});

const jpMonthLabels =  Array.from({ length: 12 }, (_, i) => `${i + 1}月`);

type FormData = yup.InferType<typeof formSchema>;

const registerMui = (res: UseFormRegisterReturn) => ({
  inputRef: res.ref,
  onChange: res.onChange,
  onBlur: res.onBlur,
  name: res.name,
})

// Styles

const modalStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const modalContentStyle = css({
  backgroundColor: 'white',
  borderRadius: '10px',
  width: '80vw',
  borderWidth: 0,
  padding: '20px',
  overflow: 'scroll',
  maxHeight: '90vh',
});

const modalTitleStyle = css({
  color: 'black',
  backgroundColor: '#F6F6F6',
  padding: '10px',
  marginBottom: '20px',
  fontSize: '14px',
  fontWeight: 'bold',
});

const rowStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  gap: '0 2%',
});

const footerButtonsStyle = css({
  textAlign: 'center',
});

const footerButtonStyle = css({
  width: '120px',
  marginLeft: '20px',
  marginRight: '20px',
});

const selectFormStyle = css({
  width: '49%',
});

const StyledTextField = styled(TextField) <{ link?: boolean }>`
  width: 49%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledTextField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};

const StyledFileField = styled(TextField) <{ link?: boolean }>`
  width: 100%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledFileField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};

import { ContractDetail, convertToContractDetail } from "../contract/ContractDetail";
import { Payment } from "../payment/PaymentFromApi";

export interface PaymentDetail {
    id: string;
    contract: ContractDetail;
    totalToPay: string;
    monthOfPay: Date;
    paymentId?: any;
    createdAt: Date;
    updatedAt: Date;
}


export interface PaymentItem {
    id: string;
    totalToPay: string;
    monthOfPay: Date;
    createdAt: Date;
    updatedAt: Date;
}


export const convertToPaymentList = (paymentList:[]): PaymentItem[] => {

    let payment_list:PaymentItem[] = [];

    paymentList.forEach(payment => {
        payment_list.push({
            id: payment["id"],
            totalToPay: payment["total_to_pay"],
            monthOfPay: payment["month_of_pay"],
            createdAt: payment["created_at"],
            updatedAt: payment["updated_at"],
        })
    });
    return payment_list;     
}

export const convertToPaymentDetail = (payment: Payment): PaymentDetail => {
    return {
        id: payment.id,
        contract: convertToContractDetail(payment.contract),
        totalToPay: payment.total_to_pay,
        monthOfPay: payment.month_of_pay,
        paymentId: payment.payment_id,
        createdAt: payment.created_at,
        updatedAt: payment.updated_at
    }
}
import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { LoginState, useUser } from "../..//hooks/useUser";
import { Loading } from "./Loading";

export const LoginCheck: FC = () => {
  const [loginState] = useUser();

  if (loginState === LoginState.UnFetched) {
    return <Loading />;
  }

  if (loginState === LoginState.Error) {
    return <Navigate to="/login" />;
  }

  return <></>;
};
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../../../atom/loggedInUserAtom";
import { StyledModal, StyledModalContainer, StyledTitle, StyledSubTitle, StyledMenuContainer, StyledCard } from "./styles";
import { MenuModalProps } from "./types";

export const MenuModal = ({
  menuType,
  onClose,
}: MenuModalProps) => {
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const organization = loggedInUser?.organization

  return (
    <StyledModal open={!!menuType} onBackdropClick={onClose}>
      <StyledModalContainer>
        <StyledTitle>{menuType === 'new_contract' ? '新規依頼／編集メニュー' : '解約メニュー'}</StyledTitle>
        {menuType === 'new_contract' &&
          <>
            <StyledSubTitle>契約タイプに応じた申込みフォームを選択してください。</StyledSubTitle>
            <StyledMenuContainer>
              <MenuButton title="Park Direct駐車場検索" url="https://www.park-direct.jp/" />
              <MenuButton title="Park Direct経由申込" url={organization?.entryPdFormUrl} topSpacer />
              <MenuButton title="代理契約申込" url={organization?.entryPdAgencyFormUrl} />
              <MenuButton title="保管場所使用承諾証明書の発行依頼" url={organization?.updateContractFormUrl} topSpacer />
            </StyledMenuContainer>
          </>
        }
        {menuType === 'cancel_contract' &&
          <>
            <StyledSubTitle>契約タイプに応じた解約フォームを選択してください。</StyledSubTitle>
            <StyledMenuContainer>
              <MenuButton title="ParkDirect経由／代理契約" url={organization?.cancelPdContractFormUrl} />
            </StyledMenuContainer>
          </>
        }
      </StyledModalContainer>
    </StyledModal>
  )
}

const MenuButton = ({ title, url, topSpacer }: { title: string, url?: string, topSpacer?: boolean }) => (
  <StyledCard topSpacer={topSpacer} onClick={() => { url && window.open(url, '_blank', 'noreferrer=yes') }}>
    {title}
    <FontAwesomeIcon icon={faUpRightFromSquare} color="#ffffff80" />
  </StyledCard>
)

import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { PaymentList } from "../parts/Payment/PaymentList";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useApiGet } from "../../hooks/useApiGet";
import moment from "moment";
import { HttpStatus } from "../../models/HttpStatus";
import { Loading } from "../parts/Loading";
import ja from 'date-fns/locale/ja';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { api } from "../../infra/Api";
import { saveAs } from "file-saver";
import { usePaymentQuery } from "../../hooks/usePaymentQuery";
import { PaymentFromApi } from "../../models/payment/PaymentFromApi";
import { convertToPaymentDetail, PaymentDetail } from "../../models/payment/PaymentDetail";
import { Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Paginator } from "../parts/Paginator/Paginator";
import { ContractDetailLinks } from "../parts/Contract/ContractDetailLinks";



// jaのロケールの設定が週頭が月曜始まりになっているので日曜始まりにする
ja.options = { weekStartsOn: 0 };
// ReactDatepickerのロケール登録
registerLocale('ja', ja);



export const PaymentListComp = () => {

  const { page } = useParams();
  const navigate = useNavigate();

  const initPage = page === undefined ? 1 : parseInt(page);
  const [currentPage, setCurrentPage] = useState(initPage);
  const [isCSVDownloadProcessing, setIsCSVDownloadProcessing] = useState(false);

  const {
    q,
    setPaymentKindPD,
    setPaymentKindCC,
    setPaymentKindDirectPd,
    setPaymentKindAccountTransfer,
    setPaymentKindTransfer,
    setPaymentMonthStart,
    setPaymentMonthEnd,
    setFreeWordState,
    paymentKindPD,
    paymentKindCC,
    paymentKindDirectPd,
    paymentKindAccountTransfer,
    paymentMonthStart,
    paymentMonthEnd,
    paymentKindTransfer,
    freeWordState,
    reset,
    isChanged
  } = usePaymentQuery();

  const [httpStatus, payments, reFetch] =
    useApiGet<PaymentFromApi>(`/api/v1/payments?ordering=id${q}&page=${currentPage}`);

  const textRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    reFetch(`/api/v1/payments?&ordering=id${q}&page=${currentPage}`);
    navigate(`/payment-list/${currentPage}`)
  }, [currentPage, reFetch, navigate, q]);


  const setPaymentKindPDSession = async(data:boolean) => {
    if (data){
      sessionStorage.setItem('paymentKindPD',"true");
    } else{
      sessionStorage.setItem('paymentKindPD',"false");
    }
  }

  const setPaymentKindTransferSession = async(data:boolean) => {
    if (data){
      sessionStorage.setItem('paymentKindTransfer',"true");
    } else{
      sessionStorage.setItem('paymentKindTransfer',"false");
    }
  }

  const setPaymentKindAccountTransferSession = async(data:boolean) => {
    if (data){
      sessionStorage.setItem('paymentKindAccountTransfer', "true");
    } else{
      sessionStorage.setItem('paymentKindAccountTransfer', "false");
    }
  }

  const setPaymentKindCCSession = async(data:boolean) => {
    if (data){
      sessionStorage.setItem('paymentKindCC', "true");
    } else{
      sessionStorage.setItem('paymentKindCC', "false");
    }
  }

  const setPaymentKindDirectPdSession = async(data:boolean) => {
    if (data){
      sessionStorage.setItem('paymentKindDirectPd', "true");
    } else{
      sessionStorage.setItem('paymentKindDirectPd', "false");
    }
  }

  const setPaymentMonthStartSession = async(date:Date) => {
    let date_string = ''
    if (date != null){
      date_string = date.toString();
    }
    sessionStorage.setItem('paymentMonthStart', date_string);
  }

  const setPaymentMonthEndSession = async(date:Date) => {
    let date_string = ''
    if (date != null){
      date_string = date.toString();
    }
    sessionStorage.setItem('paymentMonthEnd', date_string);
  }

  const setFreeWordSession = async(freeWord:string|undefined) => {
    if (freeWord == undefined){
      freeWord = ""
    }
    sessionStorage.setItem('freeWord', freeWord);
  }
  
  const downloadCSV = async (q: string, startDate: Date | null, endDate: Date | null) => {
    if (isCSVDownloadProcessing) return;

    try {
      setIsCSVDownloadProcessing(true);

      if (startDate === null || endDate === null) {
        return;
      }
  
      const start = moment(startDate).format("YYYY-MM-01");
      const end = moment(endDate).endOf("month").format("YYYY-MM-DD");
  
      const [httpStatus, csv] = await api.getRaw<string>(
        `/api/v1/payments_export?&ordering=id${q}`,
        "text/csv",
        "text/csv");
  
      if (csv === undefined) {
        return;
      }
  
      const bom = "\uFEFF";
      const blob = new Blob([bom, csv], { type: 'text/csv' });
      saveAs(blob, `決済情報-${start}-${end}.csv`);
    } finally {
      setIsCSVDownloadProcessing(false);
    }
  };
  
  if (httpStatus.status === HttpStatus.UNAUTHORIZED) {
    return <Navigate to="/login" />
  }


  if (httpStatus.status !== HttpStatus.OK || payments === undefined) {
    return <Loading />
  }

  // CSS

  const sectionStyle = css({
    width: "100%",
    paddingBottom: "10rem",
    minWidth: "800px"
  });


  const titleStyle = css({
    fontSize: "12px",
    fontWeight: "bold",
    width: "100px",
    display: "flex",
    alignItems: "center"
  });

  const subtitleStyle = css({
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    width: "215px",
    alignItems: "center"
  });

  const headStyle = css({
    marginLeft: "3rem",
    marginTop: "2rem",
    fontWeight: "normal"
  });

  const filtersStyle = css({
    margin: "1rem", marginBottom: "0", padding: "2rem 0"
  });

  const menuContainerStyle = css({
    display: "flex",
    justifyContent: "left",
    flexShrink: "initial",
    flexWrap: "wrap",
    marginLeft: "2rem",
    columnGap: "1rem",
    marginBottom: "0.5rem",
    rowGap: "1rem"
  });

  const datePickerStyle = css({
    fontSize: "14px",
    background: "#FFFFFF",
    textAlign: "left",
    padding: "0",
    paddingLeft: "1rem",
    width: "160px",
    borderRadius: "4px",
    height: "40px",
    border: "solid 1px #DCDCDC"
  });

  const inputWrapperStyle = css({
    display: "flex",
    justifyContent: "left",
    flexShrink: "initial",
    columnGap: "0.5rem",
    alignItems: "center",
  });

  const checkboxLabelStyle = css({
    fontSize: "13px",
    color: "#222222"
  });


  const buttonStyle = css({
    border: "none",
    margin: "2rem",
    marginBottom: "0",
    background: "#0351a1",
    transition: "ease-in all 0.1s",
    borderRadius: "4px",
    padding: "8px 1rem",
    minWidth: "10rem",
    color: "white",
    ":hover": {
      cursor: "pointer",
      background: "#0351a1",
      color: "white",
      border: "none",
      opacity: "0.7"
    },
    "&:disabled": {
      background: "#D4D4D4",
      cursor: "not-allowed",
      opacity: "1",
    },
  });

  const searchInputWrapperStyle = css({
    display: "flex",
    background: "white",
    alignItems: "center",
    width: "396px",
    height: "40px",
    border: "solid 1px #DCDCDC",
    borderRadius: "4px",
    paddingLeft: "1rem",
    color: "#222222",
    "&:focus": {
      outline: "2px solid"
    }
  });

  const searchInputStyle = css({
    width: "100%",
    height: "90%",
    border: "none",
    "&:focus-visible": {
      outline: "none"
    }
  });

  const checkBoxStyle = css({
    width: "18px",
    height: "18px",
    "&:checked + CheckBoxLabel": {
      backgroundColor: "#0056B0",
      borderColor: "red",
      color: "#005680"
    }
  });

  const datePickerWrapperStyle = css({
    display: "flex",
    alignItems: "center",
    minHeight: "40px"
  });


  const contractListStyle = css({
    background: "white",
    paddingBottom: "2rem"
  });


  const csvDownloadContainer = css({
    textAlign: "right",
    background: "white",
    marginLeft: "1rem",
    marginRight: "1rem",
  });

  const paginationWrapperStyle = css({
    display: "flex",
    marginRight: "2rem",
    justifyContent: "right",
  });

  const clearButtonStyle = css({
    border: "none",
    fontSize: "12px",
    marginLeft: "auto",
    marginRight: "2rem",
    background: isChanged() ? "#0351a1" : "#D4D4D4",
    transition: "ease-in all 0.1s",
    borderRadius: "4px",
    padding: "14px 1rem",
    minWidth: "10rem",
    color: "white",
    ":hover": {
      cursor: isChanged() ? "pointer" : "auto",
      color: "white",
      border: "none",
      opacity: isChanged() ? "0.7" : "1"
    }
  });

  const freeWordWrapperStyle = css({
    display: "flex",
    alignItems: "center"
  });

  const paymentDetailList: PaymentDetail[] = payments.results?.map(x => convertToPaymentDetail(x)) ?? [];

  return (
    <section css={sectionStyle}>
      <h2 css={headStyle}>決済一覧</h2>
      <article css={filtersStyle}>
        <div css={menuContainerStyle}>
          <div css={titleStyle}>決済タイプ</div>
          <div css={subtitleStyle}>ParkDirect契約／ParkDirect代理契約</div>
          <div css={inputWrapperStyle}>
            <Checkbox
              css={checkBoxStyle}
              checked={paymentKindPD}
              onChange={(e) => {setPaymentKindPDSession(e.target.checked); setPaymentKindPD(e.target.checked); setCurrentPage(1); }}
            />
            <span css={checkboxLabelStyle}>ParkDirect経由</span>
          </div>
        </div>
        <div css={menuContainerStyle}>
          <div css={titleStyle}></div>
          <div css={subtitleStyle}>直接契約</div>
          <div css={inputWrapperStyle}>
            <Checkbox
              css={checkBoxStyle}
              checked={paymentKindTransfer}
              onChange={(e) => { setPaymentKindTransferSession(e.target.checked);setPaymentKindTransfer(e.target.checked); setCurrentPage(1); }}
            />
            <span css={checkboxLabelStyle}>銀行振込</span>
          </div>
          <div css={inputWrapperStyle}>
            <Checkbox
              css={checkBoxStyle}
              checked={paymentKindAccountTransfer}
              onChange={(e) => { setPaymentKindAccountTransferSession(e.target.checked); setPaymentKindAccountTransfer(e.target.checked); setCurrentPage(1); }}
            />
            <span css={checkboxLabelStyle}>口座振替</span>
          </div>
          <div css={inputWrapperStyle}>
            <Checkbox
              css={checkBoxStyle}
              checked={paymentKindCC}
              onChange={(e) => { setPaymentKindCCSession(e.target.checked);setPaymentKindCC(e.target.checked); setCurrentPage(1); }}
            />
            <span css={checkboxLabelStyle}>クレジットカード</span>
          </div>
          <div css={inputWrapperStyle}>
            <Checkbox
              css={checkBoxStyle}
              checked={paymentKindDirectPd}
              onChange={(e) => { setPaymentKindDirectPdSession(e.target.checked);setPaymentKindDirectPd(e.target.checked); setCurrentPage(1); }}
            />
            <span css={checkboxLabelStyle}>ParkDirect経由</span>
          </div>
        </div>
        <div css={menuContainerStyle}>
          <div css={titleStyle}>支払い年月</div>
          <div css={datePickerWrapperStyle}>
            <div>
              <DatePicker
                css={datePickerStyle}
                locale='ja'
                selected={paymentMonthStart}
                onChange={(e) => { setPaymentMonthStartSession(e as Date); setPaymentMonthStart(e as Date); setCurrentPage(1); }}
                placeholderText="2022/01/01"
                dateFormat="yyyy/MM"
                showMonthDropdown
                showYearDropdown
                showMonthYearPicker
                todayButton="今日"
                dropdownMode="select" />
            </div>
            <span>　〜　</span>
            <div>
              <DatePicker
                css={datePickerStyle}
                locale='ja'
                selected={paymentMonthEnd}
                onChange={(e) => { setPaymentMonthEndSession(e as Date); setPaymentMonthEnd(e as Date); setCurrentPage(1); }}
                placeholderText="2022/01/01"
                dateFormat="yyyy/MM"
                showMonthDropdown
                showYearDropdown
                showMonthYearPicker
                todayButton="今日"
                dropdownMode="select" />
            </div>
          </div>
        </div>
        <div css={freeWordWrapperStyle}>
          <div css={menuContainerStyle}>
            <div css={titleStyle}>フリーワード</div>
            <div css={inputWrapperStyle}>
              <div>
                <div css={searchInputWrapperStyle}>
                  <FontAwesomeIcon css={{ marginRight: "0.5rem" }} color="#DCDCDC" icon={faMagnifyingGlass} />
                  <input
                    placeholder="契約ID、法人名、店舗/部署名"
                    defaultValue={freeWordState}
                    css={searchInputStyle}
                    type="text"
                    ref={textRef}
                    onKeyDown={(e) => { e.key === "Enter" && setFreeWordSession(textRef?.current?.value);e.key === "Enter" && setFreeWordState(textRef?.current?.value); e.key === "Enter" && setCurrentPage(1); }}
                  />
                </div>
              </div>
            </div>
          </div>
          <button onClick={() => { reset(); setCurrentPage(1);}} css={clearButtonStyle}><FontAwesomeIcon icon={faArrowRotateLeft} />
            条件クリア
          </button>
        </div>
      </article>
      <div css={contractListStyle}>
        <div css={csvDownloadContainer}>
          <button css={buttonStyle} onClick={() => downloadCSV(q, paymentMonthStart, paymentMonthEnd)} disabled={isCSVDownloadProcessing}>
            決済リスト（CSV）出力
          </button>
        </div>
        <div css={{ display: "flex" }}>
          <PaymentList paymentDetailList={paymentDetailList} />
          <ContractDetailLinks contractIdList={paymentDetailList.map(x => x.contract.id)} />
        </div>
        <div>
          <Paginator
            paginationWrapperStyle={paginationWrapperStyle}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            contracts={payments}
          />
        </div>
      </div>
    </section >
  );
}
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { PaymentList } from "../parts/Payment/PaymentList";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useApiGet } from "../../hooks/useApiGet";
import { HttpStatus } from "../../models/HttpStatus";
import { Loading } from "../parts/Loading";
import Pagination from "rc-pagination";
import { PaymentFromApi } from "../../models/payment/PaymentFromApi";
import { convertToPaymentDetail, PaymentDetail } from "../../models/payment/PaymentDetail";
import { ContractDetailLinks } from "../parts/Contract/ContractDetailLinks";


const sectionStyle = css({
  width: "100%",
});

const titleStyle = css({
  margin: "2rem",
  fontWeight: "normal"
});

export const NullPaymentListComp = () => {

  const { page } = useParams();
  const navigate = useNavigate();

  const initPage = page === undefined ? 1 : parseInt(page);
  const [currentPage, setCurrentPage] = useState(initPage);


  const [httpStatus, payments, reFetch] =
    useApiGet<PaymentFromApi>(`/api/v1/admin/payment_null_list/?page=${currentPage}`);

  useEffect(() => {
    reFetch(`/api/v1/admin/payment_null_list/?page=${currentPage}`);
    navigate(`/null-payment-list/${currentPage}`)
  }, [currentPage, reFetch, navigate]);


  if (httpStatus.status === HttpStatus.UNAUTHORIZED) {
    return <Navigate to="/login" />
  }


  if (httpStatus.status !== HttpStatus.OK || payments === undefined) {
    return <Loading />
  }

  const paymentDetailList: PaymentDetail[] = payments.results?.map(x => convertToPaymentDetail(x)) ?? [];


  return (
    <section css={sectionStyle}>
      <h2 css={titleStyle}>総額未入力決済一覧</h2>
      <div css={{ display: "flex" }}>
        <PaymentList paymentDetailList={paymentDetailList} />
        <ContractDetailLinks contractIdList={paymentDetailList.map(x => x.contract.id)} />
      </div>
      <article>
        <div css={{ display: "flex", justifyContent: "center", marginBottom: "2rem" }}>
          <Pagination
            // pageSize={contracts?.per_page}
            onChange={(e) => setCurrentPage(e)}
            current={currentPage}
            total={(payments?.total_pages ?? 1) * (payments?.per_page ?? 1)}
          />
        </div>
      </article>
    </section >
  );
}
export const toSnakeCase = (str: string): string => {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase();
};

export const toSnakeCaseKeys = <T extends object>(obj: T): T => {
  const result: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = toSnakeCase(key);
      const value = obj[key];
      if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
        result[newKey] = toSnakeCaseKeys(value as any);
      } else {
        result[newKey] = value;
      }
    }
  }
  return result as T;
}

import { Organization } from "../../../models/organization";

export type MenuType =
  | 'new_contract'
  | 'cancel_contract';

export type MenuModalProps = {
  menuType?: MenuType,
  onClose: () => void,
};

export const Color = {
  PD: 'rgb(31,87,170)',
  Agency: 'rgb(60,134,178)',
  Direct: 'rgb(204,204,204)'
} as const;

export type ContractSummary = {
  id: string;
  contract_kind: 0 | 1 | 2 | 3; // 0:PD契約 1:代理契約 2:直接契約/直接支払い 3:直接契約/PD支払い
  management_state: 1 | 2; // 1:契約中 2:解約済み
  total_to_pay: number;
  cancel_date: null | string;
  cancel_date_reached: boolean;
  contract_initiated: boolean;
};

export type ContractSummaries = ContractSummary[];

export type ContractSummaryTransition = {
  last_month_summary: ContractSummaries;
  this_month_summary: ContractSummaries;
};

export type Notification = {
  id: number;
  title: string;
  url: string;
  organization: Organization | null;
  created_at: string;
  updated_at: string;
};

export type Notifications = {
  count: number;
  has_next: boolean;
  has_previous: boolean;
  page: number;
  per_page: number;
  results: Notification[];
  total_pages: number;
};

import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0056B0',
    }
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          fontSize: "30px",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
});
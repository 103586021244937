import React, { useState, VFC } from "react"
import { css } from "@emotion/react";
import { ContractDetail as ContractDetailProps } from "../../../models/contract/ContractDetail";
import { PaymentItem as PaymentDetailProps } from "../../../models/payment/PaymentDetail";
import { ContractDetailBasic } from "./ContractDetailBasic";
import { ContractDetailCondition } from "./ContractDetailCondition";
import { ContractPayment } from "./ContractPayment";
import { useApiGet } from "../../../hooks/useApiGet";
import { ContractAttachmentFileComp } from "../../usecase/ContractAttachmentFileComp";

export const ContractDetail: VFC<{ contractDetail: ContractDetailProps, paymentList: PaymentDetailProps[] ,notifyPaymentChange: Function}> = ({ contractDetail,paymentList,notifyPaymentChange }) => {

  const [_, am_i_admin] = useApiGet<boolean>("/api/v1/am_i_admin/");
  const [tabPageState, setTabPageState] = useState(0);


  const tabWrapperStyle = css({
    display: "flex"
  });

  const sectionWrapper = css({
    marginTop: "3rem"
  });

  const tab0Style = css({
    marginLeft: "3rem",
    padding: "1rem 2.5rem 1rem 2rem",
    background: tabPageState === 0 ? "White" : "#ECF0F4",
    fontSize: "13px",
    clipPath: "polygon(0% 100%, 0% 0%, 90.5% 0%, 100% 100%)",
    cursor: "pointer",
    fontWeight: tabPageState === 0 ? "bold" : "normal",
    color: tabPageState === 0 ? "black" : "#0056B0",
    "&:hover": {
      opacity: 0.7
    }
  });

  const tabNStyle = (page: number) => css({
    marginLeft: "2px",
    padding: "1rem 2.5rem 1rem 2rem",
    background: tabPageState === page ? "White" : "#ECF0F4",
    fontSize: "13px",
    clipPath: "polygon(0% 100%, 0% 0%, 90.5% 0%, 100% 100%)",
    cursor: "pointer",
    fontWeight: tabPageState === page ? "bold" : "normal",
    color: tabPageState === page ? "black" : "#0056B0",
    "&:hover": {
      opacity: 0.7
    }
  });

  const changePage = (page: number) => {
    setTabPageState(page);
  }

  return (
    <div css={sectionWrapper}>
      <div css={tabWrapperStyle}>
        <div onClick={() => changePage(0)} css={tab0Style}>契約情報</div>
        <div onClick={() => changePage(1)} css={tabNStyle(1)}>支払い情報</div>
        {/* 一時的に非表示にする */}
        {/* {paymentList.length > 0 ? <div onClick={() => changePage(2)} css={tabNStyle(2)}>決済情報</div>: <></>} */}
        <div onClick={() => changePage(3)} css={tabNStyle(3)}>その他ファイル</div>
      </div>
      {tabPageState === 0 ? <ContractDetailBasic contractDetail={contractDetail} am_i_admin={am_i_admin} /> : <></>}
      {tabPageState === 1 ? <ContractDetailCondition contractDetail={contractDetail} am_i_admin={am_i_admin} /> : <></>}
      {tabPageState === 2 && paymentList.length > 0 ? <ContractPayment paymentList={paymentList} contractDetail={contractDetail} am_i_admin={am_i_admin} notifyPaymentChange={notifyPaymentChange} /> : <></>}
      {tabPageState === 3 && <ContractAttachmentFileComp contract={contractDetail} />}
    </div>
  )
}
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, TextFieldProps, Tooltip, Typography } from "@mui/material";
import { useAdminCheck } from "../../hooks/useAdminCheck";
import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { useOrganizations } from "../../hooks/useOrganizations";
import { SimpleDialog } from "../parts/SimpleDialog";
import { DatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ja } from 'date-fns/locale';
import { isValid } from 'date-fns';
import styled from "@emotion/styled";
import { Organization } from "../../models/organization";
import { api } from "../../infra/Api";
import { HttpStatus } from "../../models/HttpStatus";
import moment from "moment";
import { getFilenameAndDataUrl } from "../../util/dataUrl";
import Snackbar from '@mui/material/Snackbar';
import { Statement } from "../../models/Statement";

export const StatementComp = () => {
  useAdminCheck();

  const [fetchOrganizationsState, organizations] = useOrganizations();

  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [statements, setStatements] = useState<Statement[]>([]);
  const [confirmDeleteStatement, setConfirmDeleteStatement] = useState<Statement>();
  const [confirmUpdateStatement, setConfirmUpdateStatement] = useState<Statement>();
  const [snackData, setSnackData] = useState<{ message: string; timeout?: number }>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const fetchStatements = useCallback(async () => {
    if (selectedOrganization && selectedDate && isValid(selectedDate)) {
      const organizationId = selectedOrganization?.id
      const [httpStatus, data] = await api.get<Statement[]>(`api/v1/statements/?organization_id=${organizationId}&date=${moment(selectedDate).format('YYYY-MM-01')}`);

      if (httpStatus.status === HttpStatus.OK && data) {
        setStatements(data);
      }
    }
  }, [selectedOrganization, selectedDate]);

  const onFileUpload = useCallback(async () => {
    const organizationId = selectedOrganization?.id

    if (!organizationId || !selectedDate || !selectedFile) return;

    const results = await getFilenameAndDataUrl(selectedFile)
    if (!results) return;

    const [filename, data] = results

    try {
      setIsUploading(true);

      const [httpStatus] = await api.post(`api/v1/statements/`, {
        organization_id: organizationId,
        date: moment(selectedDate).format('YYYY-MM-01'),
        filename,
        data,
      });

      fetchStatements();

      if (httpStatus.status === HttpStatus.CREATED) {
        setSelectedFile(null);
        showSnack('明細をアップロードしました');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  }, [selectedOrganization, selectedDate, selectedFile, fetchStatements]);

  const downloadStatement = useCallback(async (statement: Statement) => {
    const [httpStatus, blob] = await api.getBlob(`api/v1/statements/download/${statement.id}/`);
    if (!blob) return;

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', statement.filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, []);

  const updateStatement = useCallback(async (statement?: Statement) => {
    if (!selectedFile || !statement) return;

    const results = await getFilenameAndDataUrl(selectedFile);
    if (!results) return;

    const [filename, data] = results;

    try {
      setIsUploading(true);

      const [httpStatus] = await api.patch(`api/v1/statements/${statement.id}/`, {
        filename,
        data,
      });

      fetchStatements();

      if (httpStatus.status === HttpStatus.OK) {
        setSelectedFile(null);
        showSnack('明細を更新しました');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  }, [selectedFile, fetchStatements]);

  const deleteStatement = useCallback(async (statement?: Statement) => {
    if (!statement) return;
    const [httpStatus] = await api.delete(`api/v1/statements/${statement.id}/`);
    fetchStatements();

    if (httpStatus.status === HttpStatus.NO_CONTENT) {
      showSnack('明細を削除しました');
    }
  }, [fetchStatements]);

  const showSnack = useCallback((message: string, timeout: number = 2000) => {
    setSnackData({ message, timeout });
  }, []);

  useEffect(() => {
    if (selectedOrganization && selectedDate) {
      fetchStatements();
    } else if (statements) {
      setStatements([]);
    }
  }, [selectedOrganization, selectedDate]);

  useEffect(() => {
    if (!selectedFile && fileInputRef.current) {
      fileInputRef.current.value = ""
    }
  }, [selectedFile]);

  const isValidSelectedDate = isValid(selectedDate);

  const isDisabledSelectFileButton = isUploading || !selectedOrganization || !isValidSelectedDate;
  const isDisabledUploadButton = isDisabledSelectFileButton || !selectedFile;
  const isDiabledResetButton = isUploading || (!selectedOrganization && !selectedDate && !selectedFile);

  return (
    <>
      <StyledSection>
        <StyledTitle>明細管理</StyledTitle>

        <StyledRow>
          <StyledLabel>企業</StyledLabel>
          <StyledAutocomplete
            options={organizations ?? []}
            getOptionLabel={(organization) => `${organization.id}: ${organization.name}`}
            value={selectedOrganization}
            renderInput={OrganizationTextFiled}
            onChange={(event, organization) => setSelectedOrganization(organization)}
          />
        </StyledRow>

        <StyledRow>
          <StyledLabel>明細の年月</StyledLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
            <DatePicker
              views={['year', 'month']}
              value={selectedDate}
              inputFormat="yyyy/MM"
              openTo="month"
              onChange={(date) => { setSelectedDate(date) }}
              renderInput={DateTextFiled}
            />
          </LocalizationProvider>
        </StyledRow>

        <StyledHiddenInput id="file-upload" type="file" ref={fileInputRef} accept=".pdf, .xlsx, .xls, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(event) => setSelectedFile(event.target.files?.[0] ?? null)} disabled={isDisabledSelectFileButton} />

        <StyledRow>
          <label htmlFor="file-upload">
            <Button variant="contained" component="span" disabled={isDisabledSelectFileButton}>
              アップロードする明細を選択
            </Button>
          </label>
          {selectedFile && <Typography>{selectedFile.name}</Typography>}
        </StyledRow>

        <StyledRow>
          <LoadingButton variant="contained" onClick={onFileUpload} disabled={isDisabledUploadButton}>
            アップロード
          </LoadingButton>
          <Button variant="outlined" disabled={isDiabledResetButton} onClick={() => {
            setSelectedOrganization(null);
            setSelectedDate(null);
            setSelectedFile(null);
          }}>
            リセット
          </Button>
        </StyledRow>

        {statements.length > 0 && (
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCellHeader >明細ファイル</StyledTableCellHeader>
                <StyledTableCellHeader colSpan={2}>更新日時</StyledTableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {statements.sort((a, b) => a.filename.localeCompare(b.filename)).map((statement, index: number) => (
                <TableRow key={`statement-${index}`}>
                  <TableCell>{statement.filename}</TableCell>
                  <TableCell>{moment(statement.updated_at).format('YYYY-MM-DD HH:mm')}</TableCell>
                  <TableCell>
                    <StyledCellButtons>
                      <Button variant="contained" component="span" disabled={isUploading} onClick={() => downloadStatement(statement)}>ダウンロード</Button>
                      <Button variant="contained" component="span" disabled={isUploading} onClick={() => setConfirmDeleteStatement(statement)}>削除</Button>
                      <Tooltip title="アップロードする明細を選択すると更新ができます" placement="right">
                        <span><Button variant="contained" component="span" disabled={isUploading || !selectedFile} onClick={() => setConfirmUpdateStatement(statement)}>更新</Button></span>
                      </Tooltip>
                    </StyledCellButtons>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        )}
      </StyledSection>

      <SimpleDialog
        open={fetchOrganizationsState === 'Error'}
        title="ネットワークエラー"
        message="組織データの読み込みに失敗しました。"
        buttons={[{ label: 'ブラウザの再読み込み', onClick: () => window.location.reload() }]}
      />

      <SimpleDialog
        open={!!confirmUpdateStatement}
        title="明細の更新"
        message={`明細を更新しますか？\n\n現在のファイル名: ${confirmUpdateStatement?.filename}\n新しいファイル名: ${selectedFile?.name}`}
        buttons={[
          {
            label: 'はい',
            onClick: () => {
              updateStatement(confirmUpdateStatement);
              setConfirmUpdateStatement(undefined);
            }
          },
          { label: 'キャンセル', onClick: () => setConfirmUpdateStatement(undefined) },
        ]}
      />

      <SimpleDialog
        open={!!confirmDeleteStatement}
        title="明細の削除"
        message={`明細を削除しますか？\n\n${confirmDeleteStatement?.filename}`}
        buttons={[
          {
            label: 'はい',
            onClick: () => {
              deleteStatement(confirmDeleteStatement);
              setConfirmDeleteStatement(undefined);
            }
          },
          { label: 'キャンセル', onClick: () => setConfirmDeleteStatement(undefined) },
        ]}
      />

      <Snackbar
        open={!!snackData}
        autoHideDuration={snackData?.timeout}
        onClose={() => setSnackData(undefined)}
        message={snackData?.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  )
}

const StyledSection = styled.section`
  margin: 2em 3em;
`;

const StyledTitle = styled.h2`
  margin-top: 2rem;
  font-weight: normal;
`;

const StyledRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  gap: 0 1%;
`;

const StyledCellButtons = styled.div`
  display: flex;
  gap: 0 .5em;
`

const StyledLabel = styled.div`
  font-size: 12px;
  width: 100px;
  font-weight: bold;
`;

const StyledHiddenInput = styled.input`
  display: none;
`;

const StyledTable = styled(Table)`
`;

const StyledTableCellHeader = styled(TableCell)`
  font-weight: bold;
  font-size: 12px;
`;

const AutocompleteTyped = Autocomplete as React.ComponentType<AutocompleteProps<Organization, false, false, false>>;

const StyledAutocomplete = styled(AutocompleteTyped)`
  width: 20em;
  background: #ffffff;
`;

const OrganizationTextFiled = (params: AutocompleteRenderInputParams) => <TextField
  {...params}
  placeholder="企業を選択"
  InputLabelProps={{ shrink: true, style: { visibility: 'hidden' } }}
  InputProps={{
    ...params.InputProps,
    style: {
      paddingTop: 0,
      paddingBottom: 0,
    }
  }}
/>

const DateTextFiled = (params: TextFieldProps) => <TextField
  {...params}
  InputLabelProps={{ shrink: true, style: { visibility: 'hidden' } }}
  inputProps={{ ...params.inputProps, placeholder: "YYYY/MM" }}
  InputProps={{
    ...params.InputProps,
    style: {
      paddingTop: 0,
      paddingBottom: 0,
      background: '#ffffff',
      width: '20em',
      height: '39px',
    }
  }}
/>

import styled from "@emotion/styled";
import { StyledPanelContainer, StyledPanelContent, StyledPanelHeader, StyledPanelTitle, StyledIcon } from "./styles";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Color, ContractSummaryTransition } from "./types";
import { api } from "../../../infra/Api";
import { useEffect, useState, useMemo } from "react";
import { Grid, MenuItem, Select } from "@mui/material";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
);

export const options: ChartOptions<'bar'> = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        precision: 0,
      }
    },
  },
  datasets: {
    bar: {
      barThickness: 40,
      borderRadius: 5,
    }
  }
};

const labels = ['先月', '当月'];

export const TransitionPanel = () => {
  // <[先月, 今月]>
  const [pdCount, setPdCount] = useState<[number, number]>();
  const [agencyCount, setAgencyCount] = useState<[number, number]>();
  const [directCount, setDirectCount] = useState<[number, number]>();
  const [pdTotal, setPdTotal] = useState<[number, number]>();
  const [agencyTotal, setAgencyTotal] = useState<[number, number]>();
  const [directTotal, setDirectTotal] = useState<[number, number]>();

  const [displayType, setDisplayType] = useState<'count' | 'pay'>('count');

  useEffect(() => { 
    const fetchSummary = async () => {
      const  { result } = await fetchData();
      if (!result) return;
  
      // 先月
      // 解約済みは除く
      const pdSummary1 = result.last_month_summary.filter(v => v.contract_kind === 0 && !v.cancel_date_reached);
      const agencySummary1 = result.last_month_summary.filter(v => v.contract_kind === 1 && !v.cancel_date_reached);
      const directSummary1 = result.last_month_summary.filter(v => (v.contract_kind === 2 || v.contract_kind === 3) && !v.cancel_date_reached);

      // 今月
      const pdSummary2 = result.this_month_summary.filter(v => v.contract_kind === 0 && v.management_state === 1);
      const agencySummary2 = result.this_month_summary.filter(v => v.contract_kind === 1 && v.management_state === 1);
      const directSummary2 = result.this_month_summary.filter(v => (v.contract_kind === 2 || v.contract_kind === 3) && v.management_state === 1);
  
      // 先月の契約件数
      // 初回契約開始日を迎えてない契約は除く
      // 同じ月に複数回の支払いがあった場合、契約数が増えてしまうので同じ契約IDが複数回カウントされないようにする
      const pdCount1 = new Set(pdSummary1.filter(v => v.contract_initiated).map(v => v.id)).size;
      const agencyCount1 = new Set(agencySummary1.filter(v => v.contract_initiated).map(v => v.id)).size;
      const directCount1 = new Set(directSummary1.filter(v => v.contract_initiated).map(v => v.id)).size;
  
      // 先月の契約金額
      const pdTotal1 = pdSummary1.reduce((prev: number, current) => prev + current.total_to_pay, 0);
      const agencyTotal1 = agencySummary1.reduce((prev: number, current) => prev + current.total_to_pay, 0);
      const directTotal1 = directSummary1.reduce((prev: number, current) => prev + current.total_to_pay, 0);
  
      // 今月の契約件数
      // 同じ月に複数回の支払いがあった場合、契約数が増えてしまうので同じ契約IDが複数回カウントされないようにする
      const pdCount2 = new Set(pdSummary2.map(v => v.id)).size;
      const agencyCount2 = new Set(agencySummary2.map(v => v.id)).size;
      const directCount2 = new Set(directSummary2.map(v => v.id)).size;

      // 今月の契約金額
      const pdTotal2 = pdSummary2.reduce((prev: number, current) => prev + current.total_to_pay, 0);
      const agencyTotal2 = agencySummary2.reduce((prev: number, current) => prev + current.total_to_pay, 0);
      const directTotal2 = directSummary2.reduce((prev: number, current) => prev + current.total_to_pay, 0);
  
      setPdCount([pdCount1, pdCount2]);
      setAgencyCount([agencyCount1, agencyCount2]);
      setDirectCount([directCount1, directCount2]);
      setPdTotal([pdTotal1, pdTotal2]);
      setAgencyTotal([agencyTotal1, agencyTotal2]);
      setDirectTotal([directTotal1, directTotal2]);
    };

    fetchSummary();
  }, []);

  const data = useMemo(() => ({
    labels,
    datasets: [
      {
        label: '直接契約',
        data: displayType === 'count' ? directCount : directTotal,
        backgroundColor: Color.Direct,
      },
      {
        label: '代理契約',
        data: displayType === 'count' ? agencyCount : agencyTotal,
        backgroundColor: Color.Agency,
      },
      {
        label: 'PD契約',
        data: displayType === 'count' ? pdCount : pdTotal,
        backgroundColor: Color.PD,
      },
    ],
  }), [displayType, pdCount, agencyCount, directCount, pdTotal, agencyTotal, directTotal]);

  const unit = displayType === 'count' ? '件' : '円';

  return (
    <StyledPanelContainer>
      <StyledPanelHeader>
        <StyledPanelTitle>推移</StyledPanelTitle>
        <StyledSelect
          value={displayType}
          onChange={(e) => setDisplayType(e.target.value as 'count' | 'pay')}>
          <MenuItem value="count">契約件数</MenuItem>
          {/* 一時的に非表示にする */}
          {/* <MenuItem value="pay">契約金額</MenuItem> */}
        </StyledSelect>
      </StyledPanelHeader>
      <StyledPanelContent>
        <Bar options={options} data={data} />
        <StyledRow>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <StyledRowTitle><StyledCircle color={Color.PD} />PD契約</StyledRowTitle>
            </Grid>
            <Grid item xs={3}>
              <StyledRowValue>
                <StyledNumber>{(displayType === 'count' ? pdCount?.[0] : pdTotal?.[0])?.numberFormat() ?? '-' }</StyledNumber>{unit}
              </StyledRowValue>
            </Grid>
            <Grid item xs={5}>
              <StyledRowValue>
                <StyledNumber>{(displayType === 'count' ? pdCount?.[1] : pdTotal?.[1])?.numberFormat() ?? '-' }</StyledNumber>{unit}
              </StyledRowValue>
            </Grid>
          </Grid>
        </StyledRow>
        <StyledRow>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <StyledRowTitle><StyledCircle color={Color.PD} />代理契約</StyledRowTitle>
            </Grid>
            <Grid item xs={3}>
              <StyledRowValue>
                <StyledNumber>{(displayType === 'count' ? agencyCount?.[0] : agencyTotal?.[0])?.numberFormat() ?? '-' }</StyledNumber>{unit}
              </StyledRowValue>
            </Grid>
            <Grid item xs={5}>
              <StyledRowValue>
                <StyledNumber>{(displayType === 'count' ? agencyCount?.[1] : agencyTotal?.[1])?.numberFormat() ?? '-' }</StyledNumber>{unit}
              </StyledRowValue>
            </Grid>
          </Grid>
        </StyledRow>
        <StyledRow>
          <Grid container alignItems="center">
            <Grid item xs={3}>
            <StyledRowTitle><StyledCircle color={Color.Direct} />直接契約</StyledRowTitle>
            </Grid>
            <Grid item xs={3}>
              <StyledRowValue>
                <StyledNumber>{(displayType === 'count' ? directCount?.[0] : directTotal?.[0])?.numberFormat() ?? '-' }</StyledNumber>{unit}
              </StyledRowValue>
            </Grid>
            <Grid item xs={5}>
              <StyledRowValue>
                <StyledNumber>{(displayType === 'count' ? directCount?.[1] : directTotal?.[1])?.numberFormat() ?? '-' }</StyledNumber>{unit}
              </StyledRowValue>
            </Grid>
          </Grid>
        </StyledRow>
      </StyledPanelContent>
    </StyledPanelContainer>
  )
}

const fetchData = async () => {
  const [status, result] = await api.get<ContractSummaryTransition>(
    "/api/v1/contract_summary_transition/",
  );
  return { status, result };
};

const StyledSelect = styled(Select)`
  font-size: 12px;
  height: 2.5em;
`;

const StyledCircle = styled.div<{ color: string }>`
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background-color: ${({ color }) => color};
  margin-right: 0.2em;
`

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: solid 1px #eee;
  padding: 1rem 0;
`

const StyledRowTitle = styled.div`
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledRowValue = styled.div`
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const StyledNumber  = styled.span`
  font-size: 15px;
  font-weight: bold;
  margin-right: 0.2em;
`

import React, { useCallback, useState } from "react";
import { css } from "@emotion/react";
import { Navigate, Outlet } from "react-router";
import logo from "../../images/PDLogoBlue.png"
import { LoginCheck } from "../parts/LoginCheck";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBell, IconDefinition, faList, faYen, faFileImport, faUser, faPen, faFile, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router";
import { useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../../atom/loggedInUserAtom";
import { MenuModal, MenuType } from "../parts/MenuModal";

const sidebarStyle = css({
  position: "fixed",
  height: "100vh",
  display: "grid",
  gridTemplateColumns: "250px 1fr",
  background: "white",
  boxShadow: "3px 0 5px #e0e0e0",
  zIndex: 1000,
});

const sidebarSpacerStyle = css({
  position: "static",
  height: "100vh",
  display: "grid",
  background: "white",
  minWidth: "250px",
});

const headerStyle = css({
  height: "64px",
  width: "100%",
  minWidth: "800px",
  background: "white"
});

const wrapper = css({
  display: "flex",
  alignSelf: "start",
});

const logoStyle = css({
  width: "100%",
  display: "block",
  marginTop: "0.5rem",
});

const menuStyle = (isSelected: boolean) => css({
  listStyleType: "none",
  paddingLeft: "3rem",
  height: "2.6rem",
  color: "#222222",
  ":hover": {
    background: "#f3f8ff"
  },
  ":Link": {
    color: "#222222"
  },
  paddingTop: "1rem",
  background: isSelected ? "#f3f8ff" : "white"
});

const navStyle = css({
  marginTop: "2rem"
});

const menuWrapperStyle = css({
  paddingLeft: "0"
});

const linkStyle = css({
  ":link": {
    textDecorationLine: "none"
  },
  ":visited": {
    textDecorationLine: "none",
    color: "black"
  },
});


export const Sidebar = () => {

  const user = useRecoilValue(loggedInUserAtom);

  const [menuModalType, setMenuModalType] = useState<MenuType>();

  const onCloseMenuModal = useCallback(() => setMenuModalType(undefined), []);

  const location = useLocation().pathname;

  const makeLinkEq = (path: string, icon: IconDefinition, label: string, menuType?: MenuType) => {
    return (
      <Link css={linkStyle} to={path} onClick={() => menuType && setMenuModalType(menuType)} >
        <li css={menuStyle(location === path)}>
          {location === path
            ? <><FontAwesomeIcon fixedWidth color="#0056B0" fontSize="18px" icon={icon} />　{label} </>
            : <><FontAwesomeIcon fixedWidth color="gray" fontSize="18px" icon={icon} />　{label} </>}
        </li>
      </Link>
    )
  }

  const makeLink = (link: string, activePath: string, icon: IconDefinition, label: string) => {
    return (
      <Link css={linkStyle} to={link} >
        <li css={menuStyle(location.startsWith(activePath))}>
          {location === link
            ? <>
              <FontAwesomeIcon fixedWidth color="#0056B0" fontSize="18px" icon={icon} /><span>　{label}</span>
            </>
            : <>
              <FontAwesomeIcon fixedWidth color="gray" fontSize="18px" icon={icon} />　{label}
            </>}
        </li>
      </Link>
    )
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <section css={wrapper}>
      <LoginCheck />
      <aside css={sidebarStyle}>
        <div>
          <img css={logoStyle} src={logo} alt="logo" />
          <nav css={navStyle}>
            <ul css={menuWrapperStyle}>
              {makeLinkEq("/", faBell, "トップ・お知らせ")}
              {makeLinkEq("#new-contract", faPen, "新規依頼", 'new_contract')}
              {makeLinkEq("#cancel-contract", faFile, "解約申請", 'cancel_contract')}
              {makeLink("/contract-list/1", "/contract-list", faList, "契約一覧")}
              {user.isAdmin && makeLink("/unset-organization-contract-list/1", "/unset-organization-contract-list", faList, "組織未設定契約一覧")}
              {/* 一時的に非表示にする */}
              {/* {makeLink("/payment-list/1", "/payment-list", faYen, "決済管理")} */}
              {user.isAdmin && makeLinkEq("/user-list/1", faUser, "アカウント管理")}
              {!user.isAdmin && makeLinkEq("/account/", faUser, "アカウント管理")}
              {user.isAdmin && makeLinkEq("/import-direct", faFileImport, "契約インポート")}
              {user.isAdmin && makeLinkEq("/statement", faFile, "明細管理")}
              {!user.isAdmin && makeLinkEq("/statement/download", faFileDownload, "明細ダウンロード")}
            </ul>
          </nav>
        </div>
      </aside>
      <aside css={sidebarSpacerStyle} />
      <div css={css({ width: "calc(100% - 250px)" })}>
        <nav css={headerStyle}>
          <Header />
        </nav>
        <main><Outlet /></main>
      </div>
      <MenuModal menuType={menuModalType} onClose={onCloseMenuModal} />
    </section>
  );
}
import moment from 'moment';
import { OrganizationFromApi } from './OrganizationFromApi'

export interface OrganizationProps {
  id: string;
  name: string;
  note: string;
  entryPdFormUrl: string;
  entryPdAgencyFormUrl: string;
  entryDirectFormUrl: string;
  updateContractFormUrl: string;
  cancelPdContractFormUrl: string;
  cancelDirectContractFormUrl: string;
  notificationsUrl: string;
  securePathId: string;
  createdAt: Date;
  updatedAt: Date;
}

export class Organization {
  public readonly id: string;
  public readonly name: string;
  public readonly note: string;
  public readonly entryPdFormUrl: string;
  public readonly entryPdAgencyFormUrl: string;
  public readonly entryDirectFormUrl: string;
  public readonly updateContractFormUrl: string;
  public readonly cancelPdContractFormUrl: string;
  public readonly cancelDirectContractFormUrl: string;
  public readonly notificationsUrl: string;
  public readonly securePathId: string;
  public readonly createdAt: Date;
  public readonly updatedAt: Date;

  constructor({
    id,
    name,
    note,
    entryPdFormUrl,
    entryPdAgencyFormUrl,
    entryDirectFormUrl,
    updateContractFormUrl,
    cancelPdContractFormUrl,
    cancelDirectContractFormUrl,
    notificationsUrl,
    securePathId,
    createdAt,
    updatedAt,
  }: OrganizationProps) {
    this.id = id;
    this.name = name;
    this.note = note;
    this.entryPdFormUrl = entryPdFormUrl;
    this.entryPdAgencyFormUrl = entryPdAgencyFormUrl;
    this.entryDirectFormUrl = entryDirectFormUrl;
    this.updateContractFormUrl = updateContractFormUrl;
    this.cancelPdContractFormUrl = cancelPdContractFormUrl;
    this.cancelDirectContractFormUrl = cancelDirectContractFormUrl;
    this.notificationsUrl = notificationsUrl;
    this.securePathId = securePathId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromApi = ({
    id,
    name,
    note,
    entry_pd_form_url: entryPdFormUrl,
    entry_pd_agency_form_url: entryPdAgencyFormUrl,
    entry_direct_form_url: entryDirectFormUrl,
    update_contract_form_url: updateContractFormUrl,
    cancel_pd_contract_form_url: cancelPdContractFormUrl,
    cancel_direct_contract_form_url: cancelDirectContractFormUrl,
    notifications_url: notificationsUrl,
    secure_path_id: securePathId,
    created_at: createdAt,
    updated_at: updatedAt,
  }: OrganizationFromApi) => new Organization({
    id,
    name,
    note,
    entryPdFormUrl,
    entryPdAgencyFormUrl,
    entryDirectFormUrl,
    updateContractFormUrl,
    cancelPdContractFormUrl,
    cancelDirectContractFormUrl,
    notificationsUrl,
    securePathId,
    createdAt: moment(createdAt).toDate(),
    updatedAt: moment(updatedAt).toDate(),
  })
}
import styled from "@emotion/styled";
import { Modal } from "@mui/material";

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModalContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 40vw;
  padding: 20px;
  max-height: 90vh;
`;

export const StyledTitle = styled.div`
  font-size: 20px;
  margin: 0 0 15px;
`;

export const StyledSubTitle = styled.div`
  font-size: 14px;
`;

export const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0 20px;
  align-items: center;
`;

export const StyledCard = styled.div<{topSpacer?: boolean}>`
  display: flex;
  width: 20rem;
  background: #0056b0;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  margin-top: ${({ topSpacer }) => topSpacer ? 15 : 0}px;
  margin-bottom: 10px;
  padding: 20px 25px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
`;

import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { api } from "../../../infra/Api";
import { HttpStatus } from "../../../models/HttpStatus";
import { Button, Modal,TextField} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import ScrollContainer from "react-indiana-drag-scroll";
import { PaymentItem } from "../../../models/payment/PaymentDetail";

const modalStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const modalContentStyle = css({
  backgroundColor: 'white',
  borderRadius: '10px',
  width: '80vw',
  borderWidth: 0,
  padding: '20px',
  overflow: 'scroll',
  maxHeight: '90vh',
});

const rowStyle = css({
  "&:hover": {
    opacity: "0.7"
  },
});

const footerButtonsStyle = css({
  textAlign: 'center',
});

const footerButtonStyle = css({
  width: '120px',
  marginLeft: '20px',
  marginRight: '20px',
});

const headerColumnStyle = css({
  fontSize: "12px",
  padding: "1rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  borderBottom: "solid 3px #ccc",
  fontWeight: "bold",
});

const tableStyle = css({
  borderCollapse: "collapse",
  background: "white",
  whiteSpace: "nowrap",
  marginLeft: "1rem",
  height: "60px",
});

const tableWrapperStyle = css({
  background: "white",
  padding: "1rem",
  paddingRight: 0,
  marginTop: "0",
  paddingTop: "0",
  overflowX: "scroll",
  display: "flex",
});

const columnStyle = css({
  fontSize: "12px",
  paddingLeft: "1rem",
  borderBottom: "solid 2px #DCDCDC",
  height: "60px",
});

export type PaymentEditFormType =
  | 'payments';


export type PaymentEditModalProps = {
  formType?: PaymentEditFormType,
  onClose: (isUpdated: boolean) => void,
  paymentList: PaymentItem[],
  am_i_admin: boolean | undefined,
  notifyPaymentChange: Function
};

const StyledTextField = styled(TextField) <{ link?: boolean }>`
  width: 49%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledTextField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};

const StyledFileField = styled(TextField) <{ link?: boolean }>`
  width: 100%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledFileField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};


export const PaymentEditModal = ({
  formType,
  onClose,
  paymentList,
  am_i_admin,
  notifyPaymentChange,
}: PaymentEditModalProps) => {

  
  const [paymentsData, setPaymentList] = useState<PaymentItem[]>();
  const [isUploading, setIsUploading] = useState(false);


  useEffect(() => {
    setPaymentList(paymentList);
    return () => {
      setPaymentList(undefined);
    }
  }, [formType, paymentList]);

  const onChangeTotalToPay = useCallback((id:string) => async (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentList((preSetting) => {
      let paymentItems = preSetting?.map(x => {
        if (id === x.id) {
           return {...x,totalToPay:event.target.value}
        }
        return x;
      });
      return paymentItems;
    }
    );
  },[]); 

  const updateContract = useCallback(async () => {
    try {
      setIsUploading(true);
      const payentsPostData = (() => {
        return {
          payments: paymentsData
         };
      })();


      const [res, response] = await api.post(am_i_admin?
        `/api/v1/admin/payments_update/`: `/api/v1/payments_update/`
        , payentsPostData);

      if (res.status === HttpStatus.OK) {
        notifyPaymentChange();
        onClose(true);
      } else {
        showError(response);
      }

    } finally {
      setIsUploading(false);
    }
  }, [notifyPaymentChange,paymentsData,paymentList]);


  let tableCountArray: number[] = [];
  let tableContainerCount = 1;
  if (paymentsData != null && paymentsData.length !== 0) {
    let tableCount = Math.ceil(paymentsData.length / 6);
    if (tableCount > 4) {
      tableContainerCount = Math.ceil(tableCount / 4);
      for (let i = 0; i <= tableContainerCount - 1; i++) {
        if (i === tableContainerCount - 1) {
          tableCountArray.push(tableCount % 4)
          continue;
        }
        tableCountArray.push(4);
      }
    } else {
      tableCountArray.push(tableCount);
    }
  }

  return (
    <Modal open={!!formType} css={modalStyle}>
      <div css={modalContentStyle}>
        {[...Array(tableContainerCount)].map((_, i) => i + 1).map((_, tableContainerCountIndex) =>
          <ScrollContainer css={tableWrapperStyle} className="container" key={tableContainerCountIndex}>
            {[...Array(tableCountArray[tableContainerCountIndex])].map((_, i) => i + 1).map((_, index) =>
              <table css={tableStyle} key={index}>
                <thead>
                  <tr css={rowStyle} key={index}>
                    <td css={headerColumnStyle} key={index}>決済月</td>
                    <td css={headerColumnStyle}>支払い総額</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    paymentsData?.slice((index + (tableContainerCountIndex * 4)) * 6, 6 * (index + 1) * ((4 * tableContainerCountIndex) + 1)).map((x,j) => (
                      <tr css={rowStyle} key={j}>
                        <td css={columnStyle} key={j} >{x.monthOfPay}</td>
                        <td css={columnStyle}><StyledTextField type="number" key={x.id} value={x.totalToPay} onChange={(onChangeTotalToPay(x.id))} /></td>
                      </tr>
                    )
                    )}
                </tbody>
              </table>
            )}
          </ScrollContainer>
        )}
        <div css={footerButtonsStyle}>
          <LoadingButton variant="contained" onClick={updateContract} css={footerButtonStyle} loading={isUploading} disabled={isUploading}>保存</LoadingButton>
          <Button variant="text" onClick={()=>onClose(false)} css={footerButtonStyle} disabled={isUploading}>キャンセル</Button>
        </div>
      </div>
    </Modal>
  )
}

const showError = (response: any) => {
  alert('決済レコード更新時にエラーが発生しました。入力項目をご確認ください。');
};
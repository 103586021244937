import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Typography } from "@mui/material";

export type SimpleDialogProps = DialogProps & {
  title?: string;
  message?: string;
  buttons?: ({ label: string; onClick?: () => void, autoFocus?: boolean })[];
};

export const SimpleDialog = ({ title, message, buttons, ...props }: SimpleDialogProps) => (
  <Dialog
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    {...props}>
    {title && (
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    )}
    <DialogContent>
      {message && (
        <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }}>
          {message}
        </Typography>
      )}
    </DialogContent>
    <DialogActions>
      {buttons?.map(({ label, onClick, autoFocus }, index) => (
        <Button onClick={onClick} autoFocus={autoFocus} key={`button-${index}`}>
          {label}
        </Button>
      ))}
    </DialogActions>
  </Dialog>
);


import React, { VFC } from "react"
import { css } from "@emotion/react";
import { paymentMethod as paymentMethodList } from "../../../constant";
import { withUnitOrNone } from "../../../util/stringFormatter";
import { PaymentDetail } from "../../../models/payment/PaymentDetail";
import moment from "moment";

const URL = process.env.REACT_APP_ADMIN_URL;


const columnStyle = css({
    fontSize: "12px",
    paddingLeft: "1rem",
    borderBottom: "solid 2px #DCDCDC",
    height: "60px",
});

const linkStyle = css({
    display: "block",
    textDecoration: "none",
    ":link": {
        borderBottom: "none",
        color: 'black'
    },
    ":visited": {
        borderBottom: "none",
        color: 'black'
    }
});

const isTotal = (paymentDetail: PaymentDetail) => {

    if (paymentDetail.totalToPay !== undefined &&
        paymentDetail.totalToPay !== null) {
        return "入力済"
    }

    if (moment(paymentDetail.monthOfPay).format("YYYY-MM") ===
        moment(paymentDetail.contract.contract.startDate).format("YYYY-MM")) {
        return "初月";
    }

    if (moment(paymentDetail.monthOfPay).format("YYYY-MM") ===
        moment(paymentDetail.contract.contract.endDate).format("YYYY-MM")) {
        return "最終月";
    }

    return "その他";
}


export const PaymentListBox: VFC<{
    paymentDetail: PaymentDetail
    amIAdmin: boolean | undefined
}> = ({
    paymentDetail,
    amIAdmin
}) => {

        return (<>
            <tr>
                {amIAdmin && <td css={columnStyle}><span>{paymentDetail.contract.organization}</span></td>}
                {amIAdmin && <td css={columnStyle}><span>{isTotal(paymentDetail)}</span></td>}
                <td css={columnStyle}><span>{paymentDetail.contract.id}</span></td>
                <td css={columnStyle}><span>{paymentDetail.id}</span></td>
                <td css={columnStyle}><span>{paymentMethodList[paymentDetail.contract.contract.paymentMethod]}</span></td>
                <td css={columnStyle}><span>{moment(paymentDetail.monthOfPay).format("YYYY-MM")}</span></td>
                <td css={columnStyle}><span>{paymentDetail.contract.user.name}</span><br /> <span>{paymentDetail.contract.user.nameKana}</span></td>
                <td css={columnStyle}><span>{paymentDetail.contract.user.affiliationBranchName}</span> <br /> <span>{paymentDetail.contract.user.affiliationDepartmentName}</span></td>
                <td css={columnStyle}><span>{paymentDetail.contract.user.employeeNumber}</span></td>
                <td css={columnStyle}><span>{paymentDetail.contract.clientCompany.parkingName}</span> <br /> <span>{paymentDetail.contract.clientCompany.partition}</span></td>
                <td css={columnStyle}><span>{paymentDetail.contract.clientCompany.clientCompanyName}</span> <br /><span>{paymentDetail.contract.clientCompany.clientStoreName}</span></td>
                <td css={columnStyle}><span>{paymentDetail.contract.contractKind === 0 ? "PD請求" : withUnitOrNone(paymentDetail.contract.contract.paymentDate, "")}</span></td>
                <td css={columnStyle}><span>{isNaN(parseInt(paymentDetail.totalToPay, 10)) ? "未入力" : withUnitOrNone(paymentDetail.totalToPay, "円")}</span></td>
                <td css={columnStyle}><span>{withUnitOrNone(paymentDetail.contract.contract.rentWithTax, "円")}</span></td>
                <td css={columnStyle}><span>{withUnitOrNone(paymentDetail.contract.contract.monthlyGuaranteeAndManagementFeeWithTax, "円")}</span></td>
                <td css={columnStyle}><span>{withUnitOrNone(paymentDetail.contract.contract.initialGuaranteeAndManagementFeeWithTax, "円")}</span></td>
                <td css={columnStyle}><span>{withUnitOrNone(paymentDetail.contract.contract.updateBaseFeeWithTax, "円")}</span></td>
                <td css={columnStyle}><span>{withUnitOrNone(paymentDetail.contract.contract.updateManagementFeeWithTax, "円")}</span></td>
                <td css={columnStyle}><span>{withUnitOrNone(paymentDetail.contract.contract.renewalManagementFeeWithTax, "円")}</span></td>
                <td css={columnStyle}><span>{withUnitOrNone(paymentDetail.contract.contract.depositWithTax, "円")}</span></td>
                <td css={columnStyle}><span>{withUnitOrNone(paymentDetail.contract.contract.keyMoneyWithTax, "円")}</span></td>
                <td css={columnStyle}><span>{withUnitOrNone(paymentDetail.contract.contract.administrativeFeeWithTax, "円")}</span></td>
                <td css={columnStyle}><span>{withUnitOrNone(paymentDetail.contract.contract.issuePaperFeeWithTax, "円")}</span></td>
                <td css={columnStyle}><span>{paymentDetail.contract.contractAttribution.other1 !== "" ? `${paymentDetail.contract.contractAttribution.other1}: ${paymentDetail.contract.contractAttribution.other1Memo}` : ""} </span></td>
                <td css={columnStyle}><span>{paymentDetail.contract.contractAttribution.other2 !== "" ? `${paymentDetail.contract.contractAttribution.other2}: ${paymentDetail.contract.contractAttribution.other2Memo}` : ""} </span></td>
                <td css={columnStyle}><span>{paymentDetail.contract.contractAttribution.other3 !== "" ? `${paymentDetail.contract.contractAttribution.other3}: ${paymentDetail.contract.contractAttribution.other3Memo}` : ""} </span></td>
                {amIAdmin && <td css={columnStyle}><a css={linkStyle} target="_blank" rel="noreferrer" href={`${URL}admin/pd_biz/payments/${paymentDetail.id}/change`}>編集画面へ</a></td>}
            </tr>
        </>)

    }

import { css } from "@emotion/react"
import React, { useEffect, useState } from "react"
import { useApiGet } from "../../hooks/useApiGet";
import { ContractFromApi } from "../../models/contract/ContractFromApi";
import { HttpStatus } from "../../models/HttpStatus";
import { ContractList } from "../parts/Contract/ContractList";
import { Loading } from "../parts/Loading";
import Pagination from 'rc-pagination';
import "rc-pagination/assets/index.css";
import { Navigate, useNavigate, useParams } from "react-router";
import { ContractDetail, convertToContractDetail } from "../../models/contract/ContractDetail";
import { ContractDetailLinks } from "../parts/Contract/ContractDetailLinks";
import { Paginator } from "../parts/Paginator/Paginator";

const sectionStyle = css({
  width: "100%",
});

const headStyle = css({
  margin: "3rem",
  fontWeight: "normal"
});

const paginationWrapperStyle = css({
  display: "flex",
  marginRight: "2rem",
  justifyContent: "right",
});

const contractListStyle = css({
  background: "white",
  paddingBottom: "2rem"
});

export const RenewContractListComp = () => {

  const { page } = useParams();
  const navigate = useNavigate();


  const [, amIAdmin] = useApiGet<boolean>("/api/v1/am_i_admin/");


  const initPage = page === undefined ? 1 : parseInt(page);

  const [currentPage, setCurrentPage] = useState(initPage);

  const [httpStatus, contracts, reFetch] =
    useApiGet<ContractFromApi>(`/api/v1/admin/contract_renew_list?ordering=id&page=${currentPage}`);

  useEffect(() => {
    reFetch(`/api/v1/admin/contract_renew_list?ordering=id&page=${currentPage}`);
    navigate(`/renew-contract-list/${currentPage}`)
  }, [currentPage, reFetch, navigate])

  if (httpStatus.status === HttpStatus.UNAUTHORIZED) {
    return <Navigate to="/login" />
  }

  if (httpStatus.status !== HttpStatus.OK || contracts === undefined) {
    return <Loading />
  }



  const contractListBoxProps: ContractDetail[] = contracts?.results?.map(x => convertToContractDetail(x)) ?? [];

  return (
    <section css={sectionStyle}>
      <h2 css={headStyle}>更新月の契約一覧</h2>
      <div css={contractListStyle}>
        <div css={{ display: "flex" }} >
          <ContractList contractList={contractListBoxProps} amIAdmin={amIAdmin} />
          <ContractDetailLinks contractIdList={contractListBoxProps.map(x => x.id)} />
        </div>
        <article>
          <div>
            <Paginator
              paginationWrapperStyle={paginationWrapperStyle}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              contracts={contracts}
            />
          </div>
        </article>
      </div>
    </section>
  )
}
import { useCallback, useMemo, VFC } from "react"
import { dateFormat } from "../../../util/stringFormatter"
import { css } from "@emotion/react";
import { contractKindList, managementState as managementStateList } from "../../../constant"
import { ContractDetail } from "../../../models/contract/ContractDetail";
import { Organization } from "../../../models/organization";


const columnStyle = css({
    fontSize: "12px",
    paddingLeft: "1rem",
    borderBottom: "solid 2px #DCDCDC",
    height: "60px",
});


const rowStyle = css({
    "&:hover": {
        opacity: "0.7"
    }
});

const selectOrganizationStyle = css({
    height: "26px",
    borderColor: "#DCDCDC",
    fontSize: "13px",
    background: "#FFFFFF",
    minWidth: "140px",
  });

const setOrganizationButtonStyle = css({
    height: "26px",
    border: "none",
    fontSize: "12px",
    marginLeft: "4px",
    borderRadius: "4px",
    paddingHorizontal: "1rem",
    color: "white",
});

const setOrganizationButtonEnabledStyle = css({
    background: "#0351a1",
});

const setOrganizationButtonDisabledStyle = css({
    background: "#D4D4D4",
});

export type ContractListBoxProps = {
    contract: ContractDetail;
    amIAdmin?: boolean;
    organizations?: Organization[];
    organizationNameFilter?: string;
    showOrganizationSelectBox?: boolean;
    openModal: Function;
};

export const ContractListBox: VFC<ContractListBoxProps> = ({
    contract,
    amIAdmin,
    organizations = [],
    organizationNameFilter,
    showOrganizationSelectBox,
    openModal,
}) => {


    const confirmUpdateOrganization = useCallback(() => {
        openModal(contract)
    }, []);


    const organizationReg = useMemo(() => {
        try {
            return organizationNameFilter ? new RegExp(organizationNameFilter) : undefined
        } catch {
            return new RegExp('(?!)')
        }
    }, [organizationNameFilter]);

    const selectBox = useMemo(() => {

        return (
            <>
                <button
                    onClick={confirmUpdateOrganization}
                    css={[setOrganizationButtonStyle, setOrganizationButtonEnabledStyle]}
                    >
                    紐付け
                </button>
            </>
       )
    }, [organizations, openModal, organizationReg]);

    return (<>
        <tr css={rowStyle}>
            {amIAdmin && <td css={columnStyle}>
                {showOrganizationSelectBox ? selectBox : <span>
                    {contract.organizationInfo.id}<br/>{contract.organizationInfo.name}
                </span>}
            </td>}
            <td css={columnStyle}><span>{contract.id}</span></td>
            <td css={columnStyle}><span>{contractKindList[contract.contractKind]}</span><br /><span>{managementStateList[contract.managementState]}</span></td>
            <td css={columnStyle}><span>{contract.clientCompany.parkingName}</span><br /><span>{contract.clientCompany.partition}</span></td>
            <td css={columnStyle}><span>{contract.clientCompany.clientCompanyName}</span><br /><span>{contract.clientCompany.clientCompanyPhoneNumber}</span></td>
            <td css={columnStyle}><span>{contract.contract.rentWithTax ? contract.contract.rentWithTax : contract.contract.rentWithoutTax}円</span></td>
            <td css={columnStyle}><span>{dateFormat(contract.contract.startDate)}〜{dateFormat(contract.contract.endDate)}</span><br /><span>{dateFormat(contract.contract.cancelDate)}</span></td>
            <td css={columnStyle}><span>{contract.user.name}</span><br /><span>{contract.user.employeeNumber}</span></td>
            <td css={columnStyle}><span>{contract.user.affiliationBranchName}</span><br /><span>{contract.user.affiliationDepartmentName}</span></td>
            <td css={columnStyle}><span>{contract.contractAttribution.other1 !== "" ? `${contract.contractAttribution.other1}: ${contract.contractAttribution.other1Memo}` : ""} </span></td>
            <td css={columnStyle}><span>{contract.contractAttribution.other2 !== "" ? `${contract.contractAttribution.other2}: ${contract.contractAttribution.other2Memo}` : ""} </span></td>
            <td css={columnStyle}><span>{contract.contractAttribution.other3 !== "" ? `${contract.contractAttribution.other3}: ${contract.contractAttribution.other3Memo}` : ""} </span></td>
        </tr>

    </>)

}

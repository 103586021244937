import { css } from "@emotion/react";


export const formSectionStyle = css({
  margin: "3rem 3rem 3rem 3rem",
  backgroundColor: "white",
  padding: "3rem 1rem 1rem 1rem",
});

export const formAreaStyle = css({
  width: "390px",
  margin: "auto"
})

export const textAreaStyle = css({
    margin: "1rem 0",
    border: "none",
    borderBottom: "1px gray solid",
    width: "50%",
    transition: "all 0.1s",
    "&:focus-visible": {
      border: "none",
      borderBottom: "3px solid black",
      outline: "none",
    },
  });
  
  export const h1Style = css({
    margin: "0 2rem 2rem 1rem",
  });
  
  export const checkboxStyle = css({
    margin: "2rem 2rem 1rem 1rem ",
  });
  
  export const titleStyle = css({
    marginTop: "1.5rem",
    width: "50%",
  });
  
  

  export const errStyle = css({
    fontSize: "0.5rem",
    color: "red",

  });
  
  export const uploadedImageStyle = css({
    maxWidth: "100%",
    left: 0,
  });
  

  
  export const submitStyle = css({
    marginTop: "4rem",
    textAlign: "center",
    display: "inline",
    right: "0",
    background:
      "linear-gradient(to right,rgba(0,86,176,1) 0,rgba(17,130,206,1) 100%)",
    color: "white",
    width: "6rem",
    height: "2rem",
    border: "none",
    borderRadius: "5px",
    fontWeight: "bold",
    marginRight: "auto",
  });
  
  export const fileUploadStyle = css({
    marginTop: "2rem",
    width: "12rem",
    height: "2rem",
    background: "#f3f8ff",
    color: "#0056b0",
    borderRadius: "5px",
    fontWeight: "bold",
    textAlign: "center",
    display: "table-cell",
    verticalAlign: "middle",
    fontSize: "0.75rem",
    "&:hover": {
      opacity: "0.7",
    },
  });
  
  export const formStyle = css({
    margin: "1rem",
  });
  
  export const auxiliaryStyle = css({
    fontSize: "0.7rem",
  });
  
  export const fileInputStyle = css({
    display: "none",
  });
  
  export const sampleImageStyle = css({
    display: "block",
    marginTop: "1rem",
    marginBottom: "1rem",
    maxWidth: "380px"
  });
  
  export const calendarStyle = (visible: boolean) => css({
    display: visible ? "block" : "none",
  });
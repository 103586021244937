import { Organization, OrganizationProps, OrganizationFromApi } from './organization'

export interface UserProps {
  id: number;
  email: string;
  username: string;
  isStaff: boolean;
  isAdmin: boolean;
  isReadOnly: boolean;
  organization?: OrganizationProps;
}

export interface UserFromApi {
  id: number;
  email: string;
  username: string;
  organization: OrganizationFromApi | null;
  is_staff: boolean;
  is_superuser: boolean;
  is_read_only: boolean;
}

export class User {
  public readonly id: number;
  public readonly email: string;
  public readonly username: string;
  public readonly isAdmin: boolean;
  public readonly isStaff: boolean;
  public readonly isReadOnly: boolean;
  public readonly organization?: Organization;

  constructor({ id, email, username, isAdmin, isStaff, isReadOnly, organization }: UserProps) {
    this.id = id;
    this.email = email;
    this.username = username;
    this.isAdmin = isAdmin;
    this.isStaff = isStaff;
    this.isReadOnly = isReadOnly;
    this.organization = organization
  }

  static fromApi = ({ id, email, username, is_superuser: isAdmin, is_staff: isStaff, is_read_only: isReadOnly, organization }: UserFromApi) =>
    new User({ id, email, username, isAdmin, isStaff, isReadOnly, organization: organization ? Organization.fromApi(organization) : undefined });
}
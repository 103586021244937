import React, { VFC } from "react";

import { css } from "@emotion/react"
import { Link } from "react-router-dom";
import { PaymentListBox } from "./PaymentListBox"
import { useApiGet } from "../../../hooks/useApiGet";
import { PaymentDetail } from "../../../models/payment/PaymentDetail";
import ScrollContainer from "react-indiana-drag-scroll";


const columnStyle = css({
  fontSize: "12px",
  padding: "1rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  borderBottom: "solid 3px #ccc",
  fontWeight: "bold"
});

const tableStyle = css({
  borderCollapse: "collapse",
  background: "white",
  margin: "1rem auto",
  whiteSpace: "nowrap",
});

const tableWrapperStyle = css({
  background: "white",
  marginLeft: "1rem",
  padding: "1rem",
  paddingRight: 0,
  marginTop: "0",
  paddingTop: "0",
  overflowX: "scroll",
});
export const PaymentList: VFC<{ paymentDetailList: PaymentDetail[] }> = ({ paymentDetailList }) => {

  const [httpStatus, amIAdmin] = useApiGet<boolean>("/api/v1/am_i_admin/");

  return (
    <ScrollContainer ignoreElements="span" css={tableWrapperStyle} className="container">
      <table css={tableStyle}>
        <thead>
          <tr>
            {amIAdmin && <td css={columnStyle}>組織ID</td>}
            {amIAdmin && <td css={columnStyle}>総額？</td>}
            <td css={columnStyle}>契約ID</td>
            <td css={columnStyle}>ID</td>
            <td css={columnStyle}>決済タイプ</td>
            <td css={columnStyle}>支払い月</td>
            <td css={columnStyle}>利用者名</td>
            <td css={columnStyle}>利用者所属拠</td>
            <td css={columnStyle}>利用者従業員番号</td>
            <td css={columnStyle}>駐車場名</td>
            <td css={columnStyle}>管理会社名</td>
            <td css={columnStyle}>毎月支払日</td>
            <td css={columnStyle}>支払総額</td>
            <td css={columnStyle}>賃料(税込)</td>
            <td css={columnStyle}>月額保証／管理料(税込)</td>
            <td css={columnStyle}>初回保証／管理料(税込)</td>
            <td css={columnStyle}>更新料(税込)</td>
            <td css={columnStyle}>更新事務手数料(税込)</td>
            <td css={columnStyle}>管理更新料(税込)</td>
            <td css={columnStyle}>敷金（非課税）</td>
            <td css={columnStyle}>礼金（税込）</td>
            <td css={columnStyle}>事務手数料（税込）</td>
            <td css={columnStyle}>車庫証明書発行料（税込）</td>
            <td css={columnStyle}>その他1</td>
            <td css={columnStyle}>その他2</td>
            <td css={columnStyle}>その他3</td>
            {amIAdmin && <td css={columnStyle}>編集</td>}
          </tr>
        </thead>
        <tbody>
          {paymentDetailList.map(x => <PaymentListBox
            paymentDetail={x}
            amIAdmin={amIAdmin}
          />)}
        </tbody>
      </table>
    </ScrollContainer>)

}

import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState, useMemo, VFC, useContext } from "react";
import { api } from "../../../infra/Api";
import { ContractDetail as ContractDetailProps } from "../../../models/contract/ContractDetail";
import { headLineStyle, headLineWrapperStyle, linkStyle, sectionStyle } from "./ContractDetailBasic";
import { css } from "@emotion/react"
import { HttpStatus } from "../../../models/HttpStatus";
import ScrollContainer from "react-indiana-drag-scroll";
import { PaymentItem as PaymentDetailProps } from "../../../models/payment/PaymentDetail";
import { PaymentEditFormType, PaymentEditModal } from "../Payment/PaymentEditModal";
import { convertToPaymentDetail, PaymentDetail } from "../../../models/payment/PaymentDetail";
import { PaymentFromApi } from "../../../models/payment/PaymentFromApi";
import { ContactlessOutlined } from "@mui/icons-material";
import { useUser } from "../../..//hooks/useUser";
import { ContractDetailContext } from "../../usecase/ContractDetailComp";

const headerColumnStyle = css({
  fontSize: "12px",
  padding: "1rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  borderBottom: "solid 3px #ccc",
  fontWeight: "bold",
});

const tableStyle = css({
  borderCollapse: "collapse",
  background: "white",
  whiteSpace: "nowrap",
  marginLeft: "1rem",
  height: "60px",

});

const tableWrapperStyle = css({
  background: "white",
  padding: "1rem",
  paddingRight: 0,
  marginTop: "0",
  paddingTop: "0",
  overflowX: "scroll",
  display: "flex",
});

const columnStyle = css({
  fontSize: "12px",
  paddingLeft: "1rem",
  borderBottom: "solid 2px #DCDCDC",
  height: "60px",
});


const rowStyle = css({
  "&:hover": {
    opacity: "0.7"
  },
});


const warningStyle = css({
  color: "red",
  marginBottom: "20px",
  marginTop: "-10px"
});

const ContractKind = [
  'PD',
  'Agency',
  'Direct',
  'Direct',
] as const;

export const ContractPayment: VFC<{ paymentList: PaymentDetailProps[], contractDetail: ContractDetailProps, am_i_admin: boolean | undefined, notifyPaymentChange: Function }> = ({
  paymentList: payments,
  contractDetail,
  am_i_admin,
  notifyPaymentChange
}) => {

  const { reloadPayments } = useContext(ContractDetailContext);

  const [paymentEditFormType, setPaymentEditFormType] = useState<PaymentEditFormType>();
  const [_, accountUser] = useUser();
  const contractKind = useMemo(() => ContractKind[contractDetail.contractKind], [contractDetail]);
  const isDisplayEditPayment = useMemo(() => {
    if (am_i_admin && contractKind === 'Agency' || am_i_admin && contractKind === 'Direct') return true;
    if (!am_i_admin && accountUser?.isReadOnly == false && contractDetail.contractKind == 2) return true;
    return false;
  }, [am_i_admin, contractKind,accountUser]);

  const isDisplayEditWarningPd = useMemo(() => {
    if (!am_i_admin && accountUser?.isReadOnly == false && contractDetail.contractKind == 3) return true;
    return false;
  }, [am_i_admin,accountUser]);

  const onCloseModal = useCallback((isUpdated: boolean) => {
    if (isUpdated) reloadPayments();
    setPaymentEditFormType(undefined);
  }, [reloadPayments]);


  let tableCountArray: number[] = [];
  let tableContainerCount = 1;


  if (payments.length != 0) {
    let tableCount = Math.ceil(payments.length / 6);
    if (tableCount > 4) {
      tableContainerCount = Math.ceil(tableCount / 4);
      for (let i = 0; i <= tableContainerCount - 1; i++) {
        if (i === tableContainerCount - 1) {
          tableCountArray.push(tableCount % 4)
          continue;
        }
        tableCountArray.push(4);
      }
    } else {
      tableCountArray.push(tableCount);
    }
  }
  return (
    <>
      <section css={sectionStyle}>
        {isDisplayEditWarningPd &&<div css={warningStyle}>変更の場合はPark Directへご連絡ください。 連絡先:pdbiz-css@nealle.com</div>}
        <div css={headLineWrapperStyle}>
          <h3 css={headLineStyle}>決済情報一覧</h3>
          {isDisplayEditPayment && <a css={linkStyle} rel="noreferrer" onClick={() => setPaymentEditFormType('payments')}><FontAwesomeIcon icon={faPen} /> 編集する</a>}
        </div>
        {[...Array(tableContainerCount)].map((_, i) => i + 1).map((_, rowNum) =>
          <ScrollContainer css={tableWrapperStyle} className="container" key={rowNum}>
            {[...Array(tableCountArray[rowNum])].map((_, i) => i + 1).map((_, columnNum) =>
              <table css={tableStyle} key={columnNum}>
                <thead>
                  <tr css={rowStyle} key={columnNum}>
                    <td css={headerColumnStyle} >決済月</td>
                    <td css={headerColumnStyle} key={columnNum}>支払い総額</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    // 決済レコードの開始と終了を指定する
                    // 開始は ( 列数 + (行数 * 4) ) * 6  ex: 0|6|12|18|24
                    // 終了は 開始の値に+6
                    payments.slice((columnNum + (rowNum * 4)) * 6, ((columnNum + (rowNum * 4)) * 6) + 6 ).map((x, j) => (
                      <tr css={rowStyle} key={j}>
                        <td css={columnStyle} >{x.monthOfPay}</td>
                        <td css={columnStyle} key={j}>{x.totalToPay}</td>
                      </tr>
                    )
                    )}
                </tbody>
              </table>
            )}
          </ScrollContainer>
        )}
        <PaymentEditModal
          formType={paymentEditFormType}
          paymentList={payments}
          onClose={onCloseModal}
          am_i_admin={am_i_admin}
          notifyPaymentChange={notifyPaymentChange} />

      </section>
    </>

  )

}
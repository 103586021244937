import { css } from "@emotion/react";
import React, { useState, useCallback} from "react";
import { AdminCheck } from "../../parts/AdminCheck";  
import {useDropzone} from 'react-dropzone';
import { uploadContractFromImageFile } from "./forPD/importPDContract";

export const ImportDirectContract = () => {
  const [uplodedContractFile, setUploadedContractFile] = useState<Array<string | undefined>>([]);
  const [errorContractFile, setErrorContractFile] = useState<Array<string>>([]);
  const [isImportingImages, setIsImportingImages] = useState(false);
  const [uploadingCurrnetCount, setUploadingCurrentCount] = useState(0);
  const increment = () => setUploadingCurrentCount((prevCount) => prevCount + 1);

    // bulk upload
  const uploadContract = async (files:File[]) => {
    const { successFiles, createErrorFiles } = await uploadContractFromImageFile(files, increment);
    setUploadedContractFile(successFiles);
    setErrorContractFile(createErrorFiles);
  }

  const bulkUploadContract = useCallback(async () => {
    setIsImportingImages(true);
    setUploadedContractFile([]);
    setErrorContractFile([]);
    await uploadContract(acceptedFiles);
    setUploadingCurrentCount(0);
    setIsImportingImages(false);
  }, [uploadContract]);

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({});

  return (
    <div css={css({ margin: "2rem" })} >
      <AdminCheck />
      <h2>賃貸借契約書・車検証登録</h2>
      <div css={css({ width: "300px", height: "200px", border: "solid 3px rgb(31,87,170)", margin: "20px 0 20px 0"})} {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps() } />
        <p css={css({ fontSize: "11px"})}>
        ここにファイルをドラッグ&ドロップするか、選択して開くウインドから画像ファイルを選択してください。</p>

        <p css={css({ fontSize: "11px"})}>
        賃貸借契約書の場合は<span css={css({fontWeight: "bold"})}>CON</span>から始まるファイル名、車検証の場合は<span css={css({fontWeight: "bold"})}>VEC</span>から始まるファイル名を指定してください</p>
      </div>
      <div>
        <div>
          <button onClick={bulkUploadContract} disabled={acceptedFiles.length <= 0 || isImportingImages}>{isImportingImages ? '取り込み中...' : '取り込み'}</button>
          <span css={css({ visibility: acceptedFiles.length > 0 ? "visible" : "hidden", margin: "0px 0px 0px 20px"})}>全{acceptedFiles.length}件{uploadingCurrnetCount > 0 ? `中${uploadingCurrnetCount}件目処理中...`:''}</span>
        </div>
      </div>

      <ul css={css({ height: "200px", overflowY:"scroll"})}>
        {acceptedFiles.map((x,i) => <div>
          <p key={i}>{x.name}</p>
        </div>)}
      </ul>
      <div>
        {uplodedContractFile.length > 0 &&
          <>
            <h3>インポートに成功したファイル</h3>
            <div>
              {uplodedContractFile.map((x,i) => <div key={i}>{x}</div>)}
            </div>
          </>
        }
      </div>
      <div>
        {errorContractFile.length > 0 &&
          <>
            <h3>インポートに失敗したファイル</h3>
            <div>
              {errorContractFile.map((x,i) => <div key={i}>{x}</div>)}
            </div>
          </>
        }
      </div>
    </div>
  )
}
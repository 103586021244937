import { css } from "@emotion/react"
import styled from "@emotion/styled";
import { useState, useCallback, ChangeEvent } from "react"
import { HttpStatus } from "../../models/HttpStatus";
import { api } from "../../infra/Api";
import "react-datepicker/dist/react-datepicker.css";
import { Checkbox, MenuItem, Typography, TextField, Select, InputLabel, FormControl, FormControlLabel } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { contractKindList, contractKindTypeList, managementState as ManagementStatus, partition, paymentMethod, paymentMethodTypeList, depositKind } from "../../constant";
import dayjs from 'dayjs';
import { getFilenameAndDataUrl } from "../../util";
import { getOrganizations } from "../../hooks/useOrganizations";
import { FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Organization } from "../../models/organization";
import { useUser } from "../..//hooks/useUser";
import { RSelect } from "../parts/form/selectbox/SelectBox";

const StyledTextField = styled(TextField) <{ link?: boolean }>`
  width: 49%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledTextField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },

};

const StyledTextFieldRequired = styled(TextField) <{ link?: boolean }>`
  width: 49%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledTextFieldRequired.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
    required: true,
  },

};

const StyledFileField = styled(TextField) <{ link?: boolean }>`
  width: 100%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledFileField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};

export const NewContractCreateComp = () => {
  const navigate = useNavigate();
  const navigateFunction = (id: string) => {
    navigate(id);
  }
  const normalContractInitVal = true;

  const [managementState, setManagementState] = useState<number>(-1);
  const [contractKind, setContractKind] = useState<number>(-1);
  const [paymentList, setPaymentList] = useState([""]);
  const [contractPeriodMonth, setContractPeriodMonth] = useState<number>(0);
  const [contractedDate, setContractedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [normalContract, setNormalContract] = useState(normalContractInitVal);
  const [cancelDate, setCancelDate] = useState();
  const [firstStartDate, setFirstStartDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [needIssuePaper, setNeedIssuePaper] = useState<number>(-1);
  const [parkingUserName, setParkingUserName] = useState<string>('');
  const [parkingUserNameKana, setParkingUserNameKana] = useState<string>('');
  const [parkingUserManager, setParkingUserManager] = useState<string>('');
  const [parkingUserEmail, setParkingUserEmail] = useState<string>('');
  const [parkingUserMobilePhoneNumber, setParkingUserMobilePhoneNumber] = useState<string>('');
  const [parkingUserPhoneNumber, setParkingUserPhoneNumber] = useState<string>('');
  const [parkingUserEmployeeNumber, setParkingUserEmployeeNumber] = useState<string>('');
  const [parkingUserAffiliationBranchName, setParkingUserAffiliationBranchName] = useState<string>('');
  const [parkingUserAffiliationBranchNumber, setParkingUserAffiliationBranchNumber] = useState<string>('');
  const [parkingUserAffiliationDepartmentName, setParkingUserAffiliationDepartmentName] = useState<string>('');
  const [parkingUserAffiliationDepartmentNumber, setParkingUserAffiliationDepartmentNumber] = useState<string>('');
  const [paymentBank, setPaymentBank] = useState<string>("");
  const [contractPaymentMethod, setPaymentMethod] = useState<number>(-1);
  const [contractPaymentDate, setPaymentDate] = useState<string>();
  const [isUploading, setIsUploading] = useState(false);
  const [clientCompanyName, setClientCompanyName] = useState();
  const [clientStoreName, setClientStoreName] = useState();
  const [clientCompanyAddress, setClientCompanyAddress] = useState();
  const [clientCompanyStaffName, setClientCompanyStaffName] = useState();
  const [clientCompanyFaxNumber, setClientCompanyFaxNumber] = useState();
  const [clientCompanyPhoneNumber, setClientCompanyPhoneNumber] = useState();
  const [clientCompanyStaffEmail, setClientCompanyStaffEmail] = useState();
  const [clientCompanyNote, setClientCompanyNote] = useState();

  // 貸主情報
  const [lessorName, setLessorName] = useState();
  const [lessorAddress, setLessorAddress] = useState();
  const [lessorPhoneNumber, setLessorPhoneNumber] = useState();
  const [lessorFaxNumber, setLessorFaxNumber] = useState();
  const [lessorEmail, setLessorEmail] = useState();
  const [lessorNote, setLessorNote] = useState();

  // 仲介会社情報
  const [brokerCompanyName, setBrokerCompanyName] = useState();
  const [brokerCompanyAddress, setBrokerCompanyAddress] = useState();
  const [brokerCompanyPhoneNumber, setBrokerCompanyPhoneNumber] = useState();
  const [brokerCompanyFaxNumber, setBrokerCompanyFaxNumber] = useState();
  const [brokerCompanyEmail, setBrokerCompanyEmail] = useState();
  const [brokerCompanyNote, setBrokerCompanyNote] = useState();

  const [contractorName, setContractorName] = useState();
  const [contractorNameKana, setContractorNameKana] = useState();
  const [contractorManager, setContractorManager] = useState();
  const [contractorEmail, setContractorEmail] = useState();
  const [contractorPhoneNumber, setContractorPhoneNumber] = useState();
  const [contractorCompanyPhoneNumber, setContractorCompanyPhoneNumber] = useState();
  const [contractorEmployeeNumber, setContractorEmployeeNumber] = useState();
  const [contractorAffiliationBranchName, setContractorAffiliationBranchName] = useState();
  const [contractorAffiliationBranchNumber, setContractorAffiliationBranchNumber] = useState();
  const [contractorAffiliationDepartmentName, setContractorAffiliationDepartmentName] = useState();
  const [contractorAffiliationDepartmentNumber, setContractorAffiliationDepartmentNumber] = useState();
  const [contractRentWithTax, setContractRenWithTax] = useState<number>(0);
  const [contractRentWithoutTax, setContractRenWithoutTax] = useState<number>(0);
  const [contractMonthlyGuaranteeAndManagementFeeWithTax, setMonthlyGuaranteeAndManagementFeeWithTax] = useState<number>(0);
  const [contractInitialGuaranteeAndManagementFeeWithTax, setInitialGuaranteeAndManagementFeeWithTax] = useState<number>(0);
  const [clientCompanyParkingName, setClientCompanyParkingName] = useState<string>('');
  const [clientCompanyPartition, setClientCompanyPartition] = useState<string>('');
  const [clientCompanyPartitionType, setClientCompanyPartitionType] = useState<number>(-1);
  const [clientCompanyParkingAddress, setClientCompanyParkingAddress] = useState<string>('');
  const [clientCompanyParkingLatitude, setClientCompanyParkingLatitude] = useState<string>('');
  const [clientCompanyParkingLongitude, setClientCompanyParkingLongitude] = useState<string>('');
  const [clientCompanyPartitionImage1File, setClientCompanyPartitionImage1File] = useState<[string, string]>();
  const [clientCompanyPartitionImage2File, setClientCompanyPartitionImage2File] = useState<[string, string]>();
  const [clientCompanyPartitionImage3File, setClientCompanyPartitionImage3File] = useState<[string, string]>();
  const [contractUpdateFeeWithTax, setUpdateFeeWithTax] = useState<number>(0);
  const [contractIssuePaperFeeWithTax, setIssuePaperFeeWithTax] = useState<number>(0);
  const [contractFirstMonthRentWithoutTax, setFirstMonthRentWithoutTax] = useState<number>(0);
  const [contractFinalMonthRentWithoutTax, setFinalMonthRentWithoutTax] = useState<number>(0);
  const [contractFirstMonthGuaranteeAndManagementFeeWithTax, setFirstMonthGuaranteeAndManagementFeeWithTax] = useState<number>(0);
  const [contractFinalMonthGuaranteeAndManagementFeeWithTax, setFinalMonthGuaranteeAndManagementFeeWithTax] = useState<number>(0);
  const [contractDepositWithTax, setDepositWithTax] = useState<number>(0);
  const [contractKeyMoneyWithTax, setKeyMoneyWithTax] = useState<number>(0);
  const [contractAdministrativeFeeWithTax, setAdministrativeFeeWithTax] = useState<number>(0);
  const [contractFirstMonthRentWithTax, setFirstMonthRentWithTax] = useState<number>(0);
  const [contractFinalMonthRentWithTax, setFinalMonthRentWithTax] = useState<number>(0);
  const [contractStartMonthTotalToPay, setStartMonthTotalToPay] = useState<number>(0);
  const [contractTotalToPay, setTotalToPay] = useState<number>(0);
  const [contractEndMonthTotalToPay, setEndMonthTotalToPay] = useState<number>(0);

  const [initPaymentCompanyName, setInitPaymentCompanyName] = useState<string>('');
  const [initPaymentSendMoneyDate, setInitPaymentSendMoneyDate] = useState();
  const [initPaymentBankNumber, setInitPaymentBankNumber] = useState<string>('');
  const [initPaymentBankName, setInitPaymentBankName] = useState<string>('');
  const [initPaymentBranchStoreNumber, setInitPaymentBranchStoreNumber] = useState<string>('');
  const [initPaymentBranchName, setInitPaymentBranchName] = useState<string>('');
  const [initPaymentDepositKindType, setInitPaymentDepositKindType] = useState<number>(-1);
  const [initPaymentBankAccountNumber, setInitPaymentBankAccountNumber] = useState<string>('');
  const [initPaymentBankAccountName, setInitPaymentBankAccountName] = useState<string>('');
  const [initPaymentSendMoneyAmount, setInitPaymentSendMoneyAmount] = useState<number>(0);

  const [monthlyPaymentCompanyName, setMonthlyPaymentCompanyName] = useState<string>('');
  const [monthlyPaymentSendMoneyDate, setMonthlyPaymentSendMoneyDate] = useState();
  const [monthlyPaymentBankNumber, setMonthlyPaymentBankNumber] = useState<string>('');
  const [monthlyPaymentBankName, setMonthlyPaymentBankName] = useState<string>('');
  const [monthlyPaymentBranchStoreNumber, setMonthlyPaymentBranchStoreNumber] = useState<string>('');
  const [monthlyPaymentBranchName, setMonthlyPaymentBranchName] = useState<string>('');
  const [monthlyPaymentDepositKindType, setMonthlyPaymentDepositKindType] = useState<number>(-1);
  const [monthlyPaymentBankAccountNumber, setMonthlyPaymentBankAccountNumber] = useState<string>('');
  const [monthlyPaymentBankAccountName, setMonthlyPaymentBankAccountName] = useState<string>('');
  const [monthlyPaymentSendMoneyAmount, setMonthlyPaymentSendMoneyAmount] = useState<number>(0);

  const [contractAttributionOther1, setContractAttributionOther1] = useState<string>('');
  const [contractAttributionOther1Memo, setContractAttributionOther1Memo] = useState<string>('');
  const [contractAttributionOther2, setContractAttributionOther2] = useState<string>('');
  const [contractAttributionOther2Memo, setContractAttributionOther2Memo] = useState<string>('');
  const [contractAttributionOther3, setContractAttributionOther3] = useState<string>('');
  const [contractAttributionOther3Memo, setContractAttributionOther3Memo] = useState<string>('');
  const [carInfoCarModel, setCarModel] = useState<string>('');
  const [carInfoRegnoRegionName, setRegnoRegionName] = useState<string>('');
  const [carInfoRegnoClassDigit, setRegnoClassDigit] = useState<string>('');
  const [carInfoRegnoHiragana, setRegnoHiragana] = useState<string>('');
  const [carInfoRegnoSeqDigit, setRegnoSeqDigit] = useState<string>('');
  const [carInfoCertificateFile, setCertificateFile] = useState<[string, string]>();
  const [contractLeaseAgreementFile, setContractLeaseAgreementFile] = useState<[string, string]>();
  const [selectedOrganization, setSelectedOrganization] = useState<string>('');


  const [contractedDateError, setContractedDateError] = useState<boolean>(false);
  const [contractedDateHelperText, setContractedDateHelpertext] = useState<string>('');
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [startDateHelperText, setStartDateHelpertext] = useState<string>('');
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [endDateHelperText, setEndDateHelpertext] = useState<string>('');
  const [firstStartDateError, setFirstStartDateError] = useState<boolean>(false);
  const [firstStartDateHelperText, setFirstStartDateHelpertext] = useState<string>('');
  const [parkingUserNameError, setParkingUserNameError] = useState<boolean>(false);
  const [parkingUserNameHelperText, setParkingUserNameHelpertext] = useState<string>('');
  const [contractKindError, setContractKindError] = useState<boolean>(false);
  const [contractKindHelperText, setContractKindHelpertext] = useState<string>('');
  const [managementStateError, setManagementStateError] = useState<boolean>(false);
  const [managementStateHelperText, setManagementStateHelpertext] = useState<string>('');
  const [contractPeriodMonthError, setContractPeriodMonthError] = useState<boolean>(false);
  const [contractPeriodMonthHelperText, setContractPeriodMonthHelperText] = useState<string>('');
  const [clientCompanyParkingNameError, setClientCompanyParkingNameError] = useState<boolean>(false);
  const [clientCompanyParkingNameHelperText, setClientCompamnyParkingNameHelperText] = useState<string>('');
  const [clientCompanyNameError, setClientCompanyNameError] = useState<boolean>(false);
  const [clientCompanyNameHelperText, setClientCompamnyNameHelperText] = useState<string>('');
  const [contractorNameError, setContractorNameError] = useState<boolean>(false);
  const [contractorNameHelperText, setContractorNameHelperText] = useState<string>('');
  const [contractorNameKanaError, setContractorNameKanaError] = useState<boolean>(false);
  const [contractorNameKanaHelperText, setContractorNameKanaHelperText] = useState<string>('');
  const [clientCompanyPartitionError, setClientCompanyPartitionError] = useState<boolean>(false);
  const [clientCompanyPartitionHelperText, setClientCompamnyPartitionHelperText] = useState<string>('');

  const [clientCompanyLongitudeError, setClientCompanyLongitudeError] = useState<boolean>(false);
  const [clientCompanyLongitudeHelperText, setClientCompamnyLongitudeHelperText] = useState<string>('');
  const [clientCompanyLatitudeError, setClientCompanyLatitudeError] = useState<boolean>(false);
  const [clientCompanyLatitudeHelperText, setClientCompamnyLatitudeHelperText] = useState<string>('');
  const [selectedOrgnaizationError, setSelectedOrgnaizationError] = useState<boolean>(false);
  const [selectedOrgnaizationHelperText, setSelectedOrgnaizationHelperText] = useState<string>('');
  const [contractStartMonthTotalToPayError, setContractStartMonthTotalToPayError] = useState<boolean>(false);
  const [contractStartMonthTotalToPayHelperText, setContractStartMonthTotalToPayHelperText] = useState<string>('');
  const [contractTotalToPayError, setContractTotalToPayError] = useState<boolean>(false);
  const [contractTotalToPayHelperText, setContractTotalToPayHelperText] = useState<string>('');
  const [contractRentWithTaxError, setContractRentWithTaxError] = useState<boolean>(false);
  const [contractRentWithTaxHelperText, setContractRentWithTaxHelperText] = useState<string>('');
  const [updateFeeWithTaxError, setUpdateFeeWithTaxError] = useState<boolean>(false);
  const [updateFeeWithTaxHelperText, setUpdateFeeWithTaxHelperText] = useState<string>('');
  const [paymentMethodError, setPaymentMethodError] = useState<boolean>(false);
  const [paymentMethodHelperText, setPaymentMethodHelperText] = useState<string>('');
  const [organizations, setOrganizations] = useState<Organization[]>();
  const [_, user] = useUser();

  const onHandleChange = useCallback((value) => {
    setSelectedOrganization(value)
  }, []);
  const onChangeContractKind = useCallback(e => {
    if (e.target.value === "代理契約") {
      setPaymentMethod(-1);
      let paymentList = paymentMethod.filter(item => item === "Park Direct");
      setPaymentList(paymentList);
    } else if (e.target.value == "直接契約/直接支払い") {
      let paymentList = paymentMethod.filter(item => item !== "Park Direct");
      setPaymentMethod(-1);
      setPaymentList(paymentList);
    } else if (e.target.value == "直接契約/PD支払い") {
      setPaymentMethod(-1);
      let paymentList = paymentMethod.filter(item => item === "Park Direct");
      setPaymentList(paymentList);
    }
    setContractKind(e.target.value)
  }, []);


  const onChangeManagementState = useCallback(e => setManagementState(e.target.value), []);
  const onChangeContractedDate = useCallback(e => setContractedDate(e.target.value), []);
  const onChangeContractPeriodMonth = useCallback(e => setContractPeriodMonth(e.target.value), []);
  const onChangeNeedIssuePaper = useCallback(e => setNeedIssuePaper(e.target.value), []);
  // const onChangeNormalContract = useCallback(e => console.log(e.target),[]);
  const onChangeCancelDate = useCallback(e => setCancelDate(e.target.value), []);
  const onChangeFirstStartDate = useCallback(e => setFirstStartDate(e.target.value), []);
  const onChangeStartDate = useCallback(e => { setStartDate(e.target.value) }, []);
  const onChangeEndDate = useCallback(e => setEndDate(e.target.value), []);
  const onChangeUserName = useCallback(e => setParkingUserName(e.target.value), []);
  const onChangeUserNameKana = useCallback(e => setParkingUserNameKana(e.target.value), []);
  const onChangeUserManager = useCallback(e => setParkingUserManager(e.target.value), []);
  const onChangeUserEmail = useCallback(e => setParkingUserEmail(e.target.value), []);
  const onChangeUserMobilePhoneNumber = useCallback(e => setParkingUserMobilePhoneNumber(e.target.value), []);
  const onChangeUserPhoneNumber = useCallback(e => setParkingUserPhoneNumber(e.target.value), []);
  const onChangeUserEmployeeNumber = useCallback(e => setParkingUserEmployeeNumber(e.target.value), []);
  const onChangeUserAffiliationBranchName = useCallback(e => setParkingUserAffiliationBranchName(e.target.value), []);
  const onChangeUserAffiliationBranchNumber = useCallback(e => setParkingUserAffiliationBranchNumber(e.target.value), []);
  const onChangeUserAffiliationDepartmentName = useCallback(e => setParkingUserAffiliationDepartmentName(e.target.value), []);
  const onChangeUserAffiliationDepartmentNumber = useCallback(e => setParkingUserAffiliationDepartmentNumber(e.target.value), []);
  const onChangeClientCompanyName = useCallback(e => setClientCompanyName(e.target.value), []);
  const onChangeClientStoreName = useCallback(e => setClientStoreName(e.target.value), []);
  const onChangeClientCompanyAddress = useCallback(e => setClientCompanyAddress(e.target.value), []);
  const onChangeClientCompanyStaffName = useCallback(e => setClientCompanyStaffName(e.target.value), []);
  const onChangeClientCompanyStaffEmail = useCallback(e => setClientCompanyStaffEmail(e.target.value), []);
  const onChangeClientCompanyNote = useCallback(e => setClientCompanyNote(e.target.value), []);
  const onChangeClientCompanyPhoneNumber = useCallback(e => setClientCompanyPhoneNumber(e.target.value), []);
  const onChangeClientCompanyFaxNumber = useCallback(e => setClientCompanyFaxNumber(e.target.value), []);

  // 貸主情報
  const onChangeLessorName = useCallback(e => setLessorName(e.target.value), []);
  const onChangeLessorAddress = useCallback(e => setLessorAddress(e.target.value), []);
  const onChangeLessorPhoneNumber = useCallback(e => setLessorPhoneNumber(e.target.value), []);
  const onChangeLessorFaxNumber = useCallback(e => setLessorFaxNumber(e.target.value), []);
  const onChangeLessorEmail = useCallback(e => setLessorEmail(e.target.value), []);
  const onChangeLessorNote = useCallback(e => setLessorNote(e.target.value), []);

  // 仲介会社情報
  const onChangeBrokerCompanyName = useCallback(e => setBrokerCompanyName(e.target.value), []);
  const onChangeBrokerCompanyAddress = useCallback(e => setBrokerCompanyAddress(e.target.value), []);
  const onChangeBrokerCompanyPhoneNumber = useCallback(e => setBrokerCompanyPhoneNumber(e.target.value), []);
  const onChangeBrokerCompanyFaxNumber = useCallback(e => setBrokerCompanyFaxNumber(e.target.value), []);
  const onChangeBrokerCompanyEmail = useCallback(e => setBrokerCompanyEmail(e.target.value), []);
  const onChangeBrokerCompanyNote = useCallback(e => setBrokerCompanyNote(e.target.value), []);

  const onChangeContractorName = useCallback(e => setContractorName(e.target.value), []);
  const onChangeContractorNameKana = useCallback(e => setContractorNameKana(e.target.value), []);
  const onChangeContractorManager = useCallback(e => setContractorManager(e.target.value), []);
  const onChangeContractorEmail = useCallback(e => setContractorEmail(e.target.value), []);
  const onChangeContractorPhoneNumber = useCallback(e => setContractorPhoneNumber(e.target.value), []);
  const onChangeContractorCompanyPhoneNumber = useCallback(e => setContractorCompanyPhoneNumber(e.target.value), []);
  const onChangeContractorEmployeeNumber = useCallback(e => setContractorEmployeeNumber(e.target.value), []);
  const onChangeContractorAffiliationBranchName = useCallback(e => setContractorAffiliationBranchName(e.target.value), []);
  const onChangeContractorAffiliationBranchNumber = useCallback(e => setContractorAffiliationBranchNumber(e.target.value), []);
  const onChangeContractorAffiliationDepartmentName = useCallback(e => setContractorAffiliationDepartmentName(e.target.value), []);
  const onChangeContractorAffiliationDepartmentNumber = useCallback(e => setContractorAffiliationDepartmentNumber(e.target.value), []);
  const onChangeClientCompanyParkingName = useCallback(e => setClientCompanyParkingName(e.target.value), []);
  const onChangeClientCompanyPartition = useCallback(e => setClientCompanyPartition(e.target.value), []);
  const onChangeClientCompanyPartitionType = useCallback(e => setClientCompanyPartitionType(e.target.value), []);
  const onChangeClientCompanyParkingAddress = useCallback(e => setClientCompanyParkingAddress(e.target.value), []);
  const onChangeClientCompanyParkingLatitude = useCallback(e => setClientCompanyParkingLatitude(e.target.value), []);
  const onChangeClientCompanyParkingLongitude = useCallback(e => setClientCompanyParkingLongitude(e.target.value), []);
  const onChangeUpdateFeeWithTax = useCallback(e => setUpdateFeeWithTax(e.target.value), []);
  const onChangeIssuePaperFeeWithTax = useCallback(e => setIssuePaperFeeWithTax(e.target.value), []);
  const onChangePaymentBank = useCallback(e => setPaymentBank(e.target.value), []);
  const onChangePaymentMethod = useCallback(e => setPaymentMethod(e.target.value), []);
  const onChangePaymentDate = useCallback(e => setPaymentDate(e.target.value), []);
  const onChangeRentWithTax = useCallback(e => setContractRenWithTax(e.target.value), []);
  const onChangeRentWithoutTax = useCallback(e => setContractRenWithoutTax(e.target.value), []);
  const onChangeFirstMonthRentWithoutTax = useCallback(e => setFirstMonthRentWithoutTax(e.target.value), []);
  const onChangeFinalMonthRentWithoutTax = useCallback(e => setFinalMonthRentWithoutTax(e.target.value), []);
  const onChangeMonthlyGuaranteeAndManagementFeeWithTax = useCallback(e => setMonthlyGuaranteeAndManagementFeeWithTax(e.target.value), []);
  const onChangeInitialGuaranteeAndManagementFeeWithTax = useCallback(e => setInitialGuaranteeAndManagementFeeWithTax(e.target.value), []);
  const onChangeFirstMonthGuaranteeAndManagementFeeWithTax = useCallback(e => setFirstMonthGuaranteeAndManagementFeeWithTax(e.target.value), []);
  const onChangeFinalMonthGuaranteeAndManagementFeeWithTax = useCallback(e => setFinalMonthGuaranteeAndManagementFeeWithTax(e.target.value), []);
  const onChangeDepositWithTax = useCallback(e => setDepositWithTax(e.target.value), []);
  const onChangeKeyMoneyWithTax = useCallback(e => setKeyMoneyWithTax(e.target.value), []);
  const onChangeAdministrativeFeeWithTax = useCallback(e => setAdministrativeFeeWithTax(e.target.value), []);

  const onChangeInitPaymentCompanyName = useCallback(e => setInitPaymentCompanyName(e.target.value), []);
  const onChangeInitPaymentSendMoneyDate = useCallback(e => setInitPaymentSendMoneyDate(e.target.value), []);
  const onChangeInitPaymentBankNumber = useCallback(e => setInitPaymentBankNumber(e.target.value), []);
  const onChangeInitPaymentBankName = useCallback(e => setInitPaymentBankName(e.target.value), []);
  const onChangeInitPaymentBranchStoreNumber = useCallback(e => setInitPaymentBranchStoreNumber(e.target.value), []);
  const onChangeInitPaymentBranchName = useCallback(e => setInitPaymentBranchName(e.target.value), []);
  const onChangeInitPaymentDepositKindType = useCallback(e => setInitPaymentDepositKindType(e.target.value), []);
  const onChangeInitPaymentBankAccountNumber = useCallback(e => setInitPaymentBankAccountNumber(e.target.value), []);
  const onChangeInitPaymentBankAccountName = useCallback(e => setInitPaymentBankAccountName(e.target.value), []);
  const onChangeInitPaymentSendMoneyAmount = useCallback(e => setInitPaymentSendMoneyAmount(e.target.value), []);

  const onChangeMonthlyPaymentCompanyName = useCallback(e => setMonthlyPaymentCompanyName(e.target.value), []);
  const onChangeMonthlyPaymentSendMoneyDate = useCallback(e => setMonthlyPaymentSendMoneyDate(e.target.value), []);
  const onChangeMonthlyPaymentBankNumber = useCallback(e => setMonthlyPaymentBankNumber(e.target.value), []);
  const onChangeMonthlyPaymentBankName = useCallback(e => setMonthlyPaymentBankName(e.target.value), []);
  const onChangeMonthlyPaymentBranchStoreNumber = useCallback(e => setMonthlyPaymentBranchStoreNumber(e.target.value), []);
  const onChangeMonthlyPaymentBranchName = useCallback(e => setMonthlyPaymentBranchName(e.target.value), []);
  const onChangeMonthlyPaymentDepositKindType = useCallback(e => setMonthlyPaymentDepositKindType(e.target.value), []);
  const onChangeMonthlyPaymentBankAccountNumber = useCallback(e => setMonthlyPaymentBankAccountNumber(e.target.value), []);
  const onChangeMonthlyPaymentBankAccountName = useCallback(e => setMonthlyPaymentBankAccountName(e.target.value), []);
  const onChangeMonthlyPaymentSendMoneyAmount = useCallback(e => setMonthlyPaymentSendMoneyAmount(e.target.value), []);

  const onChangeContractAttributionOther1 = useCallback(e => setContractAttributionOther1(e.target.value), []);
  const onChangeContractAttributionOther1Memo = useCallback(e => setContractAttributionOther1Memo(e.target.value), []);
  const onChangeContractAttributionOther2 = useCallback(e => setContractAttributionOther2(e.target.value), []);
  const onChangeContractAttributionOther2Memo = useCallback(e => setContractAttributionOther2Memo(e.target.value), []);
  const onChangeContractAttributionOther3 = useCallback(e => setContractAttributionOther3(e.target.value), []);
  const onChangeContractAttributionOther3Memo = useCallback(e => setContractAttributionOther3Memo(e.target.value), []);
  const onChangeCarModel = useCallback(e => setCarModel(e.target.value), []);
  const onChangeRegnoRegionName = useCallback(e => setRegnoRegionName(e.target.value), []);
  const onChangeRegnoClassDigit = useCallback(e => setRegnoClassDigit(e.target.value), []);
  const onChangeRegnoHiragana = useCallback(e => setRegnoHiragana(e.target.value), []);
  const onChangeRegnoSeqDigit = useCallback(e => setRegnoSeqDigit(e.target.value), []);
  const onChangeFirstMonthRentWithTax = useCallback(e => setFirstMonthRentWithTax(e.target.value), []);
  const onChangeFinalMonthRentWithTax = useCallback(e => setFinalMonthRentWithTax(e.target.value), []);
  const onChangeStartMonthTotalToPay = useCallback(e => setStartMonthTotalToPay(e.target.value), []);
  const onChangeTotalToPay = useCallback(e => setTotalToPay(e.target.value), []);
  const onChangeEndMonthTotalToPay = useCallback(e => setEndMonthTotalToPay(e.target.value), []);


  const onChangePartitionImageFile = useCallback((number: 1 | 2 | 3) => async (event: ChangeEvent<HTMLInputElement>) => {
    const results = await getFilenameAndDataUrl(event.target.files?.[0]);
    if (results) {
      if (number === 1) {
        setClientCompanyPartitionImage1File(results);
      } else if (number === 2) {
        setClientCompanyPartitionImage2File(results);
      } else if (number === 3) {
        setClientCompanyPartitionImage3File(results);
      }
    }
  }, []);


  const onChangeCertificateFile = useCallback(() => async (event: ChangeEvent<HTMLInputElement>) => {
    const results = await getFilenameAndDataUrl(event.target.files?.[0]);
    if (results) {
      setCertificateFile(results);
    }
  }, []);

  const onChangeContractLeaseAgreementFile = useCallback(() => async (event: ChangeEvent<HTMLInputElement>) => {
    const results = await getFilenameAndDataUrl(event.target.files?.[0]);
    if (results) {
      setContractLeaseAgreementFile(results);
    }
  }, []);



  const newContract = useCallback((navigate) => async () => {

    try {
      setIsUploading(true);
      const newContractPostData = {
        organization: selectedOrganization,
        // 基本情報
        management_state: managementState !== -1 ? managementState : undefined,
        contract_kind: contractKind !== -1 ? contractKindTypeList[contractKind] : undefined,
        total_to_pay: contractTotalToPay,
        normal_contract: normalContract,
        contract_condition: {
          contracted_date: contractedDate ? contractedDate : null,
          cancel_date: cancelDate ? cancelDate : null,
          need_issue_paper: needIssuePaper !== -1 ? needIssuePaper : undefined,
          contract_period_month: contractPeriodMonth,
          start_date: startDate ? startDate : null,
          end_date: endDate ? endDate : null,
          first_start_date: firstStartDate ? firstStartDate : null,
          payment_method: contractPaymentMethod !== -1 ? paymentMethodTypeList[contractPaymentMethod] : undefined,
          payment_date: contractPaymentDate,
          rent_with_tax: contractRentWithTax,
          rent_without_tax: contractRentWithoutTax,
          first_month_rent_with_tax: contractFirstMonthRentWithTax,
          final_month_rent_with_tax: contractFinalMonthRentWithTax,
          first_month_rent_without_tax: contractFirstMonthRentWithoutTax,
          final_month_rent_without_tax: contractFinalMonthRentWithoutTax,
          monthly_guarantee_and_management_fee_with_tax: contractMonthlyGuaranteeAndManagementFeeWithTax,
          initial_guarantee_and_management_fee_with_tax: contractInitialGuaranteeAndManagementFeeWithTax,
          first_month_guarantee_and_management_fee_with_tax: contractFirstMonthGuaranteeAndManagementFeeWithTax,
          final_month_guarantee_and_management_fee_with_tax: contractFinalMonthGuaranteeAndManagementFeeWithTax,
          deposit_with_tax: contractDepositWithTax,
          key_money_with_tax: contractKeyMoneyWithTax,
          administrative_fee_with_tax: contractAdministrativeFeeWithTax,
          update_fee_with_tax: contractUpdateFeeWithTax,
          issue_paper_fee_with_tax: contractIssuePaperFeeWithTax,
          start_month_total_to_pay: contractStartMonthTotalToPay,
          total_to_pay: contractTotalToPay,
          end_month_total_to_pay: contractEndMonthTotalToPay
        },

        payment_bank: paymentBank,

        // 利用者情報
        parking_user: {
          name: parkingUserName ? parkingUserName : undefined,
          name_kana: parkingUserNameKana,
          manager: parkingUserManager,
          email: parkingUserEmail,
          mobile_phone_number: parkingUserMobilePhoneNumber,
          phone_number: parkingUserPhoneNumber,
          employee_number: parkingUserEmployeeNumber,
          affiliation_branch_name: parkingUserAffiliationBranchName,
          affiliation_branch_number: parkingUserAffiliationBranchNumber,
          affiliation_department_name: parkingUserAffiliationDepartmentName,
          affiliation_department_number: parkingUserAffiliationDepartmentNumber,
        },

        // 管理会社情報
        client_company: {
          client_company_name: clientCompanyName,
          client_store_name: clientStoreName,
          parking_name: clientCompanyParkingName,
          partition: clientCompanyPartition,
          partition_type: clientCompanyPartitionType,
          address: clientCompanyParkingAddress ? clientCompanyParkingAddress : undefined,
          latitude: clientCompanyParkingLatitude ? clientCompanyParkingLatitude : undefined,
          longitude: clientCompanyParkingLongitude ? clientCompanyParkingLongitude : undefined,
          client_company_address: clientCompanyAddress,
          staff_name: clientCompanyStaffName,
          phone_number: clientCompanyPhoneNumber,
          fax_number: clientCompanyFaxNumber,
          staff_email: clientCompanyStaffEmail,
          note: clientCompanyNote
        },

        // 貸主情報
        lessor: {
          lessor_name: lessorName,
          address: lessorAddress,
          phone_number: lessorPhoneNumber,
          fax_number: lessorFaxNumber,
          email: lessorEmail,
          note: lessorNote
        },

        // 仲介会社情報
        broker_company: {
          broker_company_name: brokerCompanyName,
          address: brokerCompanyAddress,
          phone_number: brokerCompanyPhoneNumber,
          fax_number: brokerCompanyFaxNumber,
          email: brokerCompanyEmail,
          note: brokerCompanyNote
        },

        // 契約者情報
        parking_contractor: {
          name: contractorName,
          name_kana: contractorNameKana,
          manager: contractorManager,
          email: contractorEmail,
          phone_number: contractorPhoneNumber,
          company_phone_number: contractorCompanyPhoneNumber,
          employee_number: contractorEmployeeNumber,
          affiliation_branch_name: contractorAffiliationBranchName,
          affiliation_branch_number: contractorAffiliationBranchNumber,
          affiliation_department_name: contractorAffiliationDepartmentName,
          affiliation_department_number: contractorAffiliationDepartmentNumber,
        },

        contract_vehicle: {
          car_model: carInfoCarModel,
          regno_region_name: carInfoRegnoRegionName,
          regno_class_digit: carInfoRegnoClassDigit,
          regno_hiragana: carInfoRegnoHiragana,
          regno_seq_digit: carInfoRegnoSeqDigit,
        },

        // 契約情報
        contract_attribution: {
          other1: contractAttributionOther1,
          other1_memo: contractAttributionOther1Memo,
          other2: contractAttributionOther2,
          other2_memo: contractAttributionOther2Memo,
          other3: contractAttributionOther3,
          other3_memo: contractAttributionOther3Memo,
          init_payment_company_name: initPaymentCompanyName,
          init_payment_send_money_date: initPaymentSendMoneyDate ? initPaymentSendMoneyDate:null,
          init_payment_bank_number: initPaymentBankNumber,
          init_payment_bank_name: initPaymentBankName,
          init_payment_branch_store_number: initPaymentBranchStoreNumber,
          init_payment_branch_name: initPaymentBranchName,
          init_payment_deposit_kind_type: initPaymentDepositKindType,
          init_payment_bank_account_number: initPaymentBankAccountName,
          init_payment_bank_account_name: initPaymentBankAccountName,
          init_payment_send_money_amount: initPaymentSendMoneyAmount,
          monthly_payment_company_name: monthlyPaymentCompanyName,
          monthly_payment_send_money_date: monthlyPaymentSendMoneyDate ? monthlyPaymentSendMoneyDate:null,
          monthly_payment_bank_number: monthlyPaymentBankName,
          monthly_payment_bank_name: monthlyPaymentBankName,
          monthly_payment_branch_store_number: monthlyPaymentBranchStoreNumber,
          monthly_payment_branch_name: monthlyPaymentBranchName,
          monthly_payment_deposit_kind_type: monthlyPaymentDepositKindType,
          monthly_payment_bank_account_number: monthlyPaymentBankAccountNumber,
          monthly_payment_bank_account_name: monthlyPaymentBankAccountName,
          monthly_payment_send_money_amount: monthlyPaymentSendMoneyAmount,
        },

      }
      console.log(newContractPostData);
      const [res, response] = await api.post(
        `/api/v1/contract_new/`
        , newContractPostData);

      if (res.status === HttpStatus.OK) {
        if (clientCompanyPartitionImage1File != null || clientCompanyPartitionImage2File != null || clientCompanyPartitionImage3File != null || carInfoCertificateFile != null || contractLeaseAgreementFile != null) {
          const url = user?.isAdmin ? `/api/v1/admin/contract/${getContractId(response)}/` : `/api/v1/contract/${getContractId(response)}/`;
          // ファイルのアップロードは別で行う
          const [file_upload_status, file_upload_response] = await api.patch(
            url
            , {
              client_company: {
                partition_image1: clientCompanyPartitionImage1File,
                partition_image2: clientCompanyPartitionImage2File,
                partition_image3: clientCompanyPartitionImage3File,
              },
              contract_vehicle: {
                certificate_file: carInfoCertificateFile,
              },
              lease_agreement_file: contractLeaseAgreementFile,
              no_validation: true,

            });

          if (file_upload_status.status !== HttpStatus.OK) {
            showError();
            return;
          }
        }
        showSuccess(response, navigate);

      } else {
        let responseData = converResponse(response);
        setSelectedOrgnaizationError(false)
        setSelectedOrgnaizationHelperText("");
        setParkingUserNameError(false);
        setParkingUserNameHelpertext("");
        setContractKindError(false);
        setContractKindHelpertext("");
        setManagementStateError(false);
        setManagementStateHelpertext("");
        setContractPeriodMonthError(false);
        setContractPeriodMonthHelperText("");
        setClientCompanyParkingNameError(false);
        setClientCompamnyParkingNameHelperText("");
        setClientCompanyNameError(false);
        setClientCompamnyNameHelperText("");
        setClientCompanyPartitionError(false);
        setClientCompamnyPartitionHelperText("");
        setClientCompanyLatitudeError(false);
        setClientCompamnyLatitudeHelperText("");
        setClientCompanyLongitudeError(false);
        setClientCompamnyLongitudeHelperText("");
        setContractorNameError(false);
        setContractorNameHelperText("");
        setContractorNameKanaError(false);
        setContractorNameKanaHelperText("");
        setContractStartMonthTotalToPayError(false);
        setContractStartMonthTotalToPayHelperText("");
        setContractTotalToPayError(false);
        setContractTotalToPayHelperText("");
        setContractRentWithTaxError(false);
        setContractRentWithTaxHelperText("");
        setContractRentWithTaxError(false);
        setContractRentWithTaxHelperText("");
        setPaymentMethodError(false);
        setPaymentMethodHelperText("");
        setStartDateError(false);
        setStartDateHelpertext("");
        setEndDateError(false);
        setEndDateHelpertext("");
        setUpdateFeeWithTaxError(false);
        setUpdateFeeWithTaxHelperText("");

        if (responseData.organization) {
          setSelectedOrgnaizationError(true)
          setSelectedOrgnaizationHelperText(responseData.organization[0]);
        }
        if (responseData.parking_user != null && responseData.parking_user.name[0]) {
          setParkingUserNameError(true);
          setParkingUserNameHelpertext(responseData.parking_user.name[0]);
        }
        if (responseData.contract_kind) {
          setContractKindError(true);
          setContractKindHelpertext(responseData.contract_kind[0]);
        }
        if (responseData.management_state) {
          setManagementStateError(true);
          setManagementStateHelpertext(responseData.management_state[0]);
        }
        if (responseData.contract_condition != null && responseData.contract_condition.contracted_date) {
          setContractedDateError(true);
          setContractedDateHelpertext(responseData.contract_condition.contracted_date[0]);
        }
        if (responseData.contract_condition != null && responseData.contract_condition.start_date) {
          setStartDateError(true);
          setStartDateHelpertext(responseData.contract_condition.start_date[0]);
        }
        if (responseData.contract_condition != null && responseData.contract_condition.end_date) {
          setEndDateError(true);
          setEndDateHelpertext(responseData.contract_condition.end_date[0]);
        }
        if (responseData.contract_condition != null && responseData.contract_condition.first_start_date) {
          setFirstStartDateError(true);
          setFirstStartDateHelpertext(responseData.contract_condition.first_start_date[0]);
        }
        if (responseData.contract_condition != null && responseData.contract_condition.contract_period_month) {
          setContractPeriodMonthError(true);
          setContractPeriodMonthHelperText(responseData.contract_condition.contract_period_month[0]);
        }
        if (responseData.client_company != null && responseData.client_company.parking_name) {
          setClientCompanyParkingNameError(true);
          setClientCompamnyParkingNameHelperText(responseData.client_company.parking_name[0]);
        }
        if (responseData.client_company != null && responseData.client_company.client_company_name) {
          setClientCompanyNameError(true);
          setClientCompamnyNameHelperText(responseData.client_company.client_company_name[0]);
        }

        if (responseData.client_company != null && responseData.client_company.partition) {
          setClientCompanyPartitionError(true);
          setClientCompamnyPartitionHelperText(responseData.client_company.partition[0]);
        }

        if (responseData.client_company != null && responseData.client_company.latitude) {
          setClientCompanyLatitudeError(true);
          setClientCompamnyLatitudeHelperText(responseData.client_company.latitude[0]);
        }

        if (responseData.client_company != null && responseData.client_company.longitude) {
          setClientCompanyLongitudeError(true);
          setClientCompamnyLongitudeHelperText(responseData.client_company.longitude[0]);
        }


        if (responseData.parking_contractor != null && responseData.parking_contractor.name) {
          setContractorNameError(true);
          setContractorNameHelperText(responseData.parking_contractor.name[0]);
        }

        if (responseData.parking_contractor != null && responseData.parking_contractor.name_kana) {
          setContractorNameKanaError(true);
          setContractorNameKanaHelperText(responseData.parking_contractor.name_kana[0]);
        }

        if (responseData.total_to_pay) {
          setContractTotalToPayError(true);
          setContractTotalToPayHelperText(responseData.total_to_pay);
        }

        if (responseData.start_month_total_to_pay) {
          setContractStartMonthTotalToPayError(true);
          setContractStartMonthTotalToPayHelperText(responseData.start_month_total_to_pay);
        }
        if (responseData.contract_condition != null && responseData.contract_condition.rent_with_tax) {
          setContractRentWithTaxError(true);
          setContractRentWithTaxHelperText(responseData.contract_condition.rent_with_tax);
        }

        if (responseData.contract_condition != null && responseData.contract_condition.update_fee_with_tax) {
          setUpdateFeeWithTaxError(true);
          setUpdateFeeWithTaxHelperText(responseData.contract_condition.update_fee_with_tax[0]);
        }

        if (responseData.contract_condition != null && responseData.contract_condition.payment_method) {
          setPaymentMethodError(true);
          setPaymentMethodHelperText(responseData.contract_condition.payment_method);
        }

        showError();
      }
    } finally {
      setIsUploading(false);
    }
  }, [
    managementState,
    contractKind,
    contractedDate,
    normalContract,
    cancelDate,
    contractPeriodMonth,
    firstStartDate,
    startDate,
    endDate,
    needIssuePaper,
    parkingUserName,
    parkingUserNameKana,
    parkingUserManager,
    parkingUserEmail,
    parkingUserMobilePhoneNumber,
    parkingUserEmployeeNumber,
    parkingUserAffiliationBranchName,
    parkingUserAffiliationBranchNumber,
    parkingUserAffiliationDepartmentName,
    parkingUserAffiliationDepartmentNumber,
    clientCompanyName,
    clientStoreName,
    clientCompanyAddress,
    clientCompanyStaffName,
    clientCompanyPhoneNumber,
    clientCompanyFaxNumber,
    clientCompanyStaffEmail,
    clientCompanyNote,
    lessorName,
    lessorAddress,
    lessorPhoneNumber,
    lessorFaxNumber,
    lessorEmail,
    lessorNote,
    brokerCompanyName,
    brokerCompanyAddress,
    brokerCompanyPhoneNumber,
    brokerCompanyFaxNumber,
    brokerCompanyEmail,
    brokerCompanyNote,
    contractorName,
    contractorNameKana,
    contractorManager,
    contractorEmail,
    contractorPhoneNumber,
    contractorCompanyPhoneNumber,
    contractorEmployeeNumber,
    contractorAffiliationBranchName,
    contractorAffiliationBranchNumber,
    contractorAffiliationDepartmentName,
    contractorAffiliationDepartmentNumber,
    contractRentWithTax,
    contractRentWithoutTax,
    contractFirstMonthRentWithTax,
    contractFinalMonthRentWithTax,
    contractFirstMonthRentWithoutTax,
    contractFinalMonthRentWithoutTax,
    contractMonthlyGuaranteeAndManagementFeeWithTax,
    contractInitialGuaranteeAndManagementFeeWithTax,
    contractFirstMonthGuaranteeAndManagementFeeWithTax,
    contractFinalMonthGuaranteeAndManagementFeeWithTax,
    contractDepositWithTax,
    contractKeyMoneyWithTax,
    contractAdministrativeFeeWithTax,
    contractStartMonthTotalToPay,
    contractTotalToPay,
    contractEndMonthTotalToPay,
    paymentBank,
    paymentMethod, contractPaymentMethod, contractPaymentDate,
    clientCompanyParkingLatitude,
    clientCompanyParkingLongitude,
    clientCompanyPartitionImage1File,
    clientCompanyPartitionImage2File,
    clientCompanyPartitionImage3File,
    contractLeaseAgreementFile,
    clientCompanyParkingName,
    clientCompanyPartition,
    carInfoCarModel,
    carInfoRegnoRegionName,
    carInfoRegnoClassDigit,
    carInfoRegnoHiragana,
    carInfoRegnoSeqDigit,
    carInfoCertificateFile,
    selectedOrganization,
    contractAttributionOther1,
    contractAttributionOther2,
    contractAttributionOther3,
    contractAttributionOther1Memo,
    contractAttributionOther2Memo,
    contractAttributionOther3Memo,
    contractUpdateFeeWithTax,
    contractIssuePaperFeeWithTax,
    initPaymentCompanyName,
    initPaymentSendMoneyDate,
    initPaymentBankNumber,
    initPaymentBankName,
    initPaymentBranchStoreNumber,
    initPaymentBranchName,
    initPaymentDepositKindType,
    initPaymentBankAccountNumber,
    initPaymentBankAccountName,
    initPaymentSendMoneyAmount,
    monthlyPaymentCompanyName,
    monthlyPaymentSendMoneyDate,
    monthlyPaymentBankNumber,
    monthlyPaymentBankName,
    monthlyPaymentBranchStoreNumber,
    monthlyPaymentBranchName,
    monthlyPaymentDepositKindType,
    monthlyPaymentBankAccountNumber,
    monthlyPaymentBankAccountName,
    monthlyPaymentSendMoneyAmount,
  ]);



  // <CSS>

  const sectionStyle = css({
    margin: "3rem 3rem 3rem 3rem",
    backgroundColor: "white",
    padding: "1rem 1rem 1rem 1rem",
  });


  const modalTitleStyle = css({
    color: 'black',
    backgroundColor: '#F6F6F6',
    padding: '10px',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: "0.5rem",
  });


  const rowStyle = css({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    gap: '0 2%',
  });

  const selectFormStyle = css({
    width: '49%',
  });

  const partitionImageInputFileStyle = css({
    position: 'absolute',
    right: '15%',
    bottom: '7px',
    width: '30%',
  });

  const inputFileColStyle = css({
    flex: 1,
    position: 'relative',
  });

  const StyledCheckBoxLabel = styled(Typography)({
    fontSize: "13px",
    color: "#222222"
  });

  const StyledCheckBox = styled(Checkbox)({
    width: "18px",
    height: "18px",
    marginLeft: "12px",
    marginRight: "6px",
  });


  // 管理者の場合は組織を選択できるが通常ユーザーの場合は所属している組織を設定する
  if (user?.isAdmin && organizations == null) {
    getOrganizations().then((organizations) => setOrganizations(organizations));
  } else if (user != null && user?.organization != null && organizations == null) {
    let organizationList: Organization[] = new Array(user?.organization);
    setOrganizations(organizationList);
    setSelectedOrganization(user?.organization.id);
  }
  // 設定できる契約状態が管理者か通常ユーザーによって変わる
  let contractKinds;
  if (user?.isAdmin) {
    contractKinds = contractKindList.filter(item => item !== "ParkDirect");
  } else {
    contractKinds = contractKindList.filter(item => item !== "ParkDirect" && item !== "代理契約");
  }

  const noneDisplayStyle = user != null && (user?.isAdmin) ? "" : "none"

  return (
    <section css={sectionStyle}>
      <h1 >新規契約登録</h1>
      <article>
        <div css={modalTitleStyle}>組織選択</div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <RSelect
              value={selectedOrganization}
              options={organizations?.map((x) => ({value: x.id, label: x.name}))}
              onChangeFunc={onHandleChange}
            />
            <FormHelperText error={selectedOrgnaizationError}>{selectedOrgnaizationHelperText}</FormHelperText>
          </FormControl>
        </div>

        <div css={modalTitleStyle}>基本情報</div>

        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel required={true} id="contractKind" variant="standard" error={contractKindError}>契約形態</InputLabel>
            <Select label="契約形態" labelId="contractKind" variant="standard" value={contractKind} onChange={onChangeContractKind} error={contractKindError}>
              <MenuItem value={-1}>未選択</MenuItem>
              {contractKinds.map((value, index) => (<MenuItem key={index} value={value}>{value}</MenuItem>))}
            </Select>
            <FormHelperText error={contractKindError}>{contractKindHelperText}</FormHelperText>
          </FormControl>

          <FormControl css={selectFormStyle} >
            <InputLabel required={true} id="managementState" variant="standard" error={managementStateError}>契約状態</InputLabel>
            <Select label="契約状態" labelId="managementState" variant="standard" value={managementState} onChange={onChangeManagementState} error={managementStateError}>
              <MenuItem value={-1}>未選択</MenuItem>
              {ManagementStatus.map((value, index) => (
                <MenuItem key={index} value={index}>{value}</MenuItem>
              ))}
            </Select>
            <FormHelperText error={managementStateError}>{managementStateHelperText}</FormHelperText>
          </FormControl>
        </div>
        <div css={rowStyle} style={{ display: noneDisplayStyle }}>
          <FormControlLabel
            label={<StyledCheckBoxLabel>通常契約</StyledCheckBoxLabel>}
            control={
              <StyledCheckBox
                checked={normalContract}
                onChange={(e) => { setNormalContract(e.target.checked); }}
              />}
          />
        </div>
        <div css={rowStyle}>
          <StyledTextFieldRequired type="date" label="契約日" value={contractedDate} onChange={onChangeContractedDate} error={contractedDateError} helperText={contractedDateHelperText} />
          <StyledTextField type="date" label="解約日" value={cancelDate} onChange={onChangeCancelDate} />
        </div>
        <div css={rowStyle}>
          <StyledTextFieldRequired type="number" label="契約期間（ヶ月）" value={contractPeriodMonth} onChange={onChangeContractPeriodMonth} error={contractPeriodMonthError} helperText={contractPeriodMonthHelperText} />
          <StyledTextFieldRequired type="date" label="初回契約開始日" value={firstStartDate} onChange={onChangeFirstStartDate} error={firstStartDateError} helperText={firstStartDateHelperText} />
        </div>
        <div css={rowStyle}>
          <StyledTextFieldRequired type="date" label="賃貸借期間_開始" value={startDate} onChange={onChangeStartDate} error={startDateError} helperText={startDateHelperText} />
          <StyledTextFieldRequired type="date" label="賃貸借期間_終了" value={endDate} onChange={onChangeEndDate} error={endDateError} helperText={endDateHelperText} />
        </div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel id="needIssuePaper" variant="standard">保管場所使用承諾証明書</InputLabel>
            <Select label="保管場所使用承諾証明書" labelId="needIssuePaper" variant="standard" value={needIssuePaper} onChange={onChangeNeedIssuePaper}>
              <MenuItem value={-1}>未選択</MenuItem>
              <MenuItem value={1}>必要</MenuItem>
              <MenuItem value={0}>不要</MenuItem>
            </Select>
          </FormControl>
          <div css={inputFileColStyle}>
            <StyledFileField label="賃貸借契約書" value={'登録なし'} disabled />
            <div css={partitionImageInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangeContractLeaseAgreementFile()} /></div>
          </div>
        </div>

        <div css={modalTitleStyle} >利用者情報</div>
        <div css={rowStyle}>
          <StyledTextField label="利用者名" value={parkingUserName} onChange={onChangeUserName} error={parkingUserNameError} helperText={parkingUserNameHelperText} />
          <StyledTextField label="利用者名(カナ)" value={parkingUserNameKana} onChange={onChangeUserNameKana} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="利用-担当者名" value={parkingUserManager} onChange={onChangeUserManager} />
          <StyledTextField type="email" label="利用者メールアドレス" value={parkingUserEmail} onChange={onChangeUserEmail} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="利用者携帯電話番号" value={parkingUserMobilePhoneNumber} onChange={onChangeUserMobilePhoneNumber} />
          <StyledTextField label="利用者自宅電話番号" value={parkingUserPhoneNumber} onChange={onChangeUserPhoneNumber} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="利用者従業員番号" value={parkingUserEmployeeNumber} onChange={onChangeUserEmployeeNumber} />
          <div />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="利用者所属拠点名" value={parkingUserAffiliationBranchName} onChange={onChangeUserAffiliationBranchName} />
          <StyledTextField label="利用者所属拠点番号" value={parkingUserAffiliationBranchNumber} onChange={onChangeUserAffiliationBranchNumber} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="利用者所属組織名" value={parkingUserAffiliationDepartmentName} onChange={onChangeUserAffiliationDepartmentName} />
          <StyledTextField label="利用者所属組織番号" value={parkingUserAffiliationDepartmentNumber} onChange={onChangeUserAffiliationDepartmentNumber} />
        </div>

        <div css={modalTitleStyle}>契約者情報</div>
        <div css={rowStyle}>
          <StyledTextFieldRequired label="契約者名" value={contractorName} onChange={onChangeContractorName} error={contractorNameError} helperText={contractorNameHelperText} />
          <StyledTextFieldRequired label="契約者名(カナ)" value={contractorNameKana} onChange={onChangeContractorNameKana} error={contractorNameKanaError} helperText={contractorNameKanaHelperText} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="契約-担当者名" value={contractorManager} onChange={onChangeContractorManager} />
          <StyledTextField label="契約者メールアドレス" value={contractorEmail} onChange={onChangeContractorEmail} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="契約者電話番号" value={contractorPhoneNumber} onChange={onChangeContractorPhoneNumber} />
          <StyledTextField label="契約者会社電話番号" value={contractorCompanyPhoneNumber} onChange={onChangeContractorCompanyPhoneNumber} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="契約者従業員番号" value={contractorEmployeeNumber} onChange={onChangeContractorEmployeeNumber} />
          <div />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="契約者所属拠点名" value={contractorAffiliationBranchName} onChange={onChangeContractorAffiliationBranchName} />
          <StyledTextField label="契約者所属拠点番号" value={contractorAffiliationBranchNumber} onChange={onChangeContractorAffiliationBranchNumber} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="契約者所属組織名" value={contractorAffiliationDepartmentName} onChange={onChangeContractorAffiliationDepartmentName} />
          <StyledTextField label="契約者所属組織番号" value={contractorAffiliationDepartmentNumber} onChange={onChangeContractorAffiliationDepartmentNumber} />
        </div>

        <div css={modalTitleStyle}>駐車場情報</div>
        <div css={rowStyle}>
          <StyledTextFieldRequired label="駐車場名" value={clientCompanyParkingName} onChange={onChangeClientCompanyParkingName} error={clientCompanyParkingNameError} helperText={clientCompanyParkingNameHelperText} />
          <StyledTextFieldRequired label="区画番号" value={clientCompanyPartition} onChange={onChangeClientCompanyPartition} error={clientCompanyPartitionError} helperText={clientCompanyPartitionHelperText} />
        </div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel id="partitionType" variant="standard">区画種別</InputLabel>
            <Select label="区画種別" labelId="partitionType" variant="standard" value={clientCompanyPartitionType} onChange={onChangeClientCompanyPartitionType} >
              <MenuItem value={-1} hidden>未選択</MenuItem>
              {partition.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
            </Select>
          </FormControl>
          <StyledTextField label="住所" value={clientCompanyParkingAddress} onChange={onChangeClientCompanyParkingAddress} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="緯度" value={clientCompanyParkingLatitude} onChange={onChangeClientCompanyParkingLatitude} error={clientCompanyLatitudeError} helperText={clientCompanyLatitudeHelperText} />
          <StyledTextField label="経度" value={clientCompanyParkingLongitude} onChange={onChangeClientCompanyParkingLongitude} error={clientCompanyLongitudeError} helperText={clientCompanyLongitudeHelperText} />
        </div>
        <div css={rowStyle}>
          <div css={inputFileColStyle}>
            <StyledFileField label="区画図1" value={'登録なし'} disabled />
            {<div css={partitionImageInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangePartitionImageFile(1)} /></div>}
          </div>
          <div css={inputFileColStyle}>
            <StyledFileField label="区画図2" value={'登録なし'} disabled />
            {<div css={partitionImageInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangePartitionImageFile(2)} /></div>}
          </div>
        </div>
        <div css={rowStyle}>
          <div css={inputFileColStyle}>
            <StyledFileField label="区画図3" value={'登録なし'} disabled />
            {<div css={partitionImageInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangePartitionImageFile(3)} /></div>}
          </div>
          <div css={inputFileColStyle} />
        </div>

        <div css={modalTitleStyle}>管理会社情報</div>
        <div css={rowStyle}>
          <StyledTextFieldRequired label="管理会社名" value={clientCompanyName} onChange={onChangeClientCompanyName} error={clientCompanyNameError} helperText={clientCompanyNameHelperText} />
          <StyledTextField label="店舗名" value={clientStoreName} onChange={onChangeClientStoreName} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="管理会社住所" value={clientCompanyAddress} onChange={onChangeClientCompanyAddress} />
          <StyledTextField label="管理会社電話番号" value={clientCompanyPhoneNumber} onChange={onChangeClientCompanyPhoneNumber} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="管理会社FAX番号" value={clientCompanyFaxNumber} onChange={onChangeClientCompanyFaxNumber} />
          <StyledTextField label="担当者名" value={clientCompanyStaffName} onChange={onChangeClientCompanyStaffName} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="担当者メールアドレス" value={clientCompanyStaffEmail} onChange={onChangeClientCompanyStaffEmail} />
          <StyledTextField label="備考" value={clientCompanyNote} onChange={onChangeClientCompanyNote} />
        </div>

        <div css={modalTitleStyle}>貸主情報</div>
        <div css={rowStyle}>
          <StyledTextField label="貸主名" value={lessorName} onChange={onChangeLessorName}  />
          <StyledTextField label="貸主住所" value={lessorAddress} onChange={onChangeLessorAddress} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="貸主電話番号" value={lessorPhoneNumber} onChange={onChangeLessorPhoneNumber} />
          <StyledTextField label="貸主FAX番号" value={lessorFaxNumber} onChange={onChangeLessorFaxNumber} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="貸主メールアドレス" value={lessorEmail} onChange={onChangeLessorEmail} />
          <StyledTextField label="備考" value={lessorNote} onChange={onChangeLessorNote} />
        </div>

        <div css={modalTitleStyle}>仲介会社情報</div>
        <div css={rowStyle}>
          <StyledTextField label="仲介会社名" value={brokerCompanyName} onChange={onChangeBrokerCompanyName} />
          <StyledTextField label="仲介会社住所" value={brokerCompanyAddress} onChange={onChangeBrokerCompanyAddress} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="仲介会社電話番号" value={brokerCompanyPhoneNumber} onChange={onChangeBrokerCompanyPhoneNumber} />
          <StyledTextField label="仲介会社FAX番号" value={brokerCompanyFaxNumber} onChange={onChangeBrokerCompanyFaxNumber} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="仲介会社メールアドレス" value={brokerCompanyEmail} onChange={onChangeBrokerCompanyEmail} />
          <StyledTextField label="備考" value={brokerCompanyNote} onChange={onChangeBrokerCompanyNote} />
        </div>

        <div css={modalTitleStyle}>契約車両情報</div>
        <div css={rowStyle}>
          <StyledTextField label="車種" value={carInfoCarModel} onChange={onChangeCarModel} />
          <StyledTextField label="車種-登録番号（地域名）" value={carInfoRegnoRegionName} onChange={onChangeRegnoRegionName} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="車種-登録番号（分類番号3桁）" value={carInfoRegnoClassDigit} onChange={onChangeRegnoClassDigit} />
          <StyledTextField label="車種-登録番号（平仮名等）" value={carInfoRegnoHiragana} onChange={onChangeRegnoHiragana} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="車種-登録番号(一連指定番号4桁)" value={carInfoRegnoSeqDigit} onChange={onChangeRegnoSeqDigit} />
          <div css={inputFileColStyle}>
            <StyledFileField label="車検証" value={'登録なし'} disabled />
            <div css={partitionImageInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangeCertificateFile()} /></div>
          </div>
        </div>
        <div css={modalTitleStyle}>契約条件</div>
        <div css={rowStyle}>
          <StyledTextFieldRequired type="number" label="月額賃料(税込)" value={contractRentWithTax} onChange={onChangeRentWithTax} error={contractRentWithTaxError} helperText={contractRentWithTaxHelperText} />
          <StyledTextField type="number" label="月額賃料(税抜)" value={contractRentWithoutTax} onChange={onChangeRentWithoutTax} />
        </div>
        <div css={rowStyle}>
          <StyledTextField type="number" label="初月賃料(税込)" value={contractFirstMonthRentWithTax} onChange={onChangeFirstMonthRentWithTax} />
          <StyledTextField type="number" label="最終月賃料(税込)" value={contractFinalMonthRentWithTax} onChange={onChangeFinalMonthRentWithTax} />
        </div>
        <div css={rowStyle}>
          <StyledTextField type="number" label="初月賃料(税抜)" value={contractFirstMonthRentWithoutTax} onChange={onChangeFirstMonthRentWithoutTax} />
          <StyledTextField type="number" label="最終月賃料(税抜)" value={contractFinalMonthRentWithoutTax} onChange={onChangeFinalMonthRentWithoutTax} />
        </div>
        <div css={rowStyle}>
          <StyledTextField type="number" label="月額保証／管理料(税込)" value={contractMonthlyGuaranteeAndManagementFeeWithTax} onChange={onChangeMonthlyGuaranteeAndManagementFeeWithTax} />
          <StyledTextField type="number" label="初回保証／管理料(税込)" value={contractInitialGuaranteeAndManagementFeeWithTax} onChange={onChangeInitialGuaranteeAndManagementFeeWithTax} />
        </div>
        <div css={rowStyle}>
          <StyledTextField type="number" label="初月保証／管理料(税込)" value={contractFirstMonthGuaranteeAndManagementFeeWithTax} onChange={onChangeFirstMonthGuaranteeAndManagementFeeWithTax} />
          <StyledTextField type="number" label="最終月保証／管理料(税込)" value={contractFinalMonthGuaranteeAndManagementFeeWithTax} onChange={onChangeFinalMonthGuaranteeAndManagementFeeWithTax} />
        </div>
        <div css={rowStyle}>
          <StyledTextField type="number" label="敷金(非課税)" value={contractDepositWithTax} onChange={onChangeDepositWithTax} />
          <StyledTextField type="number" label="礼金(税込)" value={contractKeyMoneyWithTax} onChange={onChangeKeyMoneyWithTax} />
        </div>
        <div css={rowStyle}>
          <StyledTextField type="number" label="事務手数料(税込)" value={contractAdministrativeFeeWithTax} onChange={onChangeAdministrativeFeeWithTax} />
        </div>
        <div css={rowStyle}>
          <StyledTextFieldRequired type="number" label="初月支払い総額" value={contractStartMonthTotalToPay} onChange={onChangeStartMonthTotalToPay} error={contractStartMonthTotalToPayError} helperText={contractStartMonthTotalToPayHelperText} />
          <StyledTextField type="number" label="最終月支払い総額" value={contractEndMonthTotalToPay} onChange={onChangeEndMonthTotalToPay} />
        </div>
        <div css={rowStyle}>
          <StyledTextFieldRequired type="number" label="通常月支払い総額" value={contractTotalToPay} onChange={onChangeTotalToPay} error={contractTotalToPayError} helperText={contractTotalToPayHelperText} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="その他1" value={contractAttributionOther1} onChange={onChangeContractAttributionOther1} />
          <StyledTextField label="その他1_メモ" value={contractAttributionOther1Memo} onChange={onChangeContractAttributionOther1Memo} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="その他2" value={contractAttributionOther2} onChange={onChangeContractAttributionOther2} />
          <StyledTextField label="その他2_メモ" value={contractAttributionOther2Memo} onChange={onChangeContractAttributionOther2Memo} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="その他3" value={contractAttributionOther3} onChange={onChangeContractAttributionOther3} />
          <StyledTextField label="その他3_メモ" value={contractAttributionOther3Memo} onChange={onChangeContractAttributionOther3Memo} />
        </div>
        <div css={modalTitleStyle}>その他費用</div>
        <div css={rowStyle}>
          <StyledTextFieldRequired type="number" label="更新手数料(税込)" value={contractUpdateFeeWithTax} onChange={onChangeUpdateFeeWithTax} error={updateFeeWithTaxError} helperText={updateFeeWithTaxHelperText} />
          <StyledTextField type="number" label="保管場所使用承諾書発行手数料(税込)" value={contractIssuePaperFeeWithTax} onChange={onChangeIssuePaperFeeWithTax} />
        </div>
        <div css={modalTitleStyle}>支払い方法</div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel id="paymentMethod" variant="standard" required={true} error={paymentMethodError}>支払先</InputLabel>
            <Select label="支払先" labelId="paymentMethod" variant="standard" value={contractPaymentMethod} onChange={onChangePaymentMethod} error={paymentMethodError} >
              <MenuItem value={-1}>未選択</MenuItem>
              {paymentList.map((value, index) => (<MenuItem key={index} value={value}>{value}</MenuItem>))}
            </Select>
            <FormHelperText error={paymentMethodError}>{paymentMethodHelperText}</FormHelperText>
          </FormControl>
          <StyledTextField label="毎月支払日" type="text" value={contractPaymentDate} onChange={onChangePaymentDate} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="支払先口座情報" value={paymentBank} onChange={onChangePaymentBank} />
        </div>
        <div css={modalTitleStyle}>初期費用支払</div>
        <div css={rowStyle}>
          <StyledTextField label="法人名" value={initPaymentCompanyName} onChange={onChangeInitPaymentCompanyName} />
          <StyledTextField label="送金日" type="date" value={initPaymentSendMoneyDate} onChange={onChangeInitPaymentSendMoneyDate} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="銀行番号" value={initPaymentBankNumber} onChange={onChangeInitPaymentBankNumber} />
          <StyledTextField label="銀行名" value={initPaymentBankName} onChange={onChangeInitPaymentBankName} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="支店番号" value={initPaymentBranchStoreNumber} onChange={onChangeInitPaymentBranchStoreNumber} />
          <StyledTextField label="支店名" value={initPaymentBranchName} onChange={onChangeInitPaymentBranchName} />
        </div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel id="initPaymentDepositKindType" variant="standard">預金種目</InputLabel>
            <Select label="預金種目" labelId="initPaymentDepositKindType" variant="standard" value={initPaymentDepositKindType} onChange={onChangeInitPaymentDepositKindType} >
              <MenuItem value={-1} hidden>未選択</MenuItem>
              {depositKind.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
            </Select>
          </FormControl>
          <StyledTextField label="口座番号" value={initPaymentBankAccountNumber} onChange={onChangeInitPaymentBankAccountNumber} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="口座名" value={initPaymentBankAccountName} onChange={onChangeInitPaymentBankAccountName} />
          <StyledTextField label="送金金額" value={initPaymentSendMoneyAmount} onChange={onChangeInitPaymentSendMoneyAmount} />
        </div>
        <div css={modalTitleStyle}>毎月支払</div>
        <div css={rowStyle}>
          <StyledTextField label="法人名" value={monthlyPaymentCompanyName} onChange={onChangeMonthlyPaymentCompanyName} />
          <StyledTextField label="送金日" type="date" value={monthlyPaymentSendMoneyDate} onChange={onChangeMonthlyPaymentSendMoneyDate} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="銀行番号" value={monthlyPaymentBankNumber} onChange={onChangeMonthlyPaymentBankNumber} />
          <StyledTextField label="銀行名" value={monthlyPaymentBankName} onChange={onChangeMonthlyPaymentBankName} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="支店番号" value={monthlyPaymentBranchStoreNumber} onChange={onChangeMonthlyPaymentBranchStoreNumber} />
          <StyledTextField label="支店名" value={monthlyPaymentBranchName} onChange={onChangeMonthlyPaymentBranchName} />
        </div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel id="monthlyPaymentDepositKindType" variant="standard">預金種目</InputLabel>
            <Select label="預金種目" labelId="monthlyPaymentDepositKindType" variant="standard" value={monthlyPaymentDepositKindType} onChange={onChangeMonthlyPaymentDepositKindType} >
              <MenuItem value={-1} hidden>未選択</MenuItem>
              {depositKind.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
            </Select>
          </FormControl>
          <StyledTextField label="口座番号" value={monthlyPaymentBankAccountNumber} onChange={onChangeMonthlyPaymentBankAccountNumber} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="口座名" value={monthlyPaymentBankAccountName} onChange={onChangeMonthlyPaymentBankAccountName} />
          <StyledTextField label="送金金額" value={monthlyPaymentSendMoneyAmount} onChange={onChangeMonthlyPaymentSendMoneyAmount} />
        </div>
        <div >
          <LoadingButton variant="contained" onClick={newContract(navigateFunction)} loading={isUploading} disabled={isUploading}>保存</LoadingButton>
        </div>
      </article>
    </section>
  )
}

const showError = () => {
  alert('契約登録時にエラーが発生しました。入力項目をご確認ください。');
};

const showSuccess = (response: any, navigate: Function) => {
  alert('新規契約登録が完了しました。');
  const path = getPath(response);
  navigate(path);
}


const getPath = (response: any) => {
  let contractDetailLink = "/contract-detail/" + response.id;
  return contractDetailLink;
};

const getContractId = (response: any) => {
  return response.id;
};

const converResponse = (data: any) => {
  return data;
}

import styled from "@emotion/styled";
import { StyledPanelContainer, StyledPanelContent, StyledPanelHeader, StyledPanelTitle } from "./styles";
import { useCallback, useEffect, useState, useMemo } from "react";
import { api } from "../../../infra/Api";
import moment from "moment";
import { ContractSummaries } from "./types";
import { Grid } from "@mui/material";


export const ContractSummaryPanel = () => {
  const [totalCount, setTotalCount] = useState<number>();
  const [totalPay, setTotalPay] = useState<number>();
  const [pdCount, setPdCount] = useState<number>();
  const [agencyCount, setAgencyCount] = useState<number>();
  const [directCount, setDirectCount] = useState<number>();
  const [pdTotal, setPdTotal] = useState<number>();
  const [agencyTotal, setAgencyTotal] = useState<number>();
  const [directTotal, setDirectTotal] = useState<number>();

  const thisMonth = useMemo(() => moment().format('YYYY年M月1日'), [moment().format('YYYYMM')]);

  const fetchSummary = useCallback(async () => {
    const  { status, result } = await fetchData();
    if (!result) return;

    const pdSummary = result.filter(v => v.contract_kind === 0 && v.management_state === 1);
    const agencySummary = result.filter(v => v.contract_kind === 1 && v.management_state === 1);
    const directSummary = result.filter(v => (v.contract_kind === 2 || v.contract_kind === 3) && v.management_state === 1);

    // 解約済みは契約数から除く
    // 同じ月に複数回の支払いがあった場合、契約数が増えてしまうので同じ契約IDが複数回カウントされないようにする
    const pdCount = new Set(pdSummary.map(v => v.id)).size;
    const agencyCount = new Set(agencySummary.map(v => v.id)).size;
    const directCount = new Set(directSummary.map(v => v.id)).size;

    const pdTotal = pdSummary.reduce((prev: number, current) => prev + current.total_to_pay, 0);
    const agencyTotal = agencySummary.reduce((prev: number, current) => prev + current.total_to_pay, 0);
    const directTotal = directSummary.reduce((prev: number, current) => prev + current.total_to_pay, 0);

    const totalCount = pdCount + agencyCount + directCount;
    const totalPay = pdTotal + agencyTotal + directTotal;

    setTotalCount(totalCount);
    setTotalPay(totalPay);
    setPdCount(pdCount);
    setAgencyCount(agencyCount);
    setDirectCount(directCount);
    setPdTotal(pdTotal);
    setAgencyTotal(agencyTotal);
    setDirectTotal(directTotal);
  }, []);

  useEffect(() => { 
    fetchSummary()
  }, []);

  return (
    <StyledPanelContainer>
      <StyledPanelHeader>
        <StyledPanelTitle>契約数サマリー</StyledPanelTitle>
      </StyledPanelHeader>
      <StyledPanelContent>
        <StyledDate>{thisMonth}〜現在</StyledDate>
        <StyledRow>
          <Grid container alignItems="center">
            <Grid item xs={3}><StyledRowTitle>合計</StyledRowTitle></Grid>
            <Grid item xs={4 + 5}>{/* 一時的に9にする */}
              <StyledRowValue>
                <StyledTotalNumber>{totalCount?.numberFormat()}</StyledTotalNumber>件
              </StyledRowValue>
            </Grid>
            {/* 一時的に非表示にする */}
            {/* <Grid item xs={5}>
              <StyledRowValue>
                <StyledTotalNumber>{totalPay?.numberFormat()}</StyledTotalNumber>円
              </StyledRowValue>
            </Grid> */}
          </Grid>
        </StyledRow>
        <StyledRow>
          <Grid container alignItems="center">
            <Grid item xs={3}><StyledRowTitle><StyledCircle color="rgb(31,87,170)" />PD契約</StyledRowTitle></Grid>
            <Grid item xs={4 + 5}>{/* 一時的に9にする */}
              <StyledRowValue>
                <StyledNumber>{pdCount?.numberFormat()}</StyledNumber>件
              </StyledRowValue>
            </Grid>
            {/* 一時的に非表示にする */}
            {/* <Grid item xs={5}>
              <StyledRowValue>
                <StyledNumber>{pdTotal?.numberFormat()}</StyledNumber>円
              </StyledRowValue>
            </Grid> */}
          </Grid>
        </StyledRow>
        <StyledRow>
          <Grid container alignItems="center">
            <Grid item xs={3}><StyledRowTitle><StyledCircle color="rgb(60,134,178)" />代理契約</StyledRowTitle></Grid>
            <Grid item xs={4 + 5}>{/* 一時的に9にする */}
              <StyledRowValue>
                <StyledNumber>{agencyCount?.numberFormat()}</StyledNumber>件
              </StyledRowValue>
            </Grid>
            {/* 一時的に非表示にする */}
            {/* <Grid item xs={5}>
              <StyledRowValue>
                <StyledNumber>{agencyTotal?.numberFormat()}</StyledNumber>円
              </StyledRowValue>
            </Grid> */}
          </Grid>
        </StyledRow>
        <StyledRow>
          <Grid container alignItems="center">
            <Grid item xs={3}><StyledRowTitle><StyledCircle color="rgb(204,204,204)" />直接契約</StyledRowTitle></Grid>
            <Grid item xs={4 + 5}>{/* 一時的に9にする */}
              <StyledRowValue>
                <StyledNumber>{directCount?.numberFormat()}</StyledNumber>件
              </StyledRowValue>
            </Grid>
            {/* 一時的に非表示にする */}
            {/* <Grid item xs={5}>
              <StyledRowValue>
                <StyledNumber>{directTotal?.numberFormat()}</StyledNumber>円
              </StyledRowValue>
            </Grid> */}
          </Grid>
        </StyledRow>
      </StyledPanelContent>
    </StyledPanelContainer>
  )
}

const fetchData = async () => {
  const [status, result] = await api.get<ContractSummaries>(
    "/api/v1/contract_summary/",
  );
  return { status, result };
};

const StyledDate = styled.div`
  font-size: 13px;
`

const StyledCircle = styled.div<{ color: string }>`
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background-color: ${({ color }) => color};
  margin-right: 0.2em;
`

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: solid 1px #eee;
  padding: 1rem 0;
`

const StyledTotalNumber = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-right: 0.2em;
`

const StyledRowTitle = styled.div`
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledRowValue = styled.div`
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const StyledNumber  = styled.span`
  font-size: 15px;
  font-weight: bold;
  margin-right: 0.2em;
`

import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { SimpleDialog } from "../parts/SimpleDialog";
import { LoadingButton } from "@mui/lab";
import styled from "@emotion/styled";
import { api } from "../../infra/Api";
import { HttpStatus } from "../../models/HttpStatus";
import moment from "moment";
import { getFilenameAndDataUrl } from "../../util/dataUrl";
import Snackbar from '@mui/material/Snackbar';
import { ContractAttachmentFile } from "../../models/ContractAttachmentFile";
import { useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../../atom/loggedInUserAtom";
import { ContractDetail } from "../../models/contract/ContractDetail";

export const ContractAttachmentFileComp = ({ contract }: { contract: ContractDetail }) => {
  const loggedInUser = useRecoilValue(loggedInUserAtom);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [contractAttachmentFiles, setContractAttachmentFiles] = useState<ContractAttachmentFile[]>([]);
  const [confirmDeleteContractAttachmentFile, setConfirmDeleteContractAttachmentFile] = useState<ContractAttachmentFile>();
  const [confirmUpdateContractAttachmentFile, setConfirmUpdateContractAttachmentFile] = useState<ContractAttachmentFile>();
  const [snackData, setSnackData] = useState<{ message: string; timeout?: number }>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const fetchContractAttachmentFiles = useCallback(async () => {
    const [httpStatus, data] = await api.get<ContractAttachmentFile[]>(`api/v1/contract_attachment_files/?contract_id=${contract.id}`);

    if (httpStatus.status === HttpStatus.OK && data) {
      setContractAttachmentFiles(data);
    }
  }, []);

  const onFileUpload = useCallback(async () => {
    if (!selectedFile) return;

    const results = await getFilenameAndDataUrl(selectedFile)
    if (!results) return;

    const [filename, data] = results

    try {
      setIsUploading(true);

      const [httpStatus] = await api.post(`api/v1/contract_attachment_files/`, {
        contract_id: contract.id,
        filename,
        data,
      });

      fetchContractAttachmentFiles();

      if (httpStatus.status === HttpStatus.CREATED) {
        setSelectedFile(null);
        showSnack('ファイルをアップロードしました');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  }, [selectedFile, fetchContractAttachmentFiles]);

  const downloadContractAttachmentFile = useCallback(async (contractAttachmentFile: ContractAttachmentFile) => {
    const [httpStatus, blob] = await api.getBlob(`api/v1/contract_attachment_files/download/${contractAttachmentFile.id}/`);
    if (!blob) return;

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', contractAttachmentFile.filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, []);

  const updateContractAttachmentFile = useCallback(async (contractAttachmentFile?: ContractAttachmentFile) => {
    if (!selectedFile || !contractAttachmentFile) return;

    const results = await getFilenameAndDataUrl(selectedFile);
    if (!results) return;

    const [filename, data] = results;

    try {
      setIsUploading(true);

      const [httpStatus] = await api.patch(`api/v1/contract_attachment_files/${contractAttachmentFile.id}/`, {
        filename,
        data,
      });

      fetchContractAttachmentFiles();

      if (httpStatus.status === HttpStatus.OK) {
        setSelectedFile(null);
        showSnack('ファイルを更新しました');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  }, [selectedFile, fetchContractAttachmentFiles]);

  const deleteContractAttachmentFile = useCallback(async (contractAttachmentFile?: ContractAttachmentFile) => {
    if (!contractAttachmentFile) return;
    const [httpStatus] = await api.delete(`api/v1/contract_attachment_files/${contractAttachmentFile.id}/`);
    fetchContractAttachmentFiles();

    if (httpStatus.status === HttpStatus.NO_CONTENT) {
      showSnack('ファイルを削除しました');
    }
  }, [fetchContractAttachmentFiles]);

  const showSnack = useCallback((message: string, timeout: number = 2000) => {
    setSnackData({ message, timeout });
  }, []);

  useEffect(() => {
    if (!selectedFile && fileInputRef.current) {
      fileInputRef.current.value = ""
    }
  }, [selectedFile]);

  useEffect(() => {
    fetchContractAttachmentFiles();
  }, []);

  const isDisabledSelectFileButton = isUploading;
  const isDisabledUploadButton = isDisabledSelectFileButton || !selectedFile;
  const isDiabledResetButton = isUploading || !selectedFile;

  const canModify = loggedInUser?.isAdmin || (contract.contractKind === 2 /* 直接契約/直接支払い */ && !loggedInUser?.isReadOnly)

  return (
    <>
      <StyledSection>
        <StyledTitle>その他ファイル</StyledTitle>

        <StyledHiddenInput id="file-upload" type="file" ref={fileInputRef} accept=".pdf, .xlsx, .xls, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(event) => setSelectedFile(event.target.files?.[0] ?? null)} disabled={isDisabledSelectFileButton} />

        <StyledSectionContainer>
          {canModify && (
            <>
              <StyledRow>
                <label htmlFor="file-upload">
                  <Button variant="contained" component="span" disabled={isDisabledSelectFileButton}>
                    アップロードするファイルを選択
                  </Button>
                </label>
                {selectedFile && <Typography>{selectedFile.name}</Typography>}
              </StyledRow>

              <StyledRow>
                <LoadingButton variant="contained" onClick={onFileUpload} disabled={isDisabledUploadButton}>
                  アップロード
                </LoadingButton>
                <Button variant="outlined" disabled={isDiabledResetButton} onClick={() => {
                  setSelectedFile(null);
                }}>
                  リセット
                </Button>
              </StyledRow>
            </>
          )}

          {contractAttachmentFiles.length > 0 ? (
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCellHeader>ファイル名</StyledTableCellHeader>
                  <StyledTableCellHeader colSpan={2}>更新日時</StyledTableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {contractAttachmentFiles.sort((a, b) => a.filename.localeCompare(b.filename)).map((contractAttachmentFile, index: number) => (
                  <TableRow key={`contractAttachmentFile-${index}`}>
                    <TableCell>{contractAttachmentFile.filename}</TableCell>
                    <TableCell>{moment(contractAttachmentFile.updated_at).format('YYYY-MM-DD HH:mm')}</TableCell>
                    <TableCell>
                      <StyledCellButtons>
                        <Button variant="contained" component="span" disabled={isUploading} onClick={() => downloadContractAttachmentFile(contractAttachmentFile)}>ダウンロード</Button>
                        {canModify && (
                          <>
                            <Button variant="contained" component="span" disabled={isUploading} onClick={() => setConfirmDeleteContractAttachmentFile(contractAttachmentFile)}>削除</Button>
                            <Tooltip title="アップロードするファイルを選択すると更新ができます" placement="right">
                              <span><Button variant="contained" component="span" disabled={isUploading || !selectedFile} onClick={() => setConfirmUpdateContractAttachmentFile(contractAttachmentFile)}>更新</Button></span>
                            </Tooltip>
                          </>
                        )}
                      </StyledCellButtons>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          ) : (
            <p>その他ファイルはありません。</p>
          )}
        </StyledSectionContainer>
      </StyledSection>

      <SimpleDialog
        open={!!confirmUpdateContractAttachmentFile}
        title="ファイルの更新"
        message={`ファイルを更新しますか？\n\n現在のファイル名: ${confirmUpdateContractAttachmentFile?.filename}\n新しいファイル名: ${selectedFile?.name}`}
        buttons={[
          {
            label: 'はい',
            onClick: () => {
              updateContractAttachmentFile(confirmUpdateContractAttachmentFile);
              setConfirmUpdateContractAttachmentFile(undefined);
            }
          },
          { label: 'キャンセル', onClick: () => setConfirmUpdateContractAttachmentFile(undefined) },
        ]}
      />

      <SimpleDialog
        open={!!confirmDeleteContractAttachmentFile}
        title="ファイルの削除"
        message={`ファイルを削除しますか？\n\n${confirmDeleteContractAttachmentFile?.filename}`}
        buttons={[
          {
            label: 'はい',
            onClick: () => {
              deleteContractAttachmentFile(confirmDeleteContractAttachmentFile);
              setConfirmDeleteContractAttachmentFile(undefined);
            }
          },
          { label: 'キャンセル', onClick: () => setConfirmDeleteContractAttachmentFile(undefined) },
        ]}
      />

      <Snackbar
        open={!!snackData}
        autoHideDuration={snackData?.timeout}
        onClose={() => setSnackData(undefined)}
        message={snackData?.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  )
}

const StyledSection = styled.section`
  background-color: white;
  padding: 2rem;
`;

const StyledTitle = styled.h3`
  display: flex;
  background-color: #F6F6F6;
  padding: 13px 16px;
  margin-bottom: 1.5rem;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding-left: 28px;
`;

const StyledSectionContainer = styled.div`
  padding: 1.5rem;
`;

const StyledRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  gap: 0 1%;
`;

const StyledCellButtons = styled.div`
  display: flex;
  gap: 0 .5em;
`

const StyledHiddenInput = styled.input`
  display: none;
`;

const StyledTable = styled(Table)`
`;

const StyledTableCellHeader = styled(TableCell)`
  font-weight: bold;
  font-size: 12px;
`;

export {}

declare global {
  interface Number {
    numberFormat(): string
  }
}

Number.prototype.numberFormat = function () {
  return this.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, '$1,')
}

import { useNavigate } from "react-router"



export const useBack = () => {
    const navigate = useNavigate();

    const back = () => {
        navigate(-1);
    }

    return back;
}
import { css } from "@emotion/react"
import React, { useEffect, useState, VFC } from "react"
import { ContractDetail } from "../../../models/contract/ContractDetail";
import { ContractListBox } from "./ContractListBox"
import { v4 } from "uuid";
import ScrollContainer from "react-indiana-drag-scroll";
import { getOrganizations, useOrganizations } from "../../../hooks/useOrganizations";
import { Organization } from "../../../models/organization";

const columnStyle = css({
  fontSize: "12px",
  padding: "1rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  borderBottom: "solid 3px #ccc",
  fontWeight: "bold"
});

const tableStyle = css({
  borderCollapse: "collapse",
  background: "white",
  margin: "1rem auto",
  whiteSpace: "nowrap",
});

const tableWrapperStyle = css({
  background: "white",
  marginLeft: "1rem",
  padding: "1rem",
  paddingRight: 0,
  marginTop: "0",
  paddingTop: "0",
  overflowX: "scroll",
});

export type ContractListProps = {
  contractList: ContractDetail[];
  amIAdmin?: boolean;
  organizationNameFilter?: string;
  showOrganizationSelectBox?: boolean;
  openModal?: any;
};

export const ContractList: VFC<ContractListProps> = ({
  contractList,
  amIAdmin,
  organizationNameFilter,
  showOrganizationSelectBox,
  openModal,
}) => {
  const [organizations, setOrganizations] = useState<Organization[]>();

  useEffect(() => {
    if (amIAdmin) {
      getOrganizations().then((organizations) => setOrganizations(organizations));
    }
  }, [amIAdmin])

  if (amIAdmin && !organizations) return null

  return (
    <ScrollContainer ignoreElements="span" css={tableWrapperStyle} className="container">
        <table css={tableStyle}>
          <thead>
            <tr>
              {amIAdmin && <td css={columnStyle}>組織</td>}
              <td css={columnStyle}>契約ID</td>
              <td css={columnStyle}>契約区分</td>
              <td css={columnStyle}>駐車場情報　</td>
              <td css={columnStyle}>管理会社情報</td>
              <td css={columnStyle}>支払情報</td>
              <td css={columnStyle}>契約期間／解約日　</td>
              <td css={columnStyle}>利用者情報</td>
              <td css={columnStyle}>利用者所属</td>
              <td css={columnStyle}>メモ1</td>
              <td css={columnStyle}>メモ2</td>
              <td css={columnStyle}>メモ3</td>
            </tr>
          </thead>
          <tbody>
            {contractList.map(x => (
                <ContractListBox
                  key={v4()}
                  contract={x}
                  amIAdmin={amIAdmin}
                  organizations={organizations}
                  organizationNameFilter={organizationNameFilter}
                  showOrganizationSelectBox={amIAdmin && showOrganizationSelectBox}
                  openModal={openModal}
                />
              )
            )}
          </tbody>
        </table>
    </ScrollContainer>)
}

import React, { FC } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/CradleLoader.css";

export const Loading: FC = () => {
  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateY(-50%) translateX(-50%)",
        }}
      >
        <Loader type="TailSpin" color="#00BFFF" height={100} width={100} timeout={30000} />
      </div>
    </div>
  );
};
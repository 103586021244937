import { css } from "@emotion/react"
import React, { KeyboardEventHandler, useCallback, useEffect, useRef, useState } from "react"
import { useApiGet } from "../../hooks/useApiGet";
import { ContractFromApi } from "../../models/contract/ContractFromApi";
import { HttpStatus } from "../../models/HttpStatus";
import { ContractList } from "../parts/Contract/ContractList";
import { Loading } from "../parts/Loading";
import { Navigate, useNavigate, useParams } from "react-router";
import { api } from "../../infra/Api";
import saveAs from "file-saver";
import { useOrganizationContractQuery } from "../../hooks/useOrganizationContractQuery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ContractDetail, convertToContractDetail } from "../../models/contract/ContractDetail";
import { Box, Checkbox, FormControlLabel, MenuItem, Select, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ContractDetailLinks } from "../parts/Contract/ContractDetailLinks";
import { Paginator } from "../parts/Paginator/Paginator";
import { AdminCheck } from "../parts/AdminCheck";  
import styled from "@emotion/styled";
import { ContractOrganizationModal } from "../parts/Contract/ContractOrganizationModal";

export const UnsetOrganizationContractListComp = () => {

  const { page } = useParams();
  const navigate = useNavigate();

  const initPage = page === undefined ? 1 : parseInt(page);

  const [currentPage, setCurrentPage] = useState(initPage);

  const { q,
    setManagementState,
    setContractKindPD,
    setContractKindProxy,
    setContractKindDirect,
    setContractKindDirectPd,
    setContractDateStart,
    setContractDateEnd,
    setContractFirstStartDateStart,
    setContractFirstStartDateEnd,
    setCancelledDateStart,
    setCancelledDateEnd,
    setFreeWordState,
    managementState,
    contractKindPD,
    contractKindProxy,
    contractKindDirect,
    contractKindDirectPd,
    contractDateStart,
    contractDateEnd,
    contractFirstStartDateStart,
    contractFirstStartDateEnd,
    cancelledDateStart,
    cancelledDateEnd,
    freeWordState,
    reset,
    isChanged
  } = useOrganizationContractQuery();

  const [httpStatus, contracts, reFetch] =
    useApiGet<ContractFromApi>(`/api/v1/contract?unset_organization=1&ordering=id&page=${currentPage}${q}`);

  const [, amIAdmin] = useApiGet<boolean>("/api/v1/am_i_admin/");
  const [isModal, setModelOpen] = useState(false);
  const [targetContract, setTargetContract] = useState<ContractDetail>();
  const [contractListState, setContractList] = useState<ContractDetail[]>();
  const [isCSVDownloadProcessing, setIsCSVDownloadProcessing] = useState(false);

  useEffect(() => {
    if (contracts) {
      setContractList(contracts?.results?.map(x => convertToContractDetail(x)) ?? []);
    }
  }, [contracts]);

  const freewordTextRef = useRef<HTMLInputElement>(null);
  const organizationTextRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    reFetch(`/api/v1/contract?unset_organization=1&ordering=id&page=${currentPage}${q}`);
    navigate(`/unset-organization-contract-list/${currentPage}`)
  }, [currentPage, reFetch, navigate, q]);

  const setContractKindPDSession = async(data:boolean) => {
    if (data){
      sessionStorage.setItem('orgContractKindPD',"true");
    } else{
      sessionStorage.setItem('orgContractKindPD',"false");
    }
  }

  const setContractKindProxySession = async(data:boolean) => {
    if (data){
      sessionStorage.setItem('orgContractKindProxy',"true");
    } else{
      sessionStorage.setItem('orgContractKindProxy',"false");
    }
  }

  const setContractKindDirectSession = async(data:boolean) => {
    if (data){
      sessionStorage.setItem('orgContractKindDirect',"true");
    } else{
      sessionStorage.setItem('orgContractKindDirect',"false");
    }
  }

  const setContractKindDirectPdSession = async(data:boolean) => {
    if (data){
      sessionStorage.setItem('orgContractKindDirectPd',"true");
    } else{
      sessionStorage.setItem('orgContractKindDirectPd',"false");
    }
  }

  const setManagementSession = async(data:string) => {
    sessionStorage.setItem('orgManagementState', data);
  }

  const setStartDateSession = async(date:Date) => {
    let date_string = ''
    if (date != null){
      date_string = date.toString();
    }
    sessionStorage.setItem('orgContractDateStart', date_string);
  }

  const setEndDatetSession = async(date:Date) => {
    let date_string = ''
    if (date != null){
      date_string = date.toString();
    }
    sessionStorage.setItem('orgContractDateEnd', date_string);
  }

  const setContractFirstStartDateStartSession = async(date:Date) => {
    let date_string = ''
    if (date != null){
      date_string = date.toString();
    }
    sessionStorage.setItem('orgContractFirstStartDateStart', date_string);
  }
  
  const setContractFirstStartDateEndSession = async(date:Date) => {
    let date_string = ''
    if (date != null){
      date_string = date.toString();
    }
    sessionStorage.setItem('orgContractFirstStartDateEnd', date_string);
  }

  const setCancelledDateStartSession = async(date:Date) => {
    let date_string = ''
    if (date != null){
      date_string = date.toString();
    }
    sessionStorage.setItem('orgCancelledDateStart', date_string);
  }
  
  const setCancelledDateEndSession = async(date:Date) => {
    let date_string = ''
    if (date != null){
      date_string = date.toString();
    }
    sessionStorage.setItem('orgCancelledDateEnd', date_string);
  }

  const setFreeWordSession = async(freeWord:string|undefined) => {
    if (freeWord == undefined){
      freeWord = ""
    }
    sessionStorage.setItem('orgFreeWord', freeWord);
  }
  
  const downloadCSV = async () => {
    if (isCSVDownloadProcessing) return;

    try {
      setIsCSVDownloadProcessing(true);

      const [httpStatus, csv] = await api.getRaw<string>(
        `/api/v1/contract_export?ordering=id${q}`,
        "text/csv",
        "text/csv");
  
      if (csv === undefined) {
        return;
      }
  
      const bom = "\uFEFF";
      const blob = new Blob([bom, csv], { type: 'text/csv' });
      saveAs(blob, `契約情報.csv`)
    } finally {
      setIsCSVDownloadProcessing(false);
    }
  };

  const close = async () => {
    setModelOpen(false)
    const [httpStatus, contractFromApiData] = await api.get<ContractFromApi>(`/api/v1/contract?unset_organization=1&ordering=id&page=${currentPage}${q}`);
    if (httpStatus.status === HttpStatus.OK && contractFromApiData?.results && contractFromApiData.results.length > 0) {
      const contractDetails: ContractDetail[] = contractFromApiData.results.map(x => convertToContractDetail(x)) ?? [];
      setContractList(contractDetails);
    } else if (httpStatus.status === HttpStatus.OK && contractFromApiData?.results && contractFromApiData.results.length == 0){
      setContractList([]);
    }
  };

  // <CSS>

  const sectionStyle = css({
    width: "100%",
    paddingBottom: "10rem",
    minWidth: "800px",
  });

  const headStyle = css({
    marginLeft: "3rem",
    marginTop: "2rem",
    fontWeight: "normal"
  });

  const titleStyle = css({
    fontSize: "12px",
    fontWeight: "bold",
    width: "100px",
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap"
  });

  const inputWrapperStyle = css({
    display: "flex",
    justifyContent: "left",
    flexShrink: "initial",
    columnGap: "0.5rem",
    alignItems: "center",
    flexWrap: "nowrap"
  });

  const filtersStyle = css({
    margin: "1rem", marginBottom: "0", padding: "2rem 0"
  })

  const checkBoxStyle = css({
    width: "18px",
    height: "18px",
    "&:checked + CheckBoxLabel": {
      backgroundColor: "#0056B0",
      borderColor: "red",
      color: "#005680"
    }
  });

  const checkboxLabelStyle = css({
    fontSize: "13px",
    color: "#222222"
  });

  const menuContainerStyle = css({
    display: "flex",
    justifyContent: "left",
    flexShrink: "initial",
    flexWrap: "wrap",
    marginLeft: "2rem",
    columnGap: "1rem",
    marginBottom: "0.5rem",
    rowGap: "1rem",
  });


  const selectContractStatusStyle = css({
    height: "40px",
    borderColor: "#DCDCDC",
    fontSize: "13px",
    background: "#FFFFFF"
  });

  const datePickerWrapperStyle = css({
    display: "flex",
    alignItems: "center",
    minHeight: "40px"
  });

  const datePickerStyle = css({
    fontSize: "14px",
    background: "#FFFFFF",
    textAlign: "left",
    padding: "0",
    paddingLeft: "1rem",
    width: "160px",
    borderRadius: "4px",
    height: "40px",
    border: "solid 1px #DCDCDC"
  });

  const searchInputWrapperStyle = css({
    display: "flex",
    background: "white",
    alignItems: "center",
    width: "396px",
    height: "40px",
    border: "solid 1px #DCDCDC",
    borderRadius: "4px",
    paddingLeft: "1rem",
    color: "#222222",
    "&:focus": {
      outline: "2px solid"
    }
  });

  const searchInputStyle = css({
    display: "block",
    width: "100%",
    height: "90%",
    border: "none",
    "&:focus-visible": {
      outline: "none"
    }
  });

  const buttonStyle = css({
    border: "none",
    fontSize: "12px",
    margin: "2rem",
    marginBottom: "0",
    background: "#0351a1",
    transition: "ease-in all 0.1s",
    borderRadius: "4px",
    padding: "14px 1rem",
    minWidth: "10rem",
    color: "white",
    ":hover": {
      cursor: "pointer",
      background: "#0351a1",
      color: "white",
      border: "none",
      opacity: "0.7"
    },
    "&:disabled": {
      background: "#D4D4D4",
      cursor: "not-allowed",
      opacity: "1",
    },
  });

  const clearButtonStyle = css({
    border: "none",
    fontSize: "12px",
    marginLeft: "auto",
    marginRight: "2rem",
    background: isChanged() ? "#0351a1" : "#D4D4D4",
    transition: "ease-in all 0.1s",
    borderRadius: "4px",
    padding: "14px 1rem",
    minWidth: "10rem",
    color: "white",
    ":hover": {
      cursor: isChanged() ? "pointer" : "auto",
      color: "white",
      border: "none",
      opacity: isChanged() ? "0.7" : "1"
    }
  });

  const csvDownloadContainer = css({
    textAlign: "right",
    background: "white",
    marginLeft: "1rem",
    marginRight: "1rem",
  });

  const paginationWrapperStyle = css({
    display: "flex",
    marginRight: "2rem",
    justifyContent: "right",
  });

  const contractListStyle = css({
    background: "white",
    paddingBottom: "2rem"
  });

  const freeWordWrapperStyle = css({
    display: "flex",
    alignItems: "center"
  });

  // </CSS>

  const [organizationNameFilter, setOrganizationNameFilter] = useState('')

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback((e) => {
    if (e.key !== 'Enter') return;
    setFreeWordSession(freewordTextRef?.current?.value);
    setFreeWordState(freewordTextRef?.current?.value);
    setCurrentPage(1);
  }, [])

  if (httpStatus?.status === HttpStatus.UNAUTHORIZED) {
    return <Navigate to="/login" />
  }

   if (httpStatus?.status !== HttpStatus.OK || contractListState === undefined) {
     return <Loading />
   }

  const open = ((contract:ContractDetail) => {
    setModelOpen(true)
    setTargetContract(contract)
  });

  return (
    <section css={sectionStyle}>
      <AdminCheck />
      <h2 css={headStyle}>組織未設定契約一覧</h2>
      <article css={filtersStyle}>
      <div css={menuContainerStyle}>
          <div css={[titleStyle, { alignItems: 'flex-start' }]}>契約形態</div>
          <StyledContractKindBox>
            <FormControlLabel
              label={<StyledCheckBoxLabel>Park Direct 契約</StyledCheckBoxLabel>}
              control={
                <StyledCheckBox
                  checked={contractKindPD}
                  onChange={(e) => { setContractKindPDSession(e.target.checked) ;setContractKindPD(e.target.checked); setCurrentPage(1); }}
                />
              }
            />
            <FormControlLabel
              label={<StyledCheckBoxLabel>Park Direct 代理契約</StyledCheckBoxLabel>}
              control={
                <StyledCheckBox
                checked={contractKindProxy}
                  onChange={(e) =>  { setContractKindProxySession(e.target.checked) ;setContractKindProxy(e.target.checked); setCurrentPage(1); }}
              />
              }
            />
            <StyledContractDirectBox>
              <FormControlLabel
                label={<StyledCheckBoxLabel>直接契約</StyledCheckBoxLabel>}
                control={
                  <StyledCheckBox
                    checked={contractKindDirect && contractKindDirectPd}
                    indeterminate={contractKindDirect !== contractKindDirectPd}
                    onChange={() => {
                      if (!contractKindDirect || !contractKindDirectPd) {
                        setContractKindDirectSession(true)
                        setContractKindDirectPdSession(true)
                        setContractKindDirect(true);
                        setContractKindDirectPd(true);
                      } else {
                        setContractKindDirectSession(false)
                        setContractKindDirectPdSession(false)
                        setContractKindDirect(false);
                        setContractKindDirectPd(false);
                      }
                      setCurrentPage(1);
                    }}
                  />
                }
              />
              <Box>
                <FormControlLabel
                  label={<StyledCheckBoxLabel>直接契約/直接支払い</StyledCheckBoxLabel>}
                  control={<StyledCheckBox
                    checked={contractKindDirect}
                    onChange={(e) => { setContractKindDirectSession(e.target.checked);setContractKindDirect(e.target.checked); setCurrentPage(1); }}
                  />}
                />
                <FormControlLabel
                  label={<StyledCheckBoxLabel>直接契約/PD支払い</StyledCheckBoxLabel>}
                  control={<StyledCheckBox
                    checked={contractKindDirectPd}
                    onChange={(e) => { setContractKindDirectPdSession(e.target.checked);setContractKindDirectPd(e.target.checked); setCurrentPage(1); }}
                  />}
                />
              </Box>
            </StyledContractDirectBox>
          </StyledContractKindBox>
        </div>
        <div css={menuContainerStyle}>
          <div css={titleStyle}>契約ステータス</div>
          <div css={inputWrapperStyle}>
            <Select
              css={selectContractStatusStyle}
              value={managementState}
              onChange={(e) => { setManagementSession(e.target.value as string);setManagementState(parseInt(e.target.value as string)); setCurrentPage(1); }}
            >
              <MenuItem value={0}>全ての契約を表示</MenuItem>
              <MenuItem value={1}>契約中のみ表示</MenuItem>
              <MenuItem value={4}>申し込みのみ表示</MenuItem>
              <MenuItem value={2}>解約済みのみ表示</MenuItem>
              <MenuItem value={3}>解約済み以外を表示</MenuItem>
            </Select>
          </div>
        </div>
        <div css={menuContainerStyle}>
          <div css={titleStyle}>契約日</div>
          <div css={datePickerWrapperStyle}>
            <div>
              <DatePicker
                css={datePickerStyle}
                locale='ja'
                selected={contractDateStart}
                onChange={(e) => { setStartDateSession(e as Date); setContractDateStart(e as Date); setCurrentPage(1); }}
                placeholderText="2022/01/01"
                dateFormat="yyyy/MM"
                showMonthDropdown
                showYearDropdown
                showMonthYearPicker
                todayButton="今日"
                isClearable
                dropdownMode="select"
              />
            </div>
            <span>　〜　</span>
            <div>
              <DatePicker
                css={datePickerStyle}
                locale='ja'
                selected={contractDateEnd}
                onChange={(e) => { setEndDatetSession(e as Date);setContractDateEnd(e as Date); setCurrentPage(1); }}
                placeholderText="2022/01/01"
                dateFormat="yyyy/MM"
                showMonthDropdown
                showYearDropdown
                showMonthYearPicker
                isClearable
                todayButton="今日"
                dropdownMode="select" />
            </div>
          </div>
          <div css={{ display: "flex", columnGap: "1rem" }}>
            <div css={titleStyle}>初回契約開始日</div>
            <div css={datePickerWrapperStyle}>
              <div>
                <DatePicker
                  css={datePickerStyle}
                  locale='ja'
                  selected={contractFirstStartDateStart}
                  onChange={(e) => { setContractFirstStartDateStartSession(e as Date);setContractFirstStartDateStart(e as Date); setCurrentPage(1); }}
                  placeholderText="2022/01/01"
                  dateFormat="yyyy/MM"
                  showMonthDropdown
                  showYearDropdown
                  showMonthYearPicker
                  isClearable
                  todayButton="今日"
                  dropdownMode="select" />
              </div>
              <span>　〜　</span>
              <div>
                <DatePicker
                  css={datePickerStyle}
                  locale='ja'
                  selected={contractFirstStartDateEnd}
                  onChange={(e) => { setContractFirstStartDateEndSession(e as Date);setContractFirstStartDateEnd(e as Date); setCurrentPage(1); }}
                  placeholderText="2022/01/01"
                  dateFormat="yyyy/MM"
                  showMonthDropdown
                  showYearDropdown
                  showMonthYearPicker
                  isClearable
                  todayButton="今日"
                  dropdownMode="select" />
              </div>
            </div>
          </div>
        </div>
        <div css={menuContainerStyle}>
          <div css={titleStyle}>解約日</div>
          <div css={datePickerWrapperStyle}>
            <div>
              <DatePicker
                css={datePickerStyle}
                locale='ja'
                selected={cancelledDateStart}
                onChange={(e) => { setCancelledDateStartSession(e as Date); setCancelledDateStart(e as Date); setCurrentPage(1); }}
                placeholderText="2022/01/01"
                dateFormat="yyyy/MM"
                showMonthDropdown
                showYearDropdown
                showMonthYearPicker
                isClearable
                todayButton="今日"
                dropdownMode="select" />
            </div>
            <span>　〜　</span>
            <div>
              <DatePicker
                css={datePickerStyle}
                locale='ja'
                selected={cancelledDateEnd}
                onChange={(e) => { setCancelledDateEndSession(e as Date); setCancelledDateEnd(e as Date); setCurrentPage(1); }}
                placeholderText="2022/01/01"
                dateFormat="yyyy/MM"
                showMonthDropdown
                showYearDropdown
                showMonthYearPicker
                isClearable
                todayButton="今日"
                dropdownMode="select" />
            </div>
          </div>
        </div>
        <div css={menuContainerStyle}>
          <div css={titleStyle}>フリーワード</div>
          <div css={inputWrapperStyle}>
            <div>
              <div css={searchInputWrapperStyle}>
                <FontAwesomeIcon css={{ marginRight: "0.5rem" }} color="#DCDCDC" icon={faMagnifyingGlass} />
                <input
                  placeholder="契約ID、法人名、店舗/部署名"
                  defaultValue={freeWordState}
                  css={searchInputStyle}
                  type="text"
                  ref={freewordTextRef}
                  onKeyDown={onKeyDown}
                />
              </div>
            </div>
          </div>
        </div>
        <div css={freeWordWrapperStyle}>
          <button onClick={() => { reset(); setCurrentPage(1); }} css={clearButtonStyle}><FontAwesomeIcon icon={faArrowRotateLeft} />
            条件クリア
          </button>
        </div>
      </article>
      <div css={contractListStyle}>
        <div css={csvDownloadContainer}>
          <button css={buttonStyle} onClick={() => downloadCSV()} disabled={isCSVDownloadProcessing}>
            契約リスト（CSV）出力
          </button>
        </div>
        <div css={{ display: "flex" }}>
          <ContractList contractList={contractListState} amIAdmin={amIAdmin} organizationNameFilter={organizationNameFilter} openModal={open} showOrganizationSelectBox />
          <ContractDetailLinks contractIdList={contractListState.map(x => x.id)} />
        </div>
        <div>
          <Paginator
            paginationWrapperStyle={paginationWrapperStyle}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            contracts={contracts}
          />
        </div>
      </div>
      <ContractOrganizationModal
        open={isModal}
        contract={targetContract}
        onClose={close} />
    </section>
  )
}

const StyledContractKindBox = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
});

const StyledContractDirectBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
});


const StyledCheckBoxLabel = styled(Typography)({
  fontSize: "13px",
  color: "#222222"
});

const StyledCheckBox = styled(Checkbox)({
  width: "18px",
  height: "18px",
  marginLeft: "12px",
  marginRight: "6px",
});

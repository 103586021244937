import moment from "moment";
import { useState } from "react";


const paymentKindQueryBuild = (
  paymentKindPD: boolean,
  paymentKindTransfer: boolean,
  paymentKindAccountTransfer: boolean,
  paymentKindCC: boolean,
  paymentKindDirectPd: boolean,
) => {
  const paymentKindQ = "contract__contract_kind__in=";
  const paymentMethodQ = "contract__contract_condition__payment_method__in=";

  const paymentKinds = [];
  const paymentMethods = [];

  // 全てのフィルタがfalseならクエリは追加しない
  if (!paymentKindPD && !paymentKindTransfer && !paymentKindAccountTransfer && !paymentKindCC && !paymentKindDirectPd) {
    return undefined;
  }

  // ParkDirect契約／ParkDirect代理契約
  if (paymentKindPD) {
    paymentKinds.push(0) // ParkDirect契約
    paymentKinds.push(1) // ParkDirect代理契約
  }

  if (paymentKindTransfer || paymentKindAccountTransfer || paymentKindCC || paymentKindDirectPd) {
    paymentKinds.push(2) // 直接契約・直接支払い
    paymentKinds.push(3) // 直接契約・PD支払い
  }

  if (paymentKindPD) {
    paymentMethods.push(0);
  }
  if (paymentKindTransfer) {
    paymentMethods.push(1);
  }
  if (paymentKindAccountTransfer) {
    paymentMethods.push(2);
  }
  if (paymentKindCC) {
    paymentMethods.push(3);
  }
  if (paymentKindDirectPd) {
    paymentMethods.push(0);
  }

  return `${paymentKindQ}${paymentKinds.join(",")}&${paymentMethodQ}${paymentMethods.join(",")}`;
};

const dateFilterGenerator = (
  base: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  const start =
    startDate !== undefined && startDate !== null
      ? moment(startDate).format(`YYYY-MM-01`)
      : undefined;
  const end =
    endDate !== undefined && endDate !== null
      ? moment(endDate).format(
        `YYYY-MM-${moment(endDate).endOf("month").format("DD")}`
      )
      : undefined;

  // どちらも指定されていなければクエリ自体を行わない
  if (start === undefined && end === undefined) {
    return undefined;
  }

  // どちらも指定されている場合はrange
  if (start !== undefined && end !== undefined) {
    return `${base}__range=${start},${end}`;
  }

  // スタートだけならgraterThanEq
  if (start !== undefined) {
    return `${base}__gte=${start}`;
  }

  // 終わりだけならlessThenEq
  if (end !== undefined) {
    return `${base}__lte=${end}`;
  }

  return undefined;
};


const freeWordSearch = (text: string | undefined) => {
  if (text === undefined || text === "") {
    return undefined;
  }

  return `search=${text}`;

}

export const usePaymentQuery = () => {

  const paymentKindPDInitVal = true;
  const paymentKindTransferInitVal = true;
  const paymentKindAccountTransferInitVal = true;
  const paymentKindCCInitVal = true;
  const paymentKindDirectPdInitVal = true;
  const paymentMonthStartInitVal = moment(new Date()).startOf("month").toDate();
  const paymentMonthEndInitVal = moment(new Date()).endOf("month").toDate();
  const freeWordStateInitVal = undefined;


  let tempPaymentKindPD = null
  if(sessionStorage.getItem('paymentKindPD') == "true") {
    tempPaymentKindPD = true
  }else if(sessionStorage.getItem('paymentKindPD') == "false"){
    tempPaymentKindPD = false
  }
  let tempPaymentKindTransfer = null
  if(sessionStorage.getItem('paymentKindTransfer') == "true") {
    tempPaymentKindTransfer = true
  }else if(sessionStorage.getItem('paymentKindTransfer') == "false"){
    tempPaymentKindTransfer = false
  }
  let tempPaymentKindAccountTransfer = null
  if(sessionStorage.getItem('paymentKindAccountTransfer') == "true") {
    tempPaymentKindAccountTransfer = true
  }else if(sessionStorage.getItem('paymentKindAccountTransfer') == "false"){
    tempPaymentKindAccountTransfer = false
  }
  let tempPaymentKindCC = null
  if(sessionStorage.getItem('paymentKindCC') == "true") {
    tempPaymentKindCC = true
  }else if(sessionStorage.getItem('paymentKindCC') == "false"){
    tempPaymentKindCC = false
  }
  let tempPaymentKindDirectPd = null
  if(sessionStorage.getItem('paymentKindDirectPd') == "true") {
    tempPaymentKindDirectPd = true
  }else if(sessionStorage.getItem('paymentKindDirectPd') == "false"){
    tempPaymentKindDirectPd = false
  }

  const tempPaymentMonthStart = sessionStorage.getItem('paymentMonthStart') == "" ? null : sessionStorage.getItem('paymentMonthStart');
  const tempPaymentMonthEnd = sessionStorage.getItem('paymentMonthEnd') == "" ? null : sessionStorage.getItem('paymentMonthEnd');
  const tempFreeWord = sessionStorage.getItem('freeWord');

  // 決済方法フィルタState
  const [paymentKindPD, setPaymentKindPD] = useState(tempPaymentKindPD == null ? paymentKindPDInitVal:tempPaymentKindPD ); // パークダイレクト
  const [paymentKindTransfer, setPaymentKindTransfer] = useState(tempPaymentKindTransfer == null ? paymentKindTransferInitVal:tempPaymentKindTransfer ); // 振込
  const [paymentKindAccountTransfer, setPaymentKindAccountTransfer] = useState(tempPaymentKindAccountTransfer == null ? paymentKindAccountTransferInitVal: tempPaymentKindAccountTransfer); // 口座振替
  const [paymentKindCC, setPaymentKindCC] = useState(tempPaymentKindCC == null ? paymentKindCCInitVal: tempPaymentKindCC);  // クレジットカード
  const [paymentKindDirectPd, setPaymentKindDirectPd] = useState(tempPaymentKindDirectPd == null ? paymentKindDirectPdInitVal: tempPaymentKindDirectPd);  // 直接PD払い

  // 決済月フィルタState
  const [paymentMonthStart, setPaymentMonthStart] = useState<Date | null>(tempPaymentMonthStart == null ? paymentMonthStartInitVal: new Date(tempPaymentMonthStart));
  const [paymentMonthEnd, setPaymentMonthEnd] = useState<Date | null>(tempPaymentMonthEnd == null ? paymentMonthEndInitVal: new Date(tempPaymentMonthEnd));

  // フリーワード検索State
  const [freeWordState, setFreeWordState] = useState<string | undefined>(tempFreeWord == null ? undefined: tempFreeWord);

  const contractKindQ = paymentKindQueryBuild(
    paymentKindPD,
    paymentKindTransfer,
    paymentKindAccountTransfer,
    paymentKindCC,
    paymentKindDirectPd,
  );

  const monthOfPayQ = dateFilterGenerator(
    "month_of_pay",
    paymentMonthStart,
    paymentMonthEnd
  );

  const freeWordQ = freeWordSearch(freeWordState);

  const queries = [
    contractKindQ,
    monthOfPayQ,
    freeWordQ
  ];

  const reset = () => {
    sessionStorage.removeItem('paymentKindPD');
    sessionStorage.removeItem('paymentKindTransfer');
    sessionStorage.removeItem('paymentKindAccountTransfer');
    sessionStorage.removeItem('paymentKindCC');
    sessionStorage.removeItem('paymentKindDirectPd');
    sessionStorage.removeItem('paymentMonthStart');
    sessionStorage.removeItem('paymentMonthEnd');
    sessionStorage.removeItem('freeWord');

    setPaymentKindPD(paymentKindPDInitVal);
    setPaymentKindTransfer(paymentKindTransferInitVal);
    setPaymentKindAccountTransfer(paymentKindAccountTransferInitVal);
    setPaymentKindCC(paymentKindCCInitVal);
    setPaymentKindDirectPd(paymentKindDirectPdInitVal)
    setPaymentMonthStart(paymentMonthStartInitVal);
    setPaymentMonthEnd(paymentMonthEndInitVal);
    setFreeWordState(freeWordStateInitVal);
  }

  const isChanged = () => {
    return !(paymentKindPDInitVal === paymentKindPD &&
      paymentKindTransferInitVal === paymentKindTransfer &&
      paymentKindAccountTransferInitVal === paymentKindAccountTransfer &&
      paymentKindCCInitVal === paymentKindCC &&
      paymentKindDirectPdInitVal === paymentKindDirectPd &&
      moment(paymentMonthStartInitVal).format("YYYY-MM") === moment(paymentMonthStart).format("YYYY-MM") &&
      moment(paymentMonthEndInitVal).format("YYYY-MM") === moment(paymentMonthEnd).format("YYYY-MM") &&
      freeWordStateInitVal === freeWordState)
  }

  if (queries.every((x) => x === undefined)) {
    return {
      q: "",
      setPaymentKindPD,
      setPaymentKindCC,
      setPaymentKindDirectPd,
      setPaymentKindAccountTransfer,
      setPaymentKindTransfer,
      setPaymentMonthStart,
      setPaymentMonthEnd,
      setFreeWordState,
      paymentKindPD,
      paymentKindTransfer,
      paymentKindCC,
      paymentKindDirectPd,
      paymentKindAccountTransfer,
      paymentMonthStart,
      paymentMonthEnd,
      freeWordState,
      reset,
      isChanged
    };
  }

  return {
    q: `&${queries.filter((x) => x !== undefined).join("&")}`,
    setPaymentKindPD,
    setPaymentKindCC,
    setPaymentKindDirectPd,
    setPaymentKindAccountTransfer,
    setPaymentMonthStart,
    setPaymentMonthEnd,
    setPaymentKindTransfer,
    setFreeWordState,
    paymentKindPD,
    paymentKindCC,
    paymentKindDirectPd,
    paymentKindAccountTransfer,
    paymentKindTransfer,
    paymentMonthStart,
    paymentMonthEnd,
    freeWordState,
    reset,
    isChanged
  };
};

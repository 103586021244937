import { css } from "@emotion/react"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { Loading } from "../parts/Loading";
import { api } from "../../infra/Api";
import { User, UserFromApi } from "../../models/User";
import { HttpStatus } from "../../models/HttpStatus";
import { Button, Card, Box, Stack, TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import { loggedInUserAtom } from "../../atom/loggedInUserAtom";
import styled from "@emotion/styled";
import { AdminCheck } from "../parts/AdminCheck";  


const sectionStyle = css({
  width: "100%",
  padding: "2rem",
});

const titleStyle = css({
  fontFamily: '"Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif',
  fontWeight: "normal",
  fontSize: "1.4rem"
});

const StyledContentBody = styled.div`
  margin-top: 2rem;
`;

const StyledCard = styled(Card)`
  padding: 2rem;
  width: 50%;
  background-color: white;
`;

const StyledFotter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2rem 0rem;
`;

const StyledRowStack = styled(Stack)`
  height: 3em;
`;
StyledRowStack.defaultProps = { spacing: 4, alignItems: 'center', direction: 'row' };

const StyledTitleBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 10em;
`;

const StyledValueBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledErrosBox = styled(Box)`
  display: flex;
  margin-left: 12em;
  flex-direction: column;
  color: red;
`;

const StyledTextField = styled(TextField)`
`;
StyledTextField.defaultProps = {
  variant: 'standard',
};

type Errors = {
  email?: string[];
  password?: string[];
}

export const AccountComp = () => {
  const { id } = useParams();

  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserAtom);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editUser, setEditUser] = useState<User | undefined>();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errors, setErrors] = useState<Errors>();

  const onChangeEmail = useCallback(e => setEditUser(prev => (prev ? { ...prev, email: e.target.value } : undefined)), []);

  const onChangePassword = useCallback(e => setPassword(e.target.value), []);

  const onChangePasswordConfirm = useCallback(e => setPasswordConfirm(e.target.value), []);

  const onPressCancel = useCallback(() => {
    setEditUser(loggedInUser);
    setErrors(undefined);
    setIsEditMode(false);
    setPassword('');
    setPasswordConfirm('');
  }, []);

  const submit = useCallback(async () => {
    const url = loggedInUser?.isAdmin ? `/api/v1/admin/user/${id}/` : '/api/v1/user/';

    if ((password || passwordConfirm) && password !== passwordConfirm) {
      setErrors({
        password: ['パスワードとパスワード(再入力)が異なっています。']
      })
      return;
    }

    const [httpStatus, updatedUserFromApi] = await api.patch<{ email: string; password?: string }, UserFromApi | Errors>(url, {
      email: editUser?.email || '',
      ...(password ? { password } : {}),
    });

    if (httpStatus.status === HttpStatus.OK) {
      const updatedUser = User.fromApi(updatedUserFromApi as UserFromApi);
      if (loggedInUser?.id === updatedUser.id) {
        setLoggedInUser(updatedUser);
      }
      setIsEditMode(false);
      setPassword('');
      setPasswordConfirm('');
      setErrors(undefined);
    } else if (httpStatus.status === HttpStatus.BAD_REQUEST) {
      setErrors(updatedUserFromApi as Errors);
    }
  }, [loggedInUser, editUser, password, passwordConfirm]);

  const fetchUser = useCallback(async () => {
    const [fetchStatus, editUserFromApi] =  await api.get<UserFromApi>(`/api/v1/admin/user/${id}/`);
    if (fetchStatus.status === HttpStatus.OK && editUserFromApi) {
      setEditUser(User.fromApi(editUserFromApi));
    }
  }, []);

  useEffect(() => {
    if (loggedInUser?.isAdmin) {
      fetchUser();
    } else {
      setEditUser(loggedInUser);
    }
  }, [loggedInUser, fetchUser]);

  if (!editUser) {
    return <Loading />
  }

  return (
      <section css={sectionStyle}>
        {id && <AdminCheck />}
        <h2 css={titleStyle}>アカウント設定</h2>   
        <StyledContentBody>
          <StyledCard variant="outlined">
            {isEditMode ? (
                <Stack>
                  <StyledRowStack>
                    <StyledTitleBox>メールアドレス</StyledTitleBox>
                    <StyledValueBox>
                      <StyledTextField value={editUser.email} onChange={onChangeEmail} type="email" error={!!errors?.email}/>
                    </StyledValueBox>
                  </StyledRowStack>
                  {errors?.email &&
                    <StyledRowStack>
                      <StyledErrosBox>
                        {errors.email.map((error, index) => (<span key={`error-email-${index}`}>{error}</span>))}
                      </StyledErrosBox>
                    </StyledRowStack>
                  }
                  <StyledRowStack>
                    <StyledTitleBox>パスワード</StyledTitleBox>
                    <StyledValueBox>
                      <StyledTextField value={password} onChange={onChangePassword} type="password" placeholder="変更する場合は入力してください。" autoComplete="off" error={!!errors?.password} />
                    </StyledValueBox>
                  </StyledRowStack>
                  {errors?.password &&
                    <StyledRowStack>
                      <StyledErrosBox>
                      {errors.password.map((error, index) => (<span key={`error-password-${index}`}>{error}</span>))}
                      </StyledErrosBox>
                    </StyledRowStack>
                  }
                  <StyledRowStack>
                    <StyledTitleBox>パスワード(再入力)</StyledTitleBox>
                    <StyledValueBox>
                      <StyledTextField value={passwordConfirm} onChange={onChangePasswordConfirm} type="password" autoComplete="off" error={!!errors?.password}/>
                    </StyledValueBox>
                  </StyledRowStack>
                </Stack>
              ): (
                <Stack>
                  <StyledRowStack>
                    <StyledTitleBox>メールアドレス</StyledTitleBox>
                    <StyledValueBox>{editUser.email}</StyledValueBox>
                  </StyledRowStack>
                  <StyledRowStack>
                    <StyledTitleBox>パスワード</StyledTitleBox>
                    <StyledValueBox>非表示</StyledValueBox>
                  </StyledRowStack>
                </Stack>
              )
            }
          </StyledCard>
          <StyledFotter>
            {isEditMode ? (
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" onClick={submit}>保存</Button>
                  <Button onClick={onPressCancel}>キャンセル</Button>
                </Stack>
              ): (
                <Stack direction="row" spacing={2}>
                  {loggedInUser?.isAdmin && <Button onClick={() => window.history.back()}>戻る</Button>}
                  <Button variant="contained" onClick={() => setIsEditMode(true)}>編集</Button>
                </Stack>
              )
            }
          </StyledFotter>
        </StyledContentBody>
      </section>
  )
}
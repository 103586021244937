import React, { useEffect, useState, useCallback } from "react";
import { api } from "../infra/Api";
import { HttpStatus, HttpStatusObject } from "../models/HttpStatus";

export const useApiGet = <T>(
  path: string | undefined,
): [
  HttpStatusObject,
  T | undefined,
  React.Dispatch<React.SetStateAction<string | undefined>>,
  () => void, // reload function
] => {
  const [pathState, setPathState] = useState(path);
  const [resState, setResState] = useState<T | undefined>(undefined);
  const [httpStatusState, setHttpStatusState] = useState<HttpStatusObject>({});

  const apiGet = useCallback(async () => {
    if (pathState === undefined) {
      setResState(undefined);
      setHttpStatusState({});
      return;
    }

    setHttpStatusState({ status: HttpStatus.UN_FETCHED });
    const [httpStatus, res] = await api.get<T>(pathState);

    if (httpStatus.status === HttpStatus.OK) {
      setResState(res);
      setHttpStatusState({ status: HttpStatus.OK });
      return;
    }

    if (httpStatusState.status) {
      setHttpStatusState(api.errorHttpStatusObject(httpStatusState.status, httpStatusState.data ));
    } else {
      setHttpStatusState({});
    }
  }, [pathState]);

  const startGet = useCallback(() => {
    setResState(undefined);
    setHttpStatusState({});
    apiGet();
  }, [apiGet]);

  useEffect(startGet, [pathState, apiGet]);

  return [httpStatusState, resState, setPathState, startGet];
};
import React, { useCallback, useContext, useMemo, useState, VFC } from "react"
import { css } from "@emotion/react";
import { api } from "../../../infra/Api";
import { HttpStatus } from "../../../models/HttpStatus";
import { useNavigate } from "react-router";
import { contractKindList, initialProcedureType, lessortType, managementState, partition } from "../../../constant"
import { ContractDetail as ContractDetailProps, convertToContractDetail } from "../../../models/contract/ContractDetail";
import { dateFormat, withUnitOrNone } from "../../../util/stringFormatter";
import { extension } from "mime-types";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import { ContractDetailEditFormType } from "./ContractDetailEditFormType";
import { ContractDetailEditModal } from "./ContractDetailEditModal";
import { PDContractDetailEditModal } from "./PDContractDetailEditModal";
import { Button } from "@mui/material";
import Lightbox from 'react-image-lightbox';
import { useUser } from "../../..//hooks/useUser";
import { ContractDetailContext } from "../../usecase/ContractDetailComp";

export const articleStyle = css({
  display: "flex",
  flexWrap: "wrap",
  marginLeft: "1.5rem",
});

export const sectionStyle = css({
  background: "white",
  padding: "2rem",
});

export const headLineWrapperStyle = css({
  display: "flex",
  background: "#F6F6F6",
  padding: "13px 16px",
  marginBottom: "1.5rem"
});

export const headLineStyle = css({

  fontSize: "14px",
  fontWeight: "bold",
  margin: "0px",
  marginLeft: "12px",
  padding: "0px"
});

export const labelFullValueStyle = css({
  width: "100%",
  marginBottom: "2rem",
  fontSize: "14px"
});

export const labelValueStyle = css({
  width: "45%",
  marginBottom: "2rem",
  fontSize: "14px"
});

export const partitionThumbnailStyle = css({
  width: "33%",
  marginBottom: "2rem",
});

export const addressStyle = css({
  width: "100%",
  fontSize: "14px",
});

export const mapStyle = css({
  width: "100%",
  marginBottom: "2rem",
  fontSize: "14px"
});

export const displayMapButton = css({
  fontSize: "10px"
});

export const zoomButton = css({
  fontSize: "12px",
  width: "180px",
});

const mapContainerStyle = {
  marginTop: "1rem",
  width: "100%",
  height: "600px",
};

export const labelStyle = css({
  fontSize: "12px",
  color: "gray",
  fontWeight: "bold",
});

export const articleNameStyle = css({
  display: "block",
  width: "100%"
});

const renewStyle = css({
  marginBottom: "2rem",
  background: "white",
  border: "solid 2px red",
  cursor: "pointer",
  ":hover": {
    opacity: "0.7"
  }
});

export const linkStyle = css({
  display: "block",
  fontSize: "12px",
  marginLeft: "auto",
  color: "#0056B0",
  cursor: "pointer",
});

const downloadButtonStyle = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: "bold",
  width: "7rem",
  height: "32px",
  background: "#F3F8FF",
  borderRadius: "4px",
  textDecoration: "none",
  color: "#0056B0",
  border: "none",
  "&:Link": {
    textDecoration: "none",
    color: "#0056B0",
    background: "#F3F8FF"
  },
  "&:Visited": {
    textDecoration: "none",
    color: "#0056B0",
    background: "#F3F8FF"
  },
  "&:hover": {
    opacity: "0.7",
    cursor: "pointer"
  }
});

export const partitionThumbnailImageStyle = css({
  width: '180px',
  cursor: 'pointer',
});

export const partitionThumbnailDivStyle = css({
  display: 'flex',
  flexDirection: 'column',
});

const renewMessageStyle = css({
  marginTop: '2px',
  marginLeft: '10px',
});

const renewErrorStyle = css({
  marginTop: '2px',
  marginLeft: '10px',
  color: 'red',
});

const URL = process.env.REACT_APP_ADMIN_URL;


export const ContractDetailBasic: VFC<{ contractDetail: ContractDetailProps, am_i_admin: boolean | undefined }> = ({
  contractDetail, am_i_admin
}) => {
  const navigate = useNavigate();

  const { reloadContract } = useContext(ContractDetailContext);

  const [lightboxImageIndex, setLightboxImageIndex] = useState<number|null>(null);
  const [renewMessage, setRenewMessage] = useState<string>();
  const [renewError, setRenewError] = useState<string>();
  const [_, accountUser] = useUser();

  // 地図の表示/非表示の繰り返しで、google map apiに何度もアクセスが行かないようにする
  // null: 地図を一度も開いていない (google mapへ未アクセス)
  // true: 地図を表示中
  // false: 地図を非表示
  const [mapVisible, setMapVisible] = useState<boolean|null>(null);

  const isPd = contractDetail.contractKind === 0;

  const renew = async () => {
    const [res, data] = await api.post<{ id: string }, { message: string }>("/api/v1/admin/contract_renew/", { id: contractDetail.id });
    if (res.status === HttpStatus.OK || res.status === HttpStatus.CREATED) {
      setRenewMessage(data?.message);
    } else {
      setRenewError(data?.message);
    }
  }

  const carCertificateDownload = (contractDetail: ContractDetailProps) => {
    if (contractDetail.carInfo.certificate === null && contractDetail.carInfo.certificatePath === null) {
      return <div>登録なし</div>;
    }

    if (contractDetail.carInfo.certificate !== null) {
      return <div ><a css={downloadButtonStyle} target="_blank" rel="noreferrer" href={contractDetail.carInfo.certificate}>ダウンロード</a></div>
    }

    if (contractDetail.carInfo.certificatePath !== null) {
      return <div><button css={downloadButtonStyle} onClick={() => downloadFile("certificate", contractDetail.id)}>ダウンロード</button></div>
    }
  }

  const downloadFile = async (kind: "lease_agreement" | "certificate", id: string) => {

    const [, blob, contentType] = await api.getBlob(`/api/v1/file/${id}/${kind}`);

    if (blob === undefined) {
      return;
    }
    const pdf = new Blob([blob], { type: contentType })
    const link = document.createElement('a')
    const ext = extension(contentType ?? "pdf");
    link.href = (window.URL || window.webkitURL).createObjectURL(pdf)
    link.download = `${kind}.${ext}`
    link.click();
  }

  const showPdfDetail = (url:string | null) => {
    if(url == null){
      return;
    }
    console.log(url);
    window.open(url);
  };



  const [contractDetailEditFormType, setContractDetailEditFormType] = useState<ContractDetailEditFormType>();

  const onCloseModal = useCallback((isUpdated: boolean) => {
    if (isUpdated) reloadContract();
    setContractDetailEditFormType(undefined);
  }, [reloadContract, setContractDetailEditFormType]);

  const mapPosition = useMemo(() => (
    contractDetail?.clientCompany?.latitude && contractDetail?.clientCompany?.longitude ? {
      lat: contractDetail?.clientCompany?.latitude,
      lng: contractDetail?.clientCompany?.longitude,
    }: null), [contractDetail]);

  const partitionImages = useMemo(() => ([
    contractDetail.clientCompany.partitionImage1,
    contractDetail.clientCompany.partitionImage2,
    contractDetail.clientCompany.partitionImage3,
  ].filter(image => image) as string[]), [contractDetail.clientCompany]);

  const openMap = useCallback(() => {
    if (contractDetail?.clientCompany) {
      const { latitude, longitude } =  contractDetail?.clientCompany
      window.open(`https://www.google.co.jp/maps?q=${latitude},${longitude}`)
    }
  }, [contractDetail?.clientCompany]);

  const readOnlyStyle = accountUser!= null &&( accountUser?.isAdmin || !accountUser?.isReadOnly) ? "" : "none"
  const editableAdminOnlyStyle = accountUser != null && accountUser?.isAdmin ? "" : "none"
  const noneDisplayStyle = accountUser!= null &&( accountUser?.isAdmin) ? "" : "none";

  const showEditModal = useCallback((formType: ContractDetailEditFormType) => {
    setContractDetailEditFormType(isPd ? 'park_direct' : formType);
  }, [setContractDetailEditFormType]);

  return (

    <section css={sectionStyle}>
      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>基本情報</h3>
        <a css={linkStyle} style={{display:readOnlyStyle}} rel="noreferrer" onClick={() => showEditModal('basic_information')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>契約形態</div><div>{contractKindList[contractDetail.contractKind]}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約状態</div><div>{managementState[contractDetail.managementState]}</div></div>
        <div css={labelValueStyle} style={{display:noneDisplayStyle}}><div css={labelStyle}>通常契約</div><div>{contractDetail.normalContract ? "通常" : "イレギュラー"}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約日</div><div>{dateFormat(contractDetail.contract.contractedDate)}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>解約日</div><div>{dateFormat(contractDetail.contract.cancelDate)}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約期間</div><div>{withUnitOrNone(contractDetail.contract.contractPeriodMonth, "ヶ月")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>初回契約開始日</div><div>{dateFormat(contractDetail.contract.firstStartDate)}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>賃貸借期間_開始</div><div>{dateFormat(contractDetail.contract.startDate)}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>賃貸借期間_終了</div><div>{dateFormat(contractDetail.contract.endDate)}</div></div>
        {/* 保管場所使用承諾証明書: ひとまずコメントアウトにて対応 */}
        {/* <div css={labelValueStyle}><div css={labelStyle}>保管場所使用承諾証明書</div><div>{contractDetail.contract.needIssuePaper ? "必要" : "不要"}</div></div> */}
        <div css={labelValueStyle}><div css={labelStyle}>保管場所使用承諾証明書の発行月</div><div>{dateFormat(contractDetail.contract.issueDate, "YYYY年MM月")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>初回支払日</div><div>{dateFormat(contractDetail.contract.initialPaymentDate)}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>自動更新</div><div>{contractDetail.autoUpdate ? '自動更新' : '自動更新ではない'}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>初回契約手続き区分</div><div>{contractDetail.initialProcedureType !== null ? initialProcedureType[contractDetail.initialProcedureType] : ''}</div></div>
      </article>
      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>利用者情報</h3>
        <a css={linkStyle} style={{display:readOnlyStyle}} rel="noreferrer" onClick={() => showEditModal('user_information')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>利用者名</div><div>{contractDetail.user.name}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>利用者名(カナ)</div><div>{contractDetail.user.nameKana}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>利用-担当者名</div><div>{contractDetail.user.manager}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>利用者メールアドレス</div><div>{contractDetail.user.email}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>利用者携帯電話番号</div><div>{contractDetail.user.mobilePhoneNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>利用者自宅電話番号</div><div>{contractDetail.user.phoneNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>利用者従業員番号 </div><div>{contractDetail.user.employeeNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}></div><div></div></div>
        <div css={labelValueStyle}><div css={labelStyle}>利用者所属拠点名</div><div>{contractDetail.user.affiliationBranchName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>利用者所属拠点番号</div><div>{contractDetail.user.affiliationBranchNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>利用者所属組織名</div><div>{contractDetail.user.affiliationDepartmentName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>利用者所属組織番号</div><div>{contractDetail.user.affiliationDepartmentNumber}</div></div>


      </article>
      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>契約者情報</h3>
        <a css={linkStyle} style={{display:readOnlyStyle}} rel="noreferrer" onClick={() => showEditModal('contractor_information')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>契約者名</div><div>{contractDetail.contractor.name}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約者名(カナ)</div><div>{contractDetail.contractor.nameKana}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約-担当者名</div><div>{contractDetail.contractor.manager}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約者メールアドレス</div><div>{contractDetail.contractor.email}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約者電話番号</div><div>{contractDetail.contractor.phoneNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約者会社電話番号</div><div>{contractDetail.contractor.companyPhoneNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約者従業員番号 </div><div>{contractDetail.contractor.employeeNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}></div><div></div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約者所属拠点名</div><div>{contractDetail.contractor.affiliationBranchName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約者所属拠点番号</div><div>{contractDetail.contractor.affiliationBranchNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約者所属組織名</div><div>{contractDetail.contractor.affiliationDepartmentName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>契約者所属組織番号</div><div>{contractDetail.contractor.affiliationDepartmentNumber}</div></div>
      </article>
      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>駐車場情報</h3>
        <a css={linkStyle} style={{display:readOnlyStyle}} rel="noreferrer" onClick={() => showEditModal('parking_information')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>駐車場名</div><div>{contractDetail.clientCompany.parkingName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>区画番号</div><div>{contractDetail.clientCompany.partition}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>区画種別</div><div>{contractDetail.clientCompany?.partitionType !== null ? partition[+contractDetail.clientCompany?.partitionType] : ''}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}></div><div></div></div>
        <div css={addressStyle}><div css={labelStyle}>駐車場住所</div><div>{contractDetail.clientCompany.address}</div></div>
        <div css={mapStyle}>
          <Button onClick={() => openMap()} css={displayMapButton} disabled={!mapPosition}>地図を表示する</Button>
          {/* API_KEYの準備ができるまで外す */}
          {/* <Button onClick={() => setMapVisible(!mapVisible)} css={displayMapButton} disabled={!mapPosition}>{mapVisible ? '地図を非表示 ▲' : '地図を表示する ▼'}</Button> */}
          {/* {mapVisible !== null && mapPosition && (
            <div style={{ display: mapVisible ? 'block': 'none' }}>
              <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''}>
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={mapPosition}
                  zoom={17}>
                  <Marker position={mapPosition} />
                </GoogleMap>
              </LoadScript>
            </div>
          )} */}
        </div>
        <div css={partitionThumbnailStyle}>
          <div css={labelStyle}>区画図1</div>
          {contractDetail.clientCompany.partitionImage1 &&
            <div css={partitionThumbnailDivStyle}>
              <Button onClick={() => showPdfDetail(contractDetail.clientCompany.partitionImage1)} css={zoomButton}>プレビューを表示する <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></Button>
            </div>}
        </div>
        <div css={partitionThumbnailStyle}>
          <div css={labelStyle}>区画図2</div>
          {contractDetail.clientCompany.partitionImage2 &&
            <div css={partitionThumbnailDivStyle}>
              <Button onClick={() => showPdfDetail(contractDetail.clientCompany.partitionImage2)} css={zoomButton}>プレビューを表示する <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></Button>
            </div>}
        </div>
        <div css={partitionThumbnailStyle}>
          <div css={labelStyle}>区画図3</div>
          {contractDetail.clientCompany.partitionImage3 &&
            <div css={partitionThumbnailDivStyle}>
              <Button onClick={() => showPdfDetail(contractDetail.clientCompany.partitionImage3)} css={zoomButton}>プレビューを表示する <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></Button>
            </div>}
        </div>
        <div css={labelValueStyle}><div css={labelStyle}>貸与物</div><div>{contractDetail.rentalItem ? 'あり' : 'なし'}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>貸与物の内容</div><div>{contractDetail.rentalItemContents}</div></div>
      </article>
      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>管理会社情報</h3>
        <a css={linkStyle} style={{display:readOnlyStyle}} rel="noreferrer" onClick={() => showEditModal('management_company_information')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>管理会社名</div><div>{contractDetail.clientCompany.clientCompanyName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>店舗名</div><div>{contractDetail.clientCompany.clientStoreName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>管理会社郵便番号</div><div>{contractDetail.clientCompany.clientCompanyPostalCode}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>管理会社住所</div><div>{contractDetail.clientCompany.clientCompanyAddress}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>管理会社電話番号</div><div>{contractDetail.clientCompany.clientCompanyPhoneNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>管理会社FAX番号</div><div>{contractDetail.clientCompany.clientCompanyFaxNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>担当者名</div><div>{contractDetail.clientCompany.clientCompanyStaffName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>担当者メールアドレス</div><div>{contractDetail.clientCompany.clientCompanyStaffEmail}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>インボイス発行事業者の氏名または名称および登録番号</div><div>{contractDetail.clientCompany.clientCompanyInvoiceNameOrNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>適用税率</div><div>{withUnitOrNone(contractDetail.clientCompany.clientCompanyInvoiceTaxRate, "%")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>書類の交付を受ける事業者の氏名または名称</div><div>{contractDetail.clientCompany.clientCompanyInvoiceReceiverName}</div></div>
        <div css={labelValueStyle}></div>
        <div css={labelValueStyle}><div css={labelStyle}>保証会社名</div><div>{contractDetail.clientCompany.guarantorCompanyName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>保証会社インボイス登録番号</div><div>{contractDetail.clientCompany.guarantorInvoiceNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>備考</div><div>{contractDetail.clientCompany.clientCompanyNote}</div></div>

      </article>

      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>貸主情報</h3>
        <a css={linkStyle} style={{display:readOnlyStyle}} rel="noreferrer" onClick={() => showEditModal('lessor')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>貸主名</div><div>{contractDetail.lessor.lessorName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>貸主区分</div><div>{contractDetail.lessor.lessorType !== null ? lessortType[contractDetail.lessor.lessorType] : ''}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>貸主郵便番号</div><div>{contractDetail.lessor.lessorPostalCode}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>貸主住所</div><div>{contractDetail.lessor.lessorAddress}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>貸主電話番号</div><div>{contractDetail.lessor.lessorPhoneNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>貸主FAX番号</div><div>{contractDetail.lessor.lessorFaxNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>貸主メールアドレス</div><div>{contractDetail.lessor.lessorEmail}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}></div></div> {/* FIXME: 改行したいがスタイルを修正する手間を惜しんで空Divを追加 */}
        <div css={labelValueStyle}><div css={labelStyle}>インボイス発行事業者の氏名または名称および登録番号</div><div>{contractDetail.lessor.lessorInvoiceNameOrNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>適用税率</div><div>{withUnitOrNone(contractDetail.lessor.lessorInvoiceTaxRate, "%")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>書類の交付を受ける事業者の氏名または名称</div><div>{contractDetail.lessor.lessorInvoiceReceiverName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}></div></div> {/* FIXME: 改行したいがスタイルを修正する手間を惜しんで空Divを追加 */}
        <div css={labelValueStyle}><div css={labelStyle}>備考</div><div>{contractDetail.lessor.lessorNote}</div></div>
      </article>

      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>仲介会社情報</h3>
        <a css={linkStyle} style={{display:readOnlyStyle}} rel="noreferrer" onClick={() => showEditModal('broker_company')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>仲介会社名</div><div>{contractDetail.brokerCompany.brokerCompanyName}</div></div>
        <div css={labelValueStyle}></div>
        <div css={labelValueStyle}><div css={labelStyle}>仲介会社郵便番号</div><div>{contractDetail.brokerCompany.brokerCompanyPostalCode}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>仲介会社住所</div><div>{contractDetail.brokerCompany.brokerCompanyAddress}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>仲介会社電話番号</div><div>{contractDetail.brokerCompany.brokerCompanyPhoneNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>仲介会社FAX番号</div><div>{contractDetail.brokerCompany.brokerCompanyFaxNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>仲介会社メールアドレス</div><div>{contractDetail.brokerCompany.brokerCompanyEmail}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>インボイス発行事業者の氏名または名称および登録番号</div><div>{contractDetail.brokerCompany.brokerCompanyInvoiceNameOrNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>適用税率</div><div>{withUnitOrNone(contractDetail.brokerCompany.brokerCompanyInvoiceTaxRate, "%")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>書類の交付を受ける事業者の氏名または名称</div><div>{contractDetail.brokerCompany.brokerCompanyInvoiceReceiverName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>備考</div><div>{contractDetail.brokerCompany.brokerCompanyNote}</div></div>
      </article>

      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>契約車両情報</h3>
        <a css={linkStyle} style={{display:readOnlyStyle}} rel="noreferrer" onClick={() => showEditModal('vehicle_information')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>

        <div css={labelValueStyle}><div css={labelStyle}>車種</div><div>{contractDetail.carInfo.carModel}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>車種-登録番号（地域名）</div><div>{contractDetail.carInfo.regnoRegionName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>車種-登録番号（分類番号3桁）</div><div>{contractDetail.carInfo.regnoClassDigit}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>車種-登録番号（平仮名等）</div><div>{contractDetail.carInfo.regnoHiragana}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>車種-登録番号(一連指定番号4桁)</div><div>{contractDetail.carInfo.regnoSeqDigit}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>車検証</div>
          <div>{carCertificateDownload(contractDetail)}</div>
        </div>
      </article>
      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>お知らせ・注意喚起のメモ</h3>
        <a css={linkStyle} style={{ display: readOnlyStyle }} rel="noreferrer" onClick={() => showEditModal('notice_memo')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>メモ1</div><div>{contractDetail.contractAttribution.noticeMemo1}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>メモ2</div><div>{contractDetail.contractAttribution.noticeMemo2}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>メモ3</div><div>{contractDetail.contractAttribution.noticeMemo3}</div></div>
      </article>
      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>備考</h3>
        <a css={linkStyle} style={{display:readOnlyStyle}} rel="noreferrer" onClick={() => showEditModal('note')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>メモ1</div><div>{contractDetail.contractAttribution.memo1}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>メモ2</div><div>{contractDetail.contractAttribution.memo2}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>メモ3</div><div>{contractDetail.contractAttribution.memo3}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>使用用途</div><div>{contractDetail.contractAttribution.usage}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}></div><div></div></div>
      </article>



      <article css={articleStyle}>
        {
          am_i_admin &&
          contractDetail.contractKind !== 0 &&
          moment(contractDetail.contract.endDate).isBefore(moment(new Date()).add(1, "months")) &&
          <>
            <h2 css={articleNameStyle}>契約更新</h2>
            <article css={articleStyle}>
              <button onClick={() => renew()} css={renewStyle}>契約更新</button>
              {renewMessage && <div css={renewMessageStyle}>{renewMessage}</div>}
              {renewError && <div css={renewErrorStyle}>{renewError}</div>}
            </article>
          </>
        }
      </article>

      {isPd ? (
        <PDContractDetailEditModal
          isVisible={contractDetailEditFormType === 'park_direct'}
          isAdmin={am_i_admin}
          contractDetail={contractDetail}
          onClose={onCloseModal} />
      ) : (
        <ContractDetailEditModal
          formType={contractDetailEditFormType}
          isAdmin={am_i_admin}
          contractDetail={contractDetail}
          onClose={onCloseModal} />
      )}

      {lightboxImageIndex !== null && (
        <Lightbox
          mainSrc={partitionImages[lightboxImageIndex]}
          nextSrc={partitionImages[(lightboxImageIndex + 1) % partitionImages.length]}
          prevSrc={partitionImages[(lightboxImageIndex + partitionImages.length - 1) % partitionImages.length]}
          onCloseRequest={() => setLightboxImageIndex(null)}
          onMovePrevRequest={() => setLightboxImageIndex((lightboxImageIndex + partitionImages.length - 1) % partitionImages.length)}
          onMoveNextRequest={() => setLightboxImageIndex((lightboxImageIndex + partitionImages.length + 1) % partitionImages.length)}
        />
      )}
    </section>
  )

}

export const getFilenameAndDataUrl = (file?: File): Promise<[string, string] | void> => {
  return new Promise(resolve => {
    if (!file) {
      resolve();
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const result = event.target?.result;
      if (typeof result === 'string') {
        resolve([file.name, result]);
      } else {
        resolve();
      }
    }
  })
};
import { css } from "@emotion/react"
import React, { useCallback, useState } from "react"
import { useApiGet } from "../../hooks/useApiGet";
import { HttpStatus } from "../../models/HttpStatus";
import { Loading } from "../parts/Loading";
import { Navigate, useNavigate, useParams } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import { Table, TableHead, TableBody, TableRow, TableCell, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Paginator } from "../parts/Paginator/Paginator";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../../atom/loggedInUserAtom";
import styled from "@emotion/styled";
import { AdminCheck } from "../parts/AdminCheck";

const sectionStyle = css({
  width: "100%",
  paddingBottom: "10rem",
  minWidth: "800px",
});

const headStyle = css({
  marginLeft: "3rem",
  marginTop: "2rem",
  fontWeight: "normal"
});

const titleStyle = css({
  fontSize: "12px",
  fontWeight: "bold",
  width: "100px",
  display: "flex",
  alignItems: "center",
  flexWrap: "nowrap"
});

const inputWrapperStyle = css({
  display: "flex",
  justifyContent: "left",
  flexShrink: "initial",
  columnGap: "0.5rem",
  alignItems: "center",
  flexWrap: "nowrap"
});

const filtersStyle = css({
  margin: "1rem", marginBottom: "0", padding: "2rem 0"
})

const menuContainerStyle = css({
  display: "flex",
  justifyContent: "left",
  flexShrink: "initial",
  flexWrap: "wrap",
  marginLeft: "2rem",
  columnGap: "1rem",
  marginBottom: "0.5rem",
  rowGap: "1rem",
});

const searchInputWrapperStyle = css({
  display: "flex",
  background: "white",
  alignItems: "center",
  width: "396px",
  height: "40px",
  border: "solid 1px #DCDCDC",
  borderRadius: "4px",
  paddingLeft: "1rem",
  color: "#222222",
  "&:focus": {
    outline: "2px solid"
  }
});

const searchInputStyle = css({
  display: "block",
  width: "100%",
  height: "90%",
  border: "none",
  "&:focus-visible": {
    outline: "none"
  }
});

const paginationWrapperStyle = css({
  display: "flex",
  marginRight: "2rem",
  justifyContent: "right",
});

const contractListStyle = css({
  background: "white",
  paddingBottom: "2rem"
});

const freeWordWrapperStyle = css({
  display: "flex",
  alignItems: "center"
});

const StyledTable = styled(Table)`
  margin: 2em;
`;

const StyledResetButton = styled(Button)`
  margin-left: 2rem;
`;
StyledResetButton.defaultProps = { variant: "contained" };

export const UserListComp = () => {

  const { page } = useParams();
  const navigate = useNavigate();

  const initPage = page === undefined ? 1 : parseInt(page);

  const [currentPage, setCurrentPage] = useState(initPage);
  const [freeWord, setFreeWord] = useState('');

  const user = useRecoilValue(loggedInUserAtom);

  const [httpStatus, users, reFetch] =
    useApiGet<any>(`/api/v1/admin/user_list/?ordering=id&page=${currentPage}&search=${freeWord}`);

  const search = useCallback(() => {
    reFetch(`/api/v1/admin/user_list/?ordering=id&page=1&search=${freeWord}`);
    setCurrentPage(1)
    navigate('/user-list/1')
  }, [reFetch, navigate, freeWord]);

  const reset = useCallback(() => {
    setFreeWord('');
    setCurrentPage(1);
    reFetch(`/api/v1/admin/user_list?ordering=id&page=1`);
    navigate('/user-list/1')
  }, [reFetch, navigate]);

  if (httpStatus.status === HttpStatus.UNAUTHORIZED) {
    return <Navigate to="/login" />
  }


  const userList = users?.results;

  return (
    <section css={sectionStyle}>
       <AdminCheck />
      <h2 css={headStyle}>アカウント管理</h2>
      <article css={filtersStyle}>
        <div css={freeWordWrapperStyle}>
          <div css={menuContainerStyle}>
            <div css={titleStyle}>メールアドレス</div>
            <div css={inputWrapperStyle}>
              <div>
                <div css={searchInputWrapperStyle}>
                  <FontAwesomeIcon css={{ marginRight: "0.5rem" }} color="#DCDCDC" icon={faMagnifyingGlass} />
                  <input
                    placeholder="メールアドレス"
                    defaultValue={freeWord}
                    css={searchInputStyle}
                    type="text"
                    value={freeWord}
                    onChange={e => setFreeWord(e.target.value)}
                    onKeyDown={(e) => {e.key === "Enter" && search();}}
                  />
                </div>
              </div>
            </div>
          </div>
          <StyledResetButton onClick={reset} disabled={!freeWord}>
            <FontAwesomeIcon icon={faArrowRotateLeft} />
            条件クリア
          </StyledResetButton>
        </div>
      </article>
      <div css={contractListStyle}>
        <div css={{ display: "flex", justifyContent: 'center'}}>
          {users !== undefined ?
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell>組織</TableCell>
                  <TableCell>権限</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList.map((listUser: any, index: number) => (
                  <TableRow key={`listUser-${index}`}>
                    <TableCell><Link to={`/account/${listUser.id}`}>{listUser.email}</Link></TableCell>
                    <TableCell>{listUser.organization?.name}</TableCell>
                    <TableCell>{listUser.is_superuser ? '管理者' : listUser.is_staff ? 'スタッフ権限' : '一般ユーザー'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable> : <Loading />
          }
        </div>
        <div>
          <Paginator
            paginationWrapperStyle={paginationWrapperStyle}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            contracts={userList}
          />
        </div>
      </div>
    </section>
  )
}
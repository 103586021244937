import { css } from "@emotion/react"
import React, { VFC } from "react"
import { v4 } from "uuid";
import { Link } from "react-router-dom";

const linkStyle = css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "bold",
    width: "64px",
    height: "32px",
    background: "#F3F8FF",
    borderRadius: "4px",
    "&:Link": {
        textDecoration: "none",
        color: "#0056B0",
        background: "#F3F8FF"
    },
    "&:Visited": {
        textDecoration: "none",
        color: "#0056B0",
        background: "#F3F8FF"
    }
});

const tableStyle = css({
    borderCollapse: "collapse",
    background: "white",
    marginTop: "1rem",
    whiteSpace: "nowrap",
});
const columnStyle = css({
    fontSize: "12px",
    paddingLeft: "1rem",
    borderBottom: "solid 2px #DCDCDC",
    height: "60px",
    width: "70px",
});

const columnHeadStyle = css({
    fontSize: "12px",
    padding: "1rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    borderBottom: "solid 3px #ccc",
    fontWeight: "bold"
});

const tableWrapperStyle = css({
    background: "white",
    marginRight: "1rem",
    paddingRight: "1rem",
    marginTop: "0",
    paddingTop: "0",
});

export const ContractDetailLinks: VFC<{ contractIdList: string[]}> = ({
    contractIdList,
}) => {

    return (
        <div css={tableWrapperStyle}>
            <table css={tableStyle}>
                <thead>
                    <tr><th css={columnHeadStyle}>　</th></tr>
                </thead>
                <tbody>
                    {contractIdList.map(id => (<tr key={v4()}><td css={columnStyle}><Link css={linkStyle} to={`/contract-detail/${id}`}>詳細</Link></td></tr>))}
                </tbody>
            </table>
        </div>
    )
}
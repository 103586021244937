import { ImportContract } from "../parts/Import/ImportContracts";
import { ImportDirectContract } from "../parts/Import/ImportDirectContract";
import { ImportPDContract } from "../parts/Import/ImportPdContracts";


export const ImportContractComp = () => {
    return (
        <div>
            <ImportDirectContract />
            <ImportContract />
            <ImportPDContract />
        </div>
    );
};

import { useEffect, useState } from "react";
import { api } from "../infra/Api";
import { HttpStatus } from "../models/HttpStatus";
import { OrganizationFromApiResults } from "../models/organization/OrganizationFromApi";
import { Organization } from "../models/organization/Organization";
import moment from "moment";


type FetchState = "UnFetched" | "Error" | "OK";

export const useOrganizations = (): [FetchState, undefined | Organization[]] => {
  const [fetchState, setFetchState] = useState<FetchState>("UnFetched");
  const [organizationState, setOrganizationState] = useState<undefined | Organization[]>(undefined);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const organizations = await getOrganizations();

      if (organizations) {
        setFetchState("OK");
        setOrganizationState(organizations);
      } else {
        setFetchState("Error");
      }
    };

    fetchOrganizations();
  }, []);

  return [fetchState, organizationState];
};

export const getOrganizations = async () => {
  const [httpsState, organization] = await api.get<OrganizationFromApiResults>("/api/v1/admin/organization");

  if (httpsState.status === HttpStatus.OK && organization !== undefined) {
    return organization.results.map(({
      id,
      name,
      note,
      entry_pd_form_url: entryPdFormUrl,
      entry_pd_agency_form_url: entryPdAgencyFormUrl,
      entry_direct_form_url: entryDirectFormUrl,
      update_contract_form_url: updateContractFormUrl,
      cancel_pd_contract_form_url: cancelPdContractFormUrl,
      cancel_direct_contract_form_url: cancelDirectContractFormUrl,
      notifications_url: notificationsUrl,
      secure_path_id: securePathId,
      created_at: createdAt,
      updated_at: updatedAt
    }) => ({
      id,
      name,
      note,
      entryPdFormUrl,
      entryPdAgencyFormUrl,
      entryDirectFormUrl,
      updateContractFormUrl,
      cancelPdContractFormUrl,
      cancelDirectContractFormUrl,
      notificationsUrl,
      securePathId,
      createdAt: moment(createdAt).toDate(),
      updatedAt: moment(updatedAt).toDate(),
    }));
  }
};
import { api } from "../../../../infra/Api";
import { HttpStatus } from "../../../../models/HttpStatus";
import { getFilenameAndDataUrl } from "../../../../util";
import { ContractDetailFromApi } from "../../../../models/contract/ContractFromApi";


export const uploadContractFromImageFile = async (files: File[],increment:()=>void) => {
  const successFiles : string[]= [];
  const createErrorFiles: string[] = [];
  const fileNameSize = 19;
  const fileNameContractIdStart = 4;
  const fileNameContractIdEnd = 15;

  for (const file of files) {
    increment();
    if(file.name.length < fileNameSize){
       // ファイル名のフォーマットが正しくない
       createErrorFiles.push(`${file.name} - Incorrect format file name`);
       continue;
    }
    // ファイル名から契約IDを抜き出す
    const contractId = file.name.slice(fileNameContractIdStart, fileNameContractIdEnd)
    const filenameAndDataUrl = await getFilenameAndDataUrl(file);
    let postData;
    if(/^CON_/.test(file.name)){
      postData = {lease_agreement_file: filenameAndDataUrl}
    } else if(/^VEC_/.test(file.name)){
      postData = 
      {contract_vehicle: {
        certificate_file: filenameAndDataUrl
      }}
     
    } else {
      // プレフィックスの指定がありません
      createErrorFiles.push(`${file.name} - no prefix`);
      continue;
    }

    const [res, response] = await api.patch<any, ContractDetailFromApi>(`/api/v1/admin/contract/${contractId}/`,postData);
  
    if (res.status === HttpStatus.OK || res.status === HttpStatus.ACCEPTED || res.status === HttpStatus.CREATED) {
      successFiles.push(file.name);
      continue;
    }
    createErrorFiles.push(file.name);
  }
  return { successFiles, createErrorFiles };
};


import React, { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RhfInput } from "../../parts/form/input/RhfInput";
import { useForm } from "react-hook-form";
import { css } from "@emotion/react";
import { useNavigate, useParams } from "react-router";
import { api } from "../../../infra/Api";
import Logo from "../../../images/PDLogo.png"
import { HttpStatus } from "../../../models/HttpStatus";
import { useSetRecoilState } from "recoil";
import { loggedInUserAtom } from "../../../atom/loggedInUserAtom";
import { User, UserFromApi } from "../../../models/User";




interface Credentials {
  mail: string;
  password: string;
}

export const errStyle = css({
  fontSize: "0.5rem",
  color: "red",
  marginBottom: "1rem"
});

const versionStyle = css({
  position: 'absolute',
  bottom: '1em',
  textAlign: "center",
  fontSize: "12px",
  color: "#ffffff60",
  width: "100%",
});

const { version } = require('../../../version.json')

export const Login = () => {

  const navigate = useNavigate();
  const { organization_id } = useParams();
  const [errorState, setErrorState] = useState<string[]>([]);

  const setLoggedInUser = useSetRecoilState(loggedInUserAtom);

  const login = async (credentials: Credentials) => {
    console.log("cll");
    const [httpStatus] = await api.login(credentials.mail, credentials.password, organization_id);

    if (httpStatus.status === HttpStatus.OK) {
      const [fetchUserState, user] = await api.get<UserFromApi>("/api/v1/user/");
      if (fetchUserState.status === HttpStatus.OK && user !== undefined) {
        setLoggedInUser(User.fromApi(user));
        navigate("/");
        navigate(0);
        return;
      } 
    }

    if (httpStatus.status === HttpStatus.UNAUTHORIZED) {
      setErrorState(["メールアドレスまたはパスワードが正しくありません。"]);
      return;
    }

    setErrorState(["サービスに何らかの問題が発生しています。", "担当者までお問合せください"]);
    return;
  }

  const schema = yup
    .object()
    .shape({
      mail: yup.string().email("メールアドレスを入力してください").required("入力必須項目です"),
      password: yup.string().required("入力必須項目です")
    })
    .required();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
    register,
  } = useForm<Credentials>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  // css

  const baseStyle = css({
    width: "100%",
    height: "100%",
    background: "linear-gradient(to right, #015cb1, #0d7bb5)",
  });

  const titleStyle = css({
    color: "#0056B0",
    fontSize: "16px",
    marginBottom: "3rem",
    textAlign: "center",
  });


  const logoWrapperStyle = css({
    width: "100%"
  });

  const logoStyle = css({
    display: "block",
    position: "relative",
    width: "250px",
    margin: "auto",
    marginBottom: "2rem"
  });

  const inputStyle = (errors: string[]) => css({
    margin: "0.5rem auto",
    border: errors.length === 0 ? "1px #dedede solid" : "1px red solid",
    borderRadius: "6px",
    padding: "0.75rem",
    width: "100%",
    "&:focus-visible": {
      border: "2px black solid",
      outline: "none"
    },
  });

  const formWrapper = css({
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "636px",
    height: "100vh",
  });

  const formStyle = css({
    width: "100%",
    alignItems: "center",
    height: "25rem",
    display: "flex",
    justifyContent: "center",
    background: "white"
  });

  const loginButton = css({
    display: "block",
    margin: "0 auto",
    width: "40%",
    height: "45px",
    background: "#0056b0",
    color: "white",
    border: "none",
    borderRadius: "6px",
    marginTop: "1rem",
    fontWeight: "bold",
    transition: "all 0.3s",
    "&:hover": {
      opacity: "0.7",
      cursor: "pointer"
    }
  });

  const inputWrapper = css({
    width: "440px",
    margin: "0 auto"
  });

  return (<>
    <div css={baseStyle}>

      <div css={formWrapper}>
        <div css={logoWrapperStyle}>
          <img css={logoStyle} src={Logo} alt="logo" />
          <form onSubmit={handleSubmit(login)} css={formStyle}>
            <div css={inputWrapper}>
              <h1 css={titleStyle}>月極駐車場・利用者管理ログイン</h1>
              <div css={css({ fontSize: "12px", color: "red", textAlign: "center", marginBottom: "1rem" })}>{errorState.join("")}</div>
              <div css={css({ fontSize: "0.65rem" })}>メールアドレス</div>
              <RhfInput
                errCss={errStyle}
                placeholder="メールアドレス"
                name="mail"
                control={control}
                css={inputStyle(errorState)}
                type="text"
              />
              <div css={css({ fontSize: "0.65rem", marginTop: "1rem" })}>パスワード</div>
              <div>
                <RhfInput
                  errCss={errStyle}
                  placeholder="パスワード"
                  name="password"
                  control={control}
                  css={inputStyle(errorState)}
                  type="password"
                />
              </div>
              <button css={loginButton} type="submit">ログイン</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div css={versionStyle}>v{version}</div>
  </>);
}
import { faCommentDollar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useState } from "react";
import { constSelector } from "recoil";

const managementQueryBuild = (contractState: number) => {
  const managementStateIn = 'management_state__in';

  switch (contractState) {
    case 0: // 全ての契約を表示
      return undefined;
    case 1: // 契約中のみ表示
      return `${managementStateIn}=1`;
    case 2: // 解約済みのみ表示
      return `${managementStateIn}=2`;
    case 3: // 解約済み以外を表示
      return `${managementStateIn}=0,1,3,4`;
    case 4: // 申し込み中
      return `${managementStateIn}=0`;
    default:
      return undefined;
  }
};

const contractKindQueryBuild = (
  contractKindPD: boolean,
  contractKindProxy: boolean,
  contractKindDirect: boolean,
  contractKindDirectPd: boolean
) => {
  const contractQ = "contract_kind__in=";

  const kinds = [];

  // 全てのフィルタがfalseならクエリは追加しない
  if (!contractKindPD && !contractKindProxy && !contractKindDirect && !contractKindDirectPd) {
    return undefined;
  }

  if (contractKindPD) {
    kinds.push(0);
  }
  if (contractKindProxy) {
    kinds.push(1);
  }
  if (contractKindDirect) {
    kinds.push(2);
  }
  if (contractKindDirectPd) {
    kinds.push(3);
  }
  return `${contractQ}${kinds.join(",")}`;
};

const dateFilterGenerator = (
  base: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  const start =
    startDate !== undefined && startDate !== null
      ? moment(startDate).format(`YYYY-MM-01`)
      : undefined;
  const end =
    endDate !== undefined && endDate !== null
      ? moment(endDate).format(
        `YYYY-MM-${moment(endDate).endOf("month").format("DD")}`
      )
      : undefined;

  // どちらも指定されていなければクエリ自体を行わない
  if (start === undefined && end === undefined) {
    return undefined;
  }

  // どちらも指定されている場合はrange
  if (start !== undefined && end !== undefined) {
    return `${base}__range=${start},${end}`;
  }

  // スタートだけならgraterThanEq
  if (start !== undefined) {
    return `${base}__gte=${start}`;
  }

  // 終わりだけならlessThenEq
  if (end !== undefined) {
    return `${base}__lte=${end}`;
  }

  return undefined;
};

const freeWordSearch = (text: string | undefined) => {
  if (text === undefined || text === "") {
    return undefined;
  }

  return `search=${text}`;
}

export const useContractQuery = () => {

  const managementStateInitVal = 1;

  const contractKindPDInitVal = true;
  const contractKindProxyInitVal = true;
  const contractKindDirectInitVal = true; // 直接契約/直接支払い
  const contractKindDirectPdInitVal = true; // 直接契約/PD支払い

  const contractDateStartInitVal = null;
  const contractDateEndInitVal = null;

  const contractFirstStartDateStartInitVal = null;
  const contractFirstStartDateEndInitVal = null;
  const cancelledDateStartInitVal = null;
  const cancelledDateEndInitVal = null;
  const freeWordStateInitVal = undefined;

  const reset = () => {
    sessionStorage.removeItem('contractKindPD');
    sessionStorage.removeItem('contractKindProxy');
    sessionStorage.removeItem('contractKindDirect');
    sessionStorage.removeItem('contractKindDirectPd');
    sessionStorage.removeItem('managementState');
    sessionStorage.removeItem('contractDateStart');
    sessionStorage.removeItem('contractDateEnd');
    sessionStorage.removeItem('contractFirstStartDateStart');
    sessionStorage.removeItem('contractFirstStartDateEnd');
    sessionStorage.removeItem('cancelledDateStart');
    sessionStorage.removeItem('cancelledDateEnd');
    sessionStorage.removeItem('freeWord');
  
    setManagementState(managementStateInitVal);
    setContractKindPD(contractKindPDInitVal);
    setContractKindProxy(contractKindProxyInitVal);
    setContractKindDirect(contractKindDirectInitVal);
    setContractKindDirectPd(contractKindDirectPdInitVal);
    setContractDateStart(contractDateStartInitVal);
    setContractDateEnd(contractDateEndInitVal);
    setContractFirstStartDateStart(contractFirstStartDateStartInitVal);
    setContractFirstStartDateEnd(contractFirstStartDateEndInitVal);
    setCancelledDateStart(cancelledDateStartInitVal);
    setCancelledDateEnd(cancelledDateEndInitVal);
    setFreeWordState(freeWordStateInitVal);
  }

  const isChanged = () => {
    return !(managementState === managementStateInitVal &&
    contractKindPD === contractKindPDInitVal &&
    contractKindProxy === contractKindProxyInitVal &&
    contractKindDirect === contractKindDirectInitVal &&
    contractKindDirectPd === contractKindDirectPdInitVal &&
    contractDateStart === contractDateStartInitVal &&
    contractDateEnd === contractDateEndInitVal &&
    contractFirstStartDateStart === contractFirstStartDateStartInitVal &&
    contractFirstStartDateEnd === contractFirstStartDateEndInitVal &&
    cancelledDateStart === cancelledDateStartInitVal &&
    cancelledDateEnd === cancelledDateEndInitVal &&
    freeWordState === freeWordStateInitVal)
  }
  let tempContractKindPD = null
  if(sessionStorage.getItem('contractKindPD') == "true") {
    tempContractKindPD = true
  }else if(sessionStorage.getItem('contractKindPD') == "false"){
    tempContractKindPD = false
  }
  let tempContractKindProxy = null
  if(sessionStorage.getItem('contractKindProxy') == "true") {
    tempContractKindProxy = true
  }else if(sessionStorage.getItem('contractKindProxy') == "false"){
    tempContractKindProxy = false
  }
  let tempContractKindDirect = null
  if(sessionStorage.getItem('contractKindDirect') == "true") {
    tempContractKindDirect = true
  }else if(sessionStorage.getItem('contractKindDirect') == "false"){
    tempContractKindDirect = false
  }
  let tempContractKindDirectPd = null
  if(sessionStorage.getItem('contractKindDirectPd') == "true") {
    tempContractKindDirectPd = true
  }else if(sessionStorage.getItem('contractKindDirectPd') == "false"){
    tempContractKindDirectPd = false
  }
  const tempManagementState = sessionStorage.getItem('managementState');
  const tempContractDateStart = sessionStorage.getItem('contractDateStart') == "" ? null : sessionStorage.getItem('contractDateStart');
  const tempContractDateEnd = sessionStorage.getItem('contractDateEnd') == "" ? null : sessionStorage.getItem('contractDateEnd');
  const tempContractFirstStartDateStart = sessionStorage.getItem('contractFirstStartDateStart') == "" ? null : sessionStorage.getItem('contractFirstStartDateStart');
  const tempContractFirstStartDateEnd = sessionStorage.getItem('contractFirstStartDateEnd') == "" ? null : sessionStorage.getItem('contractFirstStartDateEnd');
  const tempCancelledDateStart = sessionStorage.getItem('cancelledDateStart') == "" ? null : sessionStorage.getItem('cancelledDateStart');
  const tempCancelledDateEnd = sessionStorage.getItem('cancelledDateEnd') == "" ? null : sessionStorage.getItem('cancelledDateEnd');

  const tempFreeWord = sessionStorage.getItem('freeWord');
  // 契約状態フィルタState
  const [managementState, setManagementState] = useState(tempManagementState == null ? managementStateInitVal:parseInt(tempManagementState)); // 0 => 両方, 1 => 契約中, 2 => 解約済み

  // 契約種別フィルタState
  const [contractKindPD, setContractKindPD] = useState(tempContractKindPD == null ? contractKindPDInitVal:tempContractKindPD );
  const [contractKindProxy, setContractKindProxy] = useState(tempContractKindProxy == null ? contractKindProxyInitVal:tempContractKindProxy );
  const [contractKindDirect, setContractKindDirect] = useState(tempContractKindDirect == null ? contractKindDirectInitVal:tempContractKindDirect );
  const [contractKindDirectPd, setContractKindDirectPd] = useState(tempContractKindDirectPd == null ? contractKindDirectPdInitVal:tempContractKindDirectPd );

  // 契約月フィルタState
  const [contractDateStart, setContractDateStart] = useState<Date | null>(tempContractDateStart == null ? contractDateStartInitVal: new Date(tempContractDateStart));
  const [contractDateEnd, setContractDateEnd] = useState<Date | null>(tempContractDateEnd == null ? contractDateEndInitVal: new Date(tempContractDateEnd));

  // 初回契約開始日フィルタState
  const [contractFirstStartDateStart, setContractFirstStartDateStart] =
    useState<Date | null>(tempContractFirstStartDateStart == null ? contractFirstStartDateStartInitVal: new Date(tempContractFirstStartDateStart));

  const [contractFirstStartDateEnd, setContractFirstStartDateEnd] = useState<
    Date | null
  >(tempContractFirstStartDateEnd == null ? contractFirstStartDateEndInitVal: new Date(tempContractFirstStartDateEnd));

  // 解約月フィルタState
  const [cancelledDateStart, setCancelledDateStart] = useState<Date | null>(
    tempCancelledDateStart == null ? cancelledDateStartInitVal: new Date(tempCancelledDateStart)
  );

  const [cancelledDateEnd, setCancelledDateEnd] = useState<Date | null>(tempCancelledDateEnd == null ? cancelledDateEndInitVal: new Date(tempCancelledDateEnd));

  // 契約状態フィルタState
  const managementStateQ = managementQueryBuild(managementState);
  const contractKindQ = contractKindQueryBuild(
    contractKindPD,
    contractKindProxy,
    contractKindDirect,
    contractKindDirectPd,
  );

  // フリーワード検索State
  const [freeWordState, setFreeWordState] = useState<string | undefined>(tempFreeWord == null ? undefined: tempFreeWord);

  const contractFirstStartDateQ = dateFilterGenerator(
    "contract_condition__first_start_date",
    contractFirstStartDateStart,
    contractFirstStartDateEnd
  );

  const contractDateQ = dateFilterGenerator(
    "contract_condition__contracted_date",
    contractDateStart,
    contractDateEnd
  );

  const cancelDateQ = dateFilterGenerator(
    "contract_condition__cancel_date",
    cancelledDateStart,
    cancelledDateEnd
  );

  const freeWordQ = freeWordSearch(freeWordState);

  const queries = [
    managementStateQ,
    contractKindQ,
    contractFirstStartDateQ,
    contractDateQ,
    cancelDateQ,
    freeWordQ
  ];


  if (queries.every((x) => x === undefined)) {
    setManagementState(tempManagementState == null ? 1 : parseInt(tempManagementState))
    return {
      q: "",
      setManagementState,
      setContractKindPD,
      setContractKindProxy,
      setContractKindDirect,
      setContractKindDirectPd,
      setContractDateStart,
      setContractDateEnd,
      setContractFirstStartDateStart,
      setContractFirstStartDateEnd,
      setCancelledDateStart,
      setCancelledDateEnd,
      setFreeWordState,
      managementState,
      contractKindPD,
      contractKindProxy,
      contractKindDirect,
      contractKindDirectPd,
      contractDateStart,
      contractDateEnd,
      contractFirstStartDateStart,
      contractFirstStartDateEnd,
      cancelledDateStart,
      cancelledDateEnd,
      freeWordState,
      reset,
      isChanged,
    };
  }

  return {
    q: `&${queries.filter((x) => x !== undefined).join("&")}`,
    setManagementState,
    setContractKindPD,
    setContractKindProxy,
    setContractKindDirect,
    setContractKindDirectPd,
    setContractDateStart,
    setContractDateEnd,
    setContractFirstStartDateStart,
    setContractFirstStartDateEnd,
    setCancelledDateStart,
    setCancelledDateEnd,
    setFreeWordState,
    managementState,
    contractKindPD,
    contractKindProxy,
    contractKindDirect,
    contractKindDirectPd,
    contractDateStart,
    contractDateEnd,
    contractFirstStartDateStart,
    contractFirstStartDateEnd,
    cancelledDateStart,
    cancelledDateEnd,
    freeWordState,
    reset,
    isChanged,
  };
};

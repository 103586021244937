import React, { useCallback, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from '@mui/material';

export type ErrorDialogProps = {
  errorEvent?: Event;
  dismiss?: () => void;
}

export const ErrorDialog: React.FC<ErrorDialogProps> = ({ errorEvent, dismiss, ...props }) => {
    const processErrorDetail = (detail: any): (string | number)[] => {
        let errors: (string | number)[] = [];

        if (Array.isArray(detail)) {
            detail.forEach(item => {
                errors = errors.concat(processErrorDetail(item));
            });
        } else if (typeof detail === 'object' && detail !== null) {
            Object.keys(detail).forEach(key => {
                errors = errors.concat(processErrorDetail(detail[key]));
            });
        } else if (typeof detail === 'string' || typeof detail === 'number') {
            errors.push(detail);
        }

        return errors;
    }

    const errorDetail = useMemo(() => {
        if (!errorEvent) return '';
        if (errorEvent instanceof CustomEvent && errorEvent.detail) {
            return processErrorDetail(errorEvent.detail).join('\n');
        }
        return '';
    }, [errorEvent]);

    const copyErrorToClipboard = useCallback(() => {
        navigator.clipboard.writeText(errorDetail).then(() => {
            alert('エラーの詳細をクリップボードにコピーしました。');
        }).catch(console.error);
    }, [errorDetail]);

    return (
        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={!!errorEvent}
            onClose={dismiss}
            {...props}>
            <DialogTitle id="alert-dialog-title">エラーが発生しました</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }}>
                        予期しないエラーが発生しました。エラーの詳細については、管理者にお問い合わせください。
                        {errorDetail && `\n\n${errorDetail}`}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={copyErrorToClipboard}>
                    エラーをコピー
                </Button>
                <Button onClick={dismiss} autoFocus>
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    );
}